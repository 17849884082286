import { problemActionTypes as types } from 'constants/problem.action.types';
  
const initialState = (window.Cypress && window.initialState && window.initialState.problemReducer) || {};

export function problemReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_PROBLEMS_REQUEST:
    case types.GET_PROBLEM_REQUEST:
    case types.CREATE_PROBLEM_REQUEST:
    case types.UPDATE_PROBLEM_REQUEST:
    case types.DELETE_PROBLEM_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.LIST_PROBLEMS_SUCCESS:
      return {
        problems: payload
    };
    case types.GET_PROBLEM_SUCCESS:
    case types.CREATE_PROBLEM_SUCCESS:
    case types.UPDATE_PROBLEM_SUCCESS:
      return {
        problem: payload
    };
    case types.DELETE_PROBLEM_SUCCESS:
      return {
          ...payload
      };
    case types.LIST_PROBLEMS_FAILURE:
    case types.GET_PROBLEM_FAILURE:
    case types.CREATE_PROBLEM_FAILURE:
    case types.UPDATE_PROBLEM_FAILURE:
    case types.DELETE_PROBLEM_FAILURE:
    default:
      return state;
  }
}
  
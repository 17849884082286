import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from 'prop-types'

import Button from "components/Button"

import Input from 'components/InputElement'
import Taglist from "components/Taglist/Taglist"
import SimpleGroupHover from "components/SimpleGroupHover"

import { createProfile } from "actions/profile.create.actions";
import { updateUserProfile } from "actions/profile.update.actions";
import { getUserProfile } from "actions/profile.get.actions"
import MultiSelectBoxMultiLevel from "components/MultiSelectBoxMultiLevel"
import { updateCheckitemState } from "utils/update-checkitem-state"
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list"

const filterValueChains = (valueChainListData, valueChainSelected, newIndustryList) => {
    if (valueChainListData?.length) {
        const oldIndustryList = valueChainListData
                        .map(item => updateCheckitemState(item, valueChainSelected))
                        .filter(item => item?.checked)
                        .map(item => item?.industry)
        const filteredList = valueChainListData
                            .filter(parent => oldIndustryList.concat(newIndustryList).includes(parent.industry))
                            .map(parent => {
                                return {
                                    ...parent,
                                    checked: valueChainSelected.includes(parent.id),
                                    children: parent.children?.map(child => ({
                                        ...child,
                                        checked: valueChainSelected.includes(child.id)
                                    })) || [],
                                };
                });

        return filteredList;
    } else {
        return [];
    }
}

export { filterValueChains }; 

/**
 * 
 * @param {*} param0 
 * @returns Editable Userform 
 */
function ProfileForm({
    isNewProfile,
    data,
    segmentListData,
    valueChainListData,
    createProfile,
    updateUserProfile,
    getUserProfile,
    setEditActive
}) {
    const { t } = useTranslation(['user', 'foren',]);
    const text = {
        title: t('header', 'Create Profile'),
        firstName: t('name1', 'First Name'),
        lastName: t('name2', 'Last Name'),
        username: t('', 'Pseudonym'),
        usernameInfo: t('', `If you check this box, your information will be hidden and other users will only see your pseudonym`),
        organisation: t('orga', 'Organisation'),
        position: t('pos', 'Position'),
        segment: t('area1', 'Areas of interest by market segment'),
        valueChain: t('area2', 'Area of interest by value chain'),
        interestTags: t('interests', 'Interests'),
        buttonInfo: t('u-btnInf', 'Please fill out all required fields')
    }

    if (isNewProfile) {
        data = {
            firstName: '',
            lastName: '',
            username: '',
            organisation: '',
            position: '',
            interestTags: [],
            segmentSelected: [],
            valueChainSelected: [],
        }
    }
    // Internal Lists of data + 'checked' value
    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([])
    const [valueChainList, setValueChainList] = useState([])
    const [filteredValueChainList, setFilteredValueChainList] = useState([])

    const [formData, setFormData] = useState({
        firstName: data ? data.firstName : null,
        lastName: data ? data.lastName : null,
        username: data ? data.username : null,
        organisation: data ? data.organisation : null,
        position: data ? data.position : null,
        interestTags: data ? data.interestTags : [],
        segmentSelected: data ? data.segmentSelected : [],
        valueChainSelected: data ? data.valueChainSelected : [],
    });

    const {
        firstName,
        lastName,
        // username,
        organisation,
        position,
        interestTags,
        segmentSelected,
        valueChainSelected
    } = formData;

    const [isValid, setIsValid] = useState({
        firstName: data?.firstName?.length > 0,
        lastName: data?.lastName?.length > 0,
    })

    const [formValid, setFormValid] = useState({})

    // // initial loading for valueChain
    useEffect(() => {
        setFilteredValueChainList(valueChainListData.map(item => ({
            ...item,
            checked: false,
            children: item.children?.map(child => ({ ...child, checked: false })) || [],
        })));
    }, [valueChainListData]);

    useEffect(() => {
        if (isValid.firstName && isValid.lastName) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [isValid]);

    // updates internal segmentList with the checked objects
    useEffect(() => {
        const newSegmentList = segmentListData.map(item => updateCheckitemState(item, segmentSelected));
        setSegmentList(newSegmentList);
        setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry))
    }, [segmentListData, segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains 
    useEffect(() => {
        let filteredList = filterValueChains(valueChainListData, valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, valueChainSelected));
        setValueChainList(newValueList);
    }, [valueChainListData, valueChainSelected, industryList]);


    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'firstName' || e.target.name === 'lastName') {
            setIsValid({ ...isValid, [e.target.name]: e.target.value?.length > 0 })
        }
    }

    // updates formData with new Checked id 
    const changeSegmentList = (item, checked) => {
        setFormData({ ...formData, segmentSelected: changeIdList(segmentSelected, segmentListData, item, checked) });
    };

    const changeValueChain = (item, checked) => {
        setFormData({ ...formData, valueChainSelected: changeIdList(valueChainSelected, filteredValueChainList, item, checked) });
    };

    const updateTags = (Taglist) => {
        setFormData({ ...formData, interestTags: Taglist });
    }

    const onCreate = (e) => {
        e.preventDefault();
        createProfile(formData).then(() => {
            getUserProfile()
        })
        setEditActive(false);
    };

    const onSave = (e) => {
        e.preventDefault();
        updateUserProfile(formData)
        setEditActive(false);
    };

    return (
        <>
            <div id="profileForm" className="h-full space-y-5 relative top-2 ml-1">
                <div className="flex flex-col md:flex-row mt-4 md:space-x-2 justify-between">
                    <Input
                        id={'firstName'}
                        label={text.firstName}
                        value={firstName}
                        onChange={onChange}
                        required
                    />
                    <Input
                        id={'lastName'}
                        label={`${text.lastName}`}
                        value={lastName}
                        onChange={onChange}
                        required
                    />
                </div>
                {/* <Input
                        id={'pseudonym'}
                        tooltip={text.usernameInfo}
                        label={text.username}
                        value={username}
                        onChange={onChange}
                    /> */}
                <div className="border-y border-gray-200 pt-2 pb-4 space-y-4">
                    <Input
                        id={'organisation'}
                        label={text.organisation}
                        value={organisation}
                        onChange={onChange}
                    />
                    <Input
                        id={'position'}
                        label={text.position}
                        value={position}
                        onChange={onChange}
                    />
                </div>
                <Input
                    id={'interestsSegment'}
                    label={text.segment}
                    other={true}>
                    <MultiSelectBoxMultiLevel
                        list={segmentList}
                        updateList={changeSegmentList}
                        name={'interestsSegment'}
                    />
                </Input>
                <Input
                    id={'interestsValueChain'}
                    label={text.valueChain}
                    other={true}>
                    <MultiSelectBoxMultiLevel
                        list={valueChainList}
                        updateList={changeValueChain}
                        name={'interestsValueChain'}
                    />
                </Input>
                <div className="pb-32">
                    <Input
                        id={'tagInput'}
                        label={text.interestTags}
                        other={true}>
                        <Taglist
                            value={interestTags}
                            onChange={updateTags}
                        />
                    </Input>
                </div>
            </div>
            <div className="fixed py-2 bg-white bottom-6 w-full pr-12 lg:pr-0 lg:w-2/3 xl:w-2/3">
                <div className="float-right" id="profileSaveBar">
                    <SimpleGroupHover
                        active={!formValid}
                        text={text.buttonInfo}
                        position={'bottom-11 right-0'}
                        overwritePosition={true}
                        color={'bg-red-200 text-red-800 border-red-500'}
                        className={'whitespace-nowrap'}
                    >
                        <Button
                            id="profileSaveButton"
                            onClick={isNewProfile ? onCreate : onSave}
                            disabled={!formValid}>
                            {isNewProfile ? text.title : t('Save', { ns: 'common' })}
                        </Button>
                    </SimpleGroupHover>
                </div>
            </div>
        </>
    );
}

ProfileForm.propTypes = {
    createProfile: PropTypes.func.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    getUserProfile: PropTypes.func.isRequired
};


export default connect(null, { createProfile, updateUserProfile, getUserProfile })(ProfileForm);
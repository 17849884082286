import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTree, faDiamondTurnRight, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

import Button from "components/Button";
import Card from "components/Card";
import CardWrapper from 'components/CardWrapper';
import CardGrid from "components/CardGrid";
import FormContainer from "components/FormContainer";

import minimalTreeImg from './Images/MinimalTree.png';
import externalDocumentImg from './Images/TreeExternalDocumentDrawn.png';
import teeTutorialImg from './Images/TreeTutorial.png'

// TODO Translate
/**
 * Starting page for 'Problem Structure'
 * Empty state: Displays three cards for 'Redirect through guided structuring process', 'Start structuring directly', 'Link or Upload your structure'
 * Item State: Displays cards of problem structures 
 */
function ProblemStructureTab() {
    // TODO get real info for empty state
    const [noStructures, setNoStructures] = useState(false);
    const { t } = useTranslation();
    const iconClass = "w-10 h-10 mx-auto"
    const text = {
        card1Title: t('Redirect through structuring process'),
        card1Text: t(`Create a structure with the help of our information.`),
        card2Title: t('Start structuring directly'),
        card2Text: t('Start structuring with our integrated structuring tool'),
        card3Title: t('Link or Upload your structure'),
        card3Text: t('Use a piece of paper, a collaborative tool, a flipchart or a PPT document.'),
        card1Icon: <FontAwesomeIcon icon={faDiamondTurnRight} className={iconClass} />,
        card2Icon: <FontAwesomeIcon icon={faTree} className={iconClass} />,
        card3Icon: <FontAwesomeIcon icon={faLink} className={iconClass} />,
    }

    const { projectId } = ':id' //TODO get real id
    const { problemId } = ':id' //TODO get real id

    const cardClassName = 'hover:bg-primary-100 pt-8 h-96';

    return (
        <div id="problemStructureTab" className="space-y-4">
            <FormContainer width='w-full'>
                {noStructures
                    ?
                    // Empty State
                    <div className="">
                        <CardGrid gridCol="grid-cols-1 sm:grid-cols-3">
                            <Link to={`/projects/${projectId}/problems/${problemId}/newProblemStructureGuided`}>
                                <Card id='card-startStructuredGuide'
                                    className={cardClassName} justify="justify-top" title={text.card1Title} text={text.card1Text} icon={text.card1Icon}>
                                    <img className="pt-8" src={teeTutorialImg} />
                                </Card>
                            </Link>
                            <Card id='card-createTree'
                                className={cardClassName} justify="justify-top" title={text.card2Title} text={text.card2Text} icon={text.card2Icon}>
                                <img className="pt-8" src={minimalTreeImg} />
                            </Card>
                            <Card id='card-uploadStructure'
                                className={cardClassName} justify="justify-top" title={text.card3Title} text={text.card3Text} icon={text.card3Icon}>
                                <img className="pt-8" src={externalDocumentImg} />
                            </Card>
                        </CardGrid>
                    </div>
                    :
                    // Displays Buttons if other structures already exist
                    <div id="problemStructureTabButtons"
                        className="relative space-x-4" style={{ paddingTop: '2px' }}>
                        <Link to='/projects/:id/problems/:id/newProblemStructureGuided'>
                            <Button id='btn-createStructure'>{t('Create Structure')}</Button>
                        </Link>
                        <Link to='/projects/:id/problems/:id/createTree'>
                            <Button id='btn-quickStart'>{t('Quick Start')}</Button>
                        </Link>
                        {/* TODO function */}
                        <Button id='btn-upload'>{t('Upload Structure')}</Button>
                        {/* TODO insert cards of wip and finished structures */}
                        <div className="pt-8">
                            <CardGrid>
                                <CardWrapper sideBar={true} sideColor={'bg-yellow-400'}>
                                    [Placeholder -- Card with structure]
                                </CardWrapper>
                                <CardWrapper sideBar={true} sideColor={'bg-yellow-400'}>
                                    [Placeholder -- Card with structure]
                                </CardWrapper>
                            </CardGrid>
                        </div>
                    </div>
                }
            </FormContainer>

            {/* TODO DELETE LATER */}
            <Button className={'absolute bottom-4'} onClick={() => setNoStructures(!noStructures)}>-- switch --</Button>
        </div>
    );
}

export default ProblemStructureTab;
import { useContext } from 'react';
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";

import CriticalDefinitionsRow from "./CriticalDefinitionsRow";
import CreateProblemContext from '../context/ProblemContext';

function CriticalDefinitionsTable(props) {
    const { t } = useTranslation();
    const text = {
        tableHeader: [
            t('1-cd-table1', "Term"),
            t('1-cd-table2', "Meaning"),
            t('1-cd-table3', "Comment"),
            ""],
    }

    const ctx = useContext(CreateProblemContext)

    const newRowHandler = () => {
        ctx.newRowHandler('criticalDefinitions');
    }

    const tableData = ctx.data.criticalDefinitions;

    return (
        <EditableTable editActive={props.editActive} tableHeader={text.tableHeader} addRow={newRowHandler}>
            {tableData && tableData.map((item, index) => (
                <CriticalDefinitionsRow
                    editActive={props.editActive}
                    key={item.id}
                    index={index}
                />
            ))}
        </EditableTable>
    );
}

export default CriticalDefinitionsTable;
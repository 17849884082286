import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";
import EditableStatTile from './EditableStatTile';
import { XIcon } from "@heroicons/react/outline";
import { useState } from 'react';

function MetricStatGrid({ data, 
    changeDescriptionHandler, changeBaseHandler, changeChangeHandler, changEstimateHandler, changeUnitHandler, changeCompanyPercentageHandler, changeCompanyGoalHandler,
    editActive, deleteHandler, deleteActive,
    headerLabel }) {
    const { t } = useTranslation();
    const [basePercentage, setBasePercentage] = useState(data.baseline != 0 ? 100 : 0)

    const calcPercentage = (base, value) => {
        let percentage = 0;
        if (parseInt(base) === 0) {
            percentage = 100;
        } else {
            percentage = Math.round(value / base * 100);
        }
        return percentage;
    }

    const handleBaselineChange = (e) => {
        if (e.target.value === (null || undefined || NaN || 0 || '0')) {
            setBasePercentage(0)
        } else {
            setBasePercentage(100)
        }
        changeBaseHandler(e);
    }

    const stats =
        [
            { name: 'Baseline', value: data.baseline, percentage: basePercentage, onChange: handleBaselineChange, },
            { name: 'Change', value: data.change, percentage: calcPercentage(data.baseline, data.change), onChange: changeChangeHandler,},
            { name: 'Estimate', value: data.estimate, percentage: calcPercentage(data.baseline, data.estimate), onChange: changEstimateHandler,},
        ]
        || [{ name: 'empty', value: '0', percentage: '0%' }];

    return (
        <div className=" mt-1 mb-2 rounded-lg bg-white shadow border">
            <div className="flex w-full justify-between">
                {/* Description */}
                {editActive ?
                    <TextareaAutosize
                        onChange={changeDescriptionHandler}
                        className='w-1/2 resize-none ml-1 p-1 border-0 focus:border-1 focus:bg-primary-100'
                        value={data.description || ''}
                        placeholder={t('1-pm-label1', "Denomination and Date")}>
                    </TextareaAutosize>
                    :
                    <label className="pl-2 text-gray-500">{data.description || '...'}</label>
                }
                <h3 className="text-base font-small mt-1 text-gray-400 mr-2">{headerLabel}</h3>
                {deleteActive && <XIcon className='w-5 h-5 mr-2 cursor-pointer m-auto group-hover:text-primary-600 text-gray-500 transform group-hover:scale-125'
                    onClick={deleteHandler} />}
            </div>
            <div className={`mt-1 grid grid-cols-2  
                2xl:grid-cols-4 
                overflow-hidden divide-x divide-gray-200`}>
                {/* Stats*/}
                {stats.map((item) => (
                    <EditableStatTile
                        key={item.name}
                        editActive={editActive}
                        name={item.name}
                        type="number"
                        value={item.value}
                        unit={data.unit}
                        percentage={item.percentage}
                        readOnly={item.readOnly}
                        onChange={item.onChange}
                        onUnitChange={changeUnitHandler}/>
                ))}
                {/* Company Goal */}
                <EditableStatTile
                    editActive={editActive}
                    name={"Company Goal"}
                    type="text"
                    value={data.companyGoal}
                    percentage={data.companyGoalPercent}
                    badgeColor={"bg-blue-100 text-blue-800"}
                    onChange={changeCompanyGoalHandler}
                    percentageEditable="true"
                    onPercentageChange={changeCompanyPercentageHandler} />
            </div>
        </div>
    );
}

export default MetricStatGrid;
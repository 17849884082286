import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/solid";

import CollapsableDiv from "components/CollapsableDiv";
import Button from "components/Button";
import Input from "components/InputElement";
import MetricStatGrid from "components/MetricStatGrid";

import CreateIssueContext from "../context/create-issue-context";
import ProofPointTable from "./ProofPointTable";

/**
 * Subform for the Issue Resolution Hypothesis
 * The Subform is needed for different displays in the form and in the overview
 */
function HypothesisSubForm({ overview }) {
    const { t } = useTranslation();
    const ctx = useContext(CreateIssueContext);
    const data = ctx.data.resolutionHypothesis || [{}];
    const resolutionCount = ctx.data.resolutionHypothesis?.length;
    const thisName = 'resolutionHypothesis';
    const text = {
        headline: t('1-rh-headline', "Sketch out one or competing resolution hypotheses and the required proof points based on what you learnt so far."),
        label1: t('3-rh-l1', 'Hypothesis'),
        hypoTips: [
            {
                title: t('3-rh-tip1t', 'Preliminary'),
                description: t('3-rh-tip1d', 'Your hypothesis might not stand after being tested – write it down nonetheless to chart a workpath for your team'),
            },
            {
                title: t('3-rh-tip2t', 'Impactful and plausible '),
                description: t('3-rh-tip2d', 'From the start, try to think from the end – could this hypothesis solve your problem and find acceptance?'),
            },
            {
                title: t('3-rh-tip3t', 'Testable'),
                description: t('3-rh-tip3d', 'Your hypothesis should be able to stand the test of experiments or other verification methods '),
            },
            {
                title: t('3-rh-tip4t', 'Clear and concise '),
                description: t('3-rh-tip4d', 'To facilitate testing, be as precise as possible with the language you use '),
            },
            {
                title: t('3-rh-tip5t', 'Action-oriented '),
                description: t('3-rh-tip5d', 'Your hypothesis should later lead to action, after being validated '),
            },
        ],
        hypoInfo: t('3-rh-info1', 'E.g., “We should replace the current heating with a pellet-powered heating system while at the same time setting up golden rules for heating behaviour”'),
        label2: t('3-rh-l2', 'Hypothesis Metrics'),
        metricTips: [
            {
                title: t('3-rh-tip6t', 'Targeted'),
                description: t('3-rh-tip6d', 'The contribution should be linked to the company goal(s) and one or more core quantitative Key Result(s)'),
            },
            {
                title: t('3-rh-tip7t', 'Plausible, pragmatic '),
                description: t('3-rh-tip7d', 'Impact estimation should be credible and reliable, but you should invest only reasonable effort – you can define analyses to solidify the numbers in the next step'),
            },
            {
                title: t('3-rh-tip8t', 'Link to Baseline '),
                description: t('3-rh-tip8d', 'The contribution should be mapped against the baseline set in the problem definition '),
            },
        ],
        metricInfo: t('3-rh-info2', 'Depending on your hypothesis, adjust the numbers you did work with when structuring the problem – those numbers can still be indicative at this stage and should be validated by the analyses you can define in this module '),
        label3: t('3-rh-l3', 'Rationale'),
        rationaleInfo: t('3-rh-info3', 'You can use the quality criteria that will become relevant later during the approach development – see Smarttips, e.g.: “Introduce a standard software core system, thereby reducing yearly IT costs by 30% and significantly improve UX for users. Ease of implementation will be hard given the need for system adaptations”'),
        rationaleTips: [
            {
                title: t('3-rh-tip9t', 'Target group'),
                description: t('3-rh-tip9d', 'Name the target segments you want to create value for, e.g., customers, colleagues, taxpayers, the environment, …'),
            },
            {
                title: t('3-rh-tip10t', 'Contributing '),
                description: t('3-rh-tip10d', 'Ensure direct or indirect contribution to the overall goals of your organization and to the Key Results defined in the problem definition'),
            },
            {
                title: t('3-rh-tip11t', 'Contribution logic'),
                description: t('3-rh-tip11d', 'State through which courses of action your approach will generate added value'),
            },
            {
                title: t('3-rh-tip12t', 'Ease of Implementation'),
                description: t('3-rh-tip12d', 'Explain the reason behind your EoI choice'),
            },
        ],
        label4: t('3-rh-label4', 'Proof points'),
        proofInfo: t('3-rh-info4', `Use this as an efficient and simple, management-like way to structure the work of your team. You can link this to a workflow application (e.g., jira) to include much more detail`),
        proofTips: [
            {
                title: t('3-rh-tip13t', 'Targeted'),
                description: t('3-rh-tip13d', 'Stay focused: No analysis without hypothesis, so make sure the analysis and the numbers you wrote down are at the center of your analyses'),
            },
            {
                title: t('3-rh-tip14t', 'Prioritized'),
                description: t('3-rh-tip14d', 'Start with the most important analyses that help you shed light on the plausibility of the hypothesis. Move into detail only gradually'),
            },
            {
                title: t('3-rh-tip15t', 'Methodically adequate'),
                description: t('3-rh-tip15d', 'Spend some time to select the right method for your analysis – sometimes pragmatic and quick heuristics can shed a lot of light, especially in the beginning of the process'),
            },
            {
                title: t('3-rh-tip16t', 'Planned and linked in'),
                description: t('3-rh-tip16d', 'Structure your team’s work around the analyses – assign clear deadlines and responsibilities. Link the workpieces to tools you use, e.g., jira or miro using the “app” function'),
            },
            {
                title: t('3-rh-tip17t', 'Interpreted and translated'),
                description: t('3-rh-tip17d', 'Review, challenge and interpret the methods and results using different lenses, e.g., from a crossfunctional team. Translate into compelling arguments'),
            },
            {
                title: t('3-rh-tip18t', 'Iterated'),
                description: t('3-rh-tip18d', 'Define more detailed analyses, potentially using different methods following your review of the results until you can falsify or validate the hypothesis'),
            },
        ],
    };
    const [collapsed, setCollapsed] = useState([false]);

    const changeHandler = (e, name, index) => {
        ctx.resolutionHypothesisHandler(thisName, name, index, e);
    }

    const handleCollapse = (index) => {
        let temp = [...collapsed];
        temp[index] = !temp[index];
        setCollapsed(temp);
    }

    const deleteHandler = (id, index) => {
        ctx.deleteRowHandler(thisName, id);
        let temp = [...collapsed];
        temp.splice(index, 1);
        temp[temp.length - 1] = false;
        setCollapsed(temp);
    }

    return (
        <div id="hypothesisSubForm"
            className={`mb-8 ${overview && 'border-r-4 border-primary-600'}`}>
            {data && data.map((hypothesis, index) => (
                <CollapsableDiv
                    key={index}
                    className={`${overview && 'mr-1 border-r-4 border-primary-400'}
                    ${(overview && collapsed[index]) ? 'mb-2' : 'mb-8'}`}
                    header={text.label1 + " " +  (index + 1)}
                    collapsed={collapsed[index]}
                    onClick={() => { handleCollapse(index) }}>
                    {/* Input Hypothese */}
                    <Input
                        id={'hypothese' + index}
                        label={text.label1}
                        tooltip={text.hypoInfo}
                        tips={text.hypoTips}
                        textarea={true}
                        value={hypothesis.name}
                        onChange={(e) => changeHandler(e, 'name', index)}
                        overview={overview}
                    />
                    {/* Metric Grid // Zahlengeruest der Hypothese */}
                    <Input
                        id={'metrics' + index}
                        label={text.label2}
                        tooltip={text.metricInfo}
                        tips={text.metricTips}
                        other={true}
                        overview={overview}>
                        {/* Metrics GRID */}
                        {hypothesis.coreMetrics.map((metric, metricIndex) => (
                            <MetricStatGrid
                                key={metricIndex}
                                data={metric}
                                editActive={true}
                                statNames={['Baseline', 'Change', 'Estimate']}
                                changeBaseHandler={(e) => ctx.coremetricHandler(index, metricIndex, "baseline", e)}
                                changeChangeHandler={(e) => ctx.coremetricHandler(index, metricIndex, "change", e)}
                                changEstimateHandler={(e) => ctx.coremetricHandler(index, metricIndex, "estimate", e)}
                                changeUnitHandler={(e) => ctx.coremetricUnitHandler(index, metricIndex, e)}
                                changeCompanyGoalHandler={(e) => ctx.coremetricHandler(index, metricIndex, "companyGoal", e)}
                                changeCompanyPercentageHandler={(e) => ctx.coremetricHandler(index, metricIndex, "companyGoalPercent", e)}
                                changeDescriptionHandler={(e) => ctx.coremetricHandler(index, metricIndex, "description", e)}
                                deleteHandler={() => ctx.deleteCoreMetricRowHandler(index, metric.id)}
                                deleteActive={hypothesis.coreMetrics?.length > 1}
                            />
                        ))}
                        {hypothesis.coreMetrics?.length < 3 &&
                            <div className="h-11 mt-1  hover:bg-primary-100 border border-grey-200 rounded-lg ">
                                <div className='cursor-pointer'
                                    onClick={() => ctx.newMetricsRowHandler(index)}>
                                    <PlusIcon className='w-7 h-11 m-auto text-gray-500' />
                                </div>
                            </div>
                        }
                    </Input>
                    {/* Input rationale for numbers // Erklaerung */}
                    <Input
                        id={'rationale' + index}
                        label={text.label3}
                        tooltip={text.rationaleInfo}
                        tips={text.rationaleTips}
                        value={hypothesis.rationale}
                        textarea={true}
                        onChange={(e) => changeHandler(e, 'rationale', index)}
                        overview={overview}
                    />
                    {/* Proof Point Table // Analysen zur Validierung */}
                    <Input
                        id={'proofPointTable' + index}
                        label={text.label4}
                        tooltip={text.proofInfo}
                        tips={text.proofTips}
                        other={true}
                        overview={overview}>
                        <ProofPointTable
                            id={hypothesis.id}
                            index={index}
                            editActive={true} />
                    </Input>
                    {/* DELETE Button */}
                    {resolutionCount > 1 &&
                        <Button
                            onClick={() => { deleteHandler(hypothesis.id, index) }}
                            className="bottom-5 right-2"
                            bg={'bg-gray-200'}
                            text={"text-gray-600 hover:text-white"}>
                            {t('del', 'Delete')}
                        </Button>}
                </CollapsableDiv>
            ))}
        </div>
    );
}

export default HypothesisSubForm;
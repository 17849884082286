import PropTypes from 'prop-types';
import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import PopUp from 'components/PopUp'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";

/**
 * Displays PopUp of ALerts since last logout
 */
//TODO translation
function AlertPopup({ profile, communities }) {
  const { t } = useTranslation(['common'])
  const [open, setOpen] = useState(false)
  const [newNotificationCount, setNewNotificationCount] = useState()

  // TODO change the way different types of notifications are pulled and sorted into information
  useEffect(() => {
    if (communities?.length > 0) {
      let totalNumber = communities.reduce((sum, { alertsCount }) => sum + alertsCount, 0)
      setNewNotificationCount(totalNumber)
    }
  }, [communities])

  useEffect(() => {
    if (newNotificationCount > 0) {
      let canBeOpen = localStorage.getItem("openWelcomePopUp")
      if (canBeOpen == null) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    } else {
      setOpen(false)
    }
  }, [newNotificationCount, localStorage.getItem("openWelcomePopUp")])

  return (
    <PopUp open={open}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center">
          <FontAwesomeIcon icon={faBell} className="h-6 w-6 text-primary-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            {`${t("not-modal1", "Welcome")} ${profile.firstName} ${profile.lastName}`}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-800">
              {t("not-modal2", "You've got")}
              <span className='text-primary-800 font-bold'> {newNotificationCount} </span>
              {t("not-modal3", "unread Notifications")}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => { setOpen(false); localStorage.setItem("openWelcomePopUp", false) }}>
          {t("Go to dashboard")}
        </button>
      </div>
    </PopUp>
  )
}

AlertPopup.propTypes = {
  profile: PropTypes.object.isRequired,
  communities: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  profile: state.profileReducer.profile,
  communities: state.communityReducer.communities
});

export default connect(mapStateToProps, {})(AlertPopup);
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";
import { PlusIcon } from '@heroicons/react/outline';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';

import HeaderText from 'components/HeaderText';

import CardGrid from 'components/CardGrid';
import ModalNewEntity from 'components/Modal';
import EmptyCardFrame from 'components/EmptyCardFrame';
import EmptyState from 'components/EmptyStateCreateNew';

import { createProject } from 'actions/project.create.actions';
import { updateProjectById } from 'actions/project.update.actions';
import { updateVoteById } from 'actions/voting.update.actions';
import { listUserProjects } from 'actions/projects.list.actions';
import { parseItem } from 'utils/session-storage';

import ProjectCard from './ProjectCard';
import LinkList from 'components/LinkList';

function ProjectsList({
    profile,
    projects,
    createProject,
    updateProjectById,
    updateVoteById,
    listUserProjects
}) {
    const { t } = useTranslation(['foren']);
    const text = {
        button: t('fo-nt', 'New Cluster'),
        labelTitle: t('fo-project-t', 'Cluster Title'),
        labelDesc: t('fo-project-desc', 'Description'),
        labelLinks: t('', 'Links'),
        headlineEmpty: "No Cluster",
        sublineEmpty: t('fo-start', "Get started by creating a new project."),
        createNewHover: t('fo-createNew', 'Create a new Cluster'),
        pageTitle: t('fo-pageTitleCluster', 'Content Clusters'),
        pageDesc: t('fo-pageDescCluster', 'Content Clusters are predefined by your convener to provide a high-level structure.')
    }

    const [organisationRole, setOrganisationRole] = useState(null);
    const [communityRole, setCommunityRole] = useState(null);
    const [canCreateCluster, setCanCreateCluster] = useState(false);
    const [editingProject, setEditingProject] = useState(null);
    const [urlInputActive, setUrlInputActive] = useState(false);
    const [modalValid, setModalValid] = useState(null);
    const [projectsSorted, setProjectsSorted] = useState([]);
    const linkListRef = useRef();

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        links: []
    });

    const { title, description, links } = formData;

    // validation for modal save button 
    useEffect(() => {
        if (editingProject) {
            setModalValid(editingProject.title?.length)
        } else {
            setModalValid(title.length > 0)
        }
    }, [formData, editingProject])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const changeLinkList = (list) => {
        setFormData({ ...formData, links: list })
        if (editingProject) {
            setEditingProject({ ...editingProject, links: list })
        }
    }

    const handleUrlActive = (state) => {
        setUrlInputActive(state)
    }

    const onEditChange = (e) =>
        setEditingProject({ ...editingProject, [e.target.name]: e.target.value })

    const createNewProject = (e) => {
        e.preventDefault()
        createProject(title, description, links).then(() => {
            listUserProjects()
        })
    }

    const handleEdit = (project) => {
        setEditingProject(project);
    };

    const handleEditProject = (e) => {
        e.preventDefault();
        updateProjectById(
            editingProject.id,
            {
                title: editingProject.title,
                desc: editingProject.desc,
                links: editingProject.links
            }
        ).then(() => {
            listUserProjects()
        });
        setEditingProject(null);
    };

    const abortEditProject = () => {
        setEditingProject(false)
        linkListRef.current.resetList();
    }

    const abortNewProject = () => {
        linkListRef.current.resetList();
    }

    // organisation role
    useEffect(() => {        
        if (parseItem('organisation')?.role) {
            setOrganisationRole(parseItem('organisation').role)
          }
    }, [sessionStorage.getItem('organisation')]);

    // community role
    useEffect(() => {
        if (parseItem('community')?.role) {
            setCommunityRole(parseItem('community').role);
        } 
    
    }, [sessionStorage.getItem('community')]);


    // flag whether user can create a cluster
    useEffect(() => {
        setCanCreateCluster(false)
        if (communityRole === 'OWNER' || communityRole === 'ADMIN') {
            setCanCreateCluster(true)
        }
        
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN') {
            setCanCreateCluster(true)
        }
    }, [communityRole, organisationRole]);

    useEffect(() => {
        setProjectsSorted(projects)
    }, [projects]);

    const upVote = (project) => {
        const userVote = project.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote + 1 <= 1 ) {
            updateVoteById(project.id, userVote + 1).then(() => {
                listUserProjects()
            })
        }
    }

    const downVote = (project) => {
        const userVote = project.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote - 1 >= -1 ) {
            updateVoteById(project.id, userVote - 1).then(() => {
                listUserProjects()
            })
        }
    }

    return (
        <div id='projectsListPage'>
            <ModalNewEntity
                id='modalNewProject'
                title={editingProject ? 'Edit Project' : text.button}
                onSave={editingProject ? handleEditProject : createNewProject}
                saveDisabled={urlInputActive || !modalValid}
                onClose={editingProject ? abortEditProject : abortNewProject}>
                <label className='block text-base font-medium text-gray-700'>
                    {text.labelTitle}
                </label>
                <input
                    id='title'
                    type='text'
                    name='title'
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={editingProject ? editingProject.title : title}
                    onChange={editingProject ? onEditChange : onChange}
                    required
                />
                <label className='block text-base font-medium text-gray-700 mt-4'>
                    {text.labelDesc}
                </label>
                <TextareaAutosize
                    id='description'
                    type="text"
                    name={editingProject ? 'desc' : 'description'}
                    minRows="3"
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={editingProject ? editingProject.desc : description}
                    onChange={editingProject ? onEditChange : onChange}
                />
                {/* Adding Links to Projects */}
                <label className='block text-base font-medium text-gray-700 mt-4'>
                    {text.labelLinks}
                </label>
                <LinkList
                    id='clusterLinks'
                    ref={linkListRef}
                    changeList={changeLinkList}
                    list={editingProject && editingProject.links}
                    editMode
                    urlInputActive={handleUrlActive}
                />
            </ModalNewEntity>
            <div className='space-y-4'>
                {/* Project Grid */}
                {projectsSorted?.length > 0 ? (
                    <CardGrid className={'items-start'}>
                        {projectsSorted.map((project, index) => (
                            <ProjectCard
                                key={index}
                                project={project}
                                userVotes={project.userVotes}
                                count={project.userVotes.reduce((acc, obj) => (acc + obj.vote), 0)}
                                showVoting={project.id === '00000000-0000-0000-0000-000000000000' ? false : true}
                                upVote={() => upVote(project)}
                                downVote={() => downVote(project)}
                                upVoteClicked={project.userVotes?.find(obj => obj.userId === profile.userId)?.vote === 1}
                                downVoteClicked={project.userVotes?.find(obj => obj.userId === profile.userId)?.vote === -1}
                                handleEdit={() => handleEdit(project)}
                                databstoggle="modal"
                                databstarget="#modalNewProject"
                            />
                        ))}
                        {/* Card for creating new Projects */}
                        {canCreateCluster &&
                            <EmptyCardFrame
                                id='newProjectCard'
                                className={'transition-all h-full items-center justify-center cursor-pointer border-dashed group hover:border-primary-700 hover:bg-primary-50'}
                                databstoggle="modal"
                                databstarget="#modalNewProject">
                                <p className='inline-flex items-center transition-all scale-100 text-gray-500 group-hover:scale-110 group-hover:text-black my-9'>
                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                    <span>{text.button}</span></p>
                            </EmptyCardFrame>
                        }
                    </CardGrid>
                ) :
                    <>
                        {canCreateCluster ?
                            <EmptyState
                                icon={faFolderPlus}
                                hoverText={text.createNewHover}
                                descriptionText={text.sublineEmpty}
                                databstoggle='modal'
                                databstarget='#modalNewProject'
                            />
                            :
                            <p className='text-gray-500'>{t('fo-noClusters', 'No Clusters have been added to this Community')}</p>}
                    </>
                }
            </div>
        </div >
    );
}

ProjectsList.propTypes = {
    profile: PropTypes.array.isRequired,
    createProject: PropTypes.func.isRequired,
    updateProjectById: PropTypes.func.isRequired,
    updateVoteById: PropTypes.func.isRequired,
    listUserProjects: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { createProject, updateVoteById, updateProjectById, listUserProjects })(ProjectsList);
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faTree, 
    faFilePen, 
    faCheckCircle, 
    faComment, 
    faCommentDots } from "@fortawesome/free-solid-svg-icons";

import FlagForCollapsableContent from "components/FlagForCollapsableContent";
import CardWrapper from "components/CardWrapper";
import Button from "components/Button";
import TooltipTop from 'components/TooltipTop';

import { getInitials } from 'utils/get-initials';

/**
 * 
 * @returns Issue card with current State Icon
 * Has a toggle Flag for Resolution Hypothesis List [To Do]
 */
function IssueCard({ issue }) {
    const { t } = useTranslation();
    // TODO Translation
    const text =
    {
        tooltipTree: t('3-li-tooltip1', 'Imported from issue tree'),
        tooltipWip: t('3-li-tooltip2', 'In progress'),
        tooltipDone: t('3-li-tooltip3', 'Done, ready to share'),
        tooltipShared: t('3-li-tooltip4', 'Done and shared'),
        tooltipFeedback: t('3-li-tooltip5', 'Feedback received'),
    }
    // FIXME, get info out of Context with id?
    const hasResolution = issue.resolutionHypothesis?.length > 0;

    const initial = <span className="font-bold text-white tracking-widest">
        {getInitials(issue.name)}
    </span>;
    const iconClass = "w-5 h-5 mx-auto text-gray-600"
    const iconList = [
        <TooltipTop text={text.tooltipTree}>
            <FontAwesomeIcon icon={faTree} className={iconClass} />
        </TooltipTop>,
        <TooltipTop text={text.tooltipWip}>
            <FontAwesomeIcon icon={faFilePen} className={iconClass} />
        </TooltipTop>,
        <TooltipTop text={text.tooltipWip}>
            <FontAwesomeIcon icon={faCheckCircle} className={iconClass} />
        </TooltipTop>,
        <TooltipTop text={text.tooltipShared}>
            <FontAwesomeIcon icon={faComment} className={iconClass} />
        </TooltipTop>,
        <TooltipTop text={text.tooltipFeedback}>
            <FontAwesomeIcon icon={faCommentDots} className={iconClass} />
        </TooltipTop>,
    ]
    const collapseId = 'collapse' + issue.id;

    // TODO Fix href
    function getHref(id) {
        return `issues/${id}`
    }

    return (
        <CardWrapper sideBar={true} sideBarContent={initial} sideColor={'bg-green-500'}>
            {/* Name of issue with Link to issue */}
            <a href={getHref(issue.id)}
                className="text-gray-900 font-bold hover:text-gray-600">
                {issue.name}
            </a>
            {/* Icon displayed dependend on the current state */}
            <div className="float-right relative">
                <span className="absolute top-2 right-6">{iconList[issue.state]}</span>
            </div>

            {/* Description of Issue */}
            <p className="text-gray-500 whitespace-normal pt-2">
                {issue.description}
            </p>
            {hasResolution &&
                <p>
                    {/* Resolution Hypothesis Flag */}
                    <FlagForCollapsableContent
                        collapseId={collapseId}
                        text={issue.resolutionHypothesis?.length + ' Resolution Hypothesis'} />
                    {issue.state === 2 &&
                        <Button className='float-right'>{t('Share')}</Button>}
                </p>
            }
            {/* TODO Map Resolution Info */}
            <div className='collapse' id={collapseId}>
                {issue.resolutionHypothesis.map((hypothesis) => (
                    <p>{hypothesis.name}</p>
                ))}
            </div>
        </CardWrapper>
    );
}

export default IssueCard;
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import DisplayTagList from "components/DisplayTagList";
import MultiSelectBox from "components/MultiSelectBox";
import useGetCheckedList from "hooks/GetCheckedList";
import TopicContext from "../context/TopicContext";

/**
 * 
 * @param {ctxName, name, dataList, selectedIdList, viewMode, bgColor, borderColor, height}  
 * @returns MultiChecklist for Problem statement, displays a Taglist in View-Mode 
 * ctxName - name for context action
 * name - internal name 
 * dataList - list of objects with ID and name
 * selectedIdList - list of selected IDs from ctx 
 * viewMode - boolean, if true just the selected Tags will be displayed
 */
function ProblemStatementMultiCheck({ ctxName, name, dataList, selectedIdList, viewMode, bgColor, borderColor, height }) {
    const ctx = useContext(TopicContext);
    const checkedList = useGetCheckedList(dataList, selectedIdList);
    const [filteredList, setFilteredList] = useState([]);

    // Provides a filtered list only if selected IDs exist
    useEffect(() => {
        if (selectedIdList) {
            const filtered = dataList.filter(item => selectedIdList.includes(item.id));
            setFilteredList(filtered);
        }
    }, [dataList, selectedIdList]);

    const handleUpdate = (item, checked) => {
        ctx.idListHandler(ctxName, item.id)
    }

    return (
        <div id={'ProblemStatementMultiCheck'}>
            {
                (viewMode) ?
                    <DisplayTagList
                        list={filteredList}
                        bgColor={bgColor}
                        borderColor={borderColor} />
                    :
                    <MultiSelectBox
                        list={checkedList}
                        updateList={handleUpdate}
                        name={name}
                        height={height}
                    />
            }
        </div>
    );
}

export default ProblemStatementMultiCheck;
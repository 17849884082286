import { useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';
import TreeContext from "./tree-context";

// TODO Remove Dummy Data, create clean state
const defaultTreeState = {
    data: {
        children: [
            {
                id: 'n1',
                name: 'parent one',
                rating: 1,
                baseline: 0,
                change: 0,
                estimate: 0,
                children: [
                    {
                        id: 'n11',
                        name: 'child one',
                        rating: 0,
                        baseline: 0,
                        change: 0,
                        estimate: 0,
                        parent: 'n1',
                    },
                    {
                        id: 'n12',
                        name: 'child two',
                        rating: 2,
                        baseline: 0,
                        change: 0,
                        estimate: 0,
                        parent: 'n1',
                        children:
                            [
                                {
                                    id: 'n121',
                                    name: 'grand-child one',
                                    baseline: 0,
                                    change: 0,
                                    estimate: 0,
                                    parent: 'n12'
                                },
                                {
                                    id: 'n122',
                                    name: 'grand-child two',
                                    baseline: 0,
                                    change: 0,
                                    estimate: 0,
                                    parent: 'n12',
                                },
                                {
                                    id: 'n123',
                                    name: 'grand-child three',
                                    baseline: 0,
                                    change: 0,
                                    estimate: 0,
                                    parent: 'n12'
                                },
                                {
                                    id: 'n124',
                                    name: 'grand-child four',
                                    baseline: 0,
                                    change: 0,
                                    estimate: 0,
                                    parent: 'n12'
                                },
                            ]
                    },
                ]
            },
            {
                id: 'n2',
                name: 'parent two',
                baseline: 0,
                change: 0,
                estimate: 0,
            },
        ],
        selected: []
    },
}

// Changes a Node according to the required variables and values
function getAndChange(theObject, id, variable, newValue) {
    let result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject?.length; i++) {
            result = getAndChange(theObject[i], id, variable, newValue);
            if (result) {
                break;
            }
        }
    }
    else {
        for (var prop in theObject) {
            if (prop === 'id') {
                if (theObject[prop] === id) {
                    theObject[variable] = newValue;
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getAndChange(theObject[prop], id, variable, newValue);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

// Finds a node object in the tree structure
function getObject(theObject, id) {
    let result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject?.length; i++) {
            result = getObject(theObject[i], id);
            if (result) {
                break;
            }
        }
    }
    else {
        for (var prop in theObject) {
            if (prop === 'id') {
                if (theObject[prop] === id) {
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObject(theObject[prop], id);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

// Calculates the sum of an array
function calcSum(array, value) {
    const sum = array.reduce((accumulator, object) => {
        return accumulator + parseInt(object[value]);
    }, 0);
    return sum;
}

// Calculates the Sum for a variable for all parent Nodes
function sumParent(data, parentId, variable) {
    const parent = getObject(data, parentId);
    let baselineSum = 0;
    if (parent.children !== null) {
        baselineSum = calcSum(parent.children, variable);
    }
    getAndChange(data, parent.id, variable, baselineSum)
    if (parent.parent) {
        sumParent(data, parent.parent, variable)
    }
    return data;
}


const treeReducer = (state, action) => {
    // Adds a new 'factor' Parent-Node to the tree
    if (action.type === 'ADD_FACTOR') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        stateCopy.data.children.push(
            {
                id: uuidv4(),
                name: '',
                rating: undefined,
                baseline: 0,
                change: 0,
                estimate: 0,
            }
        )
        return stateCopy;
    }
    // Adds Child Nodes to a Parent-Node
    if (action.type === 'ADD_CHILD') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        const parent = getObject(stateCopy, action.id);
        if (!parent.children) {
            getAndChange(stateCopy, action.id, 'children', [
                {
                    id: uuidv4(),
                    name: '',
                    rating: undefined,
                    baseline: parent.baseline,
                    change: parent.change,
                    estimate: parent.estimate,
                    parent: parent.id
                },
            ])
        } else {
            const firstChild = parent.children?.length < 1;
            parent.children.push(
                {
                    id: uuidv4(),
                    name: '',
                    rating: undefined,
                    baseline: firstChild ? parent.baseline : 0,
                    change: firstChild ? parent.change : 0,
                    estimate: firstChild ? parent.estimate : 0,
                    parent: parent.id
                },
            )
        }
        return stateCopy;
    }
    if (action.type === 'DELETE_CARD') {
        const id = action.id;
        const stateCopy = JSON.parse(JSON.stringify(state));
        const node = getObject(stateCopy, action.id);
        if (node.parent) {
            let parent = getObject(stateCopy, node.parent);
            parent.children = parent.children.filter(obj => obj.id !== id);
            sumParent(stateCopy, parent.id, 'baseline');
            sumParent(stateCopy, parent.id, 'change');
            sumParent(stateCopy, parent.id, 'estimate');
        } else {
            stateCopy.data.children = stateCopy.data.children.filter(obj => obj.id !== id);
        }
        return stateCopy;
    }
    if (action.type === 'CHANGE_NAME') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        getAndChange(stateCopy, action.id, 'name', action.e.target.value);
        return stateCopy;
    }
    if (action.type === 'CHANGE_RATING') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        getAndChange(stateCopy, action.id, 'rating', action.value);
        return stateCopy;
    }
    if (action.type === 'CHANGE_BASELINE') {
        const newBaseline = action.e.target.value
        const stateCopy = JSON.parse(JSON.stringify(state));
        const node = getAndChange(stateCopy, action.id, 'baseline', newBaseline);
        const newEstimate = parseInt(newBaseline) + parseInt(node.change);
        getAndChange(stateCopy, action.id, 'estimate', newEstimate);
        if (node.parent != null) {
            sumParent(stateCopy, node.parent, 'baseline')
            sumParent(stateCopy, node.parent, 'estimate');
        }
        return stateCopy;
    }
    if (action.type === 'CHANGE_CHANGE') {
        const newChange = parseInt(action.e.target.value);
        const stateCopy = JSON.parse(JSON.stringify(state));
        const node = getAndChange(stateCopy, action.id, 'change', newChange);
        const newEstimate = parseInt(node.baseline) + newChange;
        getAndChange(stateCopy, action.id, 'estimate', newEstimate);
        if (node.parent != null) {
            sumParent(stateCopy, node.parent, 'change');
            sumParent(stateCopy, node.parent, 'estimate');
        }
        return stateCopy;
    }
    if (action.type === 'CHANGE_ESTIMATE') {
        const newEstimate = parseInt(action.e.target.value);
        const stateCopy = JSON.parse(JSON.stringify(state));
        const node = getAndChange(stateCopy, action.id, 'estimate', action.e.target.value);
        const newChange = newEstimate - parseInt(node.baseline);
        getAndChange(stateCopy, action.id, 'change', newChange);
        if (node.parent != null) {
            sumParent(stateCopy, node.parent, 'estimate');
            sumParent(stateCopy, node.parent, 'change');
        }
        return stateCopy;
    }
    if (action.type === 'ADD_SELECTED_CARD') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        const node = getObject(stateCopy, action.id);
        stateCopy.data.selected.push(
            {
                id: node.id,
                name: node.name,
                rating: node.rating,
                baseline: node.baseline,
                change: node.change,
                estimate: node.estimate,
            }
        )
        return stateCopy;
    }
    if (action.type === 'REMOVE_SELECTED_CARD') {
        let stateCopy = JSON.parse(JSON.stringify(state));
        stateCopy.data.selected = stateCopy.data.selected.filter(data => data.id !== action.id);
        return stateCopy;
    }
}

function TreeProvider(props) {
    const [treeState, dispatchTreeAction] = useReducer(treeReducer, defaultTreeState);

    const findNode = (data, id) => {
        if (data.id === id) {
            return data;
        } else if (data.children != null) {
            var i;
            var result = null;
            for (i = 0; result === null && i < data.children?.length; i++) {
                result = findNode(data.children[i], id);
            }
            return result;
        }
        return null;
    }
    const getBaselineSum = (data) => {
        return calcSum(data.children, 'baseline');
    }
    const getEstimateSum = (data) => {
        return calcSum(data.children, 'estimate');
    }
    const addFactor = () => {
        dispatchTreeAction({ type: 'ADD_FACTOR' })
    }
    const addChild = (id) => {
        dispatchTreeAction({ type: 'ADD_CHILD', id })
    }
    const deleteCard = (id) => {
        dispatchTreeAction({ type: 'DELETE_CARD', id })
    }
    const changeName = (id, e) => {
        dispatchTreeAction({ type: 'CHANGE_NAME', id, e })
    }
    const changeRating = (id, value) => {
        dispatchTreeAction({ type: 'CHANGE_RATING', id, value })
    }
    const changeBaseline = (id, e) => {
        dispatchTreeAction({ type: 'CHANGE_BASELINE', id, e })
    }
    const changeChange = (id, e) => {
        dispatchTreeAction({ type: 'CHANGE_CHANGE', id, e })
    }
    const changeEstimate = (id, e) => {
        dispatchTreeAction({ type: 'CHANGE_ESTIMATE', id, e })
    }
    const addSelectedCard = (id) => {
        dispatchTreeAction({type: 'ADD_SELECTED_CARD', id})
    }
    const removeSelectedCard = (id) => {
        dispatchTreeAction({type: 'REMOVE_SELECTED_CARD', id})
    }

    const treeContext = {
        data: treeState.data,
        findNode: findNode,
        getBaselineSum,
        getEstimateSum,
        addFactor,
        addChild,
        deleteCard,
        changeName,
        changeRating,
        changeBaseline,
        changeChange,
        changeEstimate,
        addSelectedCard,
        removeSelectedCard
    }

    return <TreeContext.Provider value={treeContext}>
        {props.children}
    </TreeContext.Provider>

}

export { TreeProvider, defaultTreeState };


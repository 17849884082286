import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from "@fortawesome/free-regular-svg-icons";

// import CommentGridRow from 'components/CommentGridRow';
// import CommentList from 'components/Comment/CommentList';
import { createComment } from 'actions/comment.create.actions';
import { listComments } from 'actions/comments.list.actions';

const iconClass = `w-5 h-5 text-gray-400
                    hover:text-primary-700 transform duration-150 ease-in`;
const valueClassName = 'w-full'

// const dummyComments = [
//     {
//         user: 'Betty',
//         comment: `I think this is wrong m ipsum dolor sit amet, 
//         consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadips`,
//         date: '20.06.22',
//         time: '14:12',
//     },
//     {
//         user: 'Benni',
//         comment: 'I think this is correct',
//         date: '20.06.22',
//         time: '16:30',
//     },
// ]

// Props:
// label, type [area, table, other]

// TODO: how track new comments ??? Backend: listCommentsSinceLastLogin
function OverviewRowModule(props) {
    const editActive = props.editActive;
    const [commentActive, setCommentActive] = useState(false);
    const rowId = props.label.substring(0, 3) + props.index;
    const collapseId = props.label.substring(0, 3) + props.index + "collapse";

    //console.log(props.nodeId, props.commentList, initialCommentList)
    //const commentList = initialCommentList
    const [commentList, setCommentList] = useState([]);
    const [commentCount, setCommentCount] = useState(0);
    // const [newCommentCount, setNewCommentCount] = useState(0);
    // const [newComment, setNewComment] = useState(false);
    //console.log(props.nodeId, commentList, commentCount)

    // const commentCount = commentList?.length

    // setting comment list (depends on commentList)
    useEffect(() => {
        // if (! Array.isArray(initialCommentList)) {
        //     initialCommentList = [initialCommentList]
        // }
        if (props.commentList) {
            setCommentList(props.commentList.filter(x => x.node === props.node));
        }
    }, [props.commentList]);

    // setting comment count (depends on commentList)
    useEffect(() => {
        setCommentCount(commentList?.length);
    }, [commentList]);

    const updateCommentList = (newComment) => {

            const now = new Date()

            props.createComment(
                props.parentId,
                props.node,
                {
                    text: newComment,
                    createdTs: now.toISOString()
                })//.then(
                //    window.location.reload(false)
                //)
                // .then(props.listComments(
                //     props.parent,
                //     props.parentId
                //     ))
            
                setCommentList(prevCommentList => {
                    
                    const updatedComments = [...prevCommentList];
                    updatedComments.push({
                        parent: props.parent, 
                        parentId: props.parentId,
                        node: props.node,
                        username: props.profile.username,
                        text: newComment,
                        createdTs: now.toISOString(),  // TODO: this must be in sync with DB
                    });
                    setCommentCount(updatedComments?.length);
                    //setNewCommentCount(newCommentCount + 1);
                    props.updateNewCommentCount(props.index, commentCount + 1); // sends commentcount up to parent component
                    //setNewComment(true);
                    return updatedComments;
                })

                console.log(commentList)
        }

    // const updateCommentList = (newComment) => {
    //     console.log(newComment)
    //     setCommentList(prevCommentList => {
    //         const updatedComments = [...prevCommentList];
    //         updatedComments.push({
    //             user: 'Dummy-User',
    //             comment: newComment,
    //             date: getCurrentDate(),
    //             time: getCurrentTime(),
    //         });
    //         setCommentCount(updatedComments?.length);
    //         setNewCommentCount(newCommentCount + 1);
    //         props.updateNewCommentCount(props.index, newCommentCount + 1); // sends commentcount up to parent component
    //         setNewComment(true);
    //         return updatedComments;
    //     })
    // }

    return (
        <div className={`grid grid-cols-12 mb-1
        ${(commentActive) && 'bg-gray-50 border border-gray-300'} 
        ${(!editActive & !commentActive) && 'hover:bg-primary-50'} `}>
            {/* Label Area */}
            <div id='row-label-div'
                className={`
                col-span-12 font-bold p-1 mt-4 rounded-sm
                md:col-span-3 2xl:col-span-2  md:bg-transparent md:pt-3 md:px-4 md:mt-0 md:rounded-none
                text-base md:font-small md:font-normal text-gray-600 select-none`}>
                <label>{props.label}</label></div>
            {/* Content Area */}
            <div id='row-content-div'
                className={`col-span-11 md:col-span-8 2xl:col-span-9`}>
                {props.type === 'area' &&
                    <div className='w-auto'>
                        {editActive
                            ? <TextareaAutosize
                                readOnly={!editActive}
                                onChange={props.onChange}
                                className={`${valueClassName} border-1 border-gray-300 rounded focus:border-1 focus:bg-primary-100
                                             resize-none  mt-1 px-1 pt-2 pb-1`}
                                value={props.value || ''}
                                placeholder={props.placeholder || '...'}
                            >
                            </TextareaAutosize>
                            : <label className={`${valueClassName} border-white inline-block pl-1 py-3`}>{props.value}</label>
                        }
                    </div>
                }
                {(props.type === 'table' || props.type === 'other') &&
                    <div> {props.children} </div>
                }
            </div>
            {/* Comment Icon */}
            {!editActive &&
                <div id='commentCell' className='flex justify-center items-center' onClick={() => setCommentActive(!commentActive)}>
                    <a className='relative'
                        data-bs-toggle="collapse"
                        href={'#' + collapseId}
                        role="button"
                        aria-controls={collapseId}
                        aria-expanded="false">
                        <FontAwesomeIcon icon={faComment} className={`${iconClass}`} />
                        {(commentCount > 0) &&
                            <div className={`bg-primary-600
                            w-5 h-5 absolute transform translate-x-3 -translate-y-8 rounded-full 
                            flex items-center justify-center text-xs text-white font-bold`}>
                                {commentCount}
                            </div>}
                        {/* {(commentCount > 0) &&
                            <div className={`
                            ${newComment ? 'bg-primary-600' : 'bg-gray-400'}
                            w-5 h-5 absolute transform translate-x-3 -translate-y-8 rounded-full 
                            flex items-center justify-center text-xs text-white font-bold`}>
                                {newComment ? newCommentCount : commentCount}
                            </div>} */}
                    </a>

                </div>
            }
            
            {/* Comment List */}
            {/* {commentActive &&
                <>
                    <CommentList list={commentList} col={'col-start-3 col-span-9'} />
                    <CommentGridRow updateCommentList={updateCommentList} 
                                col={'col-start-3 col-span-9'} 
                                collapseId={collapseId} 
                                commentActive={commentActive} />
                </>} */}
        </div>
    );
}


OverviewRowModule.propTypes = {
    createComment: PropTypes.func.isRequired,
    listComments: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { createComment, listComments })(OverviewRowModule);
// export default OverviewRowModule;
import { createContext } from "react";

export const CommentContext = createContext({
    profile: null,
    sortType: null,
    commentList: [],
    updateSortType: () => { },
    updateCommentList: () => { },
    deleteComment: () => { },
    upVote: () => { },
    downVote: () => { },
})
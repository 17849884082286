import { organisationActionTypes as types } from 'constants/organisation.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.communityReducer) || {};

export function organisationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_ORGANISATIONS_REQUEST:
      return {
        ...state,
        ...payload
      };
    case types.LIST_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        organisations: payload
      };
    case types.LIST_COMMUNITIES_FAILURE:
    default:
      return state;
  }
}

const isHaveGreaterOrSameRole = (ownRole, userRole) => {
    let res = false
    switch (ownRole){ 
        case 'OWNER':
             switch (userRole){
                 case 'OWNER': res=true; break; 
                 case 'ADMIN': res=true; break; 
                 case 'EDITOR': res=true; break; 
                 case 'VIEWER': res=true; break; 
                 case null: res=true; break; 
                 default: res=false;
            }

        break;
        case 'ADMIN':
             switch (userRole){
                 case 'OWNER': res=false; break; 
                 case 'ADMIN': res=true; break; 
                 case 'EDITOR': res=true; break; 
                 case 'VIEWER': res=true; break; 
                 case null: res=true; break; 
                 default: res=false;
            }
            break;
        case 'EDITOR':
            switch (userRole){
                case 'OWNER': res=false; break; 
                case 'ADMIN': res=false; break; 
                case 'EDITOR': res=true; break; 
                case 'VIEWER': res=true; break; 
                case null: res=true; break; 
                default: res=false;
            }
            break;
        case 'VIEWER':
            switch (userRole){
                case 'OWNER': res=false; break; 
                case 'ADMIN': res=false; break; 
                case 'EDITOR': res=false; break; 
                case 'VIEWER': res=true; break; 
                case null: res=true; break; 
                default: res=false;
            }
            break;
        default:
            res=false
    }
    return res;
}

export { isHaveGreaterOrSameRole };
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faX } from "@fortawesome/free-solid-svg-icons";

/**
 *  A component that displays an empty state with an icon and optional text and link OR modal controls.
 * 
 * @param {string} [icon] - The icon to display in the empty state. Use faIcons for this
 * @param {string} [hoverText] - The text to display when the user hovers over the icon
 * @param {string} [descriptionText] - The description of the empty state
 * @param {string} [color] - Optional color for icon
 * @param {string} [href] - The link that the user can click on to take some action / alternative to a modal
 * @param {string} [databstarget] - identifies the target modal-element / alternative to Link
 * @param {string} [databstoggle] - identifies the type of Bootstrap component to be toggled 
 * @param {string} [className] - Any optional CSS class name to apply to the component.
 */
function EmptyState({ icon, hoverText, descriptionText, href, className, databstarget, databstoggle, color }) {

    color = color || 'text-primary-600'

    const thisIcon = <FontAwesomeIcon
        className={`${color} mx-auto h-10 w-10 transition-all group-hover:scale-105  scale-95 cursor-pointer`}
        icon={icon || faX}
    />

    return (
        <div id="emptyState"
            className={`${className} group text-center p-12  
        border-2 border-gray-300 border-dashed`}
            data-bs-target={databstarget}
            data-bs-toggle={databstoggle}>
                {href ?
                    <Link to={href}>
                        {thisIcon}
                    </Link>
                    :
                    <span>{thisIcon}</span>
                }
            <p className="mt-4 text-sm text-gray-500">
                {descriptionText}
            </p>
        </div>
    );
}

export default EmptyState;
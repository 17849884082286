const communityActionTypes = {

    GET_COMMUNITY_REQUEST: 'GET_COMMUNITY_REQUEST',
    GET_COMMUNITY_SUCCESS: 'GET_COMMUNITY_SUCCESS',
    GET_COMMUNITY_FAILURE: 'GET_COMMUNITY_FAILURE',

    LIST_COMMUNITIES_REQUEST: 'LIST_COMMUNITIES_REQUEST',
    LIST_COMMUNITIES_SUCCESS: 'LIST_COMMUNITIES_SUCCESS',
    LIST_COMMUNITIES_FAILURE: 'LIST_COMMUNITIES_FAILURE',

    CREATE_COMMUNITY_REQUEST: 'CREATE_COMMUNITY_REQUEST',
    CREATE_COMMUNITY_SUCCESS: 'CREATE_COMMUNITY_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    CREATE_COMMUNITY_FAILURE: 'CREATE_COMMUNITY_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    UPDATE_COMMUNITY_REQUEST: 'UPDATE_COMMUNITY_REQUEST',
    UPDATE_COMMUNITY_SUCCESS: 'UPDATE_COMMUNITY_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    UPDATE_COMMUNITY_FAILURE: 'UPDATE_COMMUNITY_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    DELETE_COMMUNITY_REQUEST: 'DELETE_COMMUNITY_REQUEST',
    DELETE_COMMUNITY_SUCCESS: 'DELETE_COMMUNITY_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    DELETE_COMMUNITY_FAILURE: 'DELETE_COMMUNITY_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { communityActionTypes };
import { instance as axios } from 'ajax';

import Cookies from 'js-cookie';
import { loadingActions } from './loading.actions';
import { alertActions } from './alert.actions';

import { toCamelCase } from 'utils/snake-converter';

const getSignedUrl = (id, filename, kind, dispatch) => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
        },
        params: {
            id: id,
            filename: filename,
            kind: kind
        }
    };

    dispatch(loadingActions.start());

    return axios
            .get('/api/v1/gcs', config)
            .then(
                response => {
                    dispatch(loadingActions.finish());
                    return toCamelCase(response.data)?.signedLink
                },
                error => {
                    dispatch(loadingActions.finish());

                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            )

}

export { getSignedUrl };
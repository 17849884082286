import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FlagCompactGrid from "components/FlagCompactGrid";
import topicTypesData from "./TopicTypes";
import ProptTypes from 'prop-types'

/**
 * Displays flags for topic types
 */
function TopicTypesFlagGrid({ tooltipActive, editActive, activeFlag, selectFlag, textSize }) {
    const { t } = useTranslation(['problemDefinition']);
    const topicTypes = topicTypesData(t);
    const [active, setActive] = useState(activeFlag);

    useEffect(() => {
        setActive(activeFlag)
    }, [activeFlag]);


    return (
        <FlagCompactGrid
            id="topicTypesFlagGrid"
            data={topicTypes}
            name='topicTypes'
            tooltipActive={tooltipActive}
            editActive={editActive}
            active={active}
            setActiveFlag={selectFlag}
            textSize={textSize}
        />
    );
}

TopicTypesFlagGrid.propTypes = {
    tooltipActive: ProptTypes.bool,
    editActive: ProptTypes.bool,
    activeFlag: ProptTypes.string,
    selectFlag: ProptTypes.func,
    textSize: ProptTypes.string,
}

export default TopicTypesFlagGrid;
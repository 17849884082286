import { useState, useEffect } from "react";
import { CommentContext } from "./CommentContext";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { createComment } from 'actions/comment.create.actions';
import { updateCommentById } from 'actions/comment.update.actions';
import { listComments } from "actions/comments.list.actions";
import { deleteCommentById } from 'actions/comment.delete.actions';
import { updateVoteById } from "actions/voting.update.actions";

function CommentProvider({ profile, parentId, comments, listComments, deleteCommentById, updateCommentById, createComment, updateVoteById, children }) {
    const { t } = useTranslation(['common']);
    const [commentList, setCommentList] = useState([]);
    const [sortType, setSortType] = useState('newest');

    // Initial Get Comments
    useEffect(() => {
        listComments(parentId)
    }, [parentId]);

    // Put sorted comments into local state
    useEffect(() => {
        if (comments?.length > 0) {
            const storedSortOrder = JSON.parse(localStorage.getItem('sortOrder'));
            if (storedSortOrder !== null) {
                setSortType(storedSortOrder);
            } else {
                setCommentList(sortParentAndReplies(comments))
            }
        } else {
            setCommentList([])
        }
    }, [comments]);

    useEffect(() => {
        if (comments?.length > 0) {
            setCommentList(sortParentAndReplies(comments))
            localStorage.setItem('sortOrder', JSON.stringify(sortType));
        }
    }, [comments, sortType])

    // Function to sort parent comments and their replies
    function sortParentAndReplies(comments) {
        let sortedComments = sortComments(comments);
        sortedComments = sortedComments.map(comment => {
            if (comment.replies?.length > 0) {
                return {
                    ...comment,
                    replies: sortComments(comment.replies)
                };
            }
            return comment;
        });
        return sortedComments;
    }

    // Sorts comments in a list by new, old, by votes
    const sortComments = (list) => {
        switch (sortType) {
            case 'newest':
                return [...list].sort((a, b) => new Date(b.createdTs) - new Date(a.createdTs));
            case 'oldest':
                return [...list].sort((a, b) => new Date(a.createdTs) - new Date(b.createdTs));
            case 'votes':
                return [...list].sort((a, b) => calcUserVotes(b) - calcUserVotes(a));
            default:
                return list;
        }
    };

    const calcUserVotes = (comment) => {
        let count = 0;
        if (comment.userVotes) {
            count = comment.userVotes.reduce((acc, obj) => (acc + obj.vote), 0)
        }
        return count
    }

    // Deletes comments and replies
    const handleDeleteComment = (id) => {
        deleteCommentById(id);
        setCommentList(prevCommentList => {
            let updatedComments = prevCommentList.map(comment => {
                // Check if the current comment is the one being deleted
                if (comment.id === id) {
                    return {
                        ...comment,
                        text: t('Comment was deleted'),
                        mood: null,
                        userId: profile.userId,
                        deleted: true,
                    };
                }
                // Check if the comment to delete is a reply within this comment
                if (comment.replies?.some(reply => reply.id === id)) {
                    return {
                        ...comment,
                        replies: comment.replies.map(reply => {
                            if (reply.id === id) {
                                return {
                                    ...reply,
                                    text: t('Comment was deleted'),
                                    mood: null,
                                    userId: profile.userId,
                                    deleted: true,
                                };
                            }
                            return reply;
                        })
                    };
                }
                return comment;
            });
            return updatedComments;
        });
    }


    // Handles new and updated comments, replies and replies to replies
    const handleUpdateComment = ({ id, text, mood, userId, edited, replyTo }) => {
        const now = new Date().toISOString();
        const createCommentObject = (isNew) => {
            return {
                parentId: parentId,
                username: profile.username,
                firstName: profile.firstName,
                lastName: profile.lastName,
                text,
                mood,
                userId,
                createdTs: isNew ? now : undefined,
                changedTs: isNew ? undefined : now,
                ...(replyTo && { replyTo }),
                ...(edited && { edited }),
            };
        };

        setCommentList(prevCommentList => {
            if (replyTo) {
                // Handling a reply to a comment
                return prevCommentList.map(comment => {
                    // Reply to a comment
                    if (comment.id === replyTo) {
                        const newReply = createCommentObject(true);
                        const updatedReplies = comment.replies ? [...comment.replies, newReply] : [newReply];
                        createComment(comment.id, { text, mood, userId, replyTo, createdTs: now }).then(() => listComments(parentId));
                        return { ...comment, replies: updatedReplies };

                        // Reply to a reply
                    } else if (comment.replies.some(reply => reply.id === replyTo)) {
                        // Handling a reply to another reply
                        const updatedReplies = comment.replies.map(reply => {
                            if (reply.id === replyTo) {
                                const newNestedReply = createCommentObject(true);
                                return { ...reply, replies: reply.replies ? [...reply.replies, newNestedReply] : [newNestedReply] };
                            }
                            return reply;
                        });
                        createComment(comment.id, { text, mood, userId, replyTo, createdTs: now }).then(() => listComments(parentId));
                        return { ...comment, replies: updatedReplies };
                    }
                    return comment;
                });
            } else {
                // Handling a new or edited comment / reply
                // searching if comment was edited
                const editedCommentIndex = prevCommentList.findIndex(comment => comment.id === id);
                let updatedComments;

                if (editedCommentIndex !== -1) {
                    // Update existing comment
                    const updatedComment = { ...prevCommentList[editedCommentIndex], text, mood, userId, edited, changedTs: now };
                    updatedComments = [...prevCommentList.slice(0, editedCommentIndex), updatedComment, ...prevCommentList.slice(editedCommentIndex + 1)];
                    updateCommentById(id, { text, mood, userId }).then(() => listComments(parentId));
                } else {
                    // searching for edited reply
                    let isReplyEdited = false;
                    updatedComments = prevCommentList.map(comment => {
                        // Check if the current comment has replies and update the matching reply
                        if (comment.replies) {
                            const updatedReplies = comment.replies.map(reply => {
                                if (reply.id === id) {
                                    isReplyEdited = true;
                                    return { ...reply, text, mood, userId, edited, changedTs: now };
                                }
                                return reply;
                            });
                            // Return the comment with the updated replies array if any reply was updated
                            return isReplyEdited ? { ...comment, replies: updatedReplies } : comment;
                        }
                        return comment;
                    });
                    if (isReplyEdited) {
                        updateCommentById(id, { text, mood, userId }).then(() => listComments(parentId));
                    } else {
                        // Add new comment
                        updatedComments = [...prevCommentList, createCommentObject(true)];
                        createComment(parentId, { text, mood, userId }).then(() => listComments(parentId));
                    }
                }
                return updatedComments;
            }
        });
    };

    const handleSortTypeUpdate = (type) => {
        setSortType(type)
    }

    const handleUpVote = (comment) => {
        const userVote = comment.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote + 1 <= 1 ) {
            updateVoteById(comment.id, userVote + 1).then(() => {
                listComments(parentId)
            })
        }
    }

    const handleDownVote = (comment) => {
        const userVote = comment.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote - 1 >= -1 ) {
            updateVoteById(comment.id, userVote - 1).then(() => {
                listComments(parentId)
            })
        }
    }

    const ctxValue = {
        profile: profile,
        commentList: commentList,
        sortType: sortType,
        updateSortType: handleSortTypeUpdate,
        deleteComment: handleDeleteComment,
        updateCommentList: handleUpdateComment,
        upVote: handleUpVote,
        downVote: handleDownVote,
        calcUserVotes: calcUserVotes
    }

    return (
        <CommentContext.Provider value={ctxValue}>
            {children}
        </CommentContext.Provider>
    )
}

CommentProvider.propTypes = {
    listComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    updateCommentById: PropTypes.func.isRequired,
    deleteCommentById: PropTypes.func.isRequired,
    updateVoteById: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    comments: state.commentReducer.comments,
})

export default connect(mapStateToProps, { listComments, createComment, updateCommentById, deleteCommentById, updateVoteById })(CommentProvider)

import { XIcon } from '@heroicons/react/outline'
import TextareaAutosize from 'react-textarea-autosize';
import SelectMenu from './SelectMenu';

import React, { useState } from 'react';

const cellClassNames = 'align-top'
const tableInputClassNames = `border-1 border-white 
block sm:text-sm rounded-sm 
 resize-none`

function EditableRow(props) {
    const rowData = (props.rowData || [{ id: 0, row: 1 }]);    
    const intialArray = () => {
        let tempArray = [];
        for (let index = 0; index < rowData.length; index++) {
            tempArray.push({ id: index, row: 1 })
        }
        return tempArray;
    }
    const [heightArray, setHeightArray] = useState(intialArray);
    const [minRows, setMinRows] = useState(1)

    const heightHandler = (event, id) => {
        let tempArray = [...heightArray];
        let currRow = rowCalc(event);
        tempArray[id].row = currRow;
        setHeightArray(tempArray);
        let min = Math.max(...tempArray.map(row => row.row));
        setMinRows(min);
    }

    const rowCalc = (height) => {
        return Math.round((height - 38) / 20) + 1;
    }

    const changeHandler = (event, name, id) => {
        props.changeHandler(props.rowIndex, name, event, id);
        setMinRows(1);
    }

    return (
        <tr key={props.rowId} id={"row-" + props.rowId} className="border-b border-gray-200">
            {rowData.map((item, index) => (
                // ${item.type === "selectMenu" && "pt-8px"}
                <td className={`${cellClassNames} ${item.style} `}
                    key={item.id}>
                    {item.type === "area" &&
                        <TextareaAutosize
                            readOnly={!props.editActive}
                            cacheMeasurements
                            id={item.id}
                            className={`${tableInputClassNames} ${item.innerStyle || 'w-full'} 
                                        ${props.editActive
                                    ? 'focus:ring-primary-500 focus:border-primary-500 focus:border-1 hover:border-primary-300'
                                    : 'cursor-auto border focus:ring-white focus:border-white'}`}
                            value={item.value || ''}
                            placeholder="..."
                            minRows={minRows}
                            onChange={(event) => changeHandler(event, item.name, item.id)}
                            onHeightChange={(e) => { heightHandler(e, index) }}
                        />
                    }
                    {item.type === "selectMenu" &&
                        (props.editActive
                            ? <SelectMenu itemList={item.itemList} selected={item.value} style={'w-full'} />
                            : <label className='sm:text-sm'>{item.value}</label>)
                    }
                </td>
            ))}
            {(props.oneRow || !props.editActive) ?
                <td className='w-16'></td>
                : <td className='w-16 group text-center cursor-pointer px-2' onClick={props.delete}>
                    <XIcon className='w-5 h-5 m-auto group-hover:text-primary-600 text-gray-500 transform group-hover:scale-125' />
                </td>}
        </tr>
    );
}

export default EditableRow;
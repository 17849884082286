import React, { useContext, useState } from 'react';

import Button from "components/Button";

import TreeContext from './Tree/context/tree-context';
import OverviewPanel from './Tree/OverviewPanel';
import ExportPanel from './Tree/ExportPanel';
import { TreeProvider } from './Tree/context/TreeProvider';
import Tree from "./Tree/Tree";

function TreePage() {
    const ctx = useContext(TreeContext);
    const [selectModeActive, setSelectModeActive] = useState(false);

    const handleNewFactor = () => {
        ctx.addFactor();
    }

    const activateSelectMode = () => {
        setSelectModeActive(!selectModeActive);
    }

    return (
        <div className='' style={{ contain: 'paint' }}>
            {/* Export and Overview-Panel */}
            <div className="m-4 w-full bg-blue-200 sticky top-2 z-20">
                {selectModeActive ?
                    <ExportPanel className={'z-50'} />
                    :
                    <OverviewPanel className={'z-50'} />
                }
            </div>
            {/* Menu with Buttons */}
            <div className='m-2 py-2 space-x-2 sticky top-0 z-10 backdrop-blur-md border-b border-white'
                style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Button className={'ml-4'} onClick={handleNewFactor}>Add Factor</Button>
                <Button className={''} onClick={activateSelectMode}>
                    {selectModeActive ? 'Edit Factors' : 'Select Factors'}
                </Button>
            </div>
            {/* Tree Component */}
            <div className='relative overflow-hidden'>
                <Tree selectModeActive={selectModeActive} className={'z-20'} />
            </div>

        </div>
    );
}

export default TreePage;
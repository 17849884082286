import { valueChainActionTypes as types } from 'constants/value-chain.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.valueChainReducer) || {};

export function valueChainReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_VALUE_CHAINS_REQUEST:
      return {
        ...state,
        ...payload
      };
    case types.LIST_VALUE_CHAINS_SUCCESS:
      return {
        valueChains: payload
      };
    case types.LIST_VALUE_CHAINS_FAILURE:
    default:
      return state;
  }
}

import { useState, useContext } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from "components/LabelWithSmarttips";
import GuideForm from "components/Guide/GuideForm";

import ProblemContext from '../context/ProblemContext';

function KeyResults() {
    const { t } = useTranslation();
    const text = {
        headline: t("1-kr-hl", "Key Results"),
        tooltip: t("1-kr-tooltip", "All of your key results need to be achieved in order for the problem to be considered solved sufficiently. Key results should, but need not contain quantitative elements"),
        title: t("1-kr-prompt", "When is the problem considered to be solved?"),
        tipsList: [
            {
                title: t("1-kr-1t", "Specific"),
                description: t("1-kr-1d", "What do you want to achieve? E.g. “increase profit”, “reduce response time”. Be careful not to confuse objective and constraint.")
            },
            {
                title: t("1-kr-2t", "Measurable"),
                description: t("1-kr-2d", "Provide a quantitative value. E.g. “by 50%”, “down to 5 sec”")
            },
            {
                title: t("1-kr-3t", "Realistic"),
                description: t("1-kr-3d", "Make sure the key results are reachable")
            },
            {
                title: t("1-kr-4t", "Time bound"),
                description: t("1-kr-4d", "By when should it be reached? E.g. “by 2030”, “within next 2 years”")
            },
            {
                title: t("1-kr-5t", "Open"),
                description: t("1-kr-5d", "Avoid providing any solution. Leave room for creativity and innovations")
            },
            {
                title: t("1-kr-6t", "Contributing"),
                description: t("1-kr-6d", "Ensure a measure direct or indirect contribution to the overall goals of your organization")
            },
        ],
        dodPlaceholder: "..."
    }
    const ctx = useContext(ProblemContext)
    const [guideActive, setGuideActive] = useState(false);
    // TODO Add link for racecourse plot 
    const guideText = [
        {
            header: t('1-kr-g1h', 'What is the difference between goals, objectives, key results, and constraints?'),
            text: t('1-kr-g1t',
                `•	A goal is an outcome that is often broad and long-term, like “becoming No. 1 in the market” and typically set at the top level
                \n•	An objective is more specific than a goal and set a direction how the goal should be reached
                \n•	Your problem statement is supposed to make you reach your objective. It should be inspirational and contribute as directly and measurably as possible to the goals
                \n•	Your key results need to be achieved in order for the problem to be considered solved sufficiently, and thus for the objective to be reached 
                \n•	One of these results should become your “primary quantitative key result”, e.g., “finish in less than 3h”
                \n•	If “more (or less) is better” applies, then you are probably talking about a result, e.g., “take at least third place in next year’s race” 
                \n•	By contrast, a constraint is like a boundary on a racecourse: You need to respect it in order not to get disqualified, but you can - and you often should - use all the space allowed to you 
                \nSee here for a graphical representation [TODO]
                `
            ),
            // TODO
            linkText: t('1-kr-g1l', 'See here for a graphical representation'),
        },
        {
            header: t('1-kr-g2h', 'How should you formulate your key results?'),
            text: t('1-kr-g2t', `In general
            \n•	Make sure you select only results, no constraints
            \n•	Try to be lean in quantity - it will help your team to focus and it will not restrict the solution space too much
            \n•	Set one primary quantitative key result (potentially more depending on the nature of the problem)
            \nFor each result
            \n•	Be specific: State what you want to achieve, e.g., “increase gross profit”, “reduce phone response time”, “increase NPS market ranking”. You can specify terms in your critical definitions
            \n•	Be measurable: How much do you want to achieve, e.g., “by 50%”, “down to 15 seconds”, “by at least 5 positions, minimum rank 5”
            \n•	Be realistic: Set results that can reasonably be achieved, e.g., using a market comparison or benchmarks (“competitor XY did achieve the same success last year”)
            \n•	Be time-bound: By when should the result be reached? E.g., “by 2030”, “within the next 2 years”
            \n•	Be open: Avoid providing any solution, e.g., using language like “by implementing …” or “using …”. Leave room for creativity and innovation
            \n•	Be contributing: Reaching your result should contribute directly or indirectly to the overall goals of your organization; e.g., “increase sales by 10%” would contribute to the overall objective “growth” 
            `),
        },
        {
            header: t('1-kr-g3h', 'Example: Key Result'),
            text: t('t-kr-g3t', `•	GHG emissions reduced by 25% compared to baseline within 1 year
            \n•	Conversion rate (lead to customer) increased from 5% to 10% until 2023
            `)
        },
        {
            header: t('1-kr-g4h', 'Example: Constraint'),
            text: t('t-kr-g4t', `•	No change in core systems
            \n•	Regulator with no substantial objection to chosen solution
            \n•	Alignment with group central functions documented
            `),
            // TODO
            linkText: t('1-kr-g4l', 'See here for a graphical representation')
        }
    ]

    return (
        <div>
            <GuideForm guideContent={guideText}
                active={guideActive}
                onClose={() => setGuideActive(!guideActive)} 
                width={'w-72 xl:w-1/4 2xl:w-1/3'}
                defaultOpen={false}/>
            <FormContainer title={text.headline}
                openGuide={() => setGuideActive(!guideActive)}
                guide={true} >
                {/*Definition of Done */}
                <div>
                    <Tooltip content={text.tooltip} />
                    <LabelWithSmarttips id="dodTips" title={text.title} tips={text.tipsList} />
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="6"
                            name="definitionOfDoneInputArea"
                            id="definitionOfDoneInputArea"
                            value={ctx.data.definitionOfDone}
                            onChange={ctx.handleChange('definitionOfDone')}
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={text.dodPlaceholder}
                        />
                    </div>
                </div>
            </FormContainer>
        </div>
    );
}

export default KeyResults;
import { useContext } from 'react';
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";
import CreateProblemContext from '../context/ProblemContext';

import ConstraintsRow from './ConstraintsRow';

function ConstraintsTable(props) {
    const { t } = useTranslation();
    const text = {
        tableHeader: [
            t("1-c-table1", "Constraint"),
            t("1-c-table2", "Binding Level"),
            t("1-c-table3", "Comment"),
            ""]
    }
    const ctx = useContext(CreateProblemContext);

    const newRowHandler = () => {
        ctx.newRowHandler('constraints');
    }

    const tableData = ctx.data.constraints;

    return (
        <EditableTable tableHeader={text.tableHeader} addRow={newRowHandler} editActive={props.editActive}>
            {tableData && tableData.map((item, index) => (
                <ConstraintsRow
                    editActive={props.editActive}
                    index={index}
                    data={tableData[index]}
                    key={item.id}
                />
            ))}
        </EditableTable>
    );
}

export default ConstraintsTable;
import Breadcrumbs from './Breadcrumbs'
import UserMenu from './UserMenu';

function Header({ user }) {
    return ( <div className='flex justify-between border-b border-gray-200 w-full'>
        <Breadcrumbs />
        <UserMenu user={user}/>
    </div> );
}

export default Header;
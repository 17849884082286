import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

import FormContainer from "components/FormContainer"
import PageWrapper from "components/PageWrapper"

import ProfileForm from "./ProfileForm";
import { deleteUserProfile } from "actions/profile.delete.actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import { logout } from 'actions/auth.logout.actions';
import DisplayProfile from "./DisplayProfile";
import Modal from "components/Modal";
import { translateItemWithChildren as translateItem } from "utils/translate-item-with-children";

/**
 * @returns Displays the profile for the current user
 */
function OwnUserProfilePage({
    profile,
    deleteUserProfile,
    logout,
    segments,
    valueChains
}) {
    const history = useHistory();
    const { t, i18n } = useTranslation(['user', 'foren', 'common']);
    const text = {
        modalTitle: t('Delete Account'),
        cancel: t('Cancel', { ns: 'common' }),
        modalText: t('u-delinfo', `Caution: If you delete your profile, your input (e.g., comments) will be deleted as well for data protection reasons`)
    }
    const [editActive, setEditActive] = useState(false);
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);

    useEffect(() => {
        if (segments) {
            const translatedSegments = segments.map(segment => translateItem(segment, i18n.language));
            setSegmentList(translatedSegments);
        }
    }, [segments, i18n.language]);

    useEffect(() => {
        if (valueChains) {
            const translatedValueChain = valueChains.map(valueChain => translateItem(valueChain, i18n.language));
            setValueChainList(translatedValueChain)
        }
    }, [valueChains, i18n.language]);

    const [userData, setUserData] = useState({
        firstName: null,
        lastName: null,
        email: null,
        organisation: null,
        position: null,
        interestTags: [],
        segmentSelected: [],
        valueChainSelected: [],
        problemMatches: [],
        ownProblems: []
    });

    useEffect(() => {
        if (profile) {
            setUserData({
                firstName: profile.firstName || null,
                lastName: profile.lastName || null,
                email: profile.email || null,
                organisation: profile.organisation || null,
                position: profile.position || null,
                interestTags: profile.interestTags || [],
                segmentSelected: profile.segmentSelected || [],
                valueChainSelected: profile.valueChainSelected || [],
                problemMatches: profile.matches || [],
                ownProblems: profile.ownProblems || []
            })
        }
    }, [profile]);

    const {
        problemMatches,
        ownProblems
    } = userData;

    const onDelete = (e) => {
        e.preventDefault()
        deleteUserProfile().then(() => {
            console.log('logout')
            logout();
            history.push('/');
        })
    }

    const iconClass = 'h-6 cursor-pointer'
    const hoverInfoClass = 'absolute top-8 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200 z-20'
    const spacing = 'mr-6'

    return (
        <>
            <PageWrapper scroll>
                <Modal
                    id='modalUserDelete'
                    title={text.modalTitle}
                    onSave={onDelete}
                    saveText={text.modalTitle}
                    saveBg='bg-red-700'
                    closeText={text.cancel}
                    backdrop='true'
                >
                    {text.modalText}
                </Modal>
                <FormContainer width={'w-full xl:w-3/4 2xl:w-2/3'}>
                    <div id="userProfilePage-buttonArea"
                        className="flex flex-row space-x-2 justify-end">
                        {/* Edit / Cancel Button */}
                        <span className='group relative'>
                            <FontAwesomeIcon
                                id='userProfile-toggleEditActive-btn'
                                className={`${iconClass} ${spacing} text-primary-700`}
                                onClick={() => setEditActive(!editActive)}
                                icon={editActive ? faTimes : faEdit} />
                            <span
                                className={hoverInfoClass}
                                style={{ left: 'calc(50% - 35px)' }}>
                                {editActive ? t('Cancel') : t('Edit')}
                            </span>
                        </span>
                        {/* Delete Button */}
                        <span className='group relative'
                            data-bs-toggle='modal'
                            data-bs-target='#modalUserDelete'
                        >
                            <FontAwesomeIcon
                                id='userProfile-delete-btn'
                                className={`${iconClass} ${spacing} text-red-700`}
                                icon={faTrash}
                            />
                            <span
                                className={hoverInfoClass}
                                style={{ left: 'calc(50% - 35px)' }}>
                                {t('Delete')}
                            </span>
                        </span>
                    </div>
                    {editActive ?
                        // Displays editable form
                        <ProfileForm
                            data={userData}
                            segmentListData={segmentList}
                            valueChainListData={valueChainList}
                            setEditActive={setEditActive} />
                        :
                        // Displays normal user profile             
                        <DisplayProfile
                            userData={userData}
                            segmentList={segmentList}
                            valueChainList={valueChainList}
                            problemMatches={problemMatches}
                            ownProblems={ownProblems}
                            owner
                        />
                    }
                </FormContainer>
            </PageWrapper>
        </>
    );
}

OwnUserProfilePage.propTypes = {
    profile: PropTypes.object.isRequired,
    deleteUserProfile: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    segments: PropTypes.array,
    valueChains: PropTypes.array
};

const mapStateToProps = state => ({
    profile: state.profileReducer.profile,
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains
});


export default connect(mapStateToProps, { deleteUserProfile, logout })(OwnUserProfilePage);
import { useReducer, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getProblemById } from "actions/problem.get.actions";

import ProblemContext from "./ProblemContext";
import { emptyProblemState } from "./EmptyState";

const problemReducer = (state, action) => {
    if (action.type === 'FETCH_DATA') {
        return action.payload
    }
    if (action.type === 'CHANGE') {
        return {
            data: ({ ...state.data, [action.name]: action.e.target.value })
        };
    }
    if (action.type === 'CHANGE_ITEM') {
        return {
            data: ({ ...state.data, [action.name]: action.data })
        }
    }
    if (action.type === 'CHANGE_OBJECT') {
        const tempData = { ...state.data }
        tempData[action.objectName][action.name] = action.data;
        return {
            data: tempData
        }
    }
    if (action.type === 'CHANGE_ROW') {
        const tempData = { ...state.data }
        tempData[action.tablename][action.index][action.name] = action.e.target.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'EDIT_ROW') {
        const tempData = { ...state.data }
        tempData[action.tablename][action.index][action.name] = action.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'NEW_ROW') {
        let tempData = [...state.data[action.name]]
        let newId = 1;
        if (tempData?.length > 0) {
            newId = tempData[tempData?.length - 1].id + 1
        }
        const newRow = { id: newId }
        return {
            data: { ...state.data, [action.name]: [...state.data[action.name], newRow] }
        }
    }
    if (action.type === 'DELETE_ROW') {
        const tempData = { ...state.data }
        tempData[action.name] = tempData[action.name].filter(data => data.id !== action.id);
        return {
            data: tempData
        }
    }
    if (action.type === 'COREMETRIC_BASEVALUE_CHANGE') {
        const tempData = { ...state.data }
        tempData.coreMetrics[action.index].baselineValue = action.e.target.value;
        tempData.coreMetrics[action.index].target = action.e.target.value;
        tempData.coreMetrics[action.index].deltaTarget = 0;
        return {
            data: tempData
        }
    }
    if (action.type === 'COREMETRIC_TARGET_CHANGE') {
        const tempData = { ...state.data }
        const deltaTarget = parseInt(action.e.target.value) - tempData.coreMetrics[action.index].baselineValue;
        tempData.coreMetrics[action.index].target = action.e.target.value;
        tempData.coreMetrics[action.index].deltaTarget = deltaTarget;
        return {
            data: tempData
        }
    }
    return state
}

function ProblemProvider(props) {

    const [problemState, dispatchProblemAction] = useReducer(problemReducer, emptyProblemState);

    //console.log(problemState)

    // fetching data from DB if required 
    useEffect( () => {
        // console.log(props.problemId)
        if (props.problemId) {

    //         const authHeader = getAuthHeader(); 

    //         const config = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
    //             },
    //             params: {id: props.problemId}
    //         };

    //         return axios
    //         .get('/api/problems', config)
    //         .then(
    //             response => {
    //                 dispatchProblemAction({ type: 'FETCH_DATA', payload: toCamelCase(response) })
    //             },
    //             error => {
    //                 // TODO: implement
    //                 if (error.response) {
    //                     // Request made and server responded
    //                     // TODO: implement
    //                     // dispatch(alertActions.error(error.response.data));
    //                 } else if (error.request) {
    //                     // The request was made but no response was received
    //                     // TODO: implement
    //                     // dispatch(alertActions.error(error.request));
    //                 } else {
    //                     // Something happened in setting up the request that triggered an Error
    //                     // TODO: implement
    //                     // dispatch(alertActions.error(error.message));
    //                 }
    //             }
    // )}

            props.getProblemById(props.problemId)
            //console.log(props.problems.problem)
            //const data = { data: props.problems.problem } 
            //dispatchProblemAction({ type: 'FETCH_DATA', payload: data })
        }
        
    }, []);

    useEffect( () => {
        if (props.problemId && props.problemReducer.problem) {
            const data = { data: props.problemReducer.problem } 
            dispatchProblemAction({ type: 'FETCH_DATA', payload: data })
        }
    }, [props.problemReducer.problem]);

    //console.log(props.problems.problem)
    // if(props.problems.problem && props.problems.problem !== 'undefined') {
    //     const data = { data: props.problems.problem } 
    //     dispatchProblemAction({ type: 'FETCH_DATA', payload: data })
    // }

    const changeDataHandler = (name) => (e) => {
        dispatchProblemAction({ type: 'CHANGE', name, e })
    }
    const changeItemHandler = (name, data) => {
        dispatchProblemAction({ type: 'CHANGE_ITEM', name, data })
    }
    const changeObjectHandler = (objectName, name, data) => {
        dispatchProblemAction({ type: 'CHANGE_OBJECT', objectName, name, data })
    }
    const changeRowHandler = (tablename, name, index, e) => {
        dispatchProblemAction({ type: 'CHANGE_ROW', tablename, name, index, e })
    }
    const editRowHandler = (tablename, name, index, value) => {
        dispatchProblemAction({ type: 'EDIT_ROW', tablename, name, index, value })
    }
    const newRowHandler = (name) => {
        dispatchProblemAction({ type: 'NEW_ROW', name })
    }
    const deleteRowHandler = (name, id) => {
        dispatchProblemAction({ type: 'DELETE_ROW', name, id })
    }
    const coremetricBasevalueHandler = (index, e) => {
        dispatchProblemAction({ type: 'COREMETRIC_BASEVALUE_CHANGE', index, e })
    }
    const coremetricTargetHandler = (index, e) => {
        dispatchProblemAction({ type: 'COREMETRIC_TARGET_CHANGE', index, e })
    }

    const ProblemContextValue = {
        data: problemState.data,
        handleChange: changeDataHandler,
        handleObjectChange: changeObjectHandler,
        itemHandler: changeItemHandler,
        rowHandler: changeRowHandler,
        editRowHandler: editRowHandler,
        newRowHandler: newRowHandler,
        deleteRowHandler: deleteRowHandler,
        coremetricBasevalueHandler: coremetricBasevalueHandler,
        coremetricTargetHandler: coremetricTargetHandler,
    }

    return <ProblemContext.Provider value={ProblemContextValue}>
        {props.children}
    </ProblemContext.Provider>
}

ProblemProvider.propTypes = {
    problemReducer: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
      ]),
    getProblemById: PropTypes.func.isRequired
  };
  
  const mapStateToProps = (state) => ({
      problemReducer: state.problemReducer
  });

  export default connect(mapStateToProps, { getProblemById })(ProblemProvider);
  // export default ProblemProvider;
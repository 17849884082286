function PageWrapper({ id, flexDirection, flexJustify, scroll, children }) {
    return (
        <div id={`pageWrapper-` + id}
            className={`
            ${flexDirection ? flexDirection : 'flex-col'}
            ${flexJustify ? flexJustify : 'justify-between'}
            ${scroll && 'overflow-auto'}
            h-full w-full px-4 sm:px-6 lg:px-8 flex`}>
            {children}
        </div>
    );
}

export default PageWrapper;
import React from 'react';

import ProblemProvider from './context/ProblemProvider';

import NewProblemForm from './NewProblemForm'

function CreateProblemPage() {
    return (
        <ProblemProvider>
            <NewProblemForm />
        </ProblemProvider>
    );
}


export default CreateProblemPage;
import { alertActionTypes as types } from 'constants/alert.action.types';


const initialState = (window.Cypress && window.initialState && window.initialState.alertReducer) || {};

export function alertReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SUCCESS:
      return {
        type: 'success',
        text: payload?.message || payload?.msg || payload?.reason || payload || 'Done successfully'
      };
    case types.ERROR:
      if (payload) {
        return {
          type: 'danger',
          text: payload?.message || payload?.msg || payload?.reason || payload //|| 'Something went wrong'
        };
      } else {
        return {}
      }
    case types.CLEAR:
      return {}
    default:
      return state;
  }
}

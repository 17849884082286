import { useTranslation } from "react-i18next";
  
  export default function Copyright() {

    const {t} = useTranslation(['common']);

    const year = new Date().getFullYear();

    return (
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl pb-2 pt-1">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; {`${year} solverint GmbH. ${t('All rights reserved')}.`}
            </p>
        </div>
      </footer>
    )
  }
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from "react-i18next";
import SingleCommentStructure from './SingleCommentStructure';
import NewCommentInput from './NewCommentInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from "@fortawesome/free-solid-svg-icons";
import VotingArea from 'components/VotingArea';
import { CommentContext } from './context/CommentContext';

function SingleComment({ comment, index }) {
    const { profile, upVote, downVote, calcUserVotes } = useContext(CommentContext)
    const replyRef = useRef()
    const { t } = useTranslation(['common']);
    const [replyList, setReplyList] = useState(comment.replies || [])
    const [replyListOpen, setReplyListOpen] = useState(false)
    const [activeReply, setActiveReply] = useState(null)
    const [subReplyActive, setSubReplyActive] = useState(null)

    useEffect(() => {
        if (comment.replies) {
            setReplyList(comment.replies)
        }
    }, [comment])

    const handleSubReplyClick = (index) => {
        setActiveReply(replyList[index])
        setSubReplyActive(true)
    }

    // resets state after submit
    const resetAfterSubmit = () => {
        setSubReplyActive(false)
        setActiveReply(comment)
    }

    // toggles reply list and scrolls to reply input field if open
    const handleReplyList = () => {
        setReplyListOpen(!replyListOpen)
        setSubReplyActive(false)
        if (!replyListOpen && replyRef.current) {
            replyRef.current.focus();
            replyRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start"
            })
        }
    }

    return (
        <>
            <SingleCommentStructure
                comment={comment}
                index={index}
            />
            <div className='pl-14'>
                <div className='flex justify-between'>
                    <div className='flex space-x-2'
                        data-bs-target={'#replies' + comment.id}
                        data-bs-toggle='collapse'
                        onClick={() => handleReplyList()}>
                        <div className='pb-2 cursor-pointer text-xs'>
                            {t('Reply')}
                        </div>
                        {/* Number of replies */}
                        {replyList?.length > 0 &&
                            <div className='pb-2 cursor-pointer text-xs font-semibold bg-primary-100 rounded-full h-4 px-2 text-center'>
                                {replyList.length}
                            </div>}
                    </div>
                    <div className='-mt-1'>
                        <VotingArea
                            size="small"
                            hoverInfo={false}
                            userVotes={comment.userVotes}
                            voteCount={calcUserVotes(comment)}
                            position={'top-8 right-0'}
                            upVote={() => upVote(comment)}
                            downVote={() => downVote(comment)}
                            upVoteClicked={comment.userVotes?.find(obj => obj.userId === profile.userId)?.vote === 1}
                            downVoteClicked={comment.userVotes?.find(obj => obj.userId === profile.userId)?.vote === -1}
                        />
                    </div>
                </div>
                {replyListOpen &&
                    <>
                        <div className='overflow-y-auto pr-2' style={{ maxHeight: "50vh" }}>
                            {replyList?.length > 0 &&
                                replyList.map((replyComment, index) => (
                                    <div key={index}>
                                        <SingleCommentStructure
                                            key={index}
                                            comment={replyComment}
                                            index={index}
                                            replyToObj={replyComment.replyTo ? replyList.find(r => r.id === replyComment.replyTo) : null}
                                        />
                                        <div className='flex justify-between'>
                                            <div className='pl-14 pb-2 cursor-pointer text-xs'
                                                onClick={() => handleSubReplyClick(index)}>
                                                {t('Reply')}
                                            </div>
                                            <div className='-mt-1'>
                                                <VotingArea
                                                    size="small"
                                                    hoverInfo={false}
                                                    userVotes={replyComment.userVotes}
                                                    voteCount={calcUserVotes(replyComment)}
                                                    position={'top-8 right-0'}
                                                    upVote={() => upVote(replyComment)}
                                                    downVote={() => downVote(replyComment)}
                                                    upVoteClicked={replyComment.userVotes?.find(obj => obj.userId === profile.userId)?.vote === 1}
                                                    downVoteClicked={replyComment.userVotes?.find(obj => obj.userId === profile.userId)?.vote === -1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {/* Reply and Subreply input */}
                        <div ref={replyRef} style={{ scrollMarginTop: '80px' }} className='bg-white pt-1 mb-3'>
                            <div className='relative'>
                                <NewCommentInput
                                    replyToObj={subReplyActive ? activeReply : comment}
                                    parentHandleSubmit={resetAfterSubmit}
                                    parentReply={!subReplyActive}
                                />
                                {subReplyActive &&
                                    <FontAwesomeIcon
                                        className="absolute top-4 right-5 w-5 h-6 md:w-3 md:h-4 text-gray-600 hover:text-gray-800 cursor-pointer"
                                        icon={faX}
                                        onClick={() => setSubReplyActive(false)} />}
                            </div>
                        </div>
                    </>}
            </div>
        </>
    );
}

export default SingleComment;
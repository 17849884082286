import React from 'react';

function Container({ id, children, maxW, className }) {
    return (
        <div id={id} className={`${className} ${maxW ? maxW : 'max-w-7xl'} mx-6 p-4 sm:p-6 lg:p-8`}>
            <div className="w-full">{children}</div>
        </div>
    );
}

export default Container;
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import FormContainer from "components/FormContainer";
import Button from "components/Button";
import GuideForm from "components/Guide/GuideForm";

import CreateIssueContext from "../context/create-issue-context";
import HypothesisSubForm from "./HypothesisSubForm";

function HypothesisForm() {
    const ctx = useContext(CreateIssueContext);
    const { t } = useTranslation();
    const resolutionCount = ctx.data.resolutionHypothesis?.length;
    const text = {
        headline: t('1-ip-resolution-title', "Sketch out one or competing resolution hypotheses and the required proof points based on what you learnt so far."),
        button: t('3-rh-btn1', 'Add Hypothesis'),
    }
    const guideText = [
        {
            header: t('3-rh-g1h', 'Resolution hypothesis'),
            text: t('3-rh-g1t', `A resolution hypothesis is a proposed resolution for the prioritized issue in order to contribute to solving the entire problem which cannot currently be proven right away, but which should respect certain quality criteria (e.g., being plausible, testable, clear, concise, ...). In practice, the hypothesis will mostly chart out a future course of action or a forecast, but it can also explain past phenomena, or, in rare cases, try to prove the truth of a statement`),
        },
        {
            header: t('3-rh-g2h', 'Argument'),
            text: t('3-rh-g2t', `An argument is a statement used to support the hypothesis; this statement should be enriched by a compelling reasoning and, if possible, examples, proofs, analogies, testimonials, quotations, benchmarks etc.  
            \nIn a pure case like mathematics, the reasoning would objectively establish or prove the truth of the statement. However, oftentimes no certain truth will be established, only a high likelihood of the statement being valid`),
        },
        {
            header: t('3-rh-g3h', 'How to quantify'),
            text: t('3-rh-g3t', `• Start with your baseline numbers - these should be your starting point 
            \n• Estimate how much the factor could contribute to your target 
            \n• Use your team and experts to solidify the numbers, or use heuristics if it helps 
            \n• Estimate realistically and, perhaps, rather conservatively 
            \n• Define targeted analyses to validate the numbers`),
        },
        {
            header: t('3-rh-g4h', 'Ease of implementation '),
            text: t('3-rh-g4t', `• EoI answers the question: “How likely is it I will successfully use the factor to reach my target?” 
            \n• Firstly, can you influence the factor directly or indirectly? If not, then implementation could be close to impossible 
            \n• Can you also address it, or are you prohibited from doing so, e.g., for formal reasons? 
            \n• If you can influence and address the factor, then think about technical, juridical, budgetary, stakeholder-related, political, reputational, and other obstacles. Think about the constraints you defined. Consequently, a factor infringing a hard constraint should carry an “impossible” EoI label  
            \n• An example of “very high” might be: Change a rule you are entitled to change that gets in effect immediately without sparking any substantial resistance, e.g., “do not stay in five star superior hotels when travelling” 
            \n• An example of “very low” might be: Replace all IT core systems within one year `),
        },
        {
            header: t('3-rh-g5h', 'Proof Points'),
            text: t('3-rh-g5t', `The proof points are the backbone of the reasoning within the arguments that support or refute the hypothesis. 
            Thus, this work is critical, and should be as targeted as possible along the logical chain: hypothesis – argument – reasoning – proof point `),
        },
        {
            header: t('3-rh-g6h', 'Literature'),
            text: t('3-rh-g6t', `We highly recommend chapter 4 from the following book, which did greatly inspire this module, especially the link between hypothesis and analysis: 
            \nConn, C. & McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley `),
        },
    ];

    const maxHypothesis = 4;
    const thisName = 'resolutionHypothesis';
    const [guideActive, setGuideActive] = useState(false);

    const handleHypothesis = (index) => {
        if (index < maxHypothesis - 1) {
            ctx.newRowHandler(thisName);
        }
    }

    return (
        <div id="resolutionHypothesisForm">
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4 2xl:w-1/4'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={true} onGuideActive={guideActive}
                guideWidth={"xl:w-2/3 lg:w-3/5"}
                width="2xl:w-2/3 xl:w-2/3 w-5/6"
                title={text.headline}>
                {/* Subforms with Data */}
                <HypothesisSubForm />
                {/* Add Resolution Button */}
                {(resolutionCount < (maxHypothesis - 1)) &&
                    <div>
                        <Button
                            className={"mt-2 float-right"}
                            onClick={() => handleHypothesis(resolutionCount)}>
                            <span >
                                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                                {text.button}
                            </span>
                        </Button>
                    </div>
                }
            </FormContainer>
        </div>
    );
}

export default HypothesisForm;
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import LabelWithSmarttips from "./LabelWithSmarttips";
import Tooltip from "./Tooltip";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
/**
 * Input Element
 * Can be used for simple input or textarea [textarea={true}] or other [other={true}]
 * If you use 'other' children can be used as the input element
 * important:
 * id, label, value, onChange
 * Disables tooltips and smarttips on overview [overview={true}]
 * optional: 
 * add {tooltip} 
 * add {tips} for Smarttiplist [{title, description}]
 * type: default 'text'
 * placeholder: default is '...'
 * minRows: for Textarea, default is 3
 */
function Input({ id, type, label, description, value, onChange, placeholder, required,
    textarea, minRows, maxRows, other, children,
    tooltip, tips, overview, display, labelClass, collapsable, defaultClosed }) {
    const { t } = useTranslation(['common']);

    const input = (!textarea && !other);
    const [open, setOpen] = useState(defaultClosed ? false : true)
    const [isTouched, setIsTouched] = useState(false)

    const handleBlur = () => {
        setIsTouched(true)
    };

    const requiredInfo = <span title={t("This field is required", { ns: 'common' })} className="text-red-600 px-1">*</span>

    const inputStyle = `${(required && isTouched && !value) && 'border-red-500'} shadow-sm focus:ring-primary-500 focus:border-primary-500 
    block w-full sm:text-sm border-gray-300 rounded-md`

    return (
        <div id="inputElement" className="w-full">
            {(tooltip && !overview) &&
                <Tooltip content={tooltip} />
            }
            <span
                onClick={collapsable ? () => setOpen(!open) : null}
                data-bs-toggle={collapsable && "collapse"}
                href={collapsable && "#" + id + "collapse"}
                className={`${labelClass} ${collapsable && 'transition duration-150 ease-in-out cursor-pointer'}`}>
                {(tips && !overview) ?
                    <LabelWithSmarttips id={id + 'Tips'} description={description} title={label} tips={tips} />
                    :
                    <label htmlFor={id}
                        className={`${collapsable ? 'inline' : 'block'} select-none text-base font-medium text-gray-700 mb-1`}>
                        {label}
                        {required && requiredInfo}
                    </label>
                }
                {collapsable &&
                    <>
                        {open
                            ? <ChevronUpIcon className='text-gray-700  ml-2 w-5 h-5 inline' />
                            : <ChevronDownIcon className='text-gray-700  ml-2 w-5 h-5 inline' />}
                    </>
                }
            </span>
            {/* Different input elements, 'Textarea', 'Other' default is 'Input' */}
            <div className={`${collapsable && 'collapse'}`} id={id + 'collapse'}>
                {textarea &&
                    <TextareaAutosize
                        type="text"
                        minRows={minRows || '3'}
                        maxRows={maxRows}
                        id={id}
                        name={id}
                        className={inputStyle}
                        placeholder={placeholder || "..."}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={handleBlur}
                    />
                }
                {other &&
                    children
                }
                {input &&
                    <input
                        id={id}
                        className={`${display ? display : 'block w-full'} ${inputStyle}`}
                        type={type || 'text'}
                        name={id}
                        placeholder={placeholder || '...'}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={handleBlur}
                        required={required}
                    />
                }
            </div>
        </div>
    );
}

export default Input;
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import FlagCompactGrid from "components/FlagCompactGrid";

import CreateProblemContext from '../context/ProblemContext';

function ProblemRatingFlagGrid(props) {
    const ctx = useContext(CreateProblemContext);
    const { t } = useTranslation();
    const confidentialRating = [
        {
            name: 'EASY',
            text: t('1-pr-r1t', 'Easy'),
            color: 'bg-red-400',
            hoverInfo: t('1-pr-explanation1', 'E.g.\n Emergency start your car according to a manual'),
        },
        {
            name: 'COMPLICATED',
            text: t('1-pr-r2t', 'Complicated'),
            color: 'bg-yellow-400',
            hoverInfo: t('1-pr-explanation2', 'E.g.\n Perform maintenance on a power plant'),
        },
        {
            name: 'COMPLEX',
            text: t('1-pr-r3t', 'Complex'),
            color: 'bg-yellow-200',
            hoverInfo: t('1-pr-explanation3', 'E.g.\n Developing a car for a new market'),
        },
        {
            name: 'CHAOTIC',
            text: t('1-pr-r4t', 'Chaotic'),
            color: 'bg-green-200',
            hoverInfo: t('1-pr-explanation4', 'E.g.\n Respond to lockdown of car production'),
        },
    ]

    const selectFlag = (index) => {
        ctx.itemHandler('rating' , index)
    }

    return (
        <FlagCompactGrid
            id='ConfidenialFlagGrid'
            data={confidentialRating}
            name='rating'
            tooltipActive={props.tooltipActive}
            active={ctx.data.rating}
            setActiveFlag={selectFlag}
            editActive={props.editActive}
        />
    );
}

export default ProblemRatingFlagGrid;
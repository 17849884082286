// Frameless Input component with readonly switch to 'label' component
function InputSwitch(props) {
    return (
        <div>
            {props.editActive ?
                <input
                    readOnly={!props.editActive}
                    className={`
                        ${props.className}
                        ${props.padding || "pl-1 p-0"}
                        ${props.editActive && "focus:border-1 focus:border-blue-200 focus:bg-white"}
                    text-base font-small
                    border border-primary-200 rounded-md`}
                    type={props.type}
                    value={props.value || undefined}
                    onChange={props.onChange}
                    placeholder={props.placeholder || props.placeholder}
                />
                :
                <label className={`
                ${props.className} 
                ${props.padding} 
                ${'font-bold'} 
                text-base font-small text-gray-700`}>
                    {props.value || props.placeholder}
                </label>
            }

        </div>
    );
}

export default InputSwitch;
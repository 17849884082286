function Button({bg, hover, focus, id, type, text, disabled, className, onClick, databstoggle, databstarget, databsdismiss, children }) {
    bg = bg || 'bg-primary-600';
    hover = hover || 'hover:bg-primary-700'
    focus = focus || 'focus:ring-primary-500'

    return (
        <button
            id={id}
            type={type}
            className={`inline-flex justify-center 
            py-2 px-4 leading-5
            border border-transparent 
            rounded-md shadow-sm text-base font-medium 
            ${text || "text-white"} 
            ${disabled ? 'bg-gray-400' : `${bg} ${hover} ${focus} focus:outline-none focus:ring-2 focus:ring-offset-2`}
            transition duration-150 ease-in-out
            disabled:opacity-75 disabled:cursor-default 
            ${className}`}
            onClick={onClick}
            disabled={disabled}
            data-bs-toggle={databstoggle}
            data-bs-target={databstarget}
            data-bs-dismiss={databsdismiss}
        >
            {children}
        </button>
    );
}

export default Button;
const profileActionTypes = {
    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
    CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
    CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE', // TODO: not used, replaced with 'ALERT_SUCCESS'

    DELETE_PROFILE_REQUEST: 'DELETE_PROFILE_REQUEST',
    DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    DELETE_PROFILE_FAILURE: 'DELETE_PROFILE_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { profileActionTypes };
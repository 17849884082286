import { useState } from "react";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import Tooltip from "components/Tooltip";
import GuideForm from "components/Guide/GuideForm";

import CriticalDefinitionsTable from "./CriticalDefinitionsTable";

function CriticalDefinitionsForm() {
    const [guideActive, setGuideActive] = useState(false);
    const { t } = useTranslation();

    const text = {
        headline: t('1-cd-hl',"Critical Definitions"),
        description: t('1-cd-desc',"Critical definitions you want to make clear"),
        tooltip: t('1-cd-tooltip','Especially when involving a diverse team, we recommend clearly defining important terms'),
    }

    //TODO English Guide is missing
    const guideText = [
        { header: t('1-cd-g1h', ''), text: t('1-cd-g1t', '') },
    ]

    return (
        <div>
            <GuideForm guideContent={guideText} active={guideActive} onClose={() => setGuideActive(!guideActive)}/>
            <FormContainer openGuide={() => setGuideActive(!guideActive)}
                title={text.headline} guide={true} onGuideActive={guideActive}
                width="2xl:w-4/5 w-full">
                <div>
                    <Tooltip content={text.tooltip} breakpoint={guideActive ? '' : '2xl'}/>
                    <label className="text-base font-medium text-gray-700 select-none">{text.description}</label>
                    <CriticalDefinitionsTable editActive={true}/>
                </div>
            </FormContainer>
        </div>
    );
}

export default CriticalDefinitionsForm;
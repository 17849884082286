import { useTranslation } from "react-i18next";

import CardGrid from "components/CardGrid";
import Card from "components/Card";
import FormContainer from "components/FormContainer";
import Button from "components/Button";

import Modal from "components/Modal";

import BasicProfitabilityFrameworkSvg from '../Images/svg/Folie7-BasicProfitabilityFramework.SVG';
import FunnelSvg from '../Images/svg/Folie15-Funnel.SVG';
import SameLevelExampleSvg from '../Images/svg/Folie22-SameLevel.SVG';
import DecisionTreeExampleSvg from '../Images/svg/Folie33-DecisionTree.SVG';
import SmallEquationSvg from '../Images/svg/Gleichung_ENG.svg';
import SmallFunnelSvg from '../Images/svg/TRICHTER_ENG.svg';
import SmallSameLevelSvg from '../Images/svg/Begriffe_ENG.svg';
import SmallDecisionTreeSvg from '../Images/svg/Decision_tree_ENG.svg';

//TODO Translation
//TODO Exchange images on language switch
function Step2ChooseTechnique() {
    const { t } = useTranslation();
    const text = {
        pageTitle: t('Choose your technique'),
        card1Title: t('Context'),
        card1Text: t('Choose a technique that  works best in your situation'),
        card2Title: t('Completeness'),
        card2Text: t(`Try to capture all the relevant factors that can contribute to the resolution of your problem, as specified in your Key Results`),
        card3Title: t('Ease'),
        card3Text: t('Often, starting with elements on the same logical level can achieve great results - move into other techniques gradually'),
        techCardIntro: t('Area of application: '),
        techCard1Title: t('Equation'),
        techCard1Text: t(`Your target can be modelled mathematically and ideally broken down on multiple levels`),
        techCard2Title: t('Process / Funnel'),
        techCard2Text: t(`All or some of the factors contributing to your target are part of a process`),
        techCard3Title: t('Elements on the same logical level'),
        techCard3Text: t(`When you are very much at the beginning, but you know there are multiple components contributing to your target`),
        techCard4Title: t('Decision tree'),
        techCard4Text: t(`When you need to answer sequential “if-then” questions`),
        more: t('Click for Example')
    }

    // Download Buttons for Modals
    const modalButton1 = <a id="a-DownloadBasic" href={BasicProfitabilityFrameworkSvg} download='BasicProfitabilityFramework'>
        <Button>{t('Download')}</Button></a>
    const modalButton2 = <a id="a-DownloadFunnel" href={FunnelSvg} download='DigitalMarketingFunnel'>
        <Button>{t('Download')}</Button></a>
    const modalButton3 = <a id="a-DownloadSameLevel" href={SameLevelExampleSvg} download='SameLevelFramework'>
        <Button>{t('Download')}</Button></a>
    const modalButton4 = <a id="a-DownloadDecision" href={DecisionTreeExampleSvg} download='DecisionTree'>
        <Button>{t('Download')}</Button></a>

    const cardContentClasses = 'flex flex-col place-content-between h-full space-y-2 text-center';
    const cardTextClass = 'xs:h-full xl:h-32 2xl:h-28 text-sm';
    const cardTextStyle = { minHeight: '60px' }
    const cardPadding = 'px-2 py-2';

    const more = <span className="hidden text-sm md:block my-auto text-center text-primary-600 font-semibold"> {text.more}</span>

    return (
        <div id="step-chooseTechnique">
            {/* Modals with example pictures */}
            <Modal id='equationStructure' title={text.techCard1Title} size='modal-xl'
                buttons={modalButton1}>
                <img src={BasicProfitabilityFrameworkSvg} />
            </Modal>
            <Modal id='funnelStructure' title={text.techCard2Title} size='modal-xl'
                buttons={modalButton2}>
                <img src={FunnelSvg} />
            </Modal>
            <Modal id='sameLevelStructure' title={text.techCard3Title} size='modal-xl'
                buttons={modalButton3}>
                <img src={SameLevelExampleSvg} />
            </Modal>
            <Modal id='decisionTree' title={text.techCard4Title} size='modal-xl'
                buttons={modalButton4}>
                <img src={DecisionTreeExampleSvg} />
            </Modal>

            <FormContainer title={text.pageTitle} width="x" style={{ width: '95%' }}>
                <div className="flex space-y-4 xl:space-x-4 xl:space-y-0 flex-col xl:flex-row">
                    <CardGrid gridCol="grid-cols-1">
                        {/* Context Card */}
                        <Card title={text.card1Title} text={text.card1Text} justify='justify-top'
                            noClick={true} padding='px-2 py-2'>
                            {/* CardGrid with technique examples */}
                            <CardGrid gridCol="grid-cols-1 sm:grid-cols-2 md:grid-cols-4" gap={'gap-2'}>
                                <Card title={text.techCard1Title} justify='justify-top' padding={cardPadding}
                                    databstoggle='modal' databstarget='#equationStructure'>
                                    <div className={cardContentClasses}>
                                        <img src={SmallEquationSvg} />
                                        <div className="flex flex-col">
                                            <p className={cardTextClass} style={cardTextStyle}>
                                                <span className="font-semibold">{text.techCardIntro}</span>
                                                {text.techCard1Text}
                                            </p>
                                            {more}
                                        </div>
                                    </div>
                                </Card>
                                <Card title={text.techCard2Title} justify='justify-top' padding={cardPadding}
                                    databstoggle='modal' databstarget='#funnelStructure'>
                                    <div className={cardContentClasses}>
                                        <img className="my-auto" src={SmallFunnelSvg} />
                                        <div className="flex flex-col">
                                            <p className={cardTextClass} style={cardTextStyle}>
                                                <span className="font-semibold">{text.techCardIntro}</span>
                                                {text.techCard2Text}</p>
                                            {more}
                                        </div>
                                    </div>
                                </Card>
                                <Card title={text.techCard3Title} justify='justify-top' padding={cardPadding}
                                    databstoggle='modal' databstarget='#sameLevelStructure'>
                                    <div className={cardContentClasses}>
                                        <img className="mx-auto" style={{width: '70%'}} src={SmallSameLevelSvg} />
                                        <div>
                                            <p className={cardTextClass} style={cardTextStyle}>
                                                <span className="font-semibold">{text.techCardIntro}</span>
                                                {text.techCard3Text}</p>
                                            {more}
                                        </div>
                                    </div>
                                </Card>
                                <Card title={text.techCard4Title} justify='justify-top' padding={cardPadding}
                                    databstoggle='modal' databstarget='#decisionTree'>
                                    <div className={cardContentClasses}>
                                        <img src={SmallDecisionTreeSvg} />
                                        <div>
                                            <p className={cardTextClass} style={cardTextStyle}>
                                                <span className="font-semibold">{text.techCardIntro}</span>
                                                {text.techCard4Text}</p>
                                            {more}
                                        </div>
                                    </div>
                                </Card>
                            </CardGrid>
                        </Card>
                    </CardGrid>
                    <CardGrid gridCol='grid-cols-1'>
                        <Card title={text.card2Title} text={text.card2Text} justify='justify-top' noClick={true} />
                        <Card title={text.card3Title} text={text.card3Text} justify='justify-top' noClick={true} />
                    </CardGrid>
                </div>
            </FormContainer>
        </div>
    );
}

export default Step2ChooseTechnique;
import { loadingActionTypes as types } from 'constants/loading.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.loadingReducer) || {
  activeLoadings: 0
};

export function loadingReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case types.LOADING_STARTED:
      return {
        activeLoadings: state.activeLoadings + 1
      };
    case types.LOADING_FINISHED:
      return {
        activeLoadings: state.activeLoadings - 1
      };
    default:
      return state;
  }

};


/**
 * 
 * @param {id, children, className, databstoggle, databstarget, databsdismiss}  
 * @returns Frame for cards without any content 
 * has data-bs-* for controlling modals 
 */
function EmptyCardFrame({ id, children, className, databstoggle, databstarget, databsdismiss }) {
    return (
        <div id={id}
            className={`${className} relative flex shadow-sm rounded-md border border-gray-200`}
            data-bs-toggle={databstoggle}
            data-bs-target={databstarget}
            data-bs-dismiss={databsdismiss}>
            {children}
        </div>
    );
}

export default EmptyCardFrame;
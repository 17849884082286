import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { alertActionTypes } from 'constants/alert.action.types';
import AlertBox from 'components/AlertBox';

// General Alerting based on tailwind-ui examples:
// https://tailwindui.ccomponents/application-ui/feedback/alerts

function Alert({ type, title, children }) {

    const [show, setShow] = useState(true)

    const dispatch = useDispatch()

    // TODO: timeout does not work on login
    // useEffect(() => {
    //     const timeId = setTimeout(() => {
    //       // After 3 seconds set the show value to false
    //       setShow(false)
    //     }, 3000)

    //     return () => {
    //       clearTimeout(timeId)
    //     }
    //   }, [timeout]);

    useEffect(() => {
        if (!show) {
            dispatch({ type: alertActionTypes.CLEAR })
        }
    }, [show]);

    const onClick = (e) => {
        e.preventDefault()
        dispatch({ type: alertActionTypes.CLEAR })
    }

    if (!show) {
        return null;
    }

    if (type === 'success') {
        return (
            <AlertBox
                title={title}
                onClick={onClick}
                children={children}
                color={'green'} />
        )
    }
    else if (type === 'warning') {
        return (
            <AlertBox
                title={title}
                onClick={onClick}
                children={children}
                color={'yellow'} />
        )
    }
    else if (type === 'danger') {
        return (
            <AlertBox
                title={title}
                onClick={onClick}
                children={children}
                color={'red'} />
        )
    }
    else if (type === 'info') {
        return (
            <AlertBox
                title={title}
                onClick={onClick}
                children={children}
                color={'red'} />
        )
    }

    return (null);
}


Alert.propTypes = {
    props: PropTypes.object
};

export default Alert;

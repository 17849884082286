import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import FormContainer from "components/FormContainer";
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from 'components/LabelWithSmarttips';

import TopicContext from '../context/TopicContext';

/**
 * Subform for 'create problem'
 * contains fields: situation, complication
 */
function SituationsAndComplicationsForm({ currentCommunity }) {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        headline: t('1-sc-hl', "Situation and Complication"),
        describeSituation: t('1-sc-prompt1', "Describe the fact-based “is” situation"),
        situationPlaceholder: "...",
        situationTooltip: t('1-sc-tooltip1', `E.g.\n „School building from 1980, no substantial overhaul done. 
        1000 pupils, 50 teachers. Gas-powered heating from 1980, ~70 thsd. EUR heating, ~29 thsd. EUR electricity cost per year”`),
        situationSmartTip: [
            {
                title: t('1-sc-1t', "Facts"),
                description: t('1-sc-1d', "Avoid judgment and concentrate on neutral facts"),
            },
            {
                title: t('1-sc-2t', "Consensus"),
                description: t('1-sc-2d', "The facts must fit a consensus with your organization")
            },
            {
                title: t('1-sc-3t', "Clarity"),
                description: t('1-sc-3d', "Relevant points must be clear for outsiders not familiar with the topic")
            },
        ],
        describeComplications: t('1-sc-prompt2', "Describe complications. Here you can introduce more judgment"),
        complicationsPlaceholder: "...",
        complicationsTooltip: t('1-sc-tooltip2', `E.g.:\n- Budgetary restrictions limit large construction measures.
            - Parents prefer individual transportation of their children and typically oppose changes.
            - Low sense of urgency within teaching corps complicates any implementation.`),
        complicationsSmartTip: [
            {
                title: t('1-sc-4t', 'Obstacles'),
                description: t('1-sc-4d', 'Which obstacles do you need to move overcome or bypass?'),
            },
            {
                title: t('1-sc-5t', 'Opportunities and threats'),
                description: t('1-sc-5d', 'State not only threats, but also opportunities resulting from the obstacles'),
            },
            {
                title: t('1-sc-6t', 'Judgment'),
                description: t('1-sc-6d', 'Provide balanced judgment in addition to facts'),
            },
            {
                title: t('1-sc-7t', "Clarity"),
                description: t('1-sc-7d', "Relevant points must be clear for outsiders not familiar with the topic")
            },
        ],
    }
    const ctx = useContext(TopicContext)

    return (<FormContainer title={text.headline}>
        {/* Situation */}
        {currentCommunity?.config?.isProblemSituation &&
            <div>
                <Tooltip content={text.situationTooltip} />
                <LabelWithSmarttips id="situationTips" title={text.describeSituation} tips={text.situationSmartTip} />
                <div className="mt-1">
                    <TextareaAutosize
                        type="text"
                        minRows="3"
                        name="situationInputArea"
                        id="situationInputArea"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={ctx.data.situation}
                        onChange={ctx.handleChange('situation')}
                        placeholder={text.situationPlaceholder}
                    />
                </div>
            </div>
        }
        {/* Complications */}
        {currentCommunity?.config?.isProblemComplications &&
            <div>
                <Tooltip content={text.complicationsTooltip} />
                <LabelWithSmarttips id="complicationsTips" title={text.describeComplications} tips={text.complicationsSmartTip} />
                <div className="mt-1">
                    <TextareaAutosize
                        type="text"
                        minRows="3"
                        name="complicationsInputArea"
                        id="complicationsInputArea"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={ctx.data.complications}
                        onChange={ctx.handleChange('complications')}
                        placeholder={text.complicationsPlaceholder}
                    />
                </div>
            </div>
        }
    </FormContainer>);
}

SituationsAndComplicationsForm.propTypes = {
    currentCommunity: PropTypes.object
};

const mapStateToProps = state => ({
    currentCommunity: state.communityReducer.currentCommunity
});


export default connect(mapStateToProps, null)(SituationsAndComplicationsForm)
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import FormContainer from "components/FormContainer";
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from "components/LabelWithSmarttips";
import GuideForm from "components/Guide/GuideForm";
import Taglist from 'components/Taglist/Taglist';

import ConfidentialFlagGrid from "./ConfidentialFlagGrid";
import ProblemContext from "../context/ProblemContext";

function ProblemStatementForm() {
    const { t } = useTranslation();
    const text = {
        title: t('1-ps-title', "Title"),
        headline: t('1-ps-hl', "Problem Statement"),
        titlePlaceholder: t('1-ps-placeholder', "E.g. 'CO2 reduction in school'"),
        problemStatement: t('1-ps-prompt', "Your problem statement in one question"),
        problemStatementPlaceholder: "...",
        problemStatementInfo: t('1-ps-info', "e.g. “How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?” "),
        problemStatementTips: [
            {
                title: t('1-ps-1t', "Specific"),
                description: t('1-ps-1d', "What is the objective you want to reach? E.g., “increase our NPS score”")
            },
            {
                title: t('1-ps-2t', "Measurable & Realistic"),
                description: t('1-ps-2d', "Set a quantitative, realistic objective.")
            },
            {
                title: t('1-ps-3t', "Time bound"),
                description: t('1-ps-3d', "By when should it be solved? E.g. “by 2030”, “within next 2 years”.")
            },
            {
                title: t('1-ps-4t', "Open"),
                description: t('1-ps-4d', "Avoid providing any solution. Leave room for creativity and innovations.")
            },
            {
                title: t('1-ps-5t', "Contributing"),
                description: t('1-ps-5d', "Contributing directly or indirectly to the overall goals of your organization")
            },
            {
                title: t('1-ps-6t', "Inspirational"),
                description: t('1-ps-6d', "Your problem statement should motivate a team to get the problem solved")
            },
        ],
        confidentialTile: t('1-ps-confidentialTitel', 'Confidentiality level'),
        confidentialInfo: t('1-ps-confidentialTooltip', 'For the end product “problem definition” you are currently working on, please define the appropriate level of security'),
    }
    const guideText = [
        {
            header: t('1-ps-g1t', 'Why sensitivity classifications?'),
            text: t('1-ps-g1d', `Sensitivity classifications are a time-tested instrument to ensure confidentiality of personal data and Intellectual Property rights including classified information like business secrets.\n  
            Legal obligations like the General Data Protection Regulation (GDPR) and Directive (EU) 2016/943 on the protection of undisclosed know-how and business information compel organizations to protect their personal data and other confidential information.\n
            But beyond legal requirements, confidentiality is first and foremost a prerequisite for open, trustful and enriching discussions. Regulations ensuring confidentiality therefore help all of us to adhere to the highest professional standards. `)
        },
        {
            header: t('1-ps-g2t', 'What are sensitivity classifications?'),
            text: t('1-ps-g2d', `The inappropriate disclosure of confidential information can lead to physical, material or non-material damage for individuals or legal entities, e.g., where the processing may give rise to financial loss, damage to the reputation or competitive position, or any other significant economic or social disadvantage.\n
            The higher the potential severity of the damage, the higher the sensitivity classification. `)
        },
        {
            header: t('1-ps-g3t', 'How are sensitivity classifications connected to data protection regulations? '),
            text: t('1-ps-g3d', `Protection measures of personal data, Intellectual Property rights or other classified information like business secrets are often overlapping in practice, while the legal frameworks differ.\n  
        The sensitivity classifications are helpful to protect the aforementioned categories of data respectively information. E.g., you should award the sensitivity classification “strictly confidential” to both special categories of personal data and to highly sensitive business secrets. `)
        },
        {
            header: t('1-ps-g4t', 'How are the sensitivity classifications defined? '),
            text: t('1-ps-g4d', `By default, sensitivity classifications are defined as follows:\n
            Strictly confidential:  
            • Applicable to strictly confidential information and/or special categories of personal data (Art. 9 GDPR), protected by a highly restricted access policy applying to a strictly defined number of persons listed by name only 
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause exceptionally grave damage 
            • Examples: Special categories of personal data, passwords 
            
            Confidential:  
            • Applicable to confidential information and/or personal data, protected by a restricted access policy applying to a defined group of individuals 
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause grave to medium damage 
            • Example: Personal data, product development documentations 
            
            Internal:  
            • Applicable to internal information and/or personal data, protected by a simple access policy  
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause light damage 
            • Example: internal regulations, competitive research not readily available publicly  
            
            Public:  
            • Applicable to public information or information meant to be published. There are no handling restrictions 
            • The inappropriate disclosure could reasonably be expected to cause no or no significant damage 
            • Example: marketing flyers `)
        },
        {
            header: t('1-ps-g5t', 'What are the consequences resulting from the sensitivity classification? '),
            text: t('1-ps-g5d', `Depending on the likelihood of the damage materializing, the classified information needs to be protected by technical and organisational measures to ensure a level of security appropriate to the risk.\n Risks can arise from accidental or unlawful destruction, loss, alteration, unauthorised disclosure, or access to personal data transmitted, stored or otherwise processed (cf. Art. 32 (2) GDPR).\n 
        Examples of these measures are:
        • Sensitive personal data from the stakeholder section are not shareable technically 
        • Each user has to be authenticated and has to accept a confidentiality agreement before being able to view the information 
        • Each end product is to be classified separately\n
        In the case of information classified as “public”, you could try to actively publish the information on this platform, in order to attract experts or contributors you are unaware of. `)
        },
    ];
    const ctx = useContext(ProblemContext);
    const [guideActive, setGuideActive] = useState(false);

    return (
        <div>
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4 2xl:w-1/3'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                title={text.headline}>
                {/* Title Input */}
                <div>
                    <label htmlFor={text.title} 
                    className="block text-base font-medium text-gray-700">
                        {text.title}
                    </label>
                    <div className="mt-1">
                        <input
                            id="ProblemTitleInput"
                            type="text"
                            name={text.title}
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={text.titlePlaceholder}
                            value={ctx.data.title}
                            onChange={ctx.handleChange("title")}
                        />
                    </div>
                </div>
                {/* Problem Statement, Textarea */}
                <div>
                    <Tooltip content={text.problemStatementInfo} />
                    <LabelWithSmarttips id="problemStatementTips" title={text.problemStatement} tips={text.problemStatementTips} />
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="problemStatement"
                            id="problemStatement"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={text.problemStatementPlaceholder}
                            value={ctx.data.statement}
                            onChange={ctx.handleChange("statement")}
                        />
                    </div>
                </div>
                {/* Confidentiality Level */}
                <div>
                    <Tooltip content={text.confidentialInfo} />
                    <label htmlFor='ConfidenialFlagGrid' className="block text-base font-medium text-gray-700">
                        {text.confidentialTile}
                    </label>
                    <ConfidentialFlagGrid tooltipActive={true} />
                </div>
                {/* DELETE LATER TESTING */}
                <div>
                    <Taglist />
                </div>
            </FormContainer>
        </div>
    );
}

export default ProblemStatementForm;
const authActionTypes = {
    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    // TODO: now same as LOGIN_SUCCESS, send E-Mail with confirmation
    // REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    AUTH_REQUEST: 'AUTH_REQUEST',
    AUTH_FAILURE: 'AUTH_FAILURE',
    
    LOGOUT: 'USER_LOGOUT',
};

export { authActionTypes };
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import Input from 'components/InputElement';

import { register } from 'actions/auth.register.actions';
import { listOrganisations } from 'actions/organisations.list.actions';
import { listUserCommunities } from 'actions/communities.list.actions';

function ModalJoinCommunity({ register, listOrganisations, listUserCommunities }) {

  const { t } = useTranslation(['common']);

  const [joinCommunityValues, setJoinCommunityValues] = useState({ passcode: null });
  const [joinCommunityValid, setJoinCommunityValid] = useState(false);

  useEffect(() => {
    if (joinCommunityValues.passcode) {
      setJoinCommunityValid(true)
    } else {
      setJoinCommunityValid(false)
    }
  }, [joinCommunityValues])

  const dismissJoinCommunity = () => {
    setJoinCommunityValues({
      passcode: null
    })
    setJoinCommunityValid(false)
  }

  const handleModalInput = (event) => {
    const { name, value } = event.target;
    setJoinCommunityValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  }

  const onJoinCommunity = () => {
    register(joinCommunityValues.passcode).then(() => {
      listOrganisations().then(() => {
        listUserCommunities()
      })
    })
  }

  return (
      <Modal
        id='joinCommunityModal'
        title={t('Join Community')}
        saveDisabled={!joinCommunityValid}
        onSave={onJoinCommunity}
        onClose={dismissJoinCommunity}
        saveText={t('Join')}
        closeText={t('Cancel')}
      >
        <div className='flex space-x-2'>
          <Input
            id='passcode'
            label={t('Passcode')}
            value={joinCommunityValues.passcode}
            onChange={handleModalInput}
          />
        </div>
      </Modal>
  );
}

ModalJoinCommunity.propTypes = {
  register: PropTypes.func.isRequired,
  listOrganisations: PropTypes.func.isRequired,
  listUserCommunities: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, { register, listOrganisations, listUserCommunities })(ModalJoinCommunity);
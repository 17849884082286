import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

/**
 * @param {list} param0 
 * @returns Displays a list of tags
 * only requires an array of text
 */
function SimpleDisplayTagList({ list, bgColor, borderColor, center, handleClick }) {
    const { t } = useTranslation(['problemDefinition']);
    bgColor = bgColor || 'bg-primary-100'
    borderColor = borderColor || 'border-primary-200 '
    const text = {
        noSelect: t('1-ps-noSelect', '-')
    }

    return (
        <div id="displayTagList" className={`${center && 'justify-center'} flex flex-wrap items-center`}>
            {list?.length > 0 ?
                list.map((item, index) => (
                    item ? (
                        <span
                            key={index}
                            className={`${bgColor} ${borderColor} ${handleClick && 'cursor-pointer'} text-base font-medium text-gray-700 border rounded-md mx-1 px-2 my-1 whitespace-pre-line truncate`}
                            onClick={handleClick && (() => handleClick(item))}>
                            {item}
                        </span>
                    ) : null
                ))
                :
                <span className='text-gray-500'>{text.noSelect}</span>
            }
        </div>
    );
}

SimpleDisplayTagList.propTypes = {
    list: PropTypes.array.isRequired
}

export default SimpleDisplayTagList;
import { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextareaAutosize from "react-textarea-autosize";

import FormContainer from "components/FormContainer";
import Button from "components/Button";

import { predict } from "actions/ai.actions";

function AIForm({aiReducer, predict}) {

    const title = 'Vertex AI'
    const [text, setText] = useState(null)
    const [prompt, setPrompt] = useState(null)

    const handleTextChange = (e) => {
        setText(e.target.value)
    }

    const handleContextChange = (e) => {
        setPrompt(e.target.value)
    }

    const handleClick = () => {
        if(text) {
            predict(`${prompt}.\nTEXT: ${text}`)
        } else {
            predict(prompt)
        }
    }

    return (
        <div>
            <FormContainer
                guide={false}
                title={title}>
                <div>
                    https://cloud.google.com/vertex-ai/docs/generative-ai/learn/introduction-prompt-design
                    https://cloud.google.com/vertex-ai/docs/generative-ai/learn/prompt-samples
                </div>
                <div>
                    PROMPT
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="prompt"
                            id="prompt"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="PROMPT"
                            value={prompt}
                            onChange={handleContextChange}
                        />
                    </div>
                </div>
                <div>
                    TEXT
                    <div className="mt-1">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="text"
                            id="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="TEXT"
                            value={text}
                            onChange={handleTextChange}
                        />
                    </div>
                </div>
                <div>
                    <div>{prompt}</div>
                    <div>TEXT: {text}</div>
                    <div>RESPONSE: {aiReducer?.response}</div>
                </div>
                <Button onClick={handleClick}>Submit</Button>
            </FormContainer>
        </div>
    );
}

AIForm.propTypes = {
    predict: PropTypes.func.isRequired
  };
  
const mapStateToProps = (state) => ({
    aiReducer: state.aiReducer
});

export default connect(mapStateToProps, { predict })(AIForm);
const emptyCommunityState = {
    data: {
        title: null,
        segmentSelected: [],
        valueChainSelected: [],
        config: []
    },
    segmentListData: [],
    valueChainListData: [],
}

export { emptyCommunityState };
import React from 'react';

// import emptyProblemState from './ProblemProvider'

const ProblemContext = React.createContext({
    // data: emptyProblemState.data,
    nextHandler: () => {},
    prevHandler: () => {},
    handleChange: (name, e) => {},
    handleObjectChange: (objectName, name, data) => {},
    itemHandler: (name, data) => {},
    rowHandler: (tablename, name, index, e) => {},
    editRowHandler: (tablename, name, index, value) => {},
    newRowHandler: (name) => {},
    deleteRowHandler: (name, id) => {},
    openOverview: () => {}
})

export default ProblemContext;
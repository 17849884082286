import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { uploadFile } from "actions/file.upload.actions";
import { deleteFile } from 'actions/file.delete.actions';

import Button from 'components/MainButton';

// TODO: limit N files
// TODO: limit size of files

function UploadPOC({ uploadFile, deleteFile }) {

  const [formData, setFormData] = useState({files: []});

  const uuid = '0' // TODO: change to id of the entity (i.e. topicId etc.)
  const { files } = formData;

  const onChange = (e) => {
      if (e.target.name === 'files') {
        // TODO: not e.target.value (!) in case of file
        let filesPrev = [...files]
        let fileCandidates = [...e.target.files]
        let filesToBeInserted = [] 
        fileCandidates.forEach(file => {
          if (filesPrev.findIndex((f) => f.name === file.name) === -1) {
            filesToBeInserted.push(file)
          }
        })
        if (filesToBeInserted?.length > 0) {
          setFormData({ ...formData, 'files': [...filesPrev, ...filesToBeInserted] })
        }
      } 
  };

  const onUpload = (e) => {
      e.preventDefault()

      // uploading multiple files

      // all must be fulfilled 
      // Promise.all(files.map(f => uploadFile(uuid, f)))

      // some may be rejected
      Promise.allSettled(files.map(f => uploadFile(uuid, f.name, f))).then((results) => {
        const fulfilled = results.filter(result => result.status === 'fulfilled');
        const rejected = results.filter(result => result.status === 'rejected');
      })   

      // reseting the list
      // TODO: only if all successfully uploaded (fulfilled)
      setFormData({files: []})
    };

    const removeFromList = (name) => {
      let filteredFiles = files.filter(f => {
        return f.name !== name
      }) 
      setFormData({ ...formData, 'files': filteredFiles }) 
    };

    console.log(files)

    // // a way how to delete files
    // const onDelete = (e) => {
    //   e.preventDefault()

    //   // some may be rejected
    //   Promise.allSettled(files.map(f => deleteFile(uuid, f))).then((results) => {
    //     results.forEach(result => console.log(result))

    //     const fulfilled = results.filter(result => result.status === 'fulfilled');
    //     const rejected = results.filter(result => result.status === 'rejected');
    //   }) 
    // }

    return (
        <>
        <form onSubmit={onUpload} className="space-y-6 w-64">
            <div className="col-span-full">
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="files"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
                    >
                      <span>Choose a file</span>
                      <input 
                            className="sr-only"
                            id="files" 
                            name="files" 
                            type="file"
                            multiple
                            onChange={onChange}
                             />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            { files.map(file =>
          {
            return <div key={file.name} onClick={() => removeFromList(file.name)}>{file.name}</div>
          }
        )}
            <Button>Upload</Button>
        </form>
        </>
    );
}

UploadPOC.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
};

export default connect(null, { uploadFile, deleteFile })(UploadPOC);
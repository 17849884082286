import { useEffect, useState } from "react"

/**
 * @param {key, name, title, description} 
 * @returns Checkitem with title and optional description
 */
function CheckItem({ item, index, name, title, description, handleChange }) {
    const [currentItem, setCurrentItem] = useState(item)

    useEffect(() => {
        setCurrentItem(item)
    }, [item])

    return (
        <div key={index} className="relative flex items-start md:py-1">
            <div className="flex items-center h-5">
                <input
                    id={name + 'check' + index}
                    aria-describedby="check-description"
                    name={name}
                    type="checkbox"
                    checked={currentItem?.checked}
                    onChange={(e) => handleChange(currentItem, e.target.checked)}
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded cursor-pointer"
                />
            </div>
            <div className="ml-1 text-sm">
                <label htmlFor={name + 'check' + index}
                    className="font-medium text-gray-700 pr-1">
                    {currentItem?.text}
                </label>
                <span id="smart-description" className="text-gray-500">
                    <span className="font-bold mr-1">{title}</span> {description}
                </span>
            </div>
        </div>
    );
}

export default CheckItem;
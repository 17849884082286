import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CollapsableDiv({ collapsed, onClick, header, children, className }) {
    return (
        <div id='collapsableDiv' className={className}>
            <div className={`bg-gray-100 hover:bg-primary-100 text-gray-800 w-full 
        p-1 cursor-pointer
        rounded-t flex justify-between 
        ${collapsed && 'rounded-b'}`}
                onClick={onClick}>
                <label className=" ml-2"
                >{header}
                </label>
                <FontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} className={'my-auto mr-2'} />
            </div>
            {!collapsed &&
                <div id='collapsableOpenDiv'
                    className='relative px-2 border space-y-7 border-gray-200 rounded-b pb-2'>
                    {children}
                </div>
            }
        </div>
    );
}

export default CollapsableDiv;
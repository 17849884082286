import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import PageWrapper from 'components/PageWrapper';
import StepFormWrapper from 'components/StepFormWrapper';
import Modal from 'components/Modal';

import TopicContext from './context/TopicContext';
import ProblemStatementForm from './Steps/ProblemStatementForm';
import SituationsAndComplicationsForm from './Steps/SituationAndComplicationsForm';
import { alertActionTypes } from 'constants/alert.action.types';
import TopicDefinitionOverview from './Overview/TopicDefinitionOverview';
import { createProblem } from 'actions/problem.create.actions';
import { updateProblemById } from 'actions/problem.update.actions';
import { uploadFile } from 'actions/file.upload.actions';

function NewTopicForm({ createProblem, currentCommunity, updateProblemById, uploadFile }) {
    const history = useHistory();
    const dispatch = useDispatch()

    const { t } = useTranslation(['problemDefinition']);
    const ctx = useContext(TopicContext);
    const { projectId } = useParams();
    const formSituationAndComplicationActive = (
        currentCommunity?.config?.isProblemSituation ||
        currentCommunity?.config?.isProblemComplications
    )

    const [isValid, setIsvalid] = useState(false);

    const handleValidation = (state) => {
        setIsvalid(state);
    }

    // list of all possible steps for problem statement, includes the forms
    const stepList = [
        { name: t('1-ps-hl', 'Topic'), href: '#', status: 'current', form: <ProblemStatementForm handleValidation={handleValidation} />, isActive: true },
        { name: t('1-sc-hl', 'Situation and Complication'), href: '#', status: 'upcoming', form: <SituationsAndComplicationsForm />, isActive: formSituationAndComplicationActive },
        { name: t('1-prev', 'Preview'), href: '#', status: 'upcoming', form: <TopicDefinitionOverview editOnly={true} handleValidation={handleValidation} />, isActive: true },
    ]
    // filters for active forms 
    const activeSteps = stepList.filter(step => step.isActive);

    let [currentStep, setCurrentStep] = useState(1);

    // sets 'other' as default topic type when none is set
    useEffect(() => {
        if (currentStep > 1) {
            if (!ctx.data.type) {
                ctx.itemHandler('type', 'OTHER');
            }
        }
    }, [currentStep]);

    // uploading documents
    function uploadDocuments(problemId, documentList) {
        const uploadPromises = documentList.map(doc =>
            uploadFile(problemId, doc.filename, doc.file)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'UPLOADED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'UPLOAD_ERROR', response: error });
                })
        );

        let data = {...ctx.data}

        // Wait for all uploads to complete and return the updated document list
        Promise.allSettled(uploadPromises).then(results => {
            const fulfilled = results.filter(result => result.status === 'fulfilled').map(result => result?.value);
            const rejected = results.filter(result => result.status === 'rejected').map(result => result?.reason);

            let documnetList = fulfilled.concat(rejected) // concating both again
            data['documentList'] = documnetList

            // keep fullfilled
            ctx.itemHandler('documentList', documnetList);

            if (rejected?.length > 0) {
                updateProblemById(problemId, data).then(() => {
                    dispatch({type: alertActionTypes.ERROR, payload: 'some files could not be uploaded, please redo'})
                    history.push(`/clusters/${projectId}/topics/${problemId}`)
                })

            } else {
                updateProblemById(problemId, data).then(() => {
                    history.push(`/clusters/${projectId}/topics/${problemId}`)
                })
            }
        });

        
    }

    // fileupload must be placed here
    const onSave = () => {
        let data = ctx.data
        createProblem(projectId, data).then(
            // topic got ID
            response => {
                if(ctx?.data?.documentList?.length > 0) {
                    uploadDocuments(response?.data?.payload?.id, ctx.data.documentList)
                } else {
                    history.push(`/clusters/${projectId}/topics/${response?.data?.payload?.id}`)
                }
            }
        )
    };

    return (
        <>
            <Modal id="newProblemSaveModal"
                title={t("m-saveNewProblem-title", "Problem definition saved")}
                body={t('m-saveNewProblem-body', `You successfully created your first Problem Definition!
                You can now share it inside and outside of your organization. \n
                A higher level of quality can be achieved through the inclusion of input from other perspectives. `)}
                button1text={t("Share", "Share")}
                //TODO 'onClick1' for button 1/share
                button2text={t("", "Dashboard")}
            />
            <PageWrapper id={'newProblemForm'}>
                <StepFormWrapper
                    stepList={activeSteps}
                    currentStep={setCurrentStep}
                    onSave={onSave}
                    isValid={isValid}
                    inValidMessage={t('required-hoverInfo1', 'Please fill in all required fields and select a community.')}
                    buttonGroupId={'buttonGroup-problemForm'}
                    backLink={`/clusters/${projectId}`}>
                    <div className='h-full overflow-x-visible'>
                        <div className='py-4 max-w-7xl'>
                            {/* Container for Forms */}
                            <div id='NewProblemFormSectionContainer' className='py-4 pl-1'>
                                {activeSteps[currentStep - 1].form}
                            </div>
                        </div>
                    </div>
                </StepFormWrapper>
            </PageWrapper>
        </>
    );
}

NewTopicForm.propTypes = {
    createProblem: PropTypes.func.isRequired,
    updateProblemById: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    currentCommunity: PropTypes.object,
};

const mapStateToProps = state => ({
    currentCommunity: state.communityReducer.currentCommunity
});

export default connect(mapStateToProps, { createProblem, updateProblemById, uploadFile })(NewTopicForm);

import { useState } from "react";

function TooltipTop(props) {
    const [tooltipActive, setTooltipActive] = useState(false);

    const handleMouseEnter = () => {
        setTooltipActive(true);
    }

    const handleMouseLeave = () => {
        setTooltipActive(false);
    }

    return (
        <div className="relative flex justify-center z-60">
            {tooltipActive &&
                <div className={`absolute -top-9 p-1 rounded-md
        text-primary-700 text-s font-normal bg-primary-100 
        ${props.whitespace || 'whitespace-nowrap'} 
        ${props.width}
        ${props.className}
        `}>{props.text}</div>}
            <div id="tooltip-child"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="w-full">
                {props.children}</div>
        </div>
    );
}

export default TooltipTop;
import { useReducer, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next"

import TopicContext from "./TopicContext";
import { getProblemById } from "actions/problem.get.actions";
import { emptyTopicState } from "./EmptyState";
import { translateItemWithChildren as translateItem } from 'utils/translate-item-with-children';
import { filterArray } from "utils/filter-array";

const topicReducer = (state, action) => {
    if (action.type === 'FETCH_DATA') {
        return {
            ...state, 
            data: {...state.data, ...action.payload}
        }
    }
    if (action.type === 'TRANSLATE_FETCHED_SEGMENTS') {
        return {
            ...state,
            segmentListData: action.payload
        }
    }
    if (action.type === 'TRANSLATE_FETCHED_VALUE_CHAINS') {
        return {
            ...state,
            valueChainListData: action.payload
        }
    }
    if (action.type === 'CHANGE') {
        return {
            ...state,
            data: ({ ...state.data, [action.name]: action.e.target.value })
        };
    }
    if (action.type === 'CHANGE_ITEM') {
        return {
            ...state,
            data: ({ ...state.data, [action.name]: action.data })
        }
    }
    if (action.type === 'CHANGE_OBJECT') {
        const tempData = { ...state.data }
        tempData[action.objectName][action.name] = action.data;
        return {
            ...state,
            data: tempData
        }
    }
    if (action.type === 'CHANGE_ID_LIST') {
        const tempData = { ...state.data };
        let newList = [];
        // Create property if it doesn't exist
        if (!tempData[action.listName]) {
            newList = [action.id];
        } else {
            newList = [...tempData[action.listName]];
            if (newList.includes(action.id)) {
                newList = newList.filter(itemId => itemId !== action.id);
            } else {
                newList.push(action.id);
            }
        }
        return {
            ...state,
            data: {
                ...state.data,
                [action.listName]: newList
            }
        };
    }
    return state
}

function TopicProvider({problemId, currentCommunity, problem, segments, valueChains, getProblemById, children}) {

    const { i18n } = useTranslation();
    const [topicState, dispatchTopicAction] = useReducer(topicReducer, emptyTopicState);

    // fetching data from DB if required 
    useEffect(() => {
        if (problemId) {
            getProblemById(problemId)
        }
    }, [problemId]);

    useEffect(() => {
        if (problem) {
            dispatchTopicAction({ type: 'FETCH_DATA', payload: problem })
        }
    }, [problem]);

    // segments and value chains based on Community Settings
    useEffect(() => {
        if (segments) {
            const filteredSegments = filterArray(segments, currentCommunity?.segmentSelected + problem?.segmentSelected)
            const translatedSegments = filteredSegments?.map(segment => translateItem(segment, i18n.language));
            dispatchTopicAction({ type: 'TRANSLATE_FETCHED_SEGMENTS', payload: translatedSegments })
        }
    }, [segments, currentCommunity, problem, i18n.language]);

    useEffect(() => {
        if (valueChains) {
            const filteredValueChains = filterArray(valueChains, currentCommunity?.valueChainSelected + problem?.valueChainSelected)
            const translatedValueChain = filteredValueChains?.map(valueChain => translateItem(valueChain, i18n.language));
            dispatchTopicAction({ type: 'TRANSLATE_FETCHED_VALUE_CHAINS', payload: translatedValueChain })
        }
    }, [valueChains, currentCommunity, problem, i18n.language]);

    const changeDataHandler = (name) => (e) => {
        dispatchTopicAction({ type: 'CHANGE', name, e })
    }
    const changeItemHandler = (name, data) => {
        dispatchTopicAction({ type: 'CHANGE_ITEM', name, data })
    }
    const changeObjectHandler = (objectName, name, data) => {
        dispatchTopicAction({ type: 'CHANGE_OBJECT', objectName, name, data })
    }
    const changeIdListHandler = (listName, id) => {
        dispatchTopicAction({ type: 'CHANGE_ID_LIST', listName, id })
    }

    const TopicContextValue = {
        data: topicState.data,
        segmentListData: topicState.segmentListData,
        valueChainListData: topicState.valueChainListData,
        handleChange: changeDataHandler,
        handleObjectChange: changeObjectHandler,
        itemHandler: changeItemHandler,
        idListHandler: changeIdListHandler,
    }

    return <TopicContext.Provider value={TopicContextValue}>
        {children}
    </TopicContext.Provider>
}

TopicProvider.propTypes = {
    problem: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    getProblemById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains,
    problem: state.problemReducer.problem,
    currentCommunity: state.communityReducer.currentCommunity
});

export default connect(mapStateToProps, { getProblemById })(TopicProvider);
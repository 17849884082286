import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

/**
 * @param {list} param0 
 * @returns Displays a list of tags
 * requires list with objects
 * {id: 1, text: 'text'}
 */
function DisplayTagList({ list, bgColor, borderColor, center }) {
    const { t } = useTranslation(['problemDefinition']);
    bgColor = bgColor || 'bg-primary-100'
    borderColor = borderColor || 'border-primary-200 '
    const text = {
        noSelect: t('1-ps-noSelect', '-')
    }

    return (
        <div id="displayTagList" className={`${center && 'justify-center'} flex flex-wrap items-center`}>
            {list?.length ?
                list.map((item) => (
                    item ? (
                        <span
                            key={item.id}
                            className={`${bgColor} ${borderColor} text-base font-medium text-gray-700 border rounded-md mx-1 px-2 my-1 whitespace-pre-line truncate`}>
                            {item.text}
                        </span>
                    ) : null
                ))
                :
                <span className='text-gray-500'>{text.noSelect}</span>
            }
        </div>
    );
}

DisplayTagList.propTypes = {
    list: PropTypes.array.isRequired
}

export default DisplayTagList;
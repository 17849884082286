
// Filters list of objects for ids of objects
const filterArray = (objectArray, idList) => {
    if (objectArray && idList) {
        let filteredArray = objectArray.filter((element) => {
            return idList.includes(element.id)
        })
    
        filteredArray = filteredArray.map((element) => {
            return {...element, children: filterArray(element?.children, idList)}
        })
    
        return filteredArray;
    }
}

export { filterArray }
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { parseItem } from "utils/session-storage";

function Documents({ docCollapsed, setDocCollapsed, organisationId, organisations, setLogoPath }) {
    const { t } = useTranslation(['common']);

    const [documentsNav, setDocumentsNav] = useState([]);
    const docHeight = 32 + 32 * documentsNav.length + 'px'

    useEffect(() => {
        if (organisations?.length > 0 && organisationId) {
          let matchedOrganisation = organisations.find(x => x.id === organisationId)
          if (matchedOrganisation) {
            setLogoPath(matchedOrganisation.logo)
            setDocumentsNav([
              { name: t('doc-princ', 'Principles'), href: '/principles', target: '_blank' },
              { name: t('doc-ref', 'Reference to Data Controller '), href: matchedOrganisation.referenceToDataController, target: '_blank' },
              { name: t('Privacy Notice'), href: matchedOrganisation.privacyNotice, target: '_blank' },
              { name: t('Terms of Use'), href: matchedOrganisation.termsOfUse, target: '_blank' },
              { name: t('doc-legal', 'Legal Notice'), href: '/legalNotice', target: '_blank' },
              { name: t('Contact'), href: '/support' },
            ])
          }
        }
      }, [organisationId, organisations])

    return (
        <nav className={`px-2 space-y-1 mb-4 transition-all duration-300 overflow-hidden show multi-collapse collapse-horizontal`}
          style={{ height: docCollapsed ? '40px' : docHeight }}>
          <p onClick={() => setDocCollapsed(!docCollapsed)}
            className={`${docCollapsed ? 'text-gray-400' : 'text-white bg-gray-900'} text-sm p-2 rounded-md cursor-pointer transition-all duration-300`}>
            {t('About & Legal')}</p>
          <ul className={`${docCollapsed ? 'opacity-0' : 'opacity-100'} transition-all duration-300 space-y-1`}>
            {documentsNav.map((item) => (
              <li key={item.name} className='truncate block text-gray-200 text-sm hover:text-white hover:bg-gray-700 p-1 rounded-md'>
                <a href={item.href} target={item.target} title={item.name}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
    );
}

export { Documents };
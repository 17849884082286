import { useState, useEffect, Fragment } from 'react';
import { Redirect, useLocation, Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

import { register } from 'actions/auth.register.actions';
import { alertActionTypes } from 'constants/alert.action.types';

import { parseRedirectLink } from 'utils/redirect-parser';
import CookieBanner from 'components/CookieBanner';
import LPFooter from 'components/LPFooter';
import RegisterPanel from './RegisterPanel';

// const navigation = [
//   { name: 'For Individuals', href: '#' },
//   { name: 'For Business', href: '#' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RegisterPage = ({ auth, register, alert, profile }) => {
  const { t, i18n } = useTranslation(['register', 'common']);
  const history = useHistory();

  const text = {
    accept: t('register-accept', 'By signing up, you agree to our documents: '),
    terms: t('terms', 'Terms'),
    dataPolicy: t('data-policy', 'Data Policy'),
    cookiesPolicy: t('cookies-policy', 'Cookies Policy'),
    and: t('and', 'and'),
    welcomeTitle: t('welcome', 'Welcome!'),
    welcomeBody:
      t('register-welcome',
        `On behalf of your convener, this platform supports you in preparing and shaping your event. \nWe invite you to target the collective intelligence of your group: Share problems, open questions, or ideas. Discuss, enrich and prioritize these with the other participants.
        \nIn order to register, please enter your event code and your business mail address. You will then be invited to proceed to your event space. 
        \nIf you are already registered, please use the "Log in" button.`),
    loginBtn: t('Log in', { ns: "common" })
  }

  const dispatch = useDispatch()

  // useEffect(() => {
  //   getUserProfile(auth.user_id);
  // }, [getUserProfile, auth.user_id]);

  useEffect(() => {
    if (alert.type === 'success') {
      history.push('/email-confirmation')
      dispatch({ type: alertActionTypes.CLEAR })
    }
  }, [alert])

  useEffect(() => {
    dispatch({ type: alertActionTypes.CLEAR })
  }, []);

  const [formData, setFormData] = useState({
    inviteToken: '',
    email: ''
  });

  let location = useLocation();

  const destination = parseRedirectLink(location.search)

  useEffect(() => {
    if (auth.isAuthenticated && !profile) {
      return <Redirect to={`/profiles/new?r=${destination}`} />;
    } else if (auth.isAuthenticated && destination) {
      return <Redirect to={`/${destination}`} />;
    } else if (auth.isAuthenticated) {
      return history.push('/home');
    }
  }, [auth.isAuthenticated, profile, destination]);

  const { inviteToken, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault()
    register(inviteToken, email)
      .then(
        dispatch({ type: alertActionTypes.CLEAR })
      )
  };

  const changeLanguage = (lang) => {
    // alert(`set language ${lang}`);
    i18n.changeLanguage(lang);
  }

  const linkStyle = 'inline-flex items-center px-4 py-2 border border-transparent text-base md:text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700'

  return (
    <div id='landingPage' className="relative bg-gray-800 overflow-hidden" style={{ minHeight: `calc(100vh - 32px)` }}>
      <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
        </svg>
      </div>
      <div className="relative pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            {/* <div className="flex items-center justify-between w-full md:w-auto">
              <img className='h-12 w-auto sm:h-10' src={Logo} alt='Solverint Logo' />
            </div> */}
            {/* <div className="hidden space-x-10 md:flex md:ml-10">
                    {navigation.map((item) => (
                      <a key={item.name} href={item.href} className="font-medium text-white hover:text-gray-300">
                        {item.name}
                      </a>
                    ))}
                  </div> */}
          </div>
          <div className="w-auto space-x-2 flex">
            <Link
              to="/login"
              className={linkStyle}
            >
              {text.loginBtn}
            </Link>
            {/* Language switch */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className={linkStyle}>
                  {i18n.language}
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 my-0.5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                          onClick={() => changeLanguage('en')}
                        >
                          en
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                          onClick={() => changeLanguage('de')}
                        >
                          de
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </nav>
        <div className='flex flex-col justify-between mx-auto max-w-7xl' style={{ minHeight: 'calc(100vh - 120px)' }}>
          <main className="mt-16 sm:mt-24">
            <div className="">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="text-3xl tracking-tight font-extrabold text-primary-400 sm:mt-5 md:mt-0 sm:leading-none lg:text-4xl xl:text-5xl">
                      <span className="md:block">{t(text.welcomeTitle)}</span>{' '}
                      {/* <span className="text-primary-400 md:block">complex problems</span> */}
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl whitespace-pre-line">
                      {t(text.welcomeBody)}
                    </p>
                  </div>
                </div>
                {/* Register Form */}
                <RegisterPanel
                  email={email}
                  inviteToken={inviteToken}
                  onChange={onChange}
                  onSubmit={onSubmit} />
              </div>
            </div>
          </main>
          <LPFooter />
        </div>
      </div>
      <CookieBanner />
    </div>
  )
};

RegisterPage.propTypes = {
  auth: PropTypes.object,
  register: PropTypes.func.isRequired,
  alert: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  auth: state.authReducer,
  alert: state.alertReducer,
  profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { register })(RegisterPage);

import { useRef, useState, useEffect } from "react";

const [minWidth, maxWidth, defaultWidth] = [200, 500, 256];
const scalingFactor = 1;

/**
 * Wrapper for the Sidebar
 * handles collapsing, resizing, displays children
 * delivers collapsed state via handleCollapsed
 */
function SidebarWrapper({ children, collapsed }) {

    const baseWidth = parseInt(localStorage.getItem("sidebarWidth")) || defaultWidth;
    const [width, setWidth] = useState(baseWidth);
    const [resizeActive, setResizeActive] = useState(false);
    const isResized = useRef(false);

    useEffect(() => {
        window.addEventListener("mousemove", (e) => {
          if (!isResized.current) {
            return;
          }
          setResizeActive(true)
          setWidth((previousWidth) => {
            const newWidth = previousWidth + e.movementX * scalingFactor;
            const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;
            return isWidthInRange ? newWidth : previousWidth;
          });
        });
        window.addEventListener("mouseup", () => {
          isResized.current = false;
          setResizeActive(false)
        });
      }, []);

    useEffect(() => {
        if (!collapsed) {
            localStorage.setItem("sidebarWidth", width);
        }
    }, [width]);

    useEffect(() => {
        if (collapsed) {
          setWidth(50)
        } else {
          setWidth(baseWidth)
        }
    }, [collapsed])

    return (
        <div id='sideBar' className="flex">
            <div style={{ width: `${width / 16}rem` }} className={`${!resizeActive && 'transition-all duration-300'} h-screen bg-gray-800 flex flex-col justify-between`}>
                { children }
            </div>
            {/* Handle for user resizing*/}
            {!collapsed &&
                <div
                className="w-2 cursor-col-resize hover:bg-primary-200 active:bg-primary-200"
                onMouseDown={() => {
                    isResized.current = true;
                }}
            />}
        </div>
    );
}

export { SidebarWrapper };
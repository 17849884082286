import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

function SortOptions({currentSort, onChange}) {
    const { t } = useTranslation(['common']);
    currentSort = currentSort || 'oldest'

    const optionList = [
        {value:'newest', text: t('Newest')},
        {value:'oldest', text: t('Oldest')},
        {value:'votes', text: t('Votes')},
        {value:'alphabetical', text: t('Alphabetical')},
    ]

    return (
        <div id='sortOptions' className='text-sm select-none text-right cursor-pointer text-gray-600'>
            <select
                value={currentSort}
                onChange={(e) => onChange(e.target.value)}
                className="rounded-md border-gray-300 text-sm mt-1 shadow-sm"
            >
                {optionList.map((option) => (
                    <option key={option.value} value={option.value}>{option.text}</option>
                ))}
            </select>
        </div>
    );
}

SortOptions.propTypes = {
    currentSort: PropTypes.string, 
    onChange: PropTypes.func.isRequired,
}

export {SortOptions};
import React, { Fragment, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from "@fortawesome/free-regular-svg-icons";

// import CommentGridRow from 'components/CommentGridRow';
// import CommentList from 'components/CommentList';
// import getCurrentDate from 'components/Utility/getCurrentDate';
// import getCurrentTime from 'components/Utility/getCurrentTime';

const iconClass = `w-5 h-5 text-gray-400
                    hover:text-primary-700 transform duration-150 ease-in`;
const valueClassName = 'w-full'

const dummyComments = [
    // {
    //     user: 'Betty',
    //     comment: `I think this is wrong m ipsum dolor sit amet, 
    //     consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadips`,
    //     date: '20.06.22',
    //     time: '14:12',
    // },
    // {
    //     user: 'Benni',
    //     comment: 'I think this is correct',
    //     date: '20.06.22',
    //     time: '16:30',
    // },
]

// Props:
// label, type [area, table, other]
function OverviewRowModule(props) {
    const editActive = props.editActive;
    const [commentActive, setCommentActiv] = useState(false);
    // const rowId = props.label.substring(0, 3) + props.index;
    const collapseId = props.label.substring(0, 3) + props.index + "collapse";
    // FIXME dummy comments
    const [commentList, setCommentList] = useState(dummyComments);
    const [commentCount, setCommentCount] = useState(commentList?.length);
    const [newCommentCount, setNewCommentCount] = useState(0);
    const [newComment, setNewComment] = useState(false);

    const updateCommentList = (newComment) => {
        setCommentList(prevCommentList => {
            const updatedComments = [...prevCommentList];
            updatedComments.push({
                user: 'Dummy-User',
                comment: newComment,
                date: null, // getCurrentDate(),
                time: null// getCurrentTime(),
            });
            setCommentCount(updatedComments?.length);
            setNewCommentCount(newCommentCount + 1);
            props.updateNewCommentCount(props.index, newCommentCount + 1); // sends commentcount up to parent component
            setNewComment(true);
            return updatedComments;
        })
    }

    return (
        <div id='overviewRowModule'
            className={`grid grid-cols-12 mb-1
        ${(commentActive) && 'bg-gray-50 border border-gray-300'} 
        ${(!editActive & !commentActive) && 'hover:bg-primary-50'} `}>
            {/* Label Area */}
            <div className={`col-span-2 text-base font-small text-gray-600 pt-3 select-none`}>
                <label>{props.label}</label></div>
            {/* Content Area */}
            <div className=' col-span-9'>
                {props.type === 'area' &&
                    <div className='w-auto'>
                        {editActive
                            ? <TextareaAutosize
                                readOnly={!editActive}
                                onChange={props.onChange}
                                className={`${valueClassName} border-1 border-gray-300 rounded focus:border-1 focus:bg-primary-100
                                             resize-none  mt-1 px-1 pt-2 pb-1`}
                                value={props.value}
                                placeholder={props.placeholder || '...'}
                            >
                            </TextareaAutosize>
                            : <label className={`${valueClassName} border-white inline-block pl-1 py-3`}>{props.value}</label>
                        }
                    </div>
                }
                {(props.type === 'table' || props.type === 'other') &&
                    <div className='w-auto'> {props.children} </div>
                }
            </div>
            {/* Comment Icon */}
            {!editActive &&
                <div id='commentCell' className='flex justify-center items-center' onClick={() => setCommentActiv(!commentActive)}>
                    <a className='relative'
                        data-bs-toggle="collapse"
                        href={'#' + collapseId}
                        role="button"
                        aria-controls={collapseId}
                        aria-expanded="false">
                        <FontAwesomeIcon icon={faComment} className={`${iconClass}`} />
                        {(commentCount > 0) &&
                            <div className={`
                            ${newComment ? 'bg-primary-600' : 'bg-gray-400'}
                            w-5 h-5 absolute transform translate-x-3 -translate-y-8 rounded-full 
                            flex items-center justify-center text-xs text-white font-bold`}>
                                {newComment ? newCommentCount : commentCount}
                            </div>}
                    </a>
                </div>
            }
            {/* Comment List */}
            {/* {commentActive &&
                <Fragment>
                    <CommentList list={commentList} col={'col-start-3 col-span-9'} />
                    <CommentGridRow updateCommentList={updateCommentList} col={'col-start-3 col-span-9'} collapseId={collapseId} commentActive={commentActive} />
                </Fragment>} */}
        </div>
    );
}

export default OverviewRowModule;
import Button from "components/Button";
import CheckItem from "components/CheckItem";
import SimpleGroupHover from "components/SimpleGroupHover";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Panel for User registration
 * 'create account' only available when the terms are accepted
 */
function RegisterPanel({ onChange, inviteToken, email, onSubmit }) {
    const { t } = useTranslation(['common', 'register']);
    const text = {
        accept: t('register-accept', 'By signing up, you agree to our documents: ', { ns: 'register' }),
        terms: t('register-tou', 'By registering, you confirm that you accept the Terms of Use of your convener. You can find the document within the platform.', { ns: 'register' }),
        dataPolicy: t('Privacy Notice'),
        hover: t('Please accept our Terms and Data Policy')
    }
    const [isEmailTouched, setEmailIsTouched] = useState(false)
    const [isValid, setIsValid] = useState({
        terms: false,
        email: false
    })
    const [formValid, setFormValid] = useState(false)

    const updateValidation = (item, val) => {
        setIsValid({ ...isValid, [item.name]: val })
    }

    useEffect(() => {
        if (isValid.terms && isValid.email) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [isValid]);

    const handleEmailBlur = () => {
        setEmailIsTouched(true)
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        const isEmailValid = validateEmail(emailValue);
        onChange(e);
        setIsValid({ ...isValid, email: isEmailValid });
    }

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    return (
        <div id='registerForm' className="mx-4 mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto rounded-lg sm:overflow-hidden">
                <div className="px-4 py-8 sm:px-10">
                    <form onSubmit={onSubmit} className="mt-6 space-y-5">
                        <div>
                            <label htmlFor="inviteToken" className="sr-only">
                                Invite token
                            </label>
                            <input
                                id="inviteToken"
                                name="inviteToken"
                                type="text"
                                placeholder="Invite token"
                                autoComplete=""
                                required
                                className="block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                                value={inviteToken}
                                onChange={onChange}
                            />
                        </div>
                        <div className="relative">
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="Email"
                                required
                                className={`${(!isValid.email && isEmailTouched) ? 'border-red-700 focus:border-red-700 focus:ring-red-700' : 'focus:ring-primary-500 focus:border-primary-500'} block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                value={email}
                                onBlur={handleEmailBlur}
                                onChange={handleEmailChange}
                            />
                            {(!isValid.email && isEmailTouched) && <p className="absolute -bottom-5 text-xs text-red-700">{t("register-correctEmail","Please enter a correct e-Mail adress", { ns: 'register' })}</p>}
                        </div>
                        <div className="pt-2">
                            <CheckItem
                                item={{name: 'terms', text: t(text.terms)}}
                                checked={isValid.terms}
                                index={1}
                                name='Terms'
                                handleChange={updateValidation}
                            />
                        </div>
                        <SimpleGroupHover
                            active={!formValid}
                            text={text.hover}
                            position={'-top-14 left-1/2 transform -translate-x-1/2'}
                            color='red'
                            spanClassName='block'
                        >
                            <Button
                                id="registerButton"
                                disabled={!formValid}
                                className={'w-full'}
                            >{t('Create account', { ns: 'common' })}
                            </Button>
                        </SimpleGroupHover>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegisterPanel;
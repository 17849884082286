import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session' // sessionStorage redux-persist/lib/storage - localStorage
import { batchedSubscribe } from "redux-batched-subscribe";

import rootReducer from './reducers'
// import setAuthToken from './utils/setAuthToken';

const initialState = {};

// const initialState = {
//   access_token: null, is_authenticated: null, loading: true, user_id: null
// };

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk,];

const enhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  batchedSubscribe((notify) => {
    notify();
  })
)

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
)

const store = createStore(
  persistedReducer,
  initialState,
  enhancer)
const persistor = persistStore(store)

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

export default () => {
  return { store, persistor }
}

import { useReducer, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next"

import { translateItemWithChildren as translateItem } from 'utils/translate-item-with-children';

import CommunityContext from "./CommunityContext";
import { getCommunityById } from "actions/community.get.actions";
import { emptyCommunityState } from "./EmptyState";

const communityReducer = (state, action) => {
    if (action.type === 'FETCH_DATA') {
        return {
            ...state, 
            data: {...state.data, ...action.payload}
        }
    }
    if (action.type === 'TRANSLATE_FETCHED_SEGMENTS') {
        return {
            ...state,
            segmentListData: action.payload
        }
    }
    if (action.type === 'TRANSLATE_FETCHED_VALUE_CHAINS') {
        return {
            ...state,
            valueChainListData: action.payload
        }
    }
    if (action.type === 'CHANGE') {
        return {
            ...state,
            data: ({ ...state.data, [action.name]: action.e.target.value })
        };
    }
    if (action.type === 'CHANGE_ITEM') {
        return {
            ...state,
            data: ({ ...state.data, [action.name]: action.data })
        }
    }
    if (action.type === 'CHANGE_OBJECT') {
        const tempData = { ...state.data }
        tempData[action.objectName][action.name] = action.data;
        return {
            ...state,
            data: tempData
        }
    }
    return state
}

function CommunityProvider({ segments, valueChains, communityId, currentCommunity, getCommunityById, children}) {

    const { i18n } = useTranslation();
    const [communityState, dispatchCommunityAction] = useReducer(communityReducer, emptyCommunityState);

    // fetching data from DB if required 
    useEffect(() => {
        if (communityId) {
            getCommunityById(communityId)
        }
    }, [communityId]);

    useEffect(() => {
        if (communityId && currentCommunity) {
            dispatchCommunityAction({ type: 'FETCH_DATA', payload: currentCommunity })
        }
    }, [communityId, currentCommunity]);

    useEffect(() => {
        if (segments) {
            const translatedSegments = segments.map(segment => translateItem(segment, i18n.language));
            dispatchCommunityAction({ type: 'TRANSLATE_FETCHED_SEGMENTS', payload: translatedSegments })
        }
    }, [segments, i18n.language]);

    useEffect(() => {
        if (valueChains) {
            const translatedValueChain = valueChains.map(valueChain => translateItem(valueChain, i18n.language));
            dispatchCommunityAction({ type: 'TRANSLATE_FETCHED_VALUE_CHAINS', payload: translatedValueChain })
        }
    }, [valueChains, i18n.language]);

    const changeDataHandler = (name) => (e) => {
        dispatchCommunityAction({ type: 'CHANGE', name, e })
    }
    const changeItemHandler = (name, data) => {
        dispatchCommunityAction({ type: 'CHANGE_ITEM', name, data })
    }
    const changeObjectHandler = (objectName, name, data) => {
        dispatchCommunityAction({ type: 'CHANGE_OBJECT', objectName, name, data })
    }

    const CommunityContextValue = {
        data: communityState.data,
        segmentListData: communityState.segmentListData,
        valueChainListData: communityState.valueChainListData,
        handleChange: changeDataHandler,
        handleObjectChange: changeObjectHandler,
        itemHandler: changeItemHandler
    }

    return <CommunityContext.Provider value={CommunityContextValue}>
        {children}
    </CommunityContext.Provider>
}

CommunityProvider.propTypes = {
    communityReducer: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    getCommunityById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains,
    currentCommunity: state.communityReducer.currentCommunity
});

export default connect(mapStateToProps, { getCommunityById })(CommunityProvider);
import React, { useContext} from 'react';
import NodeCard from "./NodeCard";
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import TreeContext from "./context/tree-context";


function Tree(props) {
    const ctx = useContext(TreeContext);
    const data = ctx.data.children;
    const selectModeActive = props.selectModeActive;

    const padding = 'pl-14';
    const groupMargin = 'my-2'
    const strokeWidth = 2;
    const headSize = 5;
    const path = 'smooth';
    const pathColor = '#4f46e5'


     return (
            <div className={`${props.className} flex flex-col ml-8`}>
                {data &&
                    data.map((primaryNode, index) => (
                        // Level 1
                        <Xwrapper key={primaryNode.id}>
                            <div className={`rounded flex mt-2`}>
                                <NodeCard
                                    id={primaryNode.id}
                                    number={index + 1}
                                    name={primaryNode.name}
                                    children={primaryNode.children} 
                                    selectModeActive={selectModeActive}/>
                                {/* Level 2 */}
                                {primaryNode.children &&
                                    <div className="">
                                        {primaryNode.children.map((children1, index2) => (
                                            <div key={children1.id} className={`rounded my flex ${padding}`}>
                                                <NodeCard
                                                    id={children1.id}
                                                    number={(index + 1) + "." + (index2 + 1)}
                                                    parent={primaryNode.id}
                                                    name={children1.name}
                                                    children={children1.children}
                                                    selectModeActive={selectModeActive} />
                                                <Xarrow start={primaryNode.id} end={children1.id}
                                                    strokeWidth={strokeWidth} headSize={headSize} path={path}
                                                    lineColor={pathColor} headColor={pathColor} />
                                                {/* Level 3 */}
                                                {children1.children &&
                                                    <div className={`${groupMargin}`}>
                                                        {children1.children.map((children2, index3) => (
                                                            <div key={children2.id} className={`rounded my flex ${padding}`}>
                                                                <NodeCard
                                                                    id={children2.id}
                                                                    number={(index + 1) + "." + (index2 + 1) + "." + (index3 + 1)}
                                                                    parent={children1.id}
                                                                    name={children2.name}
                                                                    children={children2.children}
                                                                    selectModeActive={selectModeActive}
                                                                />
                                                                <Xarrow start={children1.id} end={children2.id}
                                                                    strokeWidth={strokeWidth} headSize={headSize} path={path}
                                                                    lineColor={pathColor} headColor={pathColor} />
                                                                {/* Level 4 */}
                                                                {children2.children &&
                                                                    <div className={`${groupMargin}`}>
                                                                        {children2.children.map((children3, index4) => (
                                                                            <div key={children3.id} className={`${padding}`}>
                                                                                <NodeCard
                                                                                    id={children3.id}
                                                                                    number={(index + 1) + "." + (index2 + 1) + "." + (index3 + 1) + "." + (index4 + 1)}
                                                                                    parent={children2.id}
                                                                                    name={children3.name}
                                                                                    lastChild={true}
                                                                                    selectModeActive={selectModeActive}
                                                                                />
                                                                                <Xarrow start={children2.id} end={children3.id}
                                                                                    strokeWidth={strokeWidth} headSize={headSize} path={path}
                                                                                    lineColor={pathColor} headColor={pathColor} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </Xwrapper>
                    ))
                }
            </div>
    );
}

export default Tree;
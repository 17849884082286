// https://stackoverflow.com/a/65054252

export function getAbbreviation(text) {
    if (typeof text != 'string' || !text) {
      return '';
    }
    const acronym = text
      .match(/[\p{Alpha}\p{Nd}]+/gu)
      .reduce((previous, next) => previous + ((+next === 0 || parseInt(next)) ? parseInt(next): next[0] || ''), '')
      .toUpperCase()
      .slice(0, 3)
    return acronym;
  }
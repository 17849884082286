import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import FormContainer from 'components/FormContainer';
import Button from 'components/Button';
import { listComments } from 'actions/comments.list.actions';
import { updateProblemById } from 'actions/problem.update.actions';

import CriticalDefinitionsTable from '../Steps/CriticalDefinitionsTable';
import StakeholdersTable from '../Steps/StakeholdersTable';
import ConstraintsTable from '../Steps/ConstraintsTable';
import ConfidentialFlagGrid from '../Steps/ConfidentialFlagGrid';
import ProblemRatingFlagGrid from '../Steps/ProblemRatingFlagGrid';

import OverviewRowModule from './OverviewRowModule';
import MetricStatGrid from './MetricStatGrid';

import CreateProblemContext from '../context/ProblemContext';

function ProblemDefinitionOverview({
    title,
    editOnly,
    comments,
    listComments,
    updateProblemById
}) {

    const newProblem = (editOnly || false); // true for new Problem, to hide share/edit button
    const [editModeActive, setEditModeActive] = useState(newProblem ? true : false); // switch for Product 'Problem Definition' 
    //TODO calc all comments
    // const [overviewCommentCount, setOverviewCommentCount] = useState();
    const [commentList, setCommentList] = useState([]);
    const [overviewNewCommentCount, setOverviewNewCommentCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const { t } = useTranslation();
    const text = {
        header: (title || ""),
        confidentialLevel: t('1-ov-confiLabel', 'Confidentiality level'),
        title: t('1-ps-title', "Title"),
        statement: t('1-ps-hl', "Statement"),
        rating: t('1-pr-hl', 'Problem rating'),
        coreMetrics: t('1-pm-title', 'Core Metrics'),
        dod: t('1-kr-hl', 'Key Results'),
        criticalDefinitions: t('1-ov-def', 'Definitions'),
        constraints: t('1-c-hl', 'Constraints'),
        situation: t('1-ov-sit', 'Situation'),
        complications: t('1-ov-com', 'Complications'),
        stakeholders: t('1-sh-hl', 'Stakeholders'),
        metricIndicator: [
            t('1-ov-pri', 'Primary'),
            t('1-ov-sec', 'Secondary'),
            t('1-ov-ter', 'Tertiary'),
        ]
    }

    const ctx = useContext(CreateProblemContext); 

    const editActiveHandler = () => {
        setEditModeActive(!editModeActive);
    }

    const { projectId, problemId } = useParams();

    const parentId = problemId

    // list comments (it depends on parentId)
    useEffect(() => {
        listComments(parentId)
    }, [parentId]);

    // set comment list every time when comments
    useEffect(() => {
        setCommentList(comments)
    }, [comments]);

    // useEffect(async () => {
    //     console.log('using axios direct')
    //     let authHeader = getAuthHeader()

    //     const config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
    //         },
    //         params: {
    //             parent: 'PROBLEM_DEFINITION',
    //             parent_id: parentId}
    //     };

    //     const result = await axios.get(
    //       '/api/comments', config
    //     );

    //     console.log(result)
    //   }, []);

    const onSave = (e) => {
        e.preventDefault()
        let data = ctx.data
        delete data['role']
        updateProblemById(problemId, data)
        setEditModeActive(!editModeActive)
    };

    // Updates the array of new comments, sends number to Tabview
    const updateNewCommentCountProblemOverview = (index, newRowComment) => {
        const tempNewCommentCount = [...overviewNewCommentCount];
        tempNewCommentCount[index] = newRowComment;
        setOverviewNewCommentCount(tempNewCommentCount);
        const allNewComments = tempNewCommentCount.reduce((result, number) => result + number);
        // props.updateNewCommentCount('problemDefinition', allNewComments); // send number of new comments to parent, for tabview
    }

    // const selectFlag = (index) => {
    //         ctx.itemHandler('rating' , index)
    //     }

    return (
        <div className=''>
            {/* FIXME Text Translation */}
            {!newProblem &&
                <span className='float-right mt-0 mb-2'>
                    {/* Button for Edit Mode switch */}
                    {/* FIXME needs to be invisible for certain users */}
                    <Button id='problemDefinition-toggleEditActive-btn'
                        className="mr-2"
                        onClick={editModeActive ? onSave : editActiveHandler}
                        bg={editModeActive ? 'bg-green-600' : ""} >
                        {editModeActive ? t("Save") : t("Edit")}
                    </Button>
                    { editModeActive ?
                        <Button id='problemDefinition-toggleCancelActive-btn'
                            className="mr-2"
                            onClick={editActiveHandler}
                            bg='bg-primary-600' >
                            {t("Cancel")}
                        </Button>
                        : null
                    }
                    {/* Button for Share Mode */}
                    <Link to={`/projects/${projectId}/problems/${problemId}/share`}>
                        <Button id='problemDefinition-share-btn'>
                            {t("Share")}
                        </Button>
                    </Link>
                </span>
            }
            <FormContainer title={text.header} width="w-full">

                <div className="w-full">
                    <OverviewRowModule index='0'
                                        label={text.confidentialLevel}
                                        type="other"
                                        editActive={editModeActive}
                                        updateNewCommentCount={updateNewCommentCountProblemOverview}
                                        commentList={commentList}
                                        parentId={parentId}
                                        node='Problem.confidentialLevel'>
                        <ConfidentialFlagGrid editActive={editModeActive} />
                    </OverviewRowModule>
                    <OverviewRowModule index='1'
                                        label={text.title}
                                        value={ctx.data.title}
                                        onChange={ctx.handleChange('title')}
                                        type="area"
                                        editActive={editModeActive}
                                        updateNewCommentCount={updateNewCommentCountProblemOverview}
                                        commentList={commentList}
                                        parentId={parentId}
                                        node='Problem.title'
                                        />
                    <OverviewRowModule index='2'
                                        label={text.statement}
                                        value={ctx.data.statement}
                                        onChange={ctx.handleChange('statement')}
                                        type="area"
                                        editActive={editModeActive}
                                        updateNewCommentCount={updateNewCommentCountProblemOverview}
                                        commentList={commentList}
                                        parentId={parentId}
                                        node='Problem.statement'
                                        />
                    <OverviewRowModule index='3'
                                        label={text.rating}
                                        type="other"
                                        editActive={editModeActive}
                                        updateNewCommentCount={updateNewCommentCountProblemOverview}
                                        commentList={commentList}
                                        parentId={parentId}
                                        node='Problem.rating'
                                        >
                        <ProblemRatingFlagGrid editActive={editModeActive} />
                    </OverviewRowModule>
                    <OverviewRowModule index='4'
                                    label={text.coreMetrics}
                                    type="other"
                                    editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.coreMetrics'
                    >
                        <div id='problemOverviewRow-coreMetricDiv'
                            className='flex flex-col my-1 space-y-1 xl:w-full'>
                            {ctx.data.coreMetrics.map((item, index) => (
                                <MetricStatGrid
                                    index={index}
                                    key={index}
                                    headerLabel={text.metricIndicator[index]}
                                    onDescriptionChange={(e) => ctx.editRowHandler('coreMetrics', 'baselineDescription', index, e.target.value)}
                                    data={item} 
                                    editActive={editModeActive}/>
                            ))}
                        </div>
                    </OverviewRowModule>
                    <OverviewRowModule index='5'
                                    label={text.dod}
                                    value={ctx.data.definitionOfDone}
                                    onChange={ctx.handleChange('definitionOfDone')}
                                    type="area"
                                    editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.definitionOfDone'/>
                    <OverviewRowModule index='6'
                                    label={text.constraints}
                                    type='table'
                                    editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.constraints'>
                        <ConstraintsTable editActive={editModeActive} />
                    </OverviewRowModule>
                    <OverviewRowModule index='7'
                                    label={text.situation}
                                    value={ctx.data.situation}
                                    onChange={ctx.handleChange('situation')}
                                    type="area"
                                    editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.situation'
                                    />
                    <OverviewRowModule index='8'
                                    label={text.complications}
                                    value={ctx.data.complications}
                                    onChange={ctx.handleChange('complications')}
                                    type="area" editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.complications'
                                    />
                    <OverviewRowModule index='9'
                                    label={text.stakeholders}
                                    type="table"
                                    editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.stakeholders'
                                    >
                        <StakeholdersTable editActive={editModeActive} />
                    </OverviewRowModule>
                    <OverviewRowModule index='10'
                                    label={text.criticalDefinitions}
                                    type="table" editActive={editModeActive}
                                    updateNewCommentCount={updateNewCommentCountProblemOverview}
                                    commentList={commentList}
                                    parentId={parentId}
                                    node='Problem.criticalDefinitions'
                                    >
                        <CriticalDefinitionsTable editActive={editModeActive} />
                    </OverviewRowModule>
                </div>
            </FormContainer>
        </div >
    );
}

ProblemDefinitionOverview.propTypes = {
    comments: PropTypes.array,
    updateProblemById: PropTypes.func.isRequired,
    listComments: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    comments: state.commentReducer.comments
});

export default connect(mapStateToProps, { updateProblemById, listComments })(ProblemDefinitionOverview);
// export default ProblemDefinitionOverview;
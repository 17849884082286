import { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

const [maxWidth, defaultWidth] = [1000, 260];
const scalingFactor = 1;

// Resizable panel that appears on the right side of the application 
// visible with 'active' prop
// rightPosition and topPosition can be modified 
function ResizablePanelRight({ active, disableCloseButton, handleClose, children, name, rightPosition, topPosition, zIndex, height, minWidth, className, sendWidth }) {
    const baseWidth = parseInt(localStorage.getItem(name)) || defaultWidth;
    const [width, setWidth] = useState(baseWidth);
    const [resizeActive, setResizeActive] = useState(false);
    const isResized = useRef(false);
    rightPosition = rightPosition || 'right-0'
    zIndex = zIndex || 'z-50'
    minWidth = minWidth || '200'
    height = height || '100vh'

    useEffect(() => {
        window.addEventListener("mousemove", (e) => {
            if (!isResized.current) {
                return;
            }
            e.preventDefault(); // prevents text selection during resize
            setResizeActive(true)
            setWidth((previousWidth) => {
                const newWidth = previousWidth - e.movementX * scalingFactor;
                const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;
                const validWidth = isWidthInRange ? newWidth : previousWidth
                if (sendWidth) {
                    sendWidth(validWidth)
                }
                return validWidth;
            });
        });
        window.addEventListener("mouseup", () => {
            isResized.current = false;
            setResizeActive(false)
        });
    }, []);

    useEffect(() => {
        if (active) {
            if (width > parseInt(minWidth)) {
                localStorage.setItem(name, width);
            } else {
                localStorage.setItem(name, minWidth)
            }

        }
    }, [width]);

    useEffect(() => {
        if (!active) {
            setWidth(0)
        } else {
            setWidth(baseWidth)
        }
    }, [active])

    return (
        <div
            style={{ width: `${width}px`, height: height }}
            className={`${active ? rightPosition + ' overflow-auto' : '-right-full'}
                            ${!resizeActive && 'transition-all duration-300'} ${zIndex} ${topPosition} ${className}
                            absolute
                            bg-white border-l border-t border-gray-300
                            shadow-xl h-50% 
                            rounded-l-xl
                            flex`}>
            {/* Resizer */}
            {active &&
                <div
                    className="absolute h-full -left-1 w-3 cursor-col-resize hover:bg-primary-200 active:bg-primary-200"
                    onMouseDown={() => {
                        isResized.current = true;
                    }}
                />}
            {/* Close */}
            {!disableCloseButton && <FontAwesomeIcon icon={faX} className={`${zIndex} absolute right-5 top-4 text-gray-700 hover:scale-110 cursor-pointer`} onClick={handleClose} />}
            <div className="w-full h-full p-4">
                {children}
            </div>
        </div>
    );
}

ResizablePanelRight.propTypes = {
    active: PropTypes.bool,
    disableCloseButton: PropTypes.bool,
    handleClose: PropTypes.func,
    name: PropTypes.string,
    rightPosition: PropTypes.string,
    topPosition: PropTypes.string,
    zIndex: PropTypes.string,
    height: PropTypes.string,
    minWidth: PropTypes.string,
    className: PropTypes.string,
    sendWidth: PropTypes.func,

}

export { ResizablePanelRight };
import React from 'react';

import CommunityProvider from './context/CommunityProvider';

import NewCommunityForm from './NewCommunityForm'

function CreateProblemPage() {
    return (
        <CommunityProvider>
            <NewCommunityForm />
        </CommunityProvider>
    );
}


export default CreateProblemPage;
import { useState, useEffect } from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import buildPath from './build-path';
import { parseItem } from 'utils/session-storage';

// const pages = [
//   { name: 'Projects', href: '#', current: false },
//   { name: 'Project Nero', href: '#', current: true },
// ]

// const paragraph = '/clusters/someproject1';
// const regex = /\/clusters\/(?<token>[a-z0-9]+$)/
// ;
// const found = paragraph.match(regex);

// console.log(found);

function Breadcrumbs({
  currentCommunity,
  projects, 
  problems
}) {

  const location = useLocation();
  const [pages, setPages] = useState([]);
  const [currentOrganisationTitle, setCurrentOrganisationTitle] = useState(null);
  const [currentCommunityTitle, setCurrentCommunityTitle] = useState(null);

  useEffect(() => {
    setPages(buildPath(location.pathname, currentCommunity, projects, problems))
  }, [location.pathname, currentCommunity, projects, problems])

  useEffect(() => {        
    if (parseItem('organisation')?.title) {
      setCurrentOrganisationTitle(parseItem('organisation').title)
    } else {
      setCurrentOrganisationTitle(null)
    }
  }, [sessionStorage.getItem('organisation')]);

  useEffect(() => {        
    if (parseItem('community')?.title) {
      setCurrentCommunityTitle(parseItem('community').title)
    } else {
      setCurrentCommunityTitle(null)
    }
  }, [sessionStorage.getItem('community')]);

  // console.log(location)
  // console.log(location.pathname.split('/'))

  // let arr = location.pathname.split('/')
  // arr.shift()
  // console.log(arr)

  // let pages = []
  // arr.forEach((x, i) => pages.push({ name: x, href: '#', current: false }));




  return (
    <nav className="flex px-2 py-1" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li key="organisation">
          <div>
            <a href="/home" className="flex flex-row gap-x-1 text-gray-500 hover:text-gray-700">
              <HomeIcon className="h-5 w-5" aria-hidden="true" />
              <span className="text-sm font-medium">{currentOrganisationTitle}</span>
            </a>
          </div>
        </li>
        <li key="community">
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <a
              href="/home"
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {currentCommunityTitle}
            </a>
          </div>
        </li>
        {pages.map((page) => (
        <li key={page.name}>
          <div className="flex items-center">
            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <a
              href={page.href}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current={page.current ? 'page' : undefined}
            >
              {page.name}
            </a>
          </div>
        </li>
        ))}
      </ol>
    </nav>
  )
}

const mapStateToProps = (state) => ({
  currentCommunity: state.communityReducer.currentCommunity,
  projects: state.projectReducer,
  problems: state.problemReducer
});

export default connect(mapStateToProps, null)(Breadcrumbs);
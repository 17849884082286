import { useContext } from "react";
import { CommentContext } from "./context/CommentContext";
import { useTranslation } from "react-i18next";

function SortOption() {
    const { t } = useTranslation(['common']);
    const { sortType, updateSortType } = useContext(CommentContext)

    return (
        <div className=' select-none text-right text-gray-600'>
            <select
                value={sortType}
                onChange={(e) => updateSortType(e.target.value)}
                className="rounded-md border-gray-300 cursor-pointer text-sm py-1"
            >
                <option value="newest">{t('Newest')}</option>
                <option value="oldest">{t('Oldest')}</option>
                <option value="votes">{t('By Votes')}</option>
            </select>
        </div>
    );
}

export default SortOption;
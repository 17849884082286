const userDict = {
    "header":"Profil anlegen",
    "name1":"Vorname",
    "name2":"Nachname",
    "orga":"Organisation",
    "pos":"Position",
    "area1":"Interessen nach Marktsegment",
    "area2":"Interessen nach Wertschöpfungsschritt",
    "interests":"Interessen",
    'Delete Account':'Account löschen',
    "u-btnInf":"Bitte füllen Sie alle Pflichtfelder aus",
    "u-delinfo":"Achtung: Wenn Sie Ihr Profil löschen, wird aus Datenschutzgründen auch Ihr Input (z.B. in Kommentaren) gelöscht",
    "member-of-organisations": "Teilnehmer der Organisationen",
    "member-of-communities": "Teilnehmer der Communities"
}

export default userDict;
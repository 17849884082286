import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/outline'

import CircularAvatarWithInitials from 'components/avatar/CircularAvatarWithInitials';

import { logout } from 'actions/auth.logout.actions';
import { getInitials } from 'utils/get-initials';
import { updateUserProfile } from 'actions/profile.update.actions';
import MenuItem from 'components/Menu/MenuItem';
import MenuItemWrapper from 'components/Menu/MenuItemWrapper';
import Notifications from './Notifications';

function UserMenu({ profile, updateUserProfile, logout }) {
    const history = useHistory();
    const { i18n } = useTranslation();
    const dispatch = useDispatch()


    const signOut = (e) => {
        e.preventDefault();

        const now = new Date();
        updateUserProfile({...profile, logoutTs: now.toISOString()})
        console.log('logout')
        logout();
        history.push('/login');
    };

    const changeLanguage = (lang) => {
        // alert(`set language ${lang}`);
        i18n.changeLanguage(lang);
    }

    const menuClass = 'relative inline-block text-left'
    const menuButton = 'bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600'

    return (
        <div id='userMenu' className='flex space-x-6 py-4 px-8'>
            {/* Notifications */}
            <Menu as="div" className={menuClass}>
                <Notifications />
            </Menu>
            {/* User */}
            <Menu as="div" className={menuClass}>
                <Menu.Button className={menuButton}>
                    <span className="sr-only">Open user menu</span>
                    <CircularAvatarWithInitials size="8" >{getInitials(profile)}</CircularAvatarWithInitials>
                    {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                </Menu.Button>
                <MenuItemWrapper>
                    <MenuItem to="/profiles/me" label="Your Profile" />
                    <MenuItem to="#" label="Sign Out" onClick={signOut} />
                </MenuItemWrapper>
            </Menu>
            {/* Language switch */}
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {i18n.language}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <MenuItemWrapper>
                    <MenuItem label="en" onClick={() => changeLanguage('en')} />
                    <MenuItem label="de" onClick={() => changeLanguage('de')} />
                </MenuItemWrapper>
            </Menu>
        </div>
    );
}

UserMenu.propTypes = {
    profile: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { updateUserProfile, logout })(UserMenu);

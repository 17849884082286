import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import CheckItem from "./CheckItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

/**
 * Displays CheckItem with children
 */
// TODO #2403 Update List
function RecursiveListItem({ item, updateList, name, parentItemTitle, isTopLevelItem }) {
    const [childrenOpen, setChildrenOpen] = useState(item.checked)
    const [currentItem, setCurrentItem] = useState(item)
    const itemHasValidChildren = item.children && item.children?.length > 0

    useEffect(() => {
        setCurrentItem(item); // Initialize currentItem with item prop
    }, [item]);

    useEffect(() => {
        if (item.checked) {
            setChildrenOpen(item.checked)
        }
    }, [item.checked])

    const handleButtonClick = (e) => {
        e.preventDefault()
        setChildrenOpen(!childrenOpen)
    }

    const handleChange = (item, checked) => {
        updateList(item, checked)
    }

    const openChildrenButton =
        <div className='flex items-center'>
            <FontAwesomeIcon
                className="pl-2 text-gray-500"
                onClick={(e) => handleButtonClick(e)} icon={childrenOpen ? faMinus : faPlus} />
            {!childrenOpen && <span className='ml-2 bg-gray-100 text-gray-500 rounded-full text-xs px-1'>{item.children?.length}</span>}
        </div>

    return (
        <div>
            {parentItemTitle && isTopLevelItem ?
                <div className='flex items-center'>
                    <label>{item.text}</label>
                    <span>{itemHasValidChildren &&
                        openChildrenButton}
                    </span>
                </div>
                :
                <div className="flex items-center">
                    <CheckItem
                        item={currentItem}
                        key={currentItem.id}
                        handleChange={handleChange}
                        name={name}
                    />
                    {itemHasValidChildren &&
                        openChildrenButton}
                </div>
            }

            {itemHasValidChildren && childrenOpen && (
                <div className="ml-8 child-items">
                    {currentItem.children.map((child) => (
                        <RecursiveListItem
                            key={child.id}
                            item={child}
                            updateList={updateList}
                            name={name}
                            isTopLevelItem={false}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

RecursiveListItem.propTypes = {
    item: PropTypes.object.isRequired,
    updateList: PropTypes.func,
    name: PropTypes.string,
    parentItemTitle: PropTypes.bool,
    isTopLevelItem: PropTypes.bool,
}

export default RecursiveListItem;
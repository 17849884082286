import React from 'react';

import TopicProvider from './context/TopicProvider';

import NewTopicForm from './NewTopicForm'

function CreateTopicPage() {
    return (
        <TopicProvider>
            <NewTopicForm />
        </TopicProvider>
    );
}


export default CreateTopicPage;
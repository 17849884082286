/**
 * Grid for displaying Cards
 * @param {gridCol, gap, className, children} props 
 * @returns CardGrid
 */
function CardGrid(props) {
    return (
        <div className={`grid 
        ${props.gridCol || "grid-cols-1 md:grid-cols-2"}
        ${props.gap || "gap-4"} 
        ${props.className}
         `}>
            {props.children}
        </div>
    );
}

export default CardGrid;
import { useTranslation } from "react-i18next";
import { XIcon } from '@heroicons/react/outline';

import PageWrapper from "components/PageWrapper";
import Button from "components/Button";
import CircularAvatarWithInitials from 'components/avatar/CircularAvatarWithInitials';

import FormContainer from "components/FormContainer";

const people = [
  {
    name: 'Lindsay Walton',
    handle: 'lindsaywalton',
    email: 'lindsaywalton@example.com',
    role: 'Front-end Developer',
    imageId: '1517841905240-472988babdf9',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Courtney Henry',
    handle: 'courtneyhenry',
    email: 'courtneyhenry@example.com',
    role: 'Designer',
    imageId: '1438761681033-6461ffad8d80',
    imageUrl:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Tom Cook',
    handle: 'tomcook',
    email: 'tomcook@example.com',
    role: 'Director, Product Development',
    imageId: '1472099645785-5658abf4ff4e',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
]

export default function AddTeamMember() {
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <FormContainer
        guide={false}
        title='Team'>
        {/* <p className="whitespace-pre-line mt-1 text-sm text-gray-700">
          {t('share-text1',
            `You can now share your problem definition within and beyond the borders of your organization.
                \nBy including contributors with diverse perspectives, you can achieve a professional and personal enrichment and thus a higher result quality. 
                \nAll contributors will need to agree to handle the information confidentially.`)}
        </p> */}
        <form action="#" className="mt-6 flex">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter an email"
          />
          <div className="relative flex items-center">
            <label htmlFor="role" className="sr-only">
              Role
            </label>
            <select
              id="role"
              name="role"
              className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            >
              <option>can view</option>
              <option>can contribute</option>
              <option>admin access</option>
            </select>
          </div>
          <button
            type="submit"
            className="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Send invite
          </button>
        </form>
        <div className="mt-10">
          <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
            {t('Team members previously added to resource')}
          </h3>
          <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            {people.map((person, personIdx) => (
              <li key={personIdx} className="py-4 flex items-center justify-between space-x-3">
                <div className="min-w-0 flex-1 flex items-center space-x-3">
                  <div className="flex-shrink-0">
                    <CircularAvatarWithInitials size="8" >NA</CircularAvatarWithInitials>
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 truncate">{person.name}</p>
                    <p className="text-sm font-medium text-gray-500 truncate">{person.email}</p>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <label htmlFor="role" className="sr-only">
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  >
                    <option>can view</option>
                    <option>can contribute</option>
                    <option>admin access</option>
                  </select>
                </div>
                <a
                  className='cursor-pointer'
                  >
                  <XIcon className='w-5 h-5 m-auto text-gray-500' />
                </a>
              </li>
            ))}
          </ul>
        </div>

      </FormContainer>
      <div className={`flex justify-between w-full md:w-2/3 xl:w-1/2`}>
        <Button>{t('Back')}</Button>
      </div>
    </PageWrapper>
  )
}
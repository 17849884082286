import { useContext } from 'react';
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";

import CreateIssueContext from "../context/create-issue-context";
import ProofPointRow from "./ProofPointRow";


function ProofPointTable(props) {
    const { t } = useTranslation();
    const text = {
        tableHeader: [
            t('3-ppt1',"Analysis"),
            t('3-ppt2',"Responsible"),
            t('3-ppt3',"Deadline"),
            t('3-ppt4',"Result"),
            t('3-ppt5',"Interpretation​"),
            ""],
    }

    const ctx = useContext(CreateIssueContext)

    const newRowHandler = () => {
        ctx.newProofPointRowHandler(props.id,props.index,'proofPoint');
    }

    const tableData = ctx.data.resolutionHypothesis[props.index].proofPoint;

    return (
        <EditableTable editActive={props.editActive} tableHeader={text.tableHeader} addRow={newRowHandler}>
            {tableData && tableData.map((item, index) => (
                <ProofPointRow
                    editActive={props.editActive}
                    hypIndex = {props.index}
                    key={item.id}
                    index={index}
                />
            ))}
        </EditableTable>
    );
}


export default ProofPointTable;
import VertexForm from "./VertexForm";
import { ResizablePanelRight } from "components/ResizablePanelRight";


function AiDisplay({ active, context, handleClose, rightPosition, zIndex, topPosition }) {

    return (
        <ResizablePanelRight name={'vertexPanel'} active={active} handleClose={handleClose} rightPosition={rightPosition} topPosition={topPosition} zIndex={zIndex}>
            <VertexForm context={context} />
        </ResizablePanelRight>
    );
}

export { AiDisplay };
import React from 'react';

const CommunityContext = React.createContext({
    nextHandler: () => {},
    prevHandler: () => {},
    handleChange: (name, e) => {},
    handleObjectChange: (objectName, name, data) => {},
    itemHandler: (name, data) => {},
    openOverview: () => {},
})

export default CommunityContext;
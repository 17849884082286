import React from 'react';
import PropTypes from 'prop-types';

// import colors from 'tailwindcss/colors'

// Avatar Component based on tailwind-ui:
// https://tailwindui.ccomponents/application-ui/elements/avatars

function CircularAvatarWithInitials(props) {

    // TODO: get random color // this method can be common // color based on initials?
    // TODO: parse initials

    // https://tailwindcss.com/docs/customizing-colors
    let colors = ['red', 'yellow', 'green', 'blue', 'pink'] // purple excluded

    // let color = colors[Math.floor(Math.random() * colors.length)]

    let initials = props.children
    let ic = (initials.charCodeAt(0) + initials.charCodeAt(1)) % colors.length

    if (ic >= colors.length) {
        ic = ic - colors.length
    }

    let color = colors[ic]

    return (
        <span className={`inline-flex items-center justify-center h-${props.size} w-${props.size} rounded-full bg-${color}-500`}>
            <span className="text-xs font-medium leading-none text-white">{props.children}</span>
        </span>
    )
}


CircularAvatarWithInitials.propTypes = {
    props: PropTypes.object
};

export default CircularAvatarWithInitials;

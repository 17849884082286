const aiDict = {
    "v-des1":"Geben Sie Ihren Prompt ein. Ihre Problemstellung wird systemseitig als Kontext für Ihren Prompt genutzt.",
    "a_s11_st_h_1":"Klarheit",
    "a_s11_st_d_1":"Geben Sie klare Anweisungen, z.B. \"Zitiere im APA-Stil und nenne 3 Quellen\"",
    "a_s11_st_h_2":"Branchenspezifität",
    "a_s11_st_d_2":"Nutzen Sie branchen- oder funktionsspezifische Begriffe, z.B. \"Kalenderjahresschadenquote\"",
    "a_s11_st_h_2b":"Organisationsspezifität",
    "a_s11_st_d_2b":"Wenn Ihre Organisation proprietäre Wissensquellen verwendet, die anreichernd über das Large Language Model hinausgehen (z.B. Sitzungsprotokolle), können Sie auch organisationsspezifische Begriffe verwenden",
    "a_s11_st_h_3":"Präzision",
    "a_s11_st_d_3":"Seien Sie möglichst präzise in der Wortwahl, z.B. \"Deep Learning\" (als Teilbereich) statt \"Künstliche Intelligenz\" im Ganzen",
    "a_s11_st_h_4":"Kontext",
    "a_s11_st_d_4":"Vermitteln Sie Kontextinformationen, z.B. \"im Lichte der Niedrigzinsphase von 2010-2022, …\" oder \"angesichts einer Budgetrestriktion von 1000 EUR, …\"",
    "a_s11_st_h_5":"Perspektive",
    "a_s11_st_d_5":"Nennen Sie Ihren Blickwinkel, z.B. \"vom Standpunkt der Aufsichtsbehörde aus, …\"",
    "prompt1":"Was sind die wichtigsten Erkenntnisse aus Forschungsarbeiten oder Fallstudien von [z.B. Beratungsunternehmen oder Forschungsinstitute] aus der Sicht von [gewünschte Perspektive angeben, z. B. Risikomanagement]? Beschränke Dich auf [X] prägnante Stichpunkte und nenne Quellen",
    "prompt2":"Welches sind die 3 größten Risiken und die 3 größten Chancen, die [...] im Hinblick auf die Problemstellung aus der Sicht von [...] sehen? Nenne Quellen",
    "prompt3":"Welches sind Best Practices, die von [...] aus der Perspektive [...] genannt werden? Beschränke Dich auf [X] prägnante Stichpunkte und nenne Quellen",
    "prompt4":"Nenne [5] Veröffentlichungen in [geben Sie die relevante Disziplin an, z. B. Ökonometrie]-Zeitschriften mit Peer Review-Verfahren. Gib jeweils eine Zusammenfassung von höchstens [100 Wörtern] an und zitiere die Quelle im APA-Stil",
    "prompt5":"Nenne [4] führende Experten, die sich anhand von zitierten Publikationen nachweislich mit der Problemstellung aus der Sicht von [...] beschäftigt haben? Nenne Quellen",
    "prompt6":"Erläutere die zugrundeliegenden Prinzipien und Branchentrends, die beobachteten Auswirkungen und ihre Bedeutung für [geben Sie Ihr Interessengebiet an, z. B. Risikomanagement, Biologie, ...]",
}

export default aiDict;
/**
 * Just Card no content
 * @returns 
 */
function CardFrame({ children, className }) {
    return (
        <div className={`${className} 
        border-2 border-gray-200 shadow-sm rounded-lg`}>
            {children}
        </div>
    );
}

export default CardFrame;

import { useContext } from 'react';
import SingleComment from './SingleComment';
import { CommentContext } from './context/CommentContext';
import { useTranslation } from "react-i18next";

// Displays list of Comments,
function CommentList({className}) {
    const { t } = useTranslation(['common']);
    const { commentList } = useContext(CommentContext)

    return (
        <div className={`${className} space-y-1`}>
            {commentList
                ? commentList.map((comment, index) => (
                    <SingleComment
                        key={index}
                        index={index}
                        comment={comment}
                    />
                ))
                :
                // TODO Translation
                <span className='block py-6 text-center'>{t("comment-empty","Write the first comment!")}</span>
            }
        </div>
    )
}

export default CommentList;
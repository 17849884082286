import { aiActionTypes as types } from 'constants/ai.action.types';


const initialState = (window.Cypress && window.initialState && window.initialState.alertReducer) || {};

export function aiReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.AI_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.AI_SUCCESS:
      return {
          ...payload
      };
    case types.AI_FAILURE:
    default:
      return state;
  }
}

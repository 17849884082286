const problemDefinitionDict = {
    //////////////////////// Start OF 'Create Problem'
    // *** Create Problem ***
    "1-prev":"Vorschau",
    // ** Problem Statement ** > 1-ps-
    "1-ps-title": "Titel & Typ",
    "1-ps-hl": "Ihr Thema",
    "1-ps-placeholder": "z.B. CO2 Reduzierung in Schulen",
    "1-ps-prompt": "Thema & Status",
    "1-ps-desc": "z.B. Problemstellung, Idee, Forschungsfrage, Use Case, Designziel, …",
    "1-ps-confidentialTitel": "Schutzstufe",
    "1-ps-confidentialTooltip": "Bitte definieren Sie für das Endprodukt „Problemdefinition“, das Sie derzeit erstellen, die erforderliche Schutzstufe.",
    "1-ps-confi1": "Streng vertraulich",
    "1-ps-confi2": "Vertraulich ",
    "1-ps-confi3": "Intern",
    "1-ps-confi4": "Öffentlich",
    "1-ps-confi1tooltip": "Z.B. Gesundheitsdaten, dienstliche Beurteilungen",
    "1-ps-confi2tooltip": "Z.B. Projektergebnisse ",
    "1-ps-confi3tooltip": "Z.B. Rechercheergebnisse ",
    "1-ps-confi4tooltip": "Z.B. Marketingflyer",
    "1-ps-visibilityTitel": "Sichtbarkeit",
    "1-ps-visibilityTooltip": "Hier legen Sie fest, wer Ihren Input sehen und kommentieren darf.",
    "1-ps-visib1": "Plattform",
    "1-ps-visib2": "Diese und weitere Communities",
    "1-ps-visib3": "Diese Community",
    "1-ps-visib4": "Einzelpersonen",
    "1-ps-visib1tooltip": "Sichtbar für alle registrierten Nutzerinnen und Nutzer auf der Plattform",
    "1-ps-visib2tooltip": "Sichtbar für Mitglieder dieser und weiterer von Ihnen ausgewählter Communities",
    "1-ps-visib3tooltip": "Sichtbar nur für alle Mitglieder dieser Community",
    "1-ps-visib4tooltip": "Sichtbar nur für Personen, die Sie persönlich benennen",
    "area1":"Marktsegment",
    "area2":"Wertschöpfungsschritt",
    "1-ps-tags":"Schlagworte",
    "1-ps-noSelect" : "-",
    "1-ps-linkList":"Relevante Links",
    "1_ps_topicState1":"Offen",
    "1_ps_topicState2":"Implementiert",
    // Tipps
    "1-ps-1t": "Spezifisch",
    "1-ps-1d": "Was genau möchten Sie erreichen, z.B. „den NPS erhöhen“?",
    "1-ps-2t": "Messbar & Realistisch",
    "1-ps-2d": "Setzen Sie ein quantitatives und realistisches Ziel, z.B. „um 2 %-Pkt.“",
    "1-ps-3t": "Zeitgebunden",
    "1-ps-3d": "Setzen Sie einen Zeitrahmen, innerhalb dessen das Ziel erreicht werden soll, z.B. „innerhalb von 6 Monaten“",
    "1-ps-4t": "Ergebnisoffen",
    "1-ps-4d": "Vermeiden Sie eine Lösungsvorgabe. Lassen Sie Raum für Kreativität",
    "1-ps-5t": "Wertbeitragend",
    "1-ps-5d": "Stellen Sie einen möglichst direkten Beitrag zu den übergeordneten Unternehmenszielen sicher",
    "1-ps-6t": "Inspirierend",
    "1-ps-6d": "Ihre Problemstellung sollte ein Team motivieren, das Problem zu lösen",
    "1-ps-info": `Beispiel: Wie können wir den Ausstoß an Treibhausgasen unseres Gebäudes bis 2023 um 25 % reduzieren?`,
    // Guide
    "1-ps-g1t": "Warum Schutzstufen? ",
    "1-ps-g1d": `Schutzstufen sind ein bewährtes Instrument, um die Vertraulichkeit von Informationen zu gewährleisten.\n
    Zu schützende Informationen können hierbei personenbezogene Daten, aber auch Rechte geistigen Eigentums inklusive Geschäftsgeheimnissen sein.\n 
    Während die Verpflichtung zum Schutz personenbezogener Daten aus der DSGVO resultiert, folgt die Verpflichtung zum Schutz von Geschäftsgeheimnissen aus der EU-Richtlinie 2016/943 sowie den nationalen Regelwerken, z.B. in Deutschland aus dem Geschäftsgeheimnisgesetz (GeschGehG).\n 
    Vor allem aber ist Vertraulichkeit eine wesentliche Grundlage für offene, vertrauensvolle, wertstiftende Diskussionen; Richtlinien zur Vertraulichkeit stellen einen wesentlichen Beitrag zur Einhaltung höchster professioneller Standards dar. `,
    "1-ps-g2t": "Was sind Schutzstufen?",
    "1-ps-g2d": `Schutzbedarfe ergeben sich aus dem Schadenspotential, das sich aufgrund einer möglichen Verletzung der Vertraulichkeit für die betroffenen natürlichen und/oder juristischen Personen (also z.B. Ihre Mitarbeiter, Kunden und Vertriebspartner sowie Ihr Unternehmen) realisieren kann.\n
    Je höher der Grad möglicher Beeinträchtigung schutzwürdiger Belange aus physischen, materiellen oder immateriellen Schäden, umso höher der Schutzbedarf, und umso höher die Schutzstufe. `,
    "1-ps-g3t": "Wie ist der Zusammenhang zum Datenschutz?",
    "1-ps-g3d": `Der Schutz von Geschäftsgeheimnissen als Teilmenge geistiger Eigentumsrechte sowie der Schutz von personenbezogenen Daten überlagern sich häufig, auch wenn die Schutzrichtungen sich unterscheiden.\n
    Die Schutzstufenkonzept eignet sich zum Schutz aller genannten Kategorien von Daten bzw. Informationen. \n
    Zum Beispiel wäre dringend zu empfehlen, hochsensible Daten wie solche zu Umstrukturierungen Ihres Unternehmens als „streng vertraulich“ oder mindestens „vertraulich“ zu kennzeichnen, genauso wie Sie mit besonderen personenbezogenen Daten verfahren würden. `,
    "1-ps-g4t": "Wie sind die Schutzstufen definiert? ",
    "1-ps-g4d": `Schutzstufen sind in der Grundeinstellung der Software wie folgt definiert.\n
    Streng vertraulich:  
    •	Gegenstand sind streng vertrauliche Informationen und/oder besondere Kategorien personenbezogener Daten (Art. 9 DSGVO), für die strenge und stark begrenzte Zugriffsbeschränkungen für einen eng definierten Personenkreis gelten  
    •	Eine ungeregelte Weitergabe an unberechtigte Dritte ist untersagt  
    •	Bei unerlaubter Weitergabe dieser Informationen besteht das Risiko eines sehr hohen bis existenzgefährdenden Schadens 
    •	Beispiele: Gesundheitsdaten, dienstliche Beurteilungen, hochvertrauliche Projektergebnisse 
    
    Vertraulich:  
    •	Gegenstand sind vertrauliche Informationen und/oder personenbezogene Daten, für die erhöhte und begrenzte Zugriffsbeschränkungen für einen definierten Personenkreis gelten  
    •	Eine ungeregelte Weitergabe an unberechtigte Dritte ist untersagt  
    •	Bei unerlaubter Weitergabe dieser Informationen besteht das Risiko eines mittleren bis hohen Schadens 
    •	Beispiele: Projektergebnisse, die einem Wettbewerber einen Vorteil verschaffen können 
    
    Intern:  
    •	Gegenstand sind interne Informationen oder personenbezogene Daten, für die einfache Zugriffsbeschränkungen gelten  
    •	Eine ungeregelte Weitergabe an unberechtigte Dritte ist untersagt  
    •	Bei unerlaubter Weitergabe dieser Informationen besteht das Risiko eines geringen Schadens 
    •	Beispiele: Interne Regularien; Rechercheergebnisse 
    
    Öffentlich:  
    •	Gegenstand sind Informationen, die öffentlich oder für die Öffentlichkeit gedacht sind. Es bestehen keine Einschränkungen in der Handhabung 
    •	Bei Offenlegung der Informationen entsteht kein Schaden 
    •	Beispiele: Werbeflyer `,
    "1-ps-g5t": "Welche Konsequenzen ergeben sich aus der festgelegten Schutzstufe? ",
    "1-ps-g5d": `Je nachdem, wie wahrscheinlich die Realisierung des Schadenpotentials ist, resultiert aus der Schutzstufe das Erfordernis risikoadäquater technischer und organisatorischer Maßnahmen. Beispiele hierfür sind: \n
    •	Sensible personenbezogene Daten z.B. aus der Stakeholderanalyse sind technisch nicht teilbar 
    •	Vor der Zugänglichmachung von Informationen muss jeder Nutzer der Software sich authentifizieren und eine Vertraulichkeitsvereinbarung (NDA) unterzeichnen 
    •	Für die Authentifizierung gelten festgelegte Sicherheitsvorkehrungen, z.B. bzgl. der Ausgestaltung des Passworts 
    •	Für jedes Endprodukt muss der Zugriff separat erteilt werden \n
    Sollten Sie einen Vorgang als „öffentlich“ klassifizieren, so können Sie überlegen, diesen auch aktiv zu veröffentlichen, z.B. um Ihnen unbekannte Experten oder Dienstleister darauf aufmerksam zu machen.  `,
    // Topic Types
    "1_ps_tt_l1":"Problemstellung",
    "1_ps_tt_tt1":"Formulieren Sie ergebnis- bzw. wirkungsorientiert, spezifisch, messbar, realistisch und zeitgebunden. Lassen Sie Raum für Kreativität und stimmen Sie sich mit anderen Bereichen Ihrer Organisation ab.",
    "1_ps_tt_l2":"Idee",
    "1_ps_tt_tt2":"Formulieren Sie Ihre Idee zielgerichtet, durchführbar, neuartig, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte.",
    "1_ps_tt_l3":"Use Case",
    "1_ps_tt_tt3":"Geben Sie an, wer die Lösung auf welche Weise nutzen wird, und wofür sie verwendet wird.",
    "1_ps_tt_l4":"Forschungsfrage",
    "1_ps_tt_tt4":"Formulieren Sie Ihre Forschungsfrage zielgerichtet, durchführbar, neuartig, relevant, systematisch, interessant; berücksichtigen Sie ethische Aspekte",
    "1_ps_tt_l5":"POV Statement & Designziel",
    "1_ps_tt_tt5":"In Ihrem POV-Statement ('Point of View') beschreiben Sie, wer die Nutzerpersona ist, was Ihre Bedürfnisse sind und aus welchem Grund. Stellen Sie dann eine 'Wie könnten wir'-Frage, die Raum für Kreativität lässt und auf das in Ihrem POV-Statement formulierte Designziel ausgerichtet ist",
    "1_ps_tt_l6":"Sonstiges",
    "1_ps_ttinfo":`Beispiel Problemstellung:
    Wie können wir den Ausstoß an Treibhausgasen unseres Gebäudes bis 2023 um 25 % reduzieren?
    \nBeispiel Use Case:
    Ein Versicherer will eine Lösung entwickeln, die Callcentermitarbeitenden dabei hilft, durch spezifische, zeitnahe Angaben zu erweiterten Kundenanfragen die Reaktionszeit zu verkürzen und die Qualität zu verbessern.
    \nBeispiel POV-Statement & Designziel: Ein Callcentermitarbeitender braucht Abwechslung in Bezug auf die Stimmung und den Inhalt von Kundenanfragen, denn sein Engagement und damit die Kundenzufriedenheit steigen mit vielseitiger Arbeit. Wie kann man also die Abwechslung in seiner Arbeit erhöhen?`,
    // ** Critical Definitions ** > 1-cd-
    "1-cd-hl": "Wichtige Definitionen",
    "1-cd-desc": "Führen Sie hier Begriffe auf, über deren Definition Klarheit bestehen sollte",
    "1-cd-table1": "Begriff",
    "1-cd-table2": "Definition",
    "1-cd-table3": "Kommentar",
    "1-cd-tooltip": "Insbesondere in Teamkonstellationen mit verschiedenen Hintergründen bietet es sich an, wichtige Begriffe klar zu definieren",
    // Guide
    "1-cd-g1h": "Beispiel",
    "1-cd-g1t": `Treibhausgasausstoß:
    Gesamter, in Tonnen gemessener Ausstoß an Treibhausgasen aus Produktion, Nutzung und Einstellung eines Produktes oder einer Dienstleistung.
    Treibhausgase umfassen nicht nur Kohlendioxid, sondern auch Methan, Lachgas und fluorierte Treibhausgase. Die Definition soll eine Verengung auf Kohlendioxid vermeiden, denn alle genannten Gase tragen zur Erderwärmung bei.`,
    // ** Problem Rating ** > 1-pr-
    "1-pr-hl": "Problemeinstufung",
    "1-pr-desc": "Stufen Sie Ihr Problem ein",
    "1-pr-r1t": "Einfach",
    "1-pr-r1d": "Umweltzustand in der Gegenwart ist klar, in der Zukunft (nahezu) sicher",
    "1-pr-r2t": "Kompliziert",
    "1-pr-r2d": "Umweltzustände in Gegenwart und/oder Zukunft sind mit mittlerer Unsicherheit behaftet",
    "1-pr-r3t": "Komplex",
    "1-pr-r3d": "Umweltzustände in Gegenwart und/oder Zukunft sind mit hoher Unsicherheit behaftet",
    "1-pr-r4t": "Chaotisch",
    "1-pr-r4d": "Umweltzustände in Gegenwart und Zukunft sind mit extrem hoher Unsicherheit behaftet",
    "1-pr-explanation1": "Beispiel:\n Ihr Auto entlang einer Anleitung notstarten",
    "1-pr-explanation2": "Beispiel:\n Ein Kraftwerk warten",
    "1-pr-explanation3": "Beispiel:\n Ein neues Automodell für einen Auslandsmarkt entwickeln ",
    "1-pr-explanation4": "Beispiel:\n Die Produktion angesichts eines plötzlichen Lockdowns umstellen",
    // Guide
    "1-pr-g1h": "Methodik zur Problemeinstufung",
    "1-pr-g1t": "Sie können sich entlang der Dimensionen „Unsicherheit über zukünftige Umweltzustände“ (Lösungsraum) und „Unsicherheit über den gegenwärtigen Umweltzustand“ (der Kontext inkl. bekannter und unbekannter Anforderungen der Stakeholder) orientieren",
    "1-pr-g2h": "Inhaltliche Begründung",
    "1-pr-g2t": "Zunächst schafft die Einstufung der Problemstellung mehr Klarheit für das Team entlang der beiden Dimensionen.\n Vor allem aber ist sie entscheidend für die spätere Wahl der Mittel und gegebenenfalls unmittelbar für die weitere Problemdefinition, z.B. für die Zusammenstellung der Stakeholder",
    // ** Situation and Complications ** > 1-sc-
    "1-sc-hl": "Ausgangslage und Herausforderung",
    "1-sc-tooltip1": "Beispiel:\n Gebäude von 1980 ohne substantielle Renovierung seitdem. 1000 Beschäftigte als Nutzer im täglichen Durchschnitt. Gasheizung; Heizkosten von 70.000 €, Stromkosten von 20.000 € pro Jahr",
    "1-sc-prompt1": "Beschreiben Sie die Ausgangslage faktenbasiert",
    "1-sc-prompt2": "Beschreiben Sie die Herausforderungen, vor denen Sie stehen",
    "1-sc-sit":"Ausgangslage",
    "1-sc-comp":"Herausforderungen",
    // Tipps
    "1-sc-1t": "Fakten",
    "1-sc-1d": "Bleiben Sie neutral und vermeiden Sie Wertungen",
    "1-sc-2t": "Konsens",
    "1-sc-2d": "Es sollte Konsens aller Stakeholder über die Ausgangslage bestehen",
    "1-sc-3t": "Klarheit",
    "1-sc-3d": "Auch Außenstehende sollten Ihre Fakten und Definitionen nachvollziehen können",
    "1-sc-4t": "Hürden",
    "1-sc-4d": "Welche Hürden müssen beseitigt, bewältigt oder umgangen werden?",
    "1-sc-5t": "Risiken und Chancen",
    "1-sc-5d": "Nennen Sie nicht nur Risiken, sondern auch Chancen, die sich aus den Hürden ergeben",
    "1-sc-6t": "Wertung",
    "1-sc-6d": "Hier können Sie die Ebene der reinen Fakten hin zu balancierten Wertungen verlassen",
    "1-sc-7t": "Klarheit",
    "1-sc-7d": "Auch Außenstehende sollten ihre Wertungen nachvollziehen können",
    "1-sc-tooltip2": `Beispiel: 
    Eltern ziehen es vor, ihre Kinder aus Sicherheitsgründen zur Schule zu fahren, und setzen so ein Vorbild, das dem Ziel der Emissionssenkung entgegenläuft
    Die zur Meinungsbildung besonders relevante Gruppe der Lehrkräfte sieht im Durchschnitt einen geringen Handlungsbedarf, wäre aber offen für Veränderungen`,
    // ** Stakeholders ** > 1-sh
    "1-sh-hl": "Stakeholder",
    "1-sh-tooltip": "Je besser Sie verstehen, wer Ihre Stakeholder sind und weshalb, umso früher im Projekt können sie diese einbinden und zu Ko-Autoren machen",
    "1-sh-prompt": "Definieren Sie relevante interene und externe Teilnehmer",
    "impact-5": "Sehr Hoch",
    "impact-4": "Hoch",
    "impact-3": "Medium",
    "impact-2": "Niedrig",
    "impact-1": "Sehr Niedrig",
    // Table Header
    "1-sh-table-1": "Stakeholder",
    "1-sh-table-2": "Impact",
    "1-sh-table-3": "Erklärung des Impacts",
    "1-sh-table-4": "Betroffenheit",
    "1-sh-table-5": "Bedürfnisse und Erwartungen",
    "1-sh-table-6": "Persönliche Präferenzen und Motive",
    "1-sh-table-7": "Beziehung zu diesem Stakeholder",
    // Tipps
    "1-sh-1t": "Balance",
    "1-sh-1d": "Ist ihre Sicht ausbalanciert?",
    "1-sh-2t": "Eisberg",
    "1-sh-2d": "Gelingt es Ihnen, unter die Wasseroberfläche zu schauen?",
    "1-sh-3t": "Pragmatische Vollständigkeit",
    "1-sh-3d": "Haben Sie die relevanten Stakeholder erfasst? Orientieren Sie sich an Impact und Betroffenheit",
    "1-sh-4t": "Vertraulichkeit",
    "1-sh-4d": "Schreiben Sie streng vertrauliche oder geheime Sachverhalte nur in die mit „VERTRAULICH - NUR KERNTEAM“ gekennzeichneten Felder",
    // Guide
    "1-sh-g1t": "Eine Person oder eine Gruppe, die entweder direkten oder indirekten Impakt auf den Ergebnisraum ihres Projekts haben oder die von ihrem Projekt betroffen sind",
    "1-sh-g2t": "Der Impakt eines Stakeholders ergibt sich aus dessen Einfluss auf den Ergebnisraum aus professionellen oder persönlichen Gründen. Diese Information ist sehr sensibel und kann daher nur von Kernteammitgliedern eingesehen werden",
    "1-sh-g3t": "Diese Erklärung ist relevant, damit Ihr Team den Stakeholder adäquat einschätzen kann. Diese Information ist sehr sensibel und kann daher nur von Kernteammitgliedern eingesehen werden",
    "1-sh-g4t": "Ein Stakeholder kann von Ihren Handlungen und deren Konsequenzen betroffen sein, ohne einen materiellen Einfluss auf die Entscheidungsfindung auszuüben (z.B. Anwohner einer Fabrik, operative Einheiten weiter hinten in der Wertschöpfungskette)",
    "1-sh-g5t": "Was der Stakeholder ausgesprochen oder unausgesprochen von Ihrem Projekt aufgrund seiner Rolle erwartet. Hier sollten Sie so präzise und faktenbasiert wie möglich vorgehen und ggf. eine Klärung vornehmen",
    "1-sh-g6t": "Was den Stakeholder persönlich bewegt, z.B. eine mögliche Beförderung, persönliche Animositäten, der Wunsch nach langfristigem Reputationsaufbau, … \n\nDiese Information ist sehr sensibel und kann daher nur von Kernteammitgliedern eingesehen werden",
    "1-sh-g7t": "Z.B. eng, vertrauensvoll, …\n\nDiese Information ist sehr sensibel und kann daher nur von Kernteammitgliedern eingesehen werden",
    // ** Key Results (Definition of Done) ** >1-kr-
    "1-kr-hl": "Kernresultate",
    "1-kr-prompt": "Was muss gegeben sein, damit Ihr Problem als gelöst betrachtet werden kann?",
    "1-kr-tooltip": "Ihre Kernresultate müssen alle erreicht werden, damit Ihr Problem als gelöst gilt. Kernresultate sollten möglichst immer, müssen aber nicht quantitative Aspekte enthalten",
    // Tipps
    "1-kr-1t": "Spezifisch",
    "1-kr-1d": "Was genau möchten Sie erreichen, z.B. „den NPS erhöhen“. Definieren Sie Resultate, nicht Leitplanken",
    "1-kr-2t": "Messbar",
    "1-kr-2d": "Setzen Sie ein quantitatives Resultat, z.B. „um 2 %Pkt.“",
    "1-kr-3t": "Realistisch",
    "1-kr-3d": "Stellen Sie sicher, dass die Erreichung der Resultate realistisch ist",
    "1-kr-4t": "Zeitgebunden",
    "1-kr-4d": "Setzen Sie einen Zeitrahmen, innerhalb dessen das Resultat erreicht werden soll, z.B. „innerhalb von 6 Monaten“",
    "1-kr-5t": "Ergebnisoffen",
    "1-kr-5d": "Vermeiden Sie auch hier eine Lösungsvorgabe. Lassen Sie Raum für Kreativität",
    "1-kr-6t": "Wertbeitragend",
    "1-kr-6d": "Stellen Sie einen möglichst direkten, messbaren Beitrag zu den übergeordneten Unternehmenszielen sicher",
    // Guide
    "1-kr-g1h": "Was ist der Unterschied zwischen Unternehmenszielen, Zielen, Kernresultaten, und Leitplanken?",
    "1-kr-g1t": `•	Unternehmens- oder Organisationsziele (engl.: goals) sind typischerweise breit gefasst und zielen auf die lange Frist ab, z.B. „die Nr. 1 im Markt zu werden nach Umsatz“
    \n•	Ein Ziel (engl.: objective) ist spezifischer und gibt eine Richtung vor, mit der das zugehörige Unternehmensziel erreicht werden soll 
    \n•	Ihre Problemstellung enthält Ihr Ziel in Frageform. Das Ziel sollte inspirierend sein und möglichst direkt und messbar auf die Organisationsziele einzahlen
    \n•	Ihre Kernresultate (engl.: key results) können quantitative und/oder qualitative Elemente enthalten. Alle müssen erreicht werden, damit Ihr Problem als gelöst und Ihr Ziel somit als erreicht gilt
    \n•	Eines Ihrer Resultate sollte das primäre quantitative Kernresultat sein, z.B. „45 Mio. EUR an Neugeschäft bis Ende des Jahres“
    \n•	Wenn Sie ein Resultat verfolgen, maximieren (oder minimieren) Sie eine Zielgröße: Mehr (oder weniger) ist besser
    \n•	Während Sie das tun, müssen Sie jedoch Leitplanken beachten, z.B. Gesetze oder Konzernvorgaben. Tun Sie das nicht, gehen Sie möglicherweise erhebliche Risiken ein. Gleichzeitig steht es Ihnen jedoch frei, den kompletten durch die Leitplanken begrenzten Raum zu nutzen
    \n`,
    "1-kr-g1l": "Graphische Visualisierung",
    "1-kr-g2h": "Wie sollten Kernresultate formuliert sein?",
    "1-kr-g2t": `Grundsätzlich 
    \n•	Formulieren Sie Resultate, nicht Leitplanken – diese formulieren Sie gesondert 
    \n•	Bleiben Sie sparsam mit der Zahl der Resultate – dies hilft Ihrem Team bei der Fokussierung und der Kreativität 
    \n•	Setzen Sie ein primäres  quantitatives Kernresultat (problemspezifisch ggf. auch weitere) 
    \nFür jedes Kernresultat 
    \n•	Spezifität: Was genau möchten Sie erreichen, z.B. „den RNPS erhöhen“, „die telefonische Wartezeit senken“. Dies ist beides spezifischer als z.B. „das Kundenerlebnis verbessern“. Nutzen Sie ggf. die Definitionen, um Begriffe zu schärfen 
    \n•	Messbarkeit: Wieviel wollen Sie erreichen, z.B. „um 50%“, „auf 15 Sekunden“, „um 5 Positionen auf Rang 8 im Markt“ 
    \n•	Erreichbarkeit: Formulieren Sie ambitionierte, aber realistische Resultate, z.B. unter Nutzung von Benchmarks oder qualitativen Wettbewerbsvergleichen 
    \n•	Zeitliche Bindung: Bis wann wollen Sie Ihr Resultat erreicht haben? Z.B. „bis 2030“, „innerhalb des nächsten Kalenderjahres“ 
    \n•	Ergebnisoffenheit: Vermeiden Sie eine Vorgabe des Lösungsweges, z.B. durch Wendungen wie „indem wir…“ oder „durch Nutzung von …“ 
    \n•	Wertbeitrag: Zahlt die Erreichung des Resultats auf die übergeordneten Unternehmensziele ein? Z.B. zahlt eine 10%-ige Steigerung des Neugeschäfts auf das Konzernziel „Wachstum“ ein `,
    "1-kr-g3h": "Beispiele: Kernresultat ",
    "1-kr-g3t": `Treibhausgasemissionen um 25% gesenkt gegenüber Baseline 2021 innerhalb eines Jahres 
    \n•	Conversion Rate (Leads zu Abschlüssen) bis Ende 2023 gesteigert von 5% auf 10%  `,
    "1-kr-g4h": "Beispiele: Leitplanken ",
    "t-kr-g4t": `Keine Veränderung an unseren Kernsystemen 
    \n•	Aktives Einverständnis des Regulators 
    \n•	Abstimmung mit Zentralfunktionen dokumentiert  `,
    // ** Primary Metric, Kernmetriken **
    "1-pm-title": "Kernmetriken",
    "1-pm-primary": "Primäre Metrik",
    "1-pm-secondary": "Sekundäre Metrik",
    "1-pm-tertiary": "Tertiäre Metrik",
    "1-pm-button1": "Sekundäre Metrik",
    "1-pm-button2": "Tertiäre Metrik",
    "1-pm-prompt1": "Legen Sie die Baseline für Ihr quantitatives Kernresultat fest",
    "1-pm-label1": "Größe und Zeitpunkt",
    "1-pm-label2": "Wert",
    "1-pm-label3": "Einheit",
    "1-pm-tooltip1": `Beispiel\n\nGröße und Zeitpunkt: Treibhausgasemissionen zum 31.12.2020\nWert: 2000\nEinheit: Tonnen`,
    "1-pm-tooltip1-ref": "Graphische Visualisierung",
    "1-pm-tooltip2": "Auch wenn Ihr Problem multidimensional ist, sollten Sie ein primäres quantitatives Kernresultat angeben.\n Dieses wird den Fokus Ihres Teams entlang des gesamten Problemlösungsprozesses erhöhen.\n Sie können danach auch eine sekundäres und ggf. ein drittrangiges Ziel setzen",
    "1-pm-prompt2": "Setzen Sie das primäre quantitative Kernresultat fest",
    "1-pm-label4": "Wert",
    "1-pm-label5": "Delta zum Kernresultat",
    "1-pm-prompt3": "Benennen Sie das Unternehmensziel zu dem Ihr primäres quantitatives Kernresultat beiträgt",
    "1-pm-goalLabel": "Unternehmensziel",
    "1-pm-percentage": "Prozent",
    "1-pm-tooltip3": `Beispiel:\n Eine Wachstumsinitiative mit einem Kernresultat von 1000 EUR zahlt typischerweise zu 100% auf das Ziel „Wachstum“ ein.
    Sie können ein Sekundärresultat benennen, wenn die Lösung Ihres Problems auf mehr als ein Ziel einzahlt`,
    // Tipps 
    "1-pm-1t": "Zeitpunktbezogen",
    "1-pm-1d": "Die Baseline sollte auf ein Datum bezogen sein",
    "1-pm-2t": "Messbar",
    "1-pm-2d": "Die Baseline erfordert eine klar definierte Größe mit einer Einheit",
    "1-pm-3t": "Unstrittig",
    "1-pm-3d": "Die Baseline sollte möglichst wenig Anlass zu Meinungsstreit geben",
    "1-pm-4t": "Verzahnt",
    "1-pm-4d": "Die Baseline sollte möglichst dieselbe Größe und Maßeinheit aufweisen wie das hier relevanteste Konzernziel, oder aber überleitbar sein",
    "1-pm-5t": "Quantitatives Kernresultat",
    "1-pm-5d": "Eines Ihrer Kernresultate, am besten das wichtigste, welches quantifizierbar ist",
    "1-pm-6t": "Wertbeitrag",
    "1-pm-6d": "Möglichst direktes, nachvollziehbares Einzahlen auf die übergeordneten Unternehmensziele",
    "1-pm-7t": "Konsistenz",
    "1-pm-7d": "Gleiche Größe und Einheit wie die Baseline",
    "1-pm-8t": "Unternehmensziel ",
    "1-pm-8d": "Z.B. das Unternehmensziel „Wachstum auf 10 Mrd. EUR Umsatz bis 2023“, falls Ihr primäres quantitatives Kernresultat wachstumsbezogen ist. Die Unternehmensziele können zentral vorgegeben sein",
    "1-pm-9t": "Wertbeitrag ",
    "1-pm-9d": "Geben Sie an, welcher Anteil Ihres quantitativen Kernresultats auf das identifizierte Unternehmensziel einzahlt  - häufig sollten das 100% sein. Lesehilfe: “Wir erhöhen den Gewinn um 50 EURM, und 90% davon kommen dem Gewinnziel unseres Unternehmens zugute. 10% zählen nicht, weil unser Konzern nur Gewinne aus Europa gelten lässt”  ",
    "1-pm-10t": "Mehrdimensionalität",
    "1-pm-10d": "Prüfen Sie, ob Sie ggf. ein sekundäres quantitatives Kernresultat erstellen möchten, das auf ein weiteres Konzernziel einzahlt (z.B. eine Wachstumsinitiative, die auch Ertragspotentiale hat)",
    //GUIDE
    "1-pm-link": "Graphische Visualisierung",
    "1-pm-g1h": "Warum eine Baseline?",
    "1-pm-g1t": `•	Die Baseline ist gleichsam die Startlinie Ihres Projekts. Sie sollte daher objektiv und nachvollziehbar sein und nicht zu Diskussionen führen
    \n•	Die Baseline ist Voraussetzung für Messbarkeit Ihres Erfolgs
    \n•	Sie verringert die Wahrscheinlichkeit späterer Konflikte bzgl. des Erfolgs Ihres Projekts`,
    "1-pm-g2h": "Ist eine Baseline möglicherweise nicht sinnvoll?",
    "1-pm-g2t": `•	Reflektieren Sie auch im Falle qualitativer Fragestellungen, ob Sie nicht einen quantitativen Startpunkt in Form einer Baseline setzen können (z.B. einen Prozentsatz oder einen Index, der abstrakte Konzepte wie Gerechtigkeit operationalisiert, …)
    \n•	Achten Sie in diesem Fall auf eine saubere Definition der verwendeten Größe
    \n•	Ergibt dies immer noch keinen Sinn, verzichten Sie auf Baseline und quantitatives Kernresultate`,
    "1-pm-g3h": "Was ist der Unterschied zwischen Unternehmenszielen, Zielen, Kernresultaten, und Leitplanken?",
    "1-pm-g3t": `•	Unternehmens- oder Organisationsziele (engl.: goals) sind typischerweise breit gefasst und zielen auf die lange Frist ab, z.B. „die Nr. 1 im Markt zu werden nach Umsatz“
    \n•	Ein Ziel (engl.: objective) ist spezifischer und gibt eine Richtung vor, mit der das zugehörige Unternehmensziel erreicht werden soll 
    \n•	Ihre Problemstellung enthält Ihr Ziel in Frageform. Das Ziel sollte inspirierend sein und möglichst direkt und messbar auf die Organisationsziele einzahlen
    \n•	Ihre Kernresultate (engl.: key results) können quantitative und/oder qualitative Elemente enthalten. Alle müssen erreicht werden, damit Ihr Problem als gelöst und Ihr Ziel somit als erreicht gilt
    \n•	Eines Ihrer Resultate sollte das primäre quantitative Kernresultat sein, z.B. „45 Mio. EUR an Neugeschäft bis Ende des Jahres“
    \n•	Wenn Sie ein Resultat verfolgen, maximieren (oder minimieren) Sie eine Zielgröße: Mehr (oder weniger) ist besser
    \n•	Während Sie das tun, müssen Sie jedoch Leitplanken beachten, z.B. Gesetze oder Konzernvorgaben. Tun Sie das nicht, gehen Sie möglicherweise erhebliche Risiken ein. Gleichzeitig steht es Ihnen jedoch frei, den kompletten durch die Leitplanken begrenzten Raum zu nutzen`,
    "1-pm-g4h": "Gibt es Situationen, in denen ein Team mehr als ein Kernresultat braucht?",
    "1-pm-g4t": `•	All Kernresultate müssen erreicht werden, damit Ihr Problem als gelöst betrachtet werden kann
    \n•	Während Sie die Komplexität durch die Wahl möglichst weniger Kernresultate gering halten können, wird es im Regelfall mehr als eines geben (müssen)
    \n•	Es kann sogar Abwägungssituationen geben, in denen Kernresultate potentiell miteinander konfligieren (z.B. die Erhöhung der Preise mit der Verringerung der Stornoquoten) 
    \n•	Solche Konfliktsituationen sollten vermieden werden, z.B. indem Kernresultate in Leitplanken transformiert werden: Die Erhöhung der Preise darf dann nur noch mit einer Stornoquote von maximal 10% einhergehen, bzw. umgekehrt`,
    "1-pm-g5h": "Was tun bei einer großen Zahl an Kernresultaten?",
    "1-pm-g5t": `•	Halten Sie die Komplexität durch die Wahl möglichst weniger Kernresultate gering
    \n•	Transformieren Sie Kernresultate in Leitplanken, wenn diese nicht der Regel „Mehr (oder weniger) ist besser“ gehorchen
    \n•	Erstellen Sie Leitplanken im nächsten Schritt: Im Gegensatz zu einem Kernresultat muss eine Leitplanke lediglich nicht verletzt werden`,
    "1-pm-g6h": "Warum ein quantitatives Kernresultat?",
    "1-pm-g6t": `•	Es erhöht den gedanklichen Fokus Ihres Teams
    \n•	Es reduziert Komplexität im Alltag und in der Kommunikation
    \n•	Es erhöht die Objektivität und Messbarkeit Ihres Erfolgs durch Abgleich der IST-Werte mit dem quantitativen Kernresultat
    \n•	Es kann inspirierend und sinnstiftend wirken, vor allem wenn es eine direkte Verknüpfung zu Ihren Unternehmenszielen gibt
    \n•	Zu beachten: So wichtig das quantitative Kernresultat auch ist, so ist es nur notwendig, nicht hinreichend für Ihren Erfolg (auch die weiteren Kernresultate und die Leitplanken müssen Sie beachten)`,
    "1-pm-g7h": "Wann ist ein sekundäres oder sogar drittrangiges quantitatives Kernresultat angebracht?",
    "1-pm-g7t": `•	Manchmal kann zur Lösung eines Problems die Erreichung von mehr als nur einem quantitativen Kernresultat erforderlich sein oder aber ein positiver Nebeneffekt sein
    \n•	Der komplexitätsreduzierende Weg über die Formulierung einer Randbedingung ist nicht immer sinnvoll, z.B. in klassischen „Minimiere das Risiko-maximiere den Ertrag-Situationen“
    \n•	Auch ist es sinnvoll, verschiedene Kernresultate direkt und transparent mit verschiedenen Unternehmenszielen zu verknüpfen
    \n•	In solchen Fällen können sekundäre quantitative Kernresultate sinnvoll sein
    \nBeispiel Pricing 
    • 3 EURM an Umsatz durch Pricing ➔ Einzahlen zu 100% auf Ziel „Wachstum“ 
    • 1 EURM an zusätzlichem Ertrag aus diesen Pricing-maßnahmen ➔ Einzahlen zu 100% auf Ziel „Profitabilität“`,
    "1-pm-g8h": "Was tun, wenn Quantifizierung keinen Sinn ergibt?",
    "1-pm-g8t": `•	Reflektieren Sie auch im Falle qualitativer Fragestellungen, ob Sie nicht einen quantitativen Startpunkt in Form einer Baseline (z.B. einen Prozentsatz, einen Index, der abstrakte Konzepte wie Gerechtigkeit operationalisiert, …) sowie ein quantitatives Kernresultat setzen können
    •	Achten Sie in diesem Fall auf eine saubere Definition der verwendeten Größe
    •	Ergibt dies immer noch keinen Sinn, verzichten Sie auf Baseline und quantitatives Kernresultat
    `,
    // ** Constraints ** > 1-c-
    "1-c-hl": "Leitplanken",
    "1-c-tooltip": `Während ein Kernresultat der Regel „mehr (oder weniger) ist besser“ gehorcht, geht es bei einer Leitplanke darum, diese lediglich einzuhalten. 
    Geschieht dies nicht, können z.B. die erzielten Resultate entwertet werden (wie ein Tor, das aufgrund der verletzten Abseitsregel nicht zählt)`,
    "1-c-prompt": "Welche Leitplanken bzw. Randbedingungen gelten?",
    // Tipps
    "1-c-1t": "Kohärenz",
    "1-c-1d": "Leitplanken sind keine Kernresultate: Es gilt also nicht „Mehr (oder weniger) ist besser“",
    "1-c-2t": "Minimale Hürde",
    "1-c-2d": "Beschränken Sie Ihre Leitplanke auf das erforderliche Minimum, um den Lösungsraum möglichst weit zu belassen",
    "1-c-3t": "Überprüfbarkeit",
    "1-c-3d": "Die Leitplanke und ihre Bindungskraft sind überprüfbar, z.B. durch einen Blick in das Gesetzbuch oder durch die Bestätigung eines Stakeholders",
    "1-c-table1": "Leitplanke",
    "1-c-table2": "Bindungskraft",
    "1-c-table3": "Kommentar",
    "1-c-table-rating1": "Weich",
    "1-c-table-rating2": "Mittel",
    "1-c-table-rating3": "Hart",
    // **OVERVIEW** > 1-ov-
    "1-ov-title": "Übersicht",
    "1-ov-confiLabel": "Schutzstufe",
    "1-ov-visib": "Sichtbarkeit",
    "1-ov-pri": "Primär",
    "1-ov-sec": "Sekundär",
    "1-ov-ter": "Tertiär",
    "1-ov-sit": "Ausgangslage",
    "1-ov-com": "Herausforderungen",
    "1-ov-def": "Definitionen",
    // Tabview, Project View 2-
    "2-probdef": "Problemdefinition",
    "Problem Definition": "Problemdefinition",
    "2-probstru": "Problemstruktur",
    "2-iss": "Priorisierte Themen",
    "2-app": "Optionen",
    "2-decspa": "Entscheidungsraum",
    "2-com": "Executive Summary",
    // ** Share View share-
    "share-title": "Teilen und Feedback erhalten",
    "share-text1": `Sie haben nun die Möglichkeit, Ihre Problemdefinition innerhalb und außerhalb der Grenzen Ihrer Organisation zu teilen.
    \nDurch die enge Einbindung von Inputgebern mit diversen Perspektiven können Sie eine inhaltliche und zwischenmenschliche Bereicherung und somit eine höhere Ergebnisqualität erzielen.
    \nAlle Teilnehmer werden auf die Vertraulichkeit verpflichtet.`,
    "share-invited": "Eingeladene Personen",
    "share-invite-tooltip": `Bitte tragen Sie die E-Mail Adressen der einzuladenden Personen ein.
    \nTrennen Sie mehrere Adressen mit einem Komma.`,
    // GUIDE
    "share-guide-1": `Wozu dient die Teilen-Funktion?`,
    "share-guide-2": `•	Die Teilen-Funktion ist ein Herzstück dieses Programms, weil Sie hier die Diversität der Perspektiven innerhalb und außerhalb Ihrer Organisation nutzen können
    \n•	Sie können zu jedem Zeitpunkt beliebig viele Personen in Ihre Problemlösungssituation einbeziehen
    \n•	Sie können früh im Prozess sicherstellen, dass Ihre Problemdefinition auf stabilem Grund steht`,
    "share-guide-3": `Wie wird die Teilen-Funktion genutzt?`,
    "share-guide-4": `•	Fügen Sie beliebig viele Mailadressen hinzu
    \n•	Ihre benannten Inputgeber erhalten eine Mail und können auf Ihre Problemdefinition zugreifen
    \n•	Sichten Sie die eingehenden Kommentare. Es ist empfehlenswert, alle Kommentare zu beantworten, auch dann, wenn Sie diese inhaltlich nicht berücksichtigen möchten
    \n•	Bitte beachten Sie: Ihre Inputgeber können nur auf das Endprodukt „Problemdefinition“ zugreifen, nicht auf weitere Endprodukte, die Sie noch erstellen. Dies ist aus Gründen der Vertraulichkeit so geregelt
    `,
    "share-guide-5": `Wie werden die Inputgeber informiert?`,
    "share-guide-6": `•	Die Inputgeber erhalten allgemeine Informationen und die Einladung, sich an Ihrer Problemlösung zu beteiligen
    \n•	Die Inputgeber erhalten eine Datenschutzerklärung mit der Bestätigung, dass ihre personenbezogenen Daten stets entsprechend der Datenschutzgrundverordnung (EU) 2016/679 behandelt werden
    \n•	Die Inputgeber erhalten und akzeptieren eine Vertraulichkeitserklärung: Hiermit erklären sie, dass sie vertrauliche Informationen, die ihnen zugänglich gemacht werden, auch vertraulich behandeln werden. Vertrauliche Informationen im Sinne dieser Vereinbarung sind sämtliche Informationen (ob schriftlich, elektronisch, mündlich, digital verkörpert oder in anderer Form), die dem Inputgeber durch Sie zur Verfügung gestellt werden
    \n•	Die Inputgeber erhalten und akzeptieren die Allgemeinen Geschäftsbedingungen. Hier finden sich die Grundlagen zu Zugriffsberechtigung, Schulung, Support, Datenspeicherung, Leistungserbringung, SLA und Störungsmanagement, Nutzungsrechten, Weiterentwicklungen, Mitwirkungspflichten, Kundendaten und Freistellung von Ansprüchen Dritter, Herausgabe und Löschung von Daten, Verarbeitung personenbezogener Daten und Auftragsverarbeitung, Vertraulichkeit sowie weiteren allgemeinen Bestimmungen. Auch finden sich hier Verweise auf weitere Anlagen.
    `,
    // ** MODALS > m-
    "m-saveNewProblem-title": "Ihre Problemdefinition wurde gespeichert",
    "m-saveNewProblem-body": `Sie haben die erste Etappe geschafft!\n Sie haben nun die Möglichkeit, Ihre Problemdefinition innerhalb und außerhalb der Grenzen Ihrer Organisation zu teilen.\n
    Durch die enge Einbindung von Inputgebern mit diversen Perspektiven können Sie eine inhaltliche und zwischenmenschliche Bereicherung und somit eine höhere Ergebnisqualität erzielen.`,
    "required-hoverInfo1":"Bitte füllen Sie alle Pflichtfelder und wählen Sie eine Community aus."
    //////////////////////// END OF 'Create Problem'
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default problemDefinitionDict;
const findObject = (arr, id) => {
    if (arr) {
        for (const obj of arr) {
            if (obj.id === id) return obj;
      
            const nestedObj = findObject(obj?.children || [], id);
            if (nestedObj) return nestedObj;
          }
    }
  }

// Filters list of objects for ids of objects that were selected
const flatArrayForSelected = (objectList, idList, extendChildSameID = true) => {
    let filteredList = [];
    objectList = objectList || [];
    if (idList) {
        idList.forEach(id => {
            const foundObject = findObject(objectList, id)
            if (foundObject) {
                filteredList.push(foundObject);
            }
    
            // sometimes parent and its first child have the same id
            // it's only a workaround that increases the robustness 
            if (extendChildSameID) {
                const foundChildObject = findObject(foundObject?.children, id)
                if (foundChildObject) {
                    filteredList.push(foundChildObject);
                }
            }
    
        });
    }
    return filteredList;
}

export { flatArrayForSelected }
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function MenuItem({ onClick, to, label, bold, padding, children }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (<Menu.Item>
        {({ active }) => (
            <Link
                to={to}
                onClick={onClick}
                className={classNames(
                    active ? 'bg-gray-100' : '',
                    bold ? 'font-bold' : '',
                    padding ? padding : 'px-4 py-2',
                    'block text-sm text-gray-700'
                )}
            >
                {children ? children : label}
            </Link>
        )}
    </Menu.Item>);
}

export default MenuItem;
import { alertActionTypes as types } from 'constants/alert.action.types' 

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: types.SUCCESS, payload: message };
}

function error(message) {
    return { type: types.ERROR, payload: message };
}

function clear() {
    return { type: types.CLEAR };
}
/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline'
import 'tw-elements';
import { parseItem } from 'utils/session-storage'

import { listUserCommunities } from 'actions/communities.list.actions';
import { getCommunityById } from 'actions/community.get.actions';
import { listUserAlerts } from 'actions/user-alerts.list.actions';
import './sidbarScrollbar.css'

import { SidebarWrapper } from './SidebarWrapper';
import { Documents } from './Documents';
import { Communities } from './Communities';
import { Navigation } from './Navigation';
import { Logo } from './Logo';
import { ModalOrganisationSwitch } from './ModalOrganisationSwitch';
import ModalJoinCommunity from './ModalJoinCommunity';

// Changes Collapse Icon according to collapsed state
function Sidebar({
  organisations,
  communities,
  getCommunityById,
  listUserCommunities,
  listUserAlerts }) {
  const history = useHistory();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [collapseIcon, setCollapseIcon] = useState(<ChevronDoubleLeftIcon />);
  const [organisationId, setOrganisationId] = useState(parseItem('organisation')?.id || null);
  const [currentCommunity, setCurrentCommunity] = useState(parseItem('community') || { id: null });
  const [logoPath, setLogoPath] = useState(null);
  const [docCollapsed, setDocCollapsed] = useState(true)

  const handleCollapsed = (state) => {
    setCollapsed(state)
  }

  const collapseButtonHandler = () => {
    let currentIcon = collapsed ? <ChevronDoubleLeftIcon /> : <ChevronDoubleRightIcon />;
    setCollapsed(!collapsed);
    setCollapseIcon(currentIcon);
    handleCollapsed && handleCollapsed(!collapsed)
  }

  useEffect(() => {
    if (parseItem('community')?.organisationId && parseItem('organisation')?.id) {
      if (parseItem('community').organisationId === parseItem('organisation').id) {
        setCurrentCommunity(parseItem('community'))
      } else if (communities?.length > 0) {
        setCurrentCommunity(communities[0])
      } else {
        sessionStorage.setItem('community', null)
        setCurrentCommunity({ id: null })
      }
    } else if (communities?.length > 0) {
      setCurrentCommunity(communities[0])
    } else {
      sessionStorage.setItem('community', null)
      setCurrentCommunity({ id: null })
    }
  }, [communities])

  // community bypassing must not be possible
  useEffect(() => {
    if (location?.pathname) {
      if (/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/.test(location.pathname)) {
        let match = location.pathname.match(/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/)
        let communityId = match.groups.communityId

        setCurrentCommunity({ id: communityId })
      }
    }
  }, [location])

  // setting new community
  useEffect(() => {
    if (currentCommunity?.id) {
      getCommunityById(currentCommunity.id)
    }
  }, [currentCommunity])

  // getting alerts
  useEffect(() => {
    if (currentCommunity?.id) {
      listUserAlerts(currentCommunity.id)
    }
  }, [currentCommunity])

  // redirect to home by community switch
  useEffect(() => {
    if (currentCommunity?.id) {
      if (!parseItem('community') || (parseItem('community')?.id !== currentCommunity.id)) {
        sessionStorage.setItem('community', JSON.stringify(currentCommunity))
        history.push('/home')
      }
    }
  }, [currentCommunity])

  useEffect(() => {
    if (parseItem('organisation')?.id) {
      setOrganisationId(parseItem('organisation').id)
    }
  }, [sessionStorage.getItem('organisation')])

  useEffect(() => {
    if (organisationId) {
      listUserCommunities(organisationId)
    }
  }, [organisationId])

  return (
    <SidebarWrapper collapsed={collapsed}>
      {/* Modal for changing company platforns */}
      <ModalOrganisationSwitch
        organisationId={organisationId}
        organisations={organisations}
        setOrganisationId={setOrganisationId}
      />
      {/* Modal for joining Community */}
      <ModalJoinCommunity />
      <div className={`relative flex-1 flex flex-col pt-5 pb-4 overflow-x-hidden ${docCollapsed ? 'overflow-y-hidden' : 'overflow-y-auto'}`}>
        {/* Logo */}
        <Logo collapsed={collapsed} logoPath={logoPath} />
        {/* Navigation Links */}
        <Navigation
          collapsed={collapsed}
          currentCommunity={currentCommunity}
        />
        {/* Communities List */}
        <Communities
          collapsed={collapsed}
          currentCommunity={currentCommunity}
          userCommunities={communities}
          setCurrentCommunity={setCurrentCommunity}
        />
        {/* Documents */}
        <Documents
          docCollapsed={docCollapsed}
          setDocCollapsed={setDocCollapsed}
          organisationId={organisationId}
          organisations={organisations}
          setLogoPath={setLogoPath}
        />
        {/* Collapse Button */}
        <button className={`absolute bottom-0 right-0 invisible lg:visible py-2.5 font-medium text-xs text-white leading-tight rounded shadow-md h-10 w-full`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".multi-collapse"
          aria-expanded="false"
          onClick={collapseButtonHandler}>
          <div id='collapsedIcon' className={`right-4 absolute bottom-2 ml-4 h-5 w-5`}>
            {collapseIcon}
          </div>
        </button>
      </div>
    </SidebarWrapper>
  )
}

Sidebar.propTypes = {
  organisations: PropTypes.array.isRequired,
  communities: PropTypes.array.isRequired,
  listUserCommunities: PropTypes.func.isRequired,
  getCommunityById: PropTypes.func.isRequired,
  listUserAlerts: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  organisations: state.organisationReducer.organisations,
  communities: state.communityReducer.communities,
  profile: state.profileReducer.profile,
});


export default connect(mapStateToProps, { listUserCommunities, getCommunityById, listUserAlerts })(Sidebar);
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";
import CreateProblemContext from '../context/ProblemContext';

import StakeholderRow from './StakeholderRow';

function StakeholdersTable(props) {
    const { t } = useTranslation();

    const stakeholderTableHeader = [
        { name: 'stakeholder', private: false, text: t('1-sh-table-1', 'Stakeholder') },
        { name: 'impact', private: true, text: t('1-sh-table-2', 'Impact') },
        { name: 'impactExplanation', private: true, text: t('1-sh-table-3', 'Explanation of impact') },
        { name: 'affected', private: false, text: t('1-sh-table-4', 'Affectedness') },
        { name: 'need', private: false, text: t('1-sh-table-5', 'Needs & Expectations') },
        { name: 'keyPreferences', private: true, text: t('1-sh-table-6', 'Personal preferences and objectives') },
        { name: 'relationship', private: true, text: t('1-sh-table-7', 'Relationship with the stakeholder') },
        { name: ' ', private: false }
    ]

    const ctx = useContext(CreateProblemContext)
    const [tableInfoActive, setTableInfoActive] = useState(false);

    const toggleInfo = () => {
        setTableInfoActive(!tableInfoActive);
    }

    const newRowHandler = () => {
        ctx.newRowHandler('stakeholders');
    }

    const tableData = ctx.data.stakeholders;

    return (
        <div className="relative">
            <EditableTable editActive={props.editActive} tableHeader={stakeholderTableHeader} colSpan={7}
                onClick={toggleInfo}
                addRow={newRowHandler}>
                {tableData && tableData.map((item, index) => (
                    <StakeholderRow
                        editActive={props.editActive}
                        index={index}
                        key={item.id} />
                ))}
            </EditableTable>
        </div>
    );
}

export default StakeholdersTable;
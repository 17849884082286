
import React, { useState, useContext } from 'react';

import EditableRow from 'components/EditableRow';

import CreateIssueContext from '../context/create-issue-context';

function ProofPointRow(props) {
    const ctx = useContext(CreateIssueContext);
    const thisRow = ctx.data.resolutionHypothesis[props.hypIndex].proofPoint[props.index];
    const oneRow  = ctx.data.resolutionHypothesis[props.hypIndex].proofPoint?.length === 1;
    const tableName = "proofPoint";

    const [rowStructure, setRowStructure] = useState([
        { id: 0, name: 'analysis', value: thisRow.analysis, type: "area", style: "w-1/4 font-semibold" },
        { id: 1, name: 'responsible', value: thisRow.responsible, type: "area" },
        { id: 2, name: 'deadline', value: thisRow.deadline, type: "area" },
        { id: 3, name: 'result', value: thisRow.result, type: "area" },
        { id: 4, name: 'interpretation', value: thisRow.interpretation, type: "area" },
    ])
   
    const deleteHandler = () => {
        ctx.deleteProofPointRowHandler(tableName,props.hypIndex, thisRow.id);
    }

    const changeHandler = (index,name,event,id) => { 
        let tempData = [...rowStructure];
        tempData[id].value = event.target.value;
        setRowStructure(tempData);
        ctx.proofPointchangeRowHandler(props.hypIndex,index,id,name, event);
    }
   
    return (
        <EditableRow
            editActive={props.editActive}
            rowId={thisRow.id}
            rowIndex={props.index}
            oneRow={oneRow}
            rowData={rowStructure}
            delete={deleteHandler}
            changeHandler={changeHandler}>
        </EditableRow>
      
    );
}

export default ProofPointRow
function Logo({ logoPath, collapsed }) {

  return (
    <div
      className="flex items-center flex-shrink-0 mx-auto"
      id="companyIcon"
      data-bs-toggle='modal'
      data-bs-target='#companiesModal'>
      {logoPath ? (
        <img
          className={`h-auto transition-all`}
          style={{ maxWidth: collapsed ? '30px' : '150px' }}
          src={logoPath}
          alt="Logo"
        />
      ) : (
        <img
          className="h-auto w-xxs"
          src=""
          alt="Logo"
        />
      )}
    </div>
  );
}

export { Logo };
import { instance as axios } from 'ajax';

import { loadingActions } from './loading.actions';
import { alertActions } from './alert.actions';
import { getSignedUrl } from './file.signed-url.actions';

export function deleteFile(id, filename) {
    return dispatch => {

        return getSignedUrl(id, filename, 'delete', dispatch)
            .then(signedLink => {
                return deleteWithSignedUrl(signedLink, dispatch)
            });
    };
};

const deleteWithSignedUrl = (signedLink, dispatch) => {

    dispatch(loadingActions.start());

    return fetch(signedLink, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => {
            dispatch(loadingActions.finish());
            if(response.ok) {
                return Promise.resolve(response);
            } else {
                // file cannot be found -> no need to delete
                if (response.status === 404) {
                    return Promise.resolve(response); 
                }
                return Promise.reject(response);
            }
        },
        error => {
            dispatch(loadingActions.finish());

            if (error.response) {
                // Request made and server responded
                dispatch(alertActions.error(error.response.data));
            } else if (error.request) {
                // The request was made but no response was received
                dispatch(alertActions.error(error.request));
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(alertActions.error(error.message));
            }
            return Promise.reject(error);
        });

}
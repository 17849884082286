import React, { useEffect, useRef } from 'react';

/**
 * SideMenu for DotMenu
 * !! Parent needs to be 'relative' for this
 * @param {children, zIndex, ml, bg, border} props 
 * onClose = parent changes status to hide component
 * zIndex = default is z-10, use higher index if needed
 * ml = margin left, for distance from parent component
 * bg = background color for menu
 * border = border styling, color
 * children = all Button components nested inside this component
 *            use <SideMenuButton> for best integrity
 * @returns Side Menu to the right side of a component with a dot menu
 */
function SideMenu({ zIndex, ml, bg, border, children, onClose, position, width }) {
    const sideMenuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
                onClose()
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={sideMenuRef}
            className={`
        ${zIndex || 'z-10'}
        ${ml || 'ml-1'}
        ${bg || 'bg-white'}
        ${border || 'border border-gray-200'}
        ${position || 'top-9 right-1'}
        ${width}
        absolute flex flex-col
        rounded-md 
        text-sm mt-1`}>
            {children}
        </div>
    );
}

export default SideMenu;
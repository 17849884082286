import PageWrapper from "components/PageWrapper";
import { useTranslation } from "react-i18next";

function SupportPage() {
    const { t } = useTranslation(['common']);
    const text = {
        title: t('Contact'),
        text: t('com-contact',`Please feel free to contact our customer service:
        \nsupport@solverint.com
        \nWe are more than happy to assist you, e.g.,fn technical or user experience matters. Also, we are thankful for feedback of any kind.
        \nHowever, please note that the content and conditions of your event are within the scope of responsibilities of your contracting partner, who is also the “Data Controller” in the in the sense of Art. 4 No. 7 GDPR.
        \nYou will find the contact information of the Data Controller and its Data Protection Officer in the section "About and Legal" under "Reference to Data Controller".`)
    }

    return (
        <PageWrapper>
            <div className="w-7/8 lg:w-2/3 2xl:w-1/2 pr-2 pt-10">
                <p className="text-xl">{text.title}</p>
                <p className="whitespace-pre-line my-4">{text.text}</p>
            </div>
        </PageWrapper>
    );
}

export default SupportPage;
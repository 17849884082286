import TooltipBase from "./TooltipBase";
import { useState } from "react";

/**
 * 
 * @param {text, color, onClick, tooltipActive, hoverInfo, flagProps} props 
 * @returns Single flag with optional hoverInfo
 */
function Flag({ textSize, flagProps, color, ...props }) {
    const hasHoverInfo = props.tooltipActive && props.hoverInfo;
    const [hoverInfo, setHoverInfo] = useState(false);


    return (
        <div
            id={props.id}
            index={props.index}
            onClick={props.onClick}
            href={props.href}
            data-bs-toggle={props.dataBsToggle}
            aria-controls={props.ariaControls}
            onMouseOver={() => { setHoverInfo(true) }}
            onMouseOut={() => { setHoverInfo(false) }}
            className={`
                ${color || "bg-green-200"}
                ${props.style}
                ${props.cursor || 'cursor-pointer'}
                ${flagProps?.border || "border"}
                ${flagProps?.borderColor || "border-gray-400"}
                ${flagProps?.margin || 'mx-1 my-2'}
                ${textSize || 'text-base font-medium '}
                rounded-md  
                inline-flex items-baseline px-2.5 py-0.5 
                select-none relative text-center
                `
            }>
            {props.text || 'FLAG'}
            {(hoverInfo && hasHoverInfo) && <div className="absolute top-8 -left-4 z-10">
                <TooltipBase width="w-48">{props.hoverInfo}</TooltipBase>
            </div>}
        </div>
    );
}

export default Flag;
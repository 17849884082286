import { useContext } from "react";

import Button from 'components/Button';
import SidePanel from "components/SidePanel";

import TreeContext from "./context/tree-context";

// TODO Translation
// This panel displays the number of selected cards,
// Cards can be exported for further steps
function ExportPanel() {
    const ctx = useContext(TreeContext);
    const selectedCards = ctx.data.selected?.length;

    return (
        <SidePanel hideDisabled={true}>
            <p className="text-lg font-medium leading-6 text-gray-900 select-none">
                {selectedCards} Cards selected
            </p>
            <p className="w-64 py-4">You can export the selected Cards to continue working with them</p>
            {/* TODO Export Function */}
            <Button>Export Now</Button>
        </SidePanel>
    );
}

export default ExportPanel;
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import returnStoreAndPersistor from './store';
import { PersistGate } from 'redux-persist/integration/react'

// i18n
import './i18n';

import './index.css';

// routing
import BrowserRouter from './routing/BrowserRouter';
import PrivateRoute from './routing/PrivateRoute'

import NotFoundPage from './pages/NotFoundPage'
import LoginPage from './pages/LoginPage'
import EmailConfirmationPage from 'pages/LoginPage/EmailConfirmationPage';
import RegisterConfirmationPage from 'pages/RegisterPage/RegisterConfirmationPage';
import RegisterPage from './pages/RegisterPage'
import HomePage from './pages/HomePage'
import OwnUserProfilePage from './pages/UserProfilePage/OwnUserProfilePage';
import PublicUserProfilePage from 'pages/UserProfilePage/PublicUserProfilePage';
import NewProfile from './pages/UserProfilePage/NewProfile';
import CreateTopicPage from './pages/CreateTopicPage'
import CreateProblemPage from './pages/CreateProblemPage'
import CreateCommunityPage from './pages/CreateCommunityPage'
import AddTeamMember from './pages/AddTeamMember'
import CommunityPage from './pages/CommunityPage'
import ProblemsListView from './pages/ProjectPage'
import ProblemOverviewPage from './pages/ProblemOverviewPage'
import ProblemTabOverviewPage from './pages/ProblemTabOverviewPage'
import TreeWrapper from './pages/CreateProblemStructure/TreePage/TreeWrapper';
import ProblemStructureGuidedStepsPage from './pages/CreateProblemStructure/ProblemStructureGuidedStepsPage'
import CreateIssuePage from './pages/CreateIssuePage'
import IssuesList from './pages/IssuesListPage'

import PrinciplesDe from 'documents/Principles_DE';
import ImpressumDe from 'documents/Impressum_DE';
import DSEDe from 'documents/DSE_DE';
import DSEEng from 'documents/DSE_ENG';
import SupportPage from 'documents/SupportPage';

import AIForm from 'pages/AiPoc';
import UploadPOC from 'pages/UploadPOC';

// import Wrapper from './Wrapper'

// <PrivateRoute exact path="/home" component={Wrapper(HomePage)} /> // TODO: this makes infinite loop

const uuidRegex = '([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'

const { store, persistor } = returnStoreAndPersistor();

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={RegisterPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/email-confirmation" component={EmailConfirmationPage} />
              <Route exact path="/register-confirmation" component={RegisterConfirmationPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/principles" component={PrinciplesDe} />
              <Route exact path="/legalNotice" component={ImpressumDe} />
              <Route exact path="/dse" component={DSEDe} />
              <Route exact path="/dse_eng" component={DSEEng} />
              <PrivateRoute exact path="/home" component={HomePage} />
              <PrivateRoute exact path={`/community/:communityId${uuidRegex}`} component={CommunityPage} />
              <PrivateRoute exact path="/support" component={SupportPage} />
              <PrivateRoute exact path="/clusters" component={HomePage} />
              <PrivateRoute exact path="/profiles/me" component={OwnUserProfilePage} />
              <PrivateRoute exact path={`/profiles/:userId${uuidRegex}`} component={PublicUserProfilePage} />
              <PrivateRoute exact path="/profiles/new" component={NewProfile} />
              <PrivateRoute exact path="/communities/new" component={CreateCommunityPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}`} component={ProblemsListView} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/topics/new`} component={CreateTopicPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/topics/:problemId${uuidRegex}`} component={ProblemOverviewPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/new`} component={CreateProblemPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/:problemId${uuidRegex}`} component={ProblemTabOverviewPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/topics/:problemId${uuidRegex}/team`} component={AddTeamMember} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/team/add`} component={AddTeamMember} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/:problemId${uuidRegex}/createTree`} component={TreeWrapper} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/:problemId${uuidRegex}/newProblemStructureGuided`} component={ProblemStructureGuidedStepsPage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/:problemId${uuidRegex}/issues/new`} component={CreateIssuePage} />
              <PrivateRoute exact path={`/clusters/:projectId${uuidRegex}/problems/:problemId${uuidRegex}/issues`} component={IssuesList} />
              <PrivateRoute exact path="/poc/ai" component={AIForm} />
              <PrivateRoute exact path="/poc/upload" component={UploadPOC} />
              <Route component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;

import { instance as axios } from 'ajax';
import { batch } from 'react-redux';

import { authActionTypes as types } from 'constants/auth.action.types';
import { loadingActions } from './loading.actions';
import { alertActions } from './alert.actions';

export function logout() {
    return dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        batch(() => {
            dispatch(loadingActions.start());
        });

        return axios
            .post('/api/logout', config)
            .then(
                response => {
                    batch(() => {
                        dispatch(success());
                        dispatch(loadingActions.finish());
                    });

                    return response;
                },
                error => {
                    batch(() => {
                        dispatch(alertActions.error(error));
                        dispatch(loadingActions.finish());
                    });
                    
                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            );
    };

    function success() { return { type: types.LOGOUT } }

};
import HeaderText from "components/HeaderText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortOptions } from "./SortOptions";
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

/**
 * Header for Gird displays
 * Includes Title, Description, a place for filter, icon to view 'team modal' 
 * 
 * @param {string} id - id for this gridHeader 
 * @param {Object} owner  - optional, needs an object of a user, with the userId, firstName, lastName
 * @param {string} title - title that should be displayed 
 * @param {string} description - description that should be displayed 
 * @param {JSX.Element} filter - fully functional HTML input element for filtering content
 * @param {string} teamModal - id of modal that should be displayed
 * @returns 
 */
function GridHeader({ id, owner, title, description, filter, teamModal, handleSortChange, currentSortType }) {
    return (
        <div id={id} className='flex flex-col md:flex-row mb-4'>
            {/* Project Title, Owner, Description */}
            <div className='flex-grow mr-8 md:w-3/4'>
                <HeaderText>{title}</HeaderText>
                {owner && <a href={`/profiles/${owner.userId}`}>
                    <p className='text-gray-500 text-xs hover:text-gray-700 hover:font-bold'>
                        {owner.firstName} {owner.lastName}
                    </p>
                </a>}
                <div className='text-gray-600 mt-2'>{description}</div>
            </div>

            <div className='w-full md:w-1/4 flex justify-end items-end '>
                <div className='flex items-center space-x-2'>
                    {/* sorting */}
                    <SortOptions onChange={handleSortChange} currentSort={currentSortType}/>
                    {/* Search/Filter */}
                    {filter}
                    {/* Team Modal */}
                    {teamModal &&
                        <div className='group relative'
                            data-bs-toggle='modal'
                            data-bs-target={teamModal}>
                            <div id='team' className=''>
                                <FontAwesomeIcon
                                    id='team'
                                    className='group-hover:text-gray-400 text-gray-300 mr-3 mt-2 h-6 w-6'
                                    icon={faUserGroup} />
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    );
}

export {GridHeader};
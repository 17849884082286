import { faComment,
faFileLines,
faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function NotificationItem({ item }) {
    const { t } = useTranslation(['common'])
    const iconClass = item.isActive ? 'text-primary-600' : 'text-primary-200'

    let contentConfig = null
    switch(item.type) {
        case 'COMMENT':
            contentConfig = {
                icon: <FontAwesomeIcon icon={faComment} className={iconClass} />,
                title: <p>{t('not-com',`New Comment on`)}
                    <span className="font-bold whitespace-nowrap"> {item.meta.title}:</span>
                </p>,
                text: item.meta.author.firstName + " " + item.meta.author.lastName + ": " + item.meta.text,
            }
            break;
        case 'REPLY':
            contentConfig = {
                icon: <FontAwesomeIcon icon={faComment} className={iconClass} />,
                title: <p>{t('not-rep',`New Reply on`)}
                    <span className="font-bold whitespace-nowrap"> {item.meta.title}:</span>
                </p>,
                text: item.meta.author.firstName + " " + item.meta.author.lastName + ": " + item.meta.text,
            }
            break;
        case 'NEW_PROBLEM':
            contentConfig = {
                icon: <FontAwesomeIcon icon={faFileLines} className={iconClass} />,
                title: <p>{t('not-topic',`New Topic`)}
                    <span className="font-bold whitespace-nowrap"> {item.meta.title}:</span>
                </p>,
                text: item.meta.author.firstName + " " + item.meta.author.lastName + ": " + item.meta.statement,
            }
            break;
        case 'PROBLEM_MATCH':
            contentConfig = {
                icon: <FontAwesomeIcon icon={faFileLines} className={iconClass} />,
                title: <p>{t('not-topic',`Topic matches your Profile`)}
                    <span className="font-bold whitespace-nowrap"> {item.meta.title}:</span>
                </p>,
                text: item.meta.author.firstName + " " + item.meta.author.lastName + ": " + item.meta.statement,
            }
            break;
        case 'PROFILE_MATCH':
            contentConfig = {
                icon: <FontAwesomeIcon icon={faUser} className={iconClass} />,
                title: <p>{t('not-profile',`New Profile Match on`)}
                    <span className="font-bold whitespace-nowrap"> {item.meta.title}</span>
                </p>,
                text: item?.relevantProfiles?.join('\n'),
            }
            break;
        default:
            contentConfig = {
                icon: 'ℹ️', // Default icon
                title: `New Notification ${item.type}`,
                text: null
            }
    }

    return (
        <div className={`${!item.isActive && 'text-gray-400 hover:text-gray-800'} flex space-x-3 items-center pr-1`}>
            <div>{contentConfig.icon}</div>
            <div className="w-full">
                <div>
                </div>
                {contentConfig.title}
                <span className="line-clamp-2" title={contentConfig.text}>{contentConfig.text}</span>
            </div>
        </div>
    );
}

export default NotificationItem;
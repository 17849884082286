const sortList = (list, sortType) => {
    const calcUserVotes = (item) => {
        let count = 0;
        if (item.userVotes) {
            count = item.userVotes.reduce((acc, obj) => (acc + obj.vote), 0)
        }
        return count
    }

    switch (sortType) {
        case 'newest':
            return [...list].sort((a, b) => new Date(b.createdTs) - new Date(a.createdTs));
        case 'oldest':
            return [...list].sort((a, b) => new Date(a.createdTs) - new Date(b.createdTs));
        case 'votes':
            return [...list].sort((a, b) => calcUserVotes(b) - calcUserVotes(a));
        case 'alphabetical':
            return [...list].sort((a, b) => a.title.localeCompare(b.title));
        default:
            return list;
    }
};

export default sortList;

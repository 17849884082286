import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import SideMenuButton from 'components/SideMenuButton';

import { getAbbreviation } from 'utils/get-abbrevation'
import SideMenu from 'components/SideMenu';
import CardWrapper from 'components/CardWrapper';
import { deleteProject } from 'actions/project.delete.actions';
import { listUserProjects } from 'actions/projects.list.actions';
import { parseItem } from 'utils/session-storage';
import Modal from 'components/Modal';
import LinkList from 'components/LinkList';
import RoleIcon from 'components/RoleIcon';

function ProjectCard(props) {
    const { t } = useTranslation(['common']);
    const collapseId = 'collapse' + props.project.id;
    const [menuActive, setMenuActive] = useState(false);
    const [stats, setStats] = useState([
        { id: 1, name: t('Topics'), stat: null },
        { id: 2, name: t('Participants'), stat: null }
    ]);
    const [organisationRole, setOrganisationRole] = useState(null);
    const [communityRole, setCommunityRole] = useState(null);

    // TODO Translation
    const text = {
        modalTitle: t('Delete Cluster'),
        cancel: t('Cancel', { ns: 'common' }),
        modalText: t('', `Do you want to delete Cluster `),
        modalText2: t('', ` and all its content?`)
    }

    //TODO get owner
    const project = {
        id: props.project.id,
        title: props.project.title,
        links: props.project.links,
        projectDescription: props.project.desc,
        contributorsCount: props.project.contributorsCount,
        problems: props.project.problems,
        role: props.project.role,
    };

    const menuVisible = project.role === "OWNER" 
                        || project.role === "ADMIN"
                        || communityRole === "OWNER" 
                        || communityRole === "ADMIN" 
                        || organisationRole === "OWNER" 
                        || organisationRole === "ADMIN"

    const menuItemDeleteVisible = project.role === "OWNER"
                                || communityRole === "OWNER" 
                                || communityRole === "ADMIN" 
                                || organisationRole === "OWNER" 
                                || organisationRole === "ADMIN"

    useEffect(() => {
        if (props.project) {
            setStats([
                { id: 1, name: t('Topics'), stat: props.project.problems?.length },
                { id: 2, name: t('Participants'), stat: props.project.contributorsCount }
            ])
        }
    }, [props.project]);

    // organisation role
    useEffect(() => {        
        if (parseItem('organisation')?.role) {
            setOrganisationRole(parseItem('organisation').role)
          }
    }, [sessionStorage.getItem('organisation')]);

    // community role
    useEffect(() => {        
        if (parseItem('community')?.role) {
            setCommunityRole(parseItem('community').role)
          }
    }, [sessionStorage.getItem('community')]);

    function getHref(id) {
        return `clusters/${id}`
    }

    const handleRemove = (id) => {
        props.deleteProject(id).then(() => {
            props.listUserProjects()
        })
    }

    const handleEdit = () => {
        setMenuActive(false)
        props.handleEdit()
    }

    const sharedProject = project.id === '00000000-0000-0000-0000-000000000000'
    const sharedIcon = <FontAwesomeIcon icon={faFolderOpen} />

    const sideBarContent = <div className='text-center'>
        <p>{getAbbreviation(project.title)}</p>
        <p className='mt-2'><RoleIcon role={project.role} /></p>
    </div>

    return (
        // Link to single project view with multiple problems 
        <CardWrapper key={props.key} id="projectCard" menuClick={() => setMenuActive(!menuActive)}
            sideBar={true}
            sideBarContent={sharedProject ? sharedIcon : sideBarContent}
            title={project.title} titleHref={getHref(project.id)}
            description={project.projectDescription}
            userVotes={props.userVotes}
            count={props.count}
            showVoting={props.showVoting}
            upVote={props.upVote}
            downVote={props.downVote}
            upVoteClicked={props.upVoteClicked}
            downVoteClicked={props.downVoteClicked}
            sideColor={sharedProject && 'bg-cyan-500'}
            menu={!sharedProject && menuVisible}
        >
            <Modal
                id={'modalProjectDelete' + project.id}
                title={text.modalTitle}
                onSave={() => handleRemove(project.id)}
                saveText={text.modalTitle}
                saveBg='bg-red-700'
                closeText={text.cancel}
                backdrop
            >
                <p>{text.modalText} <span className='font-bold'>{project.title}</span>{text.modalText2}</p>
            </Modal>
            <LinkList list={project.links}/>
            {/* Stats */}
            {/* TODO add collapsable part for participants, tags */}
            <div>
                <dl className="mt-1 flex justify-left gap-2">
                    {stats.map((item) => (
                        <div key={item.name} className="overflow-hidden rounded-lg px-4 p-2">
                            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                            <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            {/* Sidemenu for this Project Card */}
            {menuActive &&
                <SideMenu onClose={() => setMenuActive(false)}>
                    <SideMenuButton
                        id="editButton"
                        onClick={() => handleEdit()}
                        databstoggle={props.databstoggle}
                        databstarget={props.databstarget}
                    >
                        {t('Edit')}
                    </SideMenuButton>
                    {menuItemDeleteVisible &&
                        <SideMenuButton
                            id="deleteButton"
                            onClick={() => setMenuActive(false)}
                            databstoggle='modal'
                            databstarget={'#modalProjectDelete' + project.id} >
                            {t('Delete')}
                        </SideMenuButton>
                    }
                </SideMenu>
            }
        </CardWrapper>
    );
}

ProjectCard.propTypes = {
    deleteProject: PropTypes.func.isRequired,
    listUserProjects: PropTypes.func.isRequired
};

export default connect(null, { deleteProject, listUserProjects })(ProjectCard);
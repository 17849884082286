import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Steps from "./Steps";
import { useTranslation } from "react-i18next";
import SimpleGroupHover from "./SimpleGroupHover";

/**
 * 
 * Renders a step flow component with a list of steps, current step information,
 * save action, back link, and children components.
 *
 * @param {Object} props - The props object.
 * @param {Array} props.steplist - The list of all steps needed as an object.
 * @param {string} props.steplist.name - The name of the step.
 * @param {string} props.steplist.href - The link to the step.
 * @param {string} props.steplist.status - The status of the step, e.g. 'current' or 'upcoming'.
 * @param {string} props.currentStep - Sends currentStep information back to parent.
 * @param {function} props.onSave - The action that should be handled on save.
 * @param {string} props.backLink - The link to the page of origin before the step flow started.
 */
function StepFormWrapper(props) {
    const { t } = useTranslation();
    const [stepList, setStepList] = useState(props.stepList);
    const maxSteps = stepList?.length;
    const [step, setStep] = useState(1);
    const [overviewActive, setOverviewActive] = useState(false);

    const nextHandler = () => {
        setStep(step + 1);
        let tempStepList = [...stepList];
        let prevStep = {
            ...tempStepList[step - 1],
            status: 'complete'
        }
        if (step === maxSteps) {
            setOverviewActive(true);
        } else {
            let currentStep = {
                ...tempStepList[step],
                status: 'current'
            }
            tempStepList[step] = currentStep;
        }
        tempStepList[step - 1] = prevStep;
        setStepList(tempStepList);
        updateParent(step + 1);
    }

    const prevHandler = () => {
        if (overviewActive) {
            setOverviewActive(false);

        }
        setStep(step - 1);
        let tempStepList = [...stepList];
        let currentStep = {
            ...tempStepList[step - 2],
            status: 'current'
        }
        if (step < maxSteps + 1) {
            let prevStep = {
                ...tempStepList[step - 1],
                status: 'upcoming'
            }
            stepList[step - 1] = prevStep;
        }
        stepList[step - 2] = currentStep;
        setStepList(stepList);
        updateParent(step - 1);
    }

    const updateParent = (currentStep) => {
        props.currentStep(currentStep);
    }

    const onSave = (e) => {
        e.preventDefault(e)
        props.onSave();
    };

    return (
        <div className="h-full flex flex-col justify-between">
            <span className="mt-2">
                <Steps maxSteps={maxSteps} currentStep={step} stepList={stepList} />
            </span>
            {/* Content */}
            <div className="flex-grow overflow-y-auto">{props.children}</div>
            {/* Button Group */}
            <div id={props.buttonGroupId}
                className={`flex justify-between
                ${overviewActive ? 'w-full md:w-2/3 max-w-2xl' : 'w-full max-w-7xl pr-20'}
                z-20 whitespace-nowrap space-x-2 
                pt-1 mb-1`}>
                <Link to={props.backLink}>
                    <Button>
                        {t('Cancel')}
                    </Button>
                </Link>
                <div className='flex space-x-2'>
                    <Button id='buttonBack'
                        onClick={prevHandler}
                        disabled={step === 1 ? true : false}>
                        {t('Back')}
                    </Button>
                    {/* Next button or Save button dependent on the last step, button is disabled when input is invalid */}
                    <SimpleGroupHover
                        active={!props.isValid}
                        text={props.inValidMessage || 'Not all required fields are filled'}
                        position={'bottom-11 right-0'}
                        overwritePosition={true}
                        color={'bg-red-200 text-red-800 border-red-500'}
                        className={'w-96 whitespace-normal'}
                    >
                        <Button
                            id={step < maxSteps ? 'buttonNext' : 'buttonSave'}
                            onClick={step < maxSteps ? nextHandler : onSave}
                            disabled={!props.isValid}
                        >
                            {t(step < maxSteps ? 'Next' : 'Save')}
                        </Button>
                    </SimpleGroupHover>
                </div>
            </div>
        </div>);
}

export default StepFormWrapper;
import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

// hideDisabled -- can disable the option to make the panel collappsable
function SidePanel(props) {
    const [visible, setVisible] = useState(true);

    const handleVisible = () => {
        setVisible(!visible);
    }

    return (
        <div
            className={`
        ${props.className}
        ${visible ? '-right-2 ' : '-right-64'}
        absolute right-0
        transition-all duration-300 ease-in-out
        border border-gray-700
        rounded-md pr-12 py-4
        bg-white 
        flex
        `}>
            {props.hideDisabled ?
            <div className="w-3"></div>
            :
                <div
                    onClick={handleVisible}
                    className={`w-8 flex flex-col justify-center align-middle cursor-pointer`}>
                    {visible ?
                        <ChevronRightIcon className="w-5 h-5" />
                        :
                        <ChevronLeftIcon className="w-5 h-5" />
                    }

                </div>
            }
            <div className="pl-2">
                {props.children}
            </div>
        </div>
    );
}

export default SidePanel;
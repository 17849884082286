import { useState, useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function LanguageSwitchButton({ changeLanguage, lang, color }) {

    const linkStyle = `absolute right-0 inline-flex items-center px-4 py-2 border text-base md:text-sm font-medium rounded-md 
    ${color = 'light' ? " text-black bg-white hover:bg-gray-100" : "text-white bg-gray-600 hover:bg-gray-700 border-transparent"}`

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <div>
                <Menu.Button className={linkStyle}>
                    {lang}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 my-0.5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute top-10 right-0 z-20 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full text-left'
                                    )}
                                    onClick={() => changeLanguage('en')}
                                >
                                    en
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full text-left'
                                    )}
                                    onClick={() => changeLanguage('de')}
                                >
                                    de
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </div>);
}

export default LanguageSwitchButton;
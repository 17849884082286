import PageWrapper from "components/PageWrapper";

function DSEDe() {

    let paragraph = 1
    const address = 'whitespace-pre-line mb-4 ml-8'

    return (
        <PageWrapper>
            <div id="dse-de" className="w-7/8 lg:w-2/3 2xl:w-1/2 pr-2 pt-10">
                <div className="mb-4">
                    <p className="text-xl">Datenschutzerklärung Webseite</p>
                    <p className="text-sm">in der Fassung vom 20.05.2023</p>
                    <p className="text-sm underline mt-4"><a href='/dse_eng'>English Version</a></p>
                </div>
                <p className="whitespace-pre-line mb-4 pt-4">
                    {`Die solverint GmbH ist „Verantwortlicher“ im Sinne des Datenschutzes (Art. 4 Ziff. 7 DSGVO) für die frei zugänglichen Bereiche der Webseite forum.solverint.com. 
                    \nIn den geschützten Bereichen der Webseite ist die solverint GmbH Auftragsverarbeiterin (Art. 4 Ziff. 8 DSGVO) im Auftrag ihrer jeweiligen Unternehmenskunden.
                    \nMit dieser Plattform wird den genannten Unternehmenskunden und deren Nutzerinnen und Nutzern ein geschützter Raum zur Verfügung gestellt, in welchem gemeinsam relevante Probleme und Fragestellungen beschrieben, angereichert, priorisiert und weiterentwickelt werden können. Dies ist Gegenstand eines Vertrages mit den Unternehmenskunden.
                    \nDie Webseite, für die diese Datenschutzerklärung gilt, dient hierbei der Registrierung bzw. dem Login der Nutzer.`}
                </p>

                <p className="font-bold">§{paragraph} Datenschutz</p>
                <p className="whitespace-pre-line mb-4">
                    {`Wir nehmen den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Zweck und Rechtsgrundlage für die Verarbeitung </p>
                <p className="whitespace-pre-line mb-4">
                    {`Wir verarbeiten die oben genannten erhobenen Informationen für die folgenden Zwecke: Registrierung bzw. Login auf die Webseite, Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, Gewährleistung einer komfortablen Nutzung unserer Website, Auswertung der Systemsicherheit und -stabilität, Prävention und Behebung von Störfällen sowie zu weiteren administrativen Zwecken.
                    \nEine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
                    \n(1) Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
                    \n(2) die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
                    \n(3) für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht,
                    \n(4) dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen erforderlich ist.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Rechte für Sie als Betroffene </p>
                <p className="whitespace-pre-line mb-4">
                    {`Sie haben das Recht:
                    \n(1) gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
                    \n(2) gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                    \n(3) gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                    \n(4) gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                    \n(5) gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                    \n(6) gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
                    \n(7) gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde (ULD – Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein) zu beschweren;
                    \n(8) gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Sie haben die Möglichkeit, den Widerspruch uns schriftlich, telefonisch oder per E-Mail mitzuteilen. Gemäß den Grundsätzen dieser Plattform findet jedoch Direktwerbung nicht statt.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Server-Log-Files </p>
                <p className="whitespace-pre-line mb-4">
                    {`Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten enthalten: `}</p>
                <ul className="pl-4 mb-4 list-disc">
                    <li>Browsertyp/ Browserversion </li>
                    <li>Betriebssystem des Rechners </li>
                    <li>Referrer URL </li>
                    <li>Hostname des zugreifenden Rechners </li>
                    <li>Uhrzeit der Serveranfrage </li>
                    <li>IP-Adresse </li>
                </ul>
                <p className="whitespace-pre-line mb-4">
                    {`Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Löschung von personenbezogenen Daten</p>
                <p className="whitespace-pre-line mb-4">
                    {`Die Löschung der in § 4 genannten Daten erfolgt im Regelfall nach dreißig (30) Tagen, es sei denn, es bestehen gegenläufige Aufbewahrungsrechte oder -pflichten`}
                </p>

                <p className="font-bold">§{paragraph += 1} Cookies</p>
                <p className="whitespace-pre-line mb-4">
                    {`Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die Besucher freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden und die Ihr Browser verwendet. 
                    \nUnser Angebot verwendet keine Cookies, die nicht technisch zwingend erforderlich sind.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Sicherheit und Datenspeicherung </p>
                <p className="whitespace-pre-line mb-4">
                    {`Wir implementieren Sicherheitsverfahren und technische Einschränkungen, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Zerstörung oder Änderung zu schützen. Wir speichern Ihre Daten nur so lange, wie es für die Erfüllung der in dieser Datenschutzerklärung dargelegten Zwecke erforderlich ist, es sei denn, eine längere Speicherfrist ist gesetzlich vorgeschrieben oder zulässig.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Änderung der Datenschutzbestimmungen </p>
                <p className="whitespace-pre-line mb-4">
                    {`Unsere Datenschutzerklärung kann in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Dienstleistungen umzusetzen. Für Ihren nächsten Besuch gilt dann automatisch die jeweils neue Datenschutzerklärung, welche in der aktuellen Fassung unter dieser Adresse abrufbar ist.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Kontakt zum Datenschutzbeauftragten </p>
                <p className="whitespace-pre-line mb-4">
                    {`Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an unseren Datenschutzbeauftragten: `}
                </p>
                <p className={address}>
                    Felix Kunz
                    <p>
                        E-Mail:
                        <a className="link-primary" href="mailto:f.kunz@rhein-consulting.de " target="_blank" > f.kunz@rhein-consulting.de</a>
                    </p>
                    +49 2774 924913210
                </p>
            </div>
        </PageWrapper>
    );
}

export default DSEDe;
import { useEffect } from "react";
import CheckItem from "./CheckItem";
import { useTranslation } from "react-i18next"

/**
 * 
 * @param {list, updateList, name, height}  
 * the list needs an object with id, title, and a 'checked' value 
 * @returns Wrapper for Selectboxes. It is scrollable and resizable by the y-axis
 */
function MultiSelectBox({ list, updateList, name, height }) {
    const { t } = useTranslation(['common']);
    const checkCounter = list ? list.filter(item => item.checked).length : 0
    height = height || 'h-64'

    // adds slight bounce whenever the counter is updated
    useEffect(() => {
        const element = document.querySelector('.bubble' + name);
        if (checkCounter > 0) {
            element.classList.remove('scale-100');
            element.classList.add('scale-105');
            setTimeout(() => {
                element.classList.add('scale-100');
                element.classList.remove('scale-105');
            }, 200);
        }
    }, [checkCounter]);

    return (
        <div id="multiSelectBox"
            className={`${height} overflow-y-scroll pl-4 pr-2 py-2 resize-y border border-gray-300 rounded-md transition-all scale-100`}>
            {checkCounter > 0 &&
                <div className={`sticky-top float-right bg-primary-100 border-primary-200 rounded-full p-1 h-7 w-7 flex items-center justify-center ${'bubble' + name}`}
                    style={{
                        animation: "bounce 0.2s ease-in-out",
                    }}>
                    <div className="text-center text-sm font-bold text-primary-800">{checkCounter}</div>
                </div>
            }
            {list.map((item, index) => (
                <CheckItem item={item} key={item.id} index={index} title={item.title} checked={item.checked} handleChange={updateList} name={name} />
            ))}
        </div>
    );
}

export default MultiSelectBox;
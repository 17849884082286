const userAlertActionTypes = {
    LIST_USER_ALERTS_REQUEST: 'LIST_USER_ALERTS_REQUEST',
    LIST_USER_ALERTS_SUCCESS: 'LIST_USER_ALERTS_SUCCESS',
    LIST_USER_ALERTS_FAILURE: 'LIST_USER_ALERTS_FAILURE',

    UPDATE_USER_ALERTS_REQUEST: 'UPDATE_USER_ALERT_REQUEST',
    UPDATE_USER_ALERTS_SUCCESS: 'UPDATE_USER_ALERT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    UPDATE_USER_ALERTS_FAILURE: 'UPDATE_USER_ALERT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { userAlertActionTypes };
import { communityActionTypes as types } from 'constants/community.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.communityReducer) || {};

export function communityReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_COMMUNITIES_REQUEST:
    case types.GET_COMMUNITY_REQUEST:
    case types.CREATE_COMMUNITY_REQUEST:
    case types.UPDATE_COMMUNITY_REQUEST:
    case types.DELETE_COMMUNITY_REQUEST:
      return {
        ...state,
        ...payload
      };
    case types.LIST_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: payload
      };
    case types.GET_COMMUNITY_SUCCESS:
    case types.CREATE_COMMUNITY_SUCCESS:
    case types.UPDATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        currentCommunity: payload
      };
    case types.DELETE_COMMUNITY_SUCCESS:
      return {
        ...payload
      };
    case types.LIST_COMMUNITIES_FAILURE:
    case types.GET_COMMUNITY_FAILURE:
    case types.CREATE_COMMUNITY_FAILURE:
    case types.UPDATE_COMMUNITY_FAILURE:
    case types.DELETE_COMMUNITY_FAILURE:
    default:
      return state;
  }
}

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import GuideForm from "components/Guide/GuideForm";
import Input from "components/InputElement";

import CreateIssueContext from "../context/create-issue-context";

function IssueInsightsForm() {
    const { t } = useTranslation();
    const text = {
        headline: t('1-in-header', "Provide insights"),
        label1: t('3-in-label1', "Issue Situation"),
        issueSituationInfo: t('3-in-info1', 'E.g.: “Heating currently makes up 80% of the energy-related GHG emissions”'),
        issueSituationTips: [
            {
                title: t('3-in-tip1t', 'Facts'),
                description: t('3-in-tip1d', 'Avoid judgment and concentrate on neutral facts'),
            },
            {
                title: t('3-in-tip2t', 'Consensus'),
                description: t('3-in-tip2d', `The facts must fit a consensus with your organization`),
            },
            {
                title: t('3-in-tip3t', 'Clarity'),
                description: t('3-in-tip3d', `Relevant points must be clear for outsiders not familiar with the topic`),
            },
        ],
        issueSituationPlaceholder: t('3-in-place1', `Room 123 consumes 35% of heating energy`),
        label2: t('3-in-label2', 'First insights'),
        firstInsightInfo: t('3-in-info2', 'E.g.: “We can probably get more than half of the impact from renewing the technology but also from changing the heating behaviour, where resistance is likely”'),
        firstInsightTips: [
            {
                title: t('3-in-tip4t', 'Essence'),
                description: t('3-in-tip4d', 'What were the core insights you gathered so far?'),
            },
            {
                title: t('3-in-tip5t', 'Open questions'),
                description: t('3-in-tip5d', `Which points do need further investigation?`),
            },
            {
                title: t('3-in-tip6t', 'Complications'),
                description: t('3-in-tip6d', `Which risks or challenges does your team need to be aware of?`),
            },
        ],
        insightPlaceholder: t('3-in-place2', `…Room 123 is used a lot`),
    }
    const guideText = [
        {
            header: t('3-in-guide1h','Prioritized issue: Definition'),
            text: t('3-in-guide1t',`An issue is an important unresolved question related to an impactful factor of your problem structure that you can reasonably address
            \nSource: Conn, C. & McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley; cf. Chapter 4, page 91 ff.`),
        },
        {
            header: t('3-in-guide2h','Hypothesis-driven work'),
            text: t('3-in-guide2t',`The entire problem solving approach is hypothesis-driven*, so you should make plausible statements based on assumptions early on in the process, sometimes even educated guesses. 
            This method is usually quick and resource-efficient, especially when you have deep expertise in your team, but also needs interactive iterations until the path and the numbers become stable
            \n*A resolution hypothesis is a proposed resolution for the prioritized issue which cannot currently be proven right away, but which should respect certain quality criteria (e.g., being testable)`),
        },
        {
            header: t('3-in-guide3h','Documentation'),
            text: t('3-in-guide3t',`Using this tool will allow you to codify decision-relevant knowledge and thus to transmit and save it efficiently. This might sometimes feel tedious, but it will generate huge rewards for you, your team members and also people working on this subject later.
            \nTry to take your time to write down the most essential points`),
        },
    ];

    const ctx = useContext(CreateIssueContext);
    const [guideActive, setGuideActive] = useState(false);

    return (
        <div>
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                title={text.headline}>
                {/* Issue Situation Input */}
                <Input id='issueSituation'
                    tooltip={text.issueSituationInfo}
                    tips={text.issueSituationTips}
                    label={text.label1}
                    textarea={true}
                    value={ctx.data.situation}
                    placeholder={text.issueSituationPlaceholder}
                    onChange={ctx.handleChange("situation")}
                />
                <Input id='firstInsights'
                    tooltip={text.firstInsightInfo}
                    tips={text.firstInsightTips}
                    label={text.label2}
                    textarea={true}
                    value={ctx.data.firstInsight}
                    placeholder={text.insightPlaceholder}
                    onChange={ctx.handleChange("firstInsight")}
                />
            </FormContainer>
        </div>
    );
}

export default IssueInsightsForm;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import CheckItem from "./CheckItem";

function LabelWithSmarttips({tips, title, description, id, required}) {
    const { t } = useTranslation(['common']);
    const [active, setActive] = useState(false);
    const tipList = (tips || [{ title: "No items", description: "No Items for Tips found, add them with 'title' and 'description'" }]);
    title = (title || "No Title, add with 'title'")

    const toggleActive = () => {
        const current = active;
        setActive(!current);
    };

    const requiredInfo = <span title={t("This field is required")} className="text-red-600 px-1">*</span>
    const chevronStyle = 'ml-2 w-5 h-5 inline transform -translate-y-[3px]'

    return (
        <div className="w-full">
            <p className={`${active && ''} w-full md:space-x-1`}>
                <a className={`inline-block text-base font-medium text-gray-700 transition duration-150 ease-in-out select-none`}
                    data-bs-toggle="collapse"
                    href={"#" + id}
                    role="button"
                    aria-expanded="false"
                    aria-controls={id}
                    onClick={toggleActive}>
                    <span>{title}</span>
                    {required && requiredInfo}
                    {active && <ChevronUpIcon className={chevronStyle} />}
                    {!active && <ChevronDownIcon className={chevronStyle} />}
                </a>
            </p>
            <span className="text-sm text-gray-600">{description}</span>
            <div className="collapse" id={id}>
                <div className="block text-sm bg-white border-t-2 mt-1">
                    <div className="pt-1">
                        {tipList.map((item, index) => (
                            <CheckItem
                                key={item.title}
                                index={index} 
                                name={'checkSmartTip'} 
                                title={item.title} 
                                description={item.description} 
                                handleChange={() => {}}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LabelWithSmarttips;
import { useState } from "react";
import Flag from "./Flag";

/**
 * 
 * @param {collapseId, text, flagColor, 
 * flagBorderColor, hiddenBorderColor} props 
 * Important: collapseId cannot start with a number!
 * @returns Flag component that can collapse another item
 * on the same page
 * This component needs the classname 'collapsable' 
 * and the same 'collapseId'
 * For example:
 * <div className='collapse' id={collapseId}></div>
 */
function FlagForCollapsableContent({ collapseId, text, flagColor, disabled }) {
    const [contentOpen, setContentOpen] = useState(false);

    return (
        <div className="inline-block">
            {disabled ?
                <Flag text={text}
                    color={flagColor || "bg-gray-200"}
                    flagProps={{ borderColor: 'border-gray-200' }}
                    cursor={'cursor-default'}
                >
                </Flag>
                :
                <a data-bs-toggle="collapse"
                    href={'#' + collapseId}
                    role="button"
                    aria-controls={collapseId}
                    aria-expanded="false">
                    <Flag text={text}
                        color={flagColor || "bg-primary-200"}
                        flagProps={
                            { borderColor: contentOpen ? 'border-primary-500' : 'border-primary-200' }
                        }
                        onClick={() => setContentOpen(!contentOpen)}
                    >
                    </Flag>
                </a>}
        </div>);
}

export default FlagForCollapsableContent;
import React from 'react';

// import { defaultTreeState } from './TreeProvider';

const TreeContext = React.createContext({
    // data: defaultTreeState.data,
    findNode: (data, id) => {},
    addFactor: () => {},
    addChild: (id) => {},
    deleteCard: (id) => {},
    changeName: (id, event) => {},
    changeBaseline: (id, event) => {},
    changeChange: (id, event) => {},
    changeRating: (id, index) => {},
    changeEstimate: (id, event) => {},
    addSelectedCard: (id) => {},
    removeSelectedCard: (id) => {},
})

export default TreeContext;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types'

function CloseButton({ onClose, className, top, right }) {
    top = top || 'top-4'
    right = right || 'right-5'

    return (
        <FontAwesomeIcon icon={faX} className={`${top} ${right} absolute text-gray-700 hover:scale-110 cursor-pointer ${className}`} onClick={onClose} />
    );
}

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
}

export { CloseButton };
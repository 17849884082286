import React from 'react';

// Transforms Text that has '-·' at the beginning of the line to be displayed correctly
const BulletPointLabel = ({ text }) => {
    const formattedText = text.replace(/^\s*[-·]/gm, "•"); // replace "·" and "-" at the beginning of lines (with optional whitespace) with bullet point symbol
    const labelStyle = {
        whiteSpace: 'pre-wrap', // preserve line breaks and spaces
    };
    return <label className='' style={labelStyle}>{formattedText}</label>;
};

export default BulletPointLabel;
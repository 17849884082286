import React from 'react';

import { CreateIssueProvider } from './context/CreateIssueProvider';

import NewIssueForm from './NewIssueForm'

function CreateIssuePage() {
    return (
        <CreateIssueProvider>
            <NewIssueForm />
        </CreateIssueProvider>
    );
}


export default CreateIssuePage;
const commentActionTypes = {
    LIST_COMMENTS_REQUEST: 'LIST_COMMENTS_REQUEST',
    LIST_COMMENTS_SUCCESS: 'LIST_COMMENTS_SUCCESS',
    LIST_COMMENTS_FAILURE: 'LIST_COMMENTS_FAILURE',

    GET_COMMENT_REQUEST: 'GET_COMMENT_REQUEST',
    GET_COMMENT_SUCCESS: 'GET_COMMENT_SUCCESS',
    GET_COMMENT_FAILURE: 'GET_COMMENT_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { commentActionTypes };
const emptyTopicState = {
    data: {
        visibility: null,
        title: null,
        type: null,
        statement: null,
        isOpen: true,
        documentList: [],
        linkList: [],
        situation: null,
        complications: null,
        communitiesSelected: [],
        segmentSelected: [],
        valueChainSelected: [],
        tags: [],
        createdBy: {
            userId: null,
            firstname: null,
            lastName: null,
            username: null
        }
    },
    segmentListData: [],
    valueChainListData: []
}

export { emptyTopicState };
/**
 * @param {onClick, className, padding, children} props 
 * padding = default is p-1, use other 'py-0' for compact menu
 * @returns Button for <SideMenu>
 */
function SideMenuButton(props) {
    return (
        <button
            id={props.id}
            onClick={props.onClick}
            className={`${props.className} 
            ${props.padding || 'p-1'}
            rounded hover:bg-primary-100 text-left`}
            data-bs-toggle={props.databstoggle}
            data-bs-target={props.databstarget}
        >
            {props.children}
        </button>
    );
}

export default SideMenuButton;
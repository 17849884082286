/**
 * @param {*} objectList 
 * sets checks to a list of objects depending on a list of selected IDs
 */
const useGetCheckedList = (objectList, selectedIds) => {
    if (selectedIds) {
        const result = objectList.map(item => {
            if (selectedIds.includes(item.id)) {
                return { ...item, checked: true };
            }
            return { ...item, checked: false };
        });
        return result
    } else {
        const result = objectList.map(item => ({ ...item, checked: false }));
        return result;
    }
}

export default useGetCheckedList;
import { loadingActionTypes as types } from 'constants/loading.action.types';

export const loadingActions = {
    start,
    finish
};

function start() {
    return { type: types.LOADING_STARTED }
}

function finish() {
    return { type: types.LOADING_FINISHED }
}
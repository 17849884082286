import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import PageWrapper from 'components/PageWrapper';
import Modal from 'components/Modal'
import StepFormWrapper from 'components/StepFormWrapper';
import { alertActionTypes } from 'constants/alert.action.types';
import { createProblem } from 'actions/problem.create.actions';

import ProblemContext from './context/ProblemContext';
import ProblemStatementForm from './Steps/ProblemStatementForm';
import CriticalDefinitionsForm from './Steps/CriticalDefinitionsForm';
import ProblemRatingForm from './Steps/ProblemRatingForm';
import SituationsAndComplicationsForm from './Steps/SituationAndComplicationsForm';
import StakeholdersForm from './Steps/StakeholdersForm';
import KeyResults from './Steps/KeyResults';
import CoreMetricsForm from './Steps/CoreMetricsForm';
import ConstraintsForm from './Steps/ConstraintsForm';
// import NewProblemOverview from './Overview/NewProblemOverview';
import ProblemDefinitionOverview from './Overview/ProblemDefinitionOverview';
// import Alert from '../_components/Alert';

function NewProblemForm({ createProblem, alert }) {
    const history = useHistory();
    const { t } = useTranslation();
    const ctx = useContext(ProblemContext);
    const { projectId } = useParams();

    // clearing all Alerts that was before
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: alertActionTypes.CLEAR })
    }, [dispatch]);

    // useEffect(() => {
    //     if (alert.type === 'success' && alert.text === 'problem created successfully') {
    //         history.push(`/projects/${projectId}`)
    //     }
    // }, [alert]);

    const stepList = [{ name: 'Step 1', href: '#', status: 'current' },
    { name: 'Step 2', href: '#', status: 'upcoming' },
    { name: 'Step 3', href: '#', status: 'upcoming' },
    { name: 'Step 4', href: '#', status: 'upcoming' },
    { name: 'Step 5', href: '#', status: 'upcoming' },
    { name: 'Step 6', href: '#', status: 'upcoming' },
    { name: 'Step 7', href: '#', status: 'upcoming' },
    { name: 'Step 8', href: '#', status: 'upcoming' },]
    let [currentStep, setCurrentStep] = useState(1);

    // this kills all
    //if(alert.type === 'success') {
    //    history.push(`/projects/${projectId}`) // TODO: /projects/{id}/problems/{id}
    //}

    // it waits
    const onSave = async () => {
        // console.log(ctx.data)
        await createProblem(projectId, ctx.data)
        history.push(`/projects/${projectId}`)
    };

    // it does not wait
    // const onSave = () => {
    //     // console.log(ctx.data)
    //     createProblem(projectId, ctx.data).then(
    //         history.push(`/projects/${projectId}`)
    //     )
    // };


    // Redirect to finished Product Tabview
    //const RedirectToProblemViewTab = () => {
    //    let path = '/projects/:id/tabs/problemDefinition'; //FIXME dynamic project id
    //    history.push(path);
    //}

    return (
        <>
            <Modal id="newProblemSaveModal"
                title={t("m-saveNewProblem-title", "Problem definition saved")}
                body={t('m-saveNewProblem-body', `You successfully created your first Problem Definition!
                You can now share it inside and outside of your organization. \n
                A higher level of quality can be achieved through the inclusion of input from other perspectives. `)}
                button1text={t("Share", "Share")}
                //TODO 'onClick1' for button 1/share
                button2text={t("", "Dashboard")}
            //TODO 'onClick2' for button 2/Dashboard
            // databstoggle="modal" databstarget="#newProblemSaveModal"
            //TODO 'onClick2' for button 2/Dashboard
            />
            <PageWrapper>
                <StepFormWrapper 
                    stepList={stepList} 
                    isValid={true}
                    currentStep={setCurrentStep} 
                    onSave={onSave}
                    buttonGroupId={'buttonGroup-problemForm'}>
                    <div className='h-full overflow-x-visible'>
                        <div className='py-4 max-w-7xl'>
                            {/* Container for Forms */}
                            <div id='NewProblemFormSectionContainer' className='py-4'>
                                {currentStep === 1 && <ProblemStatementForm />}
                                {currentStep === 2 && <CriticalDefinitionsForm />}
                                {currentStep === 3 && <ProblemRatingForm />}
                                {currentStep === 4 && <SituationsAndComplicationsForm />}
                                {currentStep === 5 && <StakeholdersForm />}
                                {currentStep === 6 && <KeyResults />}
                                {currentStep === 7 && <CoreMetricsForm />}
                                {currentStep === 8 && <ConstraintsForm />}
                                {currentStep === 9 && <ProblemDefinitionOverview editOnly={true} />}
                            </div>
                        </div>
                    </div>
                </StepFormWrapper>
            </PageWrapper>
        </>
    );
}

NewProblemForm.propTypes = {
    createProblem: PropTypes.func.isRequired,
    alert: PropTypes.object,
};

const mapStateToProps = state => ({
    alert: state.alertReducer
});

export default connect(mapStateToProps, { createProblem })(NewProblemForm);

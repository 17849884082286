import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

function MenuItemWrapper({ children, position, width }) {
    let positionClasses = 'right-0';

    switch (position) {
        case 'left':
            positionClasses = 'left-0';
            break;
        case 'center':
            positionClasses = 'left-1/2 transform -translate-x-1/2';
            break;
        case 'right':
        default:
            positionClasses = 'right-0';
            break;
    }

    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={`${positionClasses} ${width || 'w-36'} overflow-y-auto origin-top-right absolute z-[100] mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} style={{maxHeight: "calc(100vh - 80px)"}}>
                {children}
            </Menu.Items>
        </Transition>
    );
}

export default MenuItemWrapper;
/**
 * Hover component that wraps around the elemen that needs the hover
 * 
 * @param {boolean} active -  Whether the group is currently active. If not provided, default: true. Best used for conditional hoverinformation
 * @param {string} text - The text to display when hovering over the group.
 * @param {ReactNode} children - The content to render inside the group.
 * @param {string} position - The position of the hover text (top, bottom, left, or right). Write as 'top-0' top/bottom positions will be centered by default
 * @param {string} color - The color of the hover text and background e.g. 'text-primary-700 bg-primary-100 border-primary-200'
 * @param {string} SpanClassName - Additional CSS classes for the wrapping span element.
 * @param {string} className - Additional CSS classes to apply to the group element.
 */
function SimpleGroupHover({ active, text, children, position, overwritePosition, color, className, spanClassName }) {
    const isActive = active === undefined ? true : active;

    const hoverInfoClass = 'absolute scale-0 transition-all rounded px-2 py-1 text-sm group-hover:scale-100 border z-20'

    let dynamicClass = '';

    color = color === 'red'
        ? 'bg-red-200 text-red-800 border-red-500'
        : color || 'text-primary-700 bg-primary-100 border-primary-200';

    if(position){
        // centers tooltips that should be displayed at top or bottom
        if(!overwritePosition && (position.includes('top') || position.includes('bottom'))) {
            dynamicClass = 'left-1/2 transform -translate-x-1/2 ' + position;
        } else {
            dynamicClass = position;
        }
    } else {
        // default position
        dynamicClass = 'top-0 right-0'
    }

    return (
        <>
            {isActive ?
                <span className={`${spanClassName} group relative`}>
                    {children}
                    <span className={`${hoverInfoClass} ${dynamicClass} ${color} ${className}`}>
                        {text}
                    </span>
                </span>
                :
                <div>{children}</div>
            }
        </>
    );
}

export default SimpleGroupHover;
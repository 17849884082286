import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import FormContainer from "components/FormContainer";
import Button from "components/Button";
import GuideForm from "components/Guide/GuideForm";
import ProblemContext from '../context/ProblemContext';

import CoreMetricsSubForm from "./CoreMetricsSubForm";
// TODO Link Plot

function CoreMetricsForm() {
    const ctx = useContext(ProblemContext);
    const thisName = 'coreMetrics';
    const { t } = useTranslation();
    const text = {
        headline: t('1-pm-title', "Core Metrics"),
        subHeadlines: [
            t('1-pm-primary', 'Primary Metric'),
            t('1-pm-secondary', 'Secondary Metric'),
            t('1-pm-tertiary', 'Tertiary Metric'),
        ],
        buttonText: [
            t('1-pm-button1', 'Add Secondary'),
            t('1-pm-button2', 'Add Tertiary'),
        ],
    }
    const guideText = [
        {
            header: t('1-pm-g1h', 'Why a baseline?'),
            text: t('1-pm-g1t', `•	It denotes a solid, ideally undisputed starting point (avoid taking numbers that are prone to change or discussion)
        •	It sets the basis for measurability
        •	It reduces the probability of later conflict when it comes to evaluation`),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g2h', 'What if I think a baseline makes no sense?'),
            text: t('1-pm-g2t', `•	Take a few moments to reflect whether a quantified starting point could still make sense, even for a rather qualitative case (e.g., using percentages, or an index denoting a qualitative concept like democracy, equity, ...)
            •	If so, you will need to carefully define the denomination 
            •	If this does still not make no sense, skip the quantitative part and steer the project with  qualitative key results
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g3h', 'What is the difference between goals, objectives, key results, and constraints?'),
            text: t('1-pm-g3t', `•	A goal is an outcome that is often broad and long-term, like “becoming No. 1 in the market” and typically set at the top level
            •	An objective is more specific than a goal and set a direction how the goal should be reached
            •	Your problem statement is supposed to make you reach your objective. It should be inspirational and contribute as directly and measurably as possible to the goals
            •	Your key results need to be achieved in order for the problem to be considered solved sufficiently, and thus for the objective to be reached 
            •	One of these results should become your “primary quantitative key result”, e.g., “finish in less than 3h”
            •	If “more (or less) is better” applies, then you are probably talking about a result, e.g., “take at least third place in next year’s race” 
            By contrast, a constraint is like a boundary on a racecourse: You need to respect it in order not to get disqualified, but you can - and you often should - use all the space allowed to you 
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g4h', 'Are there situations where a team needs to reach multiple key results?'),
            text: t('1-pm-g4t', `•	All of the key results need to be reached for your problem to be considered solved  
            •	While you should limit the number of your key results as much as possible to reduce complexity, there will usually be more than one key result
            •	In some trade-off situations, key results can even be potentially conflicting (e.g., maximize the impact from rate increases while minimizing the churn rate)
            •	These conflicts should be avoided, e.g., by transforming a key result into a constraint: Maximize impact from rate increases while respective a maximum churn threshold of 10%, or vice versa
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g5h', 'What to do if there are many key results?'),
            text: t('1-pm-g5t', `•	Rethink the key results and make them as lean as you sensibly can
            •	Move key results that do not follow the rule “More (or less) is better” into your constraints
            •	Formulate crisp constraints in the next step: A constraint needs to be met only at the minimum required level
            `),
        },
        {
            header: t('1-pm-g6h', 'Why a primary quantitative key result?'),
            text: t('1-pm-g6t', `•	It keeps your team focused and aligned 
            •	It thus reduces complexity in your daily business and in your communication
            •	Measurability is made easier; this reduces the probability of later conflict when it comes to evaluation
            •	It creates inspiration and sense, especially if it is directly linked to your organization goals
            •	Note: Reaching the primary quantitative key result is only necessary, not sufficient (given there might be more key results and also constraints you need to respect)
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g7h', 'When should I define a secondary or even tertiary quantitative key result?'),
            text: t('1-pm-g7t', `•	Sometimes, solving your problem will require reaching more than one quantitative indicator; and framing as a constraint will not be sensible
            •	Also, you might want to achieve a clear-cut contribution transparency to the organizational goals
            •	In this case, you can create a secondary and even tertiary quantitative key result
            \nExample: Pricing 
            • Achieve 3 EURM of additional sales through price changes ➔ Contribution to goal “Growth” to 100% 
            • Ensure 1 EURM of direct bottom line impact through pricing ➔ Contribution to goal “Profitability”to 100%  
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
        {
            header: t('1-pm-g8h', 'What if I think quantification makes no sense?'),
            text: t('1-pm-g8t', `•	Take a few moments to reflect whether quantification could still make sense, even for a rather qualitative case (e.g., using percentages, or an index denoting a qualitative concept like democracy, equity, ...)
            •	If not, skip the quantitative key results
            `),
            //TODO ADD LINK
            linkText: t('1-pm-link', 'See here for a graphical representation'),
        },
    ]
    const data = ctx.data.coreMetrics || [{}];
    const maxMetrics = 3;
    const [collapsed, setCollapsed] = useState([false])
    const [guideActive, setGuideActive] = useState(false);

    const deleteHandler = (id, index) => {
        ctx.deleteRowHandler(thisName, id);
        let temp = [...collapsed];
        temp.splice(index, 1);
        temp[temp?.length - 1] = false;
        setCollapsed(temp);
    }

    const handleNewMetric = (index) => {
        if (index < maxMetrics - 1) {
            ctx.newRowHandler(thisName);
        }
        let temp = [...collapsed];
        temp.forEach((element, index) => {
            temp[index] = true;
        });
        temp.push(false)
        setCollapsed(temp);
    }

    const handleCollapse = (index) => {
        let temp = [...collapsed];
        temp[index] = !temp[index];
        setCollapsed(temp);
    }

    return (
        <div>
            <GuideForm guideContent={guideText}
                width={'w-72 xl:w-1/4 2xl:w-1/4'}
                defaultOpen={false}
                active={guideActive}
                onClose={() => setGuideActive(!guideActive)} />
            <FormContainer openGuide={() => setGuideActive(!guideActive)}
                guide={true} onGuideActive={guideActive}
                title={text.headline} heightScroll={true}
                guideWidth={"xl:w-2/3 lg:w-3/5"}
                width="2xl:w-2/3 xl:w-2/3 w-5/6">
                <div className="space-y-2">
                    {data && data.map((item, index) => (
                        <div key={item.id} >
                            <CoreMetricsSubForm header={text.subHeadlines[index]}
                                id={item.id}
                                index={index}
                                guideActive={guideActive}
                                collapsed={collapsed[index]}
                                onClick={() => { handleCollapse(index) }}
                                deleteHandler={() => { deleteHandler(item.id, index) }}
                            />
                            {(index < (maxMetrics - 1) && index === data?.length - 1) &&
                                <div className="">
                                    <Button
                                        className={"mt-2 float-right"}
                                        onClick={() => handleNewMetric(index)}>
                                        <span >
                                            <FontAwesomeIcon className="mr-2" icon={faPlus} />
                                            {text.buttonText[index]}
                                        </span>
                                    </Button>
                                </div>}
                        </div>
                    ))}
                </div>
            </FormContainer>
        </div>
    );
}

export default CoreMetricsForm;
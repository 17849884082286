import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '@heroicons/react/outline'

function EditableTable(props) {
    const tableHeaderList = (props.tableHeader || ['Placeholder, add with "TableHeader"']);
    const colSpan = (props.colSpan || tableHeaderList.length)

    const tableHeaderStyle = "px-2 py-3 text-xs font-medium text-gray-700 uppercase tracking-wider align-top text-left relative"

    return (
        <div className="flex flex-col">
            <div className="sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow border-b bg-white border-gray-200 sm:rounded-lg">
                        <table className="table-fixed min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50" onClick={props.onClick}>
                                <tr>
                                    {(typeof tableHeaderList[0] === 'string') && tableHeaderList.map((item) => (
                                        <th
                                            scope="col"
                                            key={item}
                                            className={tableHeaderStyle}
                                        >
                                            {item}
                                        </th>
                                    ))}
                                    {(typeof tableHeaderList[0] === 'object') && tableHeaderList.map((item) => (
                                        <th
                                            scope="col"
                                            key={item.name}
                                            className={`${tableHeaderStyle}`}
                                        >
                                            {item.private && <p className='w-full text-gray-500'>
                                                <FontAwesomeIcon icon={faLock} className="mb-1 left-1/2 w-3 h-3" /></p>}
                                            {!item.private && <p className='h-5'></p>}
                                            {item.text}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {props.children}
                                {props.editActive && <tr className='h-11 hover:bg-primary-100'>
                                    <td
                                        className='cursor-pointer'
                                        colSpan={colSpan}
                                        onClick={props.addRow}>
                                        <PlusIcon className='w-7 h-7 m-auto text-gray-500' />
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditableTable;
import { profileActionTypes as types } from 'constants/profile.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.profileReducer) || {};

export function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PROFILE_REQUEST:
    case types.CREATE_PROFILE_REQUEST:
    case types.UPDATE_PROFILE_REQUEST:
    case types.DELETE_PROFILE_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.GET_PROFILE_SUCCESS:
    case types.CREATE_PROFILE_SUCCESS:
    case types.UPDATE_PROFILE_SUCCESS:
      return {
          profile: payload,
      };
    case types.DELETE_PROFILE_SUCCESS:
      return {
        ...payload,
      };
    case types.GET_PROFILE_FAILURE:
    case types.CREATE_PROFILE_FAILURE:
    case types.UPDATE_PROFILE_FAILURE:
    case types.DELETE_PROFILE_FAILURE:
    default:
      return state;
  }
}
  
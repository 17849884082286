import React from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Displays deletable row
 */
function RowDeletable({ editMode, id, filename, handleDelete, children }) {

    return (
        <ul>
            <div className="group flex items-center justify-between p-1">
                <div className='group-hover:bg-primary-100 w-full rounded-sm pl-1 flex space-x-6 items-center'>
                    {children}
                </div>
                {editMode && <button
                    className="delete-button group-hover:bg-red-100 ml-1  px-1 rounded-sm"
                    onClick={() => handleDelete(id, filename)}>
                    <FontAwesomeIcon
                        className="text-gray-500 h-3 hover:text-black"
                        icon={faTimes} />
                </button>}
            </div>
        </ul>
    )
}

RowDeletable.propTypes = {
    editMode: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    handleDelete: PropTypes.func.isRequired,
}

export default RowDeletable
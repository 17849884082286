import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import DotMenu from "components/DotMenu";
import SideMenu from "components/SideMenu";
import SideMenuButton from "components/SideMenuButton";

const sideMenuItemStyle = 'text-black hover:bg-primary-100'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function Communities({ currentCommunity, userCommunities, setCurrentCommunity, collapsed }) {

    const { t } = useTranslation(['common']);
    const history = useHistory();

    const [menuActive, setMenuActive] = useState(false);

    const onCreateClick = () => {
        setMenuActive(false)
        history.push('/communities/new')
      }

    return (
        <nav className={`${collapsed ? "w-0 invisible" : "w-full"} mt-5 flex-1 flex-shrink px-2 py-2 bg-gray-800 space-y-1 whitespace-nowrap show overflow-hidden transition-all`}
          id="communitiesList"
          aria-label="Sidebar">
          <div className='group flex items-center w-full mb-2 uppercase text-white leading-4 font-semibold justify-between'>
            <div className='flex items-center'>Communities</div>
            <div>
              <DotMenu
                onClick={() => setMenuActive(!menuActive)}
                alignment={'horizontal'}
                color={'text-gray-300 hover:text-white'}
              />
              {/* Action Items for dotmenu */}
              {menuActive &&
                <div className='fixed z-[500]'>
                  <SideMenu
                    position='-top-[25px] left-[25px]'
                    onClose={() => setMenuActive(false)}>
                    <SideMenuButton
                      onClick={onCreateClick}
                      className={sideMenuItemStyle}
                    >
                      {t('Create')}
                    </SideMenuButton>
                    <SideMenuButton
                      className={sideMenuItemStyle}
                      onClick={() => setMenuActive(false)}
                      databstoggle='modal'
                      databstarget='#joinCommunityModal'
                    >
                      {t('Join')}
                    </SideMenuButton>
                  </SideMenu>
                </div>}
            </div>
          </div>
          <div className='overflow-y-scroll pr-2' style={{ height: `calc(100% - 40px)` }}>
            {userCommunities && userCommunities.map((item) => (
              <a
                key={item.title}
                href={item.href}
                onClick={() => setCurrentCommunity(item)}
                className={classNames(
                  currentCommunity?.id === item.id ? 'bg-gray-300 text-black' : 'text-gray-300 hover:bg-gray-700 group-hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer'
                )}
              >
                <span className="flex-1 truncate" title={item.title}>{item.title}</span>
                {item?.alertsCount > 0 && <span className={`${currentCommunity && item.id === currentCommunity.id ? 'bg-primary-600 text-white' : 'bg-primary-300  text-black'} rounded-full px-2 font-semibold`}>
                  {item.alertsCount}
                </span>}
              </a>
            ))}
          </div>
        </nav>

    );
}

export { Communities };
export const updateCheckitemState = (item, itemsSelected) => {
    const newChecked = itemsSelected.includes(item.id);
    let updatedItem = { ...item, checked: newChecked };
    // Recursively update children, if any
    if (updatedItem.children) {
        updatedItem.children = updatedItem.children.map(child => updateCheckitemState(child, itemsSelected));
    }

    return updatedItem;
};

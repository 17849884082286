import { useState, useContext } from 'react';
import { Fragment } from 'react';
import {
    EmojiHappyIcon,
} from '@heroicons/react/solid';
import commentMoods from './CommentMoods';
import { Listbox, Transition } from '@headlessui/react';
import { useTranslation } from "react-i18next";
import Blockquote from 'components/Blockquote';
import { CommentContext } from './context/CommentContext';
import ReactTextareaAutosize from 'react-textarea-autosize';

const moods = commentMoods;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function CommentInput({ id, commentValue, currentMood, replyToObj, parentReply, handleSubmit }) {
    const { profile, updateCommentList } = useContext(CommentContext)
    const { t } = useTranslation(['common']);
    const [comment, setComment] = useState(commentValue || undefined);
    const currentMoodIndex = moods.findIndex((mood => mood.value === currentMood))
    const [mood, setMood] = useState(currentMoodIndex !== -1 ? moods[currentMoodIndex] : moods[4]);

    const handleKeypress = e => {
        if (e.charCode === 13) {
            if (!e.shiftKey) {
                onSubmit(e);
            }
        }
    };

    const onSubmit = (e) => {
        e.preventDefault()
        updateCommentList({
            id: id ? id : '',
            text: comment,
            mood: mood.value,
            userId: profile.userId,
            replyTo: replyToObj && replyToObj.id ? replyToObj.id : null,
        })
        setComment('')
        setMood(moods[4])
        handleSubmit?.()
    }

    const handleChange = (e) => {
        setComment(e.target.value);
    }

    return (
        <div id='commentInputArea' className="min-w-0 flex-1">
            <form onSubmit={onSubmit} className="relative">
                <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
                    <label htmlFor="comment" className="sr-only">
                        {t('com-add', 'Add your comment')}
                    </label>
                    {replyToObj &&
                        <div className='p-2'>
                            <Blockquote
                                borderColor={parentReply && 'border-primary-300'}
                                replyToObj={replyToObj} />
                        </div>
                    }
                    <ReactTextareaAutosize
                        rows={1}
                        name="comment"
                        id="comment"
                        className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
                        placeholder={t('com-add', 'Add your comment')}
                        onChange={handleChange}
                        value={comment}
                        onKeyPress={handleKeypress}
                        autoFocus
                    />
                    {/* Spacer element to match the height of the toolbar */}
                    <div className="" aria-hidden="true">
                        {/* Matches height of button in toolbar (1px border + 36px content height) */}
                        <div className="py-px">
                            <div className="h-9" />
                        </div>
                    </div>
                </div>

                {/* Mood */}
                <div className="absolute inset-x-0 bottom-0 py-1 pl-3 pr-2 mb-1">
                    <div className="flex items-center space-x-5">
                        <div className="flex items-center">
                            <Listbox value={mood} onChange={setMood}>
                                {({ open }) => (
                                    <>
                                        <Listbox.Label className="sr-only"> Your mood </Listbox.Label>
                                        <div className="relative">
                                            <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                                <span className="flex items-center justify-center">
                                                    {mood.value === null ? (
                                                        <span>
                                                            <EmojiHappyIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                                            <span className="sr-only"> Add your mood </span>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span
                                                                className={classNames(
                                                                    mood.bgColor,
                                                                    'flex h-7 w-7 items-center justify-center rounded-full'
                                                                )}
                                                            >
                                                                <mood.icon className="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />
                                                            </span>
                                                            <span className="sr-only">{mood.name}</span>
                                                        </span>
                                                    )}
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options
                                                    className="absolute z-10 mt-1 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                                    {moods.map((mood) => (
                                                        <Listbox.Option
                                                            key={mood.value}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-gray-100' : 'bg-white',
                                                                    'relative cursor-default select-none py-2 px-3'
                                                                )
                                                            }
                                                            value={mood}
                                                        >
                                                            <div className="flex items-center">
                                                                <div
                                                                    className={classNames(
                                                                        mood.bgColor,
                                                                        'w-7 h-7 rounded-full flex items-center justify-center'
                                                                    )}
                                                                >
                                                                    <mood.icon
                                                                        className={classNames(mood.iconColor, 'flex-shrink-0 h-5 w-5')}
                                                                        aria-hidden="true"
                                                                    />
                                                                </div>
                                                                <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                                            </div>
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                    </div>
                    <div className="flex-shrink-0 absolute bottom-0 right-1">
                        <button
                            type="submit"
                            className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        >
                            {t('Post')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CommentInput;
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Input from 'components/InputElement';
import { predict } from 'actions/ai.actions';
import Button from 'components/Button';
import { faCopy, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButton } from 'components/Elements/CloseButton';

function AiForm({ context, aiReducer, predict, onClose, closeButton }) {
    const { t } = useTranslation(['ai']);
    const [prompt, setPrompt] = useState(null)
    const [response, setResponse] = useState(null)
    const [showNotification, setShowNotification] = useState(false);
    const [examplePromptsOpen, setExamplePromptsOpen] = useState(false)
    const [submitValid, setSubmitValid] = useState(false)
    const text = {
        label1: t('', 'Prompt'),
        desc1: t('v-des1', 'Type in your prompt. Your problem statement will be used as context for your prompt.'),
        tips: [{ title: t("a_s11_st_h_1", "Clarity"), description: t("a_s11_st_d_1", "Provide clear instructions, e.g., \"name sources in APA citation style and give 3 examples\"") },
        { title: t("a_s11_st_h_2", "Industry Specificity"), description: t("a_s11_st_d_2", "Use industry- or discipline-specific terms, e.g., \"profitability as measured by the combined ratio\"") },
        { title: t("a_s11_st_h_2b", "Organizational Specificity"), description: t("a_s11_st_d_2b", "If your organization uses proprietary knowledge sources (e.g., meeting minutes) beyond the Large Language model, then you can also use terms specific to your organization") },
        { title: t("a_s11_st_h_3", "Conciseness"), description: t("a_s11_st_d_3", "Be concise in your wording, e.g., \"large language model\" instead of just \"model\"") },
        { title: t("a_s11_st_h_4", "Context"), description: t("a_s11_st_d_4", "Provide additional context, e.g., \"in the context of low interest rates from 2010-2022, provide …\", or, \"given a budget restriction of 1000 USD, suggest …\"") },
        { title: t("a_s11_st_h_5", "Perspective"), description: t("a_s11_st_d_5", "Provide your role, discipline, or perspective, e.g., \"from a Risk Management point of view, provide …\"") }],
        examplePrompts: [t("prompt1", "What are key insights derived from research or case studies by [insert, e.g., research institutions] from a [state the desired perspective, e.g., Risk Management] point of view? Limit yourself to [X] concise bullet points and name sources"),
        t("prompt2", "What are the top 3 risks and the top 3 opportunities according to [...] regarding the problem statement from a [...] point of view? Name sources"),
        t("prompt3", "What are Best Practices as stated by [...] regarding the problem statement from a [...] point of view? Limit yourself to [X] concise bullet points and name sources"),
        t("prompt4", "Name [5] publications in peer-reviewed [state the relevant discipline, e.g., Econometrics] journals. For each publication, provide an abstract of not more than [100 words] each"),
        t("prompt5", "What are leading experts regarding the problem statement from a [state the desired perspective and/or geography, e.g., Risk Management in Europe] point of view? Limit yourself to 5 experts and name sources"),
        t("prompt6", "Explain the underlying principles and industry trends, observed effects, and their significance in [enter your field of interest, e.g., Risk Management, Biology, …]."),
        ]
    }

    useEffect(() => {
        // only set response if prompt, context and response are not empty
        if (aiReducer?.response && prompt !== null && context) {
            setResponse(aiReducer.response)
        }
    }, [aiReducer?.response])

    useEffect(() => {
        const contextValid = context && context.length > 0
        const promptValid = prompt && prompt.length > 0
        setSubmitValid(contextValid && promptValid)
    }, [context, prompt])

    const handlePromptChange = (e) => {
        setPrompt(e.target.value)
    }

    const handleSubmit = () => {
        if (context) {
            predict(`${prompt}.\nTEXT: ${context}`)
        } else {
            predict(prompt)
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(response);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 2000);
    };

    const handleExamplePromptClick = (examplePrompt) => {
        setPrompt(examplePrompt)
        setExamplePromptsOpen(false)
    }

    return (
        <div className='relative'>
            {closeButton && <CloseButton onClose={onClose} className='z-50' top='top-0' />}
            {/* PROMPT INPUT */}
            <div id='promptInputDiv' className=''>
                <div className='relative'>
                    <Input
                        id='promptInput'
                        label={text.label1}
                        description={text.desc1}
                        tips={text.tips}
                        textarea
                        value={prompt}
                        onChange={handlePromptChange}
                    />
                    {/* TODO PROMPTS */}
                    <FontAwesomeIcon onClick={() => setExamplePromptsOpen(!examplePromptsOpen)} className='absolute bottom-2 right-2 bg-primary-100 hover:bg-primary-200 p-[5px] rounded-md text-primary-900  cursor-pointer' icon={faLightbulb} />
                </div>
                {examplePromptsOpen &&
                    <div className='h-44 bg-primary-50 overflow-y-scroll my-2 p-2 border-gray-300 border rounded-md shadow-sm resize-y'>
                        {text.examplePrompts.map((prompt) => (
                            <p className='p-1 text-sm hover:bg-primary-200 rounded-md cursor-pointer'
                                onClick={() => handleExamplePromptClick(prompt)}>
                                {prompt}
                            </p>
                        ))}
                    </div>}
                <Button disabled={!submitValid} className={'mt-1 float-right'} onClick={handleSubmit}>{t('Submit')}</Button>
            </div>
            {/* RESPONSE */}
            <div className='relative mt-8'>
                <Input
                    id='responseField'
                    textarea
                    maxRows={12}
                    label={t('Response')}
                    value={response}
                />
                <button className='absolute right-2 bottom-2 text-gray-600' onClick={copyToClipboard}>
                    <FontAwesomeIcon icon={faCopy} />
                </button>
                {showNotification && (
                    <div className="absolute bg-primary-100 rounded-md p-1 text-sm bottom-2 right-8 shadow-md">
                        Copied to clipboard!
                    </div>
                )}
            </div>
        </div>
    );
}

AiForm.propTypes = {
    active: PropTypes.bool,
    predict: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    closeButton: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    aiReducer: state.aiReducer
});

export default connect(mapStateToProps, { predict })(AiForm);
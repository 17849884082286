import { publicProfileActionTypes as types } from 'constants/public-profile.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.publicProfileReducer) || {};

export function publicProfileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PUBLIC_PROFILE_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.GET_PUBLIC_PROFILE_SUCCESS:
      return {
          profile: payload,
      };
    case types.GET_PUBLIC_PROFILE_FAILURE:
    default:
      return state;
  }
}
  
import GuideItem from "./GuideItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from "@fortawesome/free-solid-svg-icons";

/**
 * 
 * @param {guideContent, width, defaultOpen, active, onClose} props 
 * @returns 
 */
function GuideForm(props) {
    const guideContent = (props.guideContent || [{ header: 'Empty', text: 'This Guide is empty' }])
    const width = (props.width || "w-72 2xl:w-96");
    const defaultOpen = (props.defaultOpen === undefined ? true : props.defaultOpen)

    return (
        <div className={`${props.active ? 'right-0 overflow-auto p-4 2xl:pr-6' : '-right-full'}
        transition-all duration-300 ease-in-out
        absolute top-0 h-screen ${width}
        space-y-2
        bg-white border-l border-gray-300
        shadow-xl z-50`}>
            <div className={`${props.active ? 'right-0 opacity-100' : '-right-full opacity-0'}
            transition-all duration-300 ease-in-out
            fixed ${width} top-0 h-7 z-50
            bg-white border-l border-b border-gray-300 cursor-pointer`}
                onClick={props.onClose}>
                <span className="absolute top-0 left-4 text-gray-600 pt-1">Guide</span>
                <FontAwesomeIcon className="absolute top-1 right-8" icon={faX} />
            </div>
            <div className="h-2"></div>
            {guideContent?.length > 0 && guideContent.map((item) =>
                <GuideItem key={item.header}
                    header={item.header} text={item.text}
                    link={item.link} linkText={item.linkText}
                    defaultOpen={defaultOpen} />
            )}
        </div>
    );
}

export default GuideForm;
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormContainer from "components/FormContainer"
import PageWrapper from "components/PageWrapper"
import ProfileForm from "./ProfileForm";
import InfoBanner from "components/InfoBanner";
import i18n from 'i18n'
import { Menu } from "@headlessui/react";
import LanguageSwitchButton from "components/LanguageSwitchButton";
import { translateItemWithChildren as translateItem } from "utils/translate-item-with-children";

function NewProfile({
    segments,
    valueChains
}) {
    const { t } = useTranslation(['user', 'infobanners']);
    const text = {
        banner: t('banner-new-profile', 'Please set up your profile', { ns: 'infobanners' }),
        title: t('header', 'Create Profile'),
    }
    const [editActive, setEditActive] = useState(false);
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        if (segments) {
            const translatedSegments = segments.map(segment => translateItem(segment, i18n.language));
            setSegmentList(translatedSegments);
        }
    }, [segments, i18n.language]);

    useEffect(() => {
        if (valueChains) {
            const translatedValueChain = valueChains.map(valueChain => translateItem(valueChain, i18n.language));
            setValueChainList(translatedValueChain)
        }
    }, [valueChains, i18n.language]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang);
    }

    return (
        <PageWrapper id={'newProfile'}>
            <div className='my-2'>
                <InfoBanner className='m-2'>{text.banner}</InfoBanner>
            </div>
            <FormContainer width={'w-full lg:w-2/3 xl:w-1/2'}>
                {/* Language switch */}
                <Menu as={'div'} className="relative">
                    <LanguageSwitchButton
                        color="light"
                        lang={lang}
                        changeLanguage={changeLanguage} />
                </Menu>
                <ProfileForm
                    isNewProfile={true}
                    segmentListData={segmentList}
                    valueChainListData={valueChainList}
                    setEditActive={setEditActive}
                />
            </FormContainer>
        </PageWrapper>
    );
}

NewProfile.propTypes = {
    segments: PropTypes.array,
    valueChains: PropTypes.array
};

const mapStateToProps = state => ({
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains
});

export default connect(mapStateToProps, null)(NewProfile);
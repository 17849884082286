import { camelCase, snakeCase } from 'lodash';

// https://stackoverflow.com/a/50620653
const toCamelCase = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => toCamelCase(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: toCamelCase(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

const toSnakeCase = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => toSnakeCase(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [snakeCase(key)]: toSnakeCase(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

export { toCamelCase, toSnakeCase };
import Container from './Container';
import PropTypes from 'prop-types';
import Modal from './Modal';
import TeamTable from './TeamTable';
import InfoBanner from './InfoBanner';
import { GridHeader } from './GridHeader';
import Input from './InputElement';
import { useEffect, useState } from 'react';
import MiniSearch from 'minisearch';

/**
 * Displays a View with 
 * - info-banner [place for information for user, use bannerText]
 * - Gridheader which contains title, optional owner and description
 * - filter and sorting 
 * - displays children
 */
function InfoFilterView({ 
    cardContentList, 
    updateList, 
    handleSortChange, 
    sortType, 
    resetList, 
    teamUserList, 
    bannerText, 
    id, 
    text, 
    owner, 
    children, 
    searchTag, 
    searchFields, 
    storeFields, 
    searchBoost, 
    showProjectRoles }) {

    const [searchIndex, setSearchIndex] = useState(null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (cardContentList?.length > 0) {
            // Search MiniSearch initialization
            const index = new MiniSearch({
                fields: searchFields || ['title'],
                storeFields: storeFields || ['title'],
                searchOptions: {
                    boost: searchBoost || { title: 1 },
                    prefix: true,
                    fuzzy: 0.25,
                }
            });
            index.addAll(cardContentList);
            setSearchIndex(index)
        }
    }, [cardContentList])

    // handles search for tag clicks
    useEffect(() => {
        setInputValue(searchTag)
        handleSearch(searchTag)
    }, [searchTag])

    const handleSearch = (value) => {
        const newResults = searchIndex?.search(value, {});
        setInputValue(value);
        updateList(newResults)
        if (value === '' || null) {
            resetList()
        }
    };

    return (
        <Container id='infoFilterView'>
            <Modal id='modalTeam'
                size='modal-xl'
                backdrop>
                <TeamTable
                    showOrganisationRoles
                    showCommunityRoles
                    showProjectRoles={showProjectRoles || false}
                    showLastLogin
                    users={teamUserList}
                />
            </Modal>
            {bannerText && <div className='-mt-4 mb-3'>
                <InfoBanner>
                    {bannerText}
                </InfoBanner>
            </div>}
            <GridHeader
                id={id}
                title={text.title}
                description={text.description}
                handleSortChange={handleSortChange}
                currentSortType={sortType}
                owner={owner}
                filter={
                    <Input
                        value={inputValue}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder={'Filter'}
                    />}
                teamModal={'#modalTeam'} />
            {children}
        </Container>
    );
}

InfoFilterView.propTypes = {
    teamUserList: PropTypes.array.isRequired,
    bannerText: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.object.isRequired,
    updateList: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    searchFields: PropTypes.array,
    storeFields: PropTypes.array,
    searchBoost: PropTypes.object,
    sortType: PropTypes.string.isRequired,
    searchTag: PropTypes.string,
    owner: PropTypes.object
}

export {InfoFilterView};
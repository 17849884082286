import SimpleGroupHover from "./SimpleGroupHover";
import { useTranslation } from "react-i18next";
import PopUp from "./PopUp";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import CircularAvatarWithInitials from "./avatar/CircularAvatarWithInitials";

function VotingArea({ voteCount, userVotes, upVote, upVoteClicked, downVote, downVoteClicked, hoverInfo, size, position }) {
    const { t } = useTranslation(['common']);
    const [viewUser, setViewUser] = useState(false)
    const hoverInfoClass = 'absolute bottom-8 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200'

    const userList = () => {
        if (voteCount === 0 && userVotes?.length === 0) {
            return <p className="whitespace-nowrap">No Votes</p>;
        }
        return userVotes?.map((user) => (user.vote !== 0 &&
             <p className="whitespace-nowrap" key={user.firstName + user.lastName}>{user.firstName} {user.lastName}</p>
        ));
    }

    const handleClose = () => {
        setViewUser(false)
    }

    const votingClass = 'h-4 transform -translate-x-1 translate-y-3'
    const iconClass = `${size === 'small' ? 'h-4 w-4' : 'h-5 w-5'} p-1`

    return (
        <div className='flex align-middle items-center mt-0'>
            <PopUp open={viewUser} onClose={handleClose}>
                {userVotes?.map((user) => (user.vote !== 0 &&
                    <div className="flex mb-4 align-middle items-center" key={user.firstName + user.lastName}>
                        <div className="mr-4">
                            <CircularAvatarWithInitials size="8" >
                                {`${user.firstName?.charAt(0) || 'N'}${user.lastName?.charAt(0) || 'N'}`}
                            </CircularAvatarWithInitials>
                            <span className="text-xl">
                                {user.vote === 1 ? (
                                    <FontAwesomeIcon icon={faThumbsUp} className={`text-green-600 ${votingClass}`} />
                                ) : user.vote === -1 ? (
                                    <FontAwesomeIcon icon={faThumbsDown} className={`text-red-600 ${votingClass}`} />
                                ) : null}
                            </span>
                        </div>
                        <div className="">{user.firstName} {user.lastName}</div>
                    </div>
                ))}
            </PopUp>
            <button
                id='upvoteButton'
                className='group relative'
                onClick={upVote}
                disabled={upVoteClicked}>
                <FontAwesomeIcon icon={faThumbsUp} className={`${upVoteClicked ? 'text-gray-800' : 'text-gray-400 hover:text-primary-700'} ${iconClass}`} />
                {hoverInfo && <span className={hoverInfoClass} style={{ width: '256px', left: '-128px' }}>
                    {t('c-up', 'This is relevant / useful for me')}
                </span>}
            </button>
            <span className="">
                <SimpleGroupHover
                    position={`${position ? position : 'top-8'}`}
                    overwritePosition={position}
                    text={userList()}>
                    <span className={`${voteCount > 0 ? 'text-green-600' : voteCount === 0 ? 'text-gray-400' : 'text-red-600'} text-base font-bold px-2 cursor-pointer text-center`}
                        onClick={() => setViewUser(true)}>
                        {voteCount}
                    </span>
                </SimpleGroupHover>
            </span>
            <button
                id='downvoteButton'
                className='group relative'
                onClick={downVote}
                disabled={downVoteClicked}>
                <FontAwesomeIcon icon={faThumbsDown} className={`${downVoteClicked ? 'text-gray-800' : 'text-gray-400 hover:text-primary-700'} ${iconClass} transform translate-y-1`} />
                {hoverInfo && <span className={hoverInfoClass} style={{ width: '300px', left: '-150px' }}>
                    {t('c-down', 'This is not relevant / useful for me')}
                </span>}
            </button>
        </div>
    );
}

export default VotingArea;
import { useTranslation } from "react-i18next";
import Button from "components/Button";

/**
 * @param {id, title, children, onSave, saveText, size, buttons} props
 *  id       Modal id, default is 'modalCenter'
 *  title    Modal title
    children Modal content
    onSave   action when 'save' button is clicked
    saveText alternative Text for the 'save' button]
    size     size of Modal, default is 'modal-dialog', alternatives are: modal-sm, modal-lg, modal-xl
    buttons  for any additional buttons beside close/save
    backdrop closes modal on backdrop click if "true"
 * @returns 
 */
function Modal(props) {
    const { t } = useTranslation();
    return (
        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id={props.id || "modalCenter"}
            tabIndex="-1"
            aria-labelledby="modalCenterTitle"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop={props.backdrop ? "true" : "static"}
            data-bs-keyboard={props.keyboard !== undefined ? props.keyboard : false}>
            <div className={`${props.size || 'modal-dialog-centered'} modal-dialog relative w-auto pointer-events-none`}>
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                            {props.title}
                        </h5>
                        <button type="button"
                            onClick={props.onClose}
                            className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body relative p-4">
                        {props.children}
                    </div>
                    <div
                        className={`modal-footer flex flex-shrink-0 flex-wrap items-center space-x-2
                        justify-end p-4 border-t border-gray-200 rounded-b-md`}>
                        {props.buttons}
                        <Button
                            id="modalCloseButton"
                            className=""
                            onClick={props.onClose}
                            bg="bg-primary-500"
                            databsdismiss="modal">
                            {props.closeText || t('Close')}
                        </Button>
                        {props.onSave &&
                            <Button
                                id="modalSaveButton"
                                className=""
                                onClick={props.onSave}
                                disabled={props.saveDisabled}
                                databsdismiss="modal"
                                bg={props.saveBg}>
                                {props.saveText || t('Save')}
                            </Button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
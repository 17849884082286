import { useState, useRef } from "react";
import PropTypes from 'prop-types'
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipBase from "./TooltipBase";
import useOutsideClick from "hooks/useOutsideClick";

/**
 * Label with an 'i' icon on the top right
 * tooltip is activated by click
 * if used in the same parent, only one tooltip can be activated at time 
 * tooltips are closed with outside click 
 */
function LabelWithInfoHover({ label, tooltip }) {
    const [active, setActive] = useState(false);
    const iconRef = useRef(null)

    useOutsideClick(iconRef, () => {
        if (active) setActive(false)
    })

    return (
        <div className="relative flex justify-center">
            <div className="flex relative justify-center">
                <span className="truncate max-w-[140px]">{label}</span>
                <span id='notificationCountBubble'
                    ref={iconRef}
                    className="bg-primary-500 text-white text-xs rounded-full h-3 w-3 flex items-center justify-center transform -translate-y-1 cursor-pointer"
                    onClick={() => setActive(!active)}>
                    <FontAwesomeIcon className="h-2 w-2" icon={faInfo} />
                </span>
            </div>
            {active &&
                <TooltipBase
                    width="w-auto max-w-[160px]"
                    className="absolute translate-y-4 text-sm font-normal">
                    {tooltip}
                </TooltipBase>}
        </div>
    );
}

LabelWithInfoHover.propTypes = {
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
}

export default LabelWithInfoHover;
import { useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';
import CreateIssueContext from "./create-issue-context";

const defaultCreateIssueState = {
    data: {
        title: '',
        question: '',
        situation: '',
        firstInsight: '',
        resolutionHypothesis: [
            {
                name: '',
                rationale: '',
                id: 1,
                coreMetrics:
                    [
                        {
                            id: 1,
                            description: 'Name 1',
                            baseline: 500,
                            unit: '',
                            rating: '',
                            change: '',
                            estimate: '',
                            companyGoal: '',
                        },
                        {
                            id: 2,
                            description: 'Name 2',
                            baseline: 800,
                            unit: '',
                            rating: '',
                            change: '',
                            estimate: '',
                            companyGoal: '',
                        },
                    ],
                proofPoint: [
                    {
                        id: 1,
                        analysis: '',
                        responsible: '',
                        deadline: '',
                        result: '',
                        interpretation: '',
                    }
                ]
            }
        ],
    }
}

// console.log('TABLELOGTEST', defaultCreateProblemState.data.criticalDefinitions[2])

const createIssueReducer = (state, action) => {
    if (action.type === 'CHANGE') {

        return {
            data: ({ ...state.data, [action.name]: action.e.target.value })
        };
    }
    if (action.type === 'CHANGE_ITEM') {
        return {
            data: ({ ...state.data, [action.name]: action.data })
        }
    }
    if (action.type === 'CHANGE_OBJECT') {
        const tempData = { ...state.data }
        tempData[action.objectName][action.name] = action.data;
        return {
            data: tempData
        }
    }
    if (action.type === 'CHANGE_ROW') {
        const tempData = { ...state.data }
        tempData[action.tablename][action.index][action.name] = action.e.target.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'EDIT_ROW') {
        const tempData = { ...state.data }
        tempData[action.tablename][action.index][action.name] = action.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'NEW_ROW') {
        const newRow = { id: uuidv4() }
        if (action.name === "resolutionHypothesis") {
            newRow.proofPoint = [{ id: uuidv4() }];
            newRow.coreMetrics = [];
            newRow.coreMetrics.push({ id: uuidv4() })
            newRow.rationale = null;
        }
        return {
            data: { ...state.data, [action.name]: [...state.data[action.name], newRow] }
        }
    }
    if (action.type === 'NEW_PROOF_POINT_ROW') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        const newRow = { id: uuidv4() }
        stateCopy.data.resolutionHypothesis[action.index].proofPoint.push(newRow);
        return stateCopy;
    }
    if (action.type === 'NEW_METRICS_ROW') {
        const stateCopy = JSON.parse(JSON.stringify(state));
        const newRow = { id: uuidv4() }
        stateCopy.data.resolutionHypothesis[action.hypothesisIndex].coreMetrics.push(newRow);
        return stateCopy;

    }
    if (action.type === 'DELETE_ROW') {
        const tempData = { ...state.data }
        tempData[action.name] = tempData[action.name].filter(data => data.id !== action.id);
        return {
            data: tempData
        }
    }
    if (action.type === 'DELETE_PROOF_POINT_ROW') {
        const tempData = { ...state.data }
        tempData["resolutionHypothesis"][action.index].proofPoint = tempData["resolutionHypothesis"][action.index].proofPoint.filter(data => data.id !== action.id);
        return {
            data: tempData
        }
    }
    if (action.type === 'DELETE_COREMETRIC_ROW') {
        // console.log('delete row in provider index', action.index)
        // console.log('delete row in provider id', action.metricId)
        const tempData = { ...state.data }
        if (tempData["resolutionHypothesis"][action.index].coreMetrics?.length > 1) {
            tempData["resolutionHypothesis"][action.index].coreMetrics = tempData["resolutionHypothesis"][action.index].coreMetrics.filter((data) => data.id !== action.metricId);
        }
        return {
            data: tempData
        }
    }
    if (action.type === 'COREMETRIC_BASEVALUE_CHANGE') {
        const tempData = { ...state.data }
        const tempDataElement = tempData.resolutionHypothesis[action.index].coreMetrics[action.elIndex]
        switch (action.elName) {
            case "baseline":
                tempDataElement[action.elName] = action.e.target.value;
                tempDataElement["estimate"] = parseInt(tempDataElement["baseline"]) + parseInt(tempDataElement["change"])
                break;
            case "change":
                tempDataElement[action.elName] = action.e.target.value;
                tempDataElement["estimate"] = parseInt(tempDataElement["baseline"]) + parseInt(tempDataElement["change"]);
                break
            case "estimate":
                tempDataElement[action.elName] = action.e.target.value;
                tempDataElement["change"] = parseInt(tempDataElement["estimate"]) - parseInt(tempDataElement["baseline"])
                break
            default:
                tempDataElement[action.elName] = action.e.target.value;
        }
        return {
            data: tempData
        }
    }
    if (action.type === 'RESOLUTION_HYPOTHESIS_CHANGE') {
        const tempData = { ...state.data }
        tempData.resolutionHypothesis[action.index][action.name] = action.e.target.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'COREMETRIC_UNIT_CHANGE') {
        const tempData = { ...state.data }
        tempData.resolutionHypothesis[action.index].coreMetrics[action.elIndex].unit = action.e.target.value;
        return {
            data: tempData
        }
    }
    if (action.type === 'CHANGE_PROOF_POINT_ROW') {
        const tempData = { ...state.data }
        const updatedData = tempData.resolutionHypothesis[action.hypIndex].proofPoint.map((item, i) => {
            if (i === action.index) {
                item[action.name] = action.e.target.value;
                // item.responsible = action.e.target.value;
                // item.result = action.e.target.value;
                // item.interpretation = action.e.target.value;
            }
            return item;
        });
        tempData.resolutionHypothesis[action.hypIndex].proofPoint = [...updatedData];
        // tempData.resolutionHypothesis[action.index].proofPoint.analysis = action.e.target.value;
        // tempData.resolutionHypothesis[action.index].proofPoint.responsible = action.e.target.value;
        // tempData.resolutionHypothesis[action.index].proofPoint.result = action.e.target.value;
        // tempData.resolutionHypothesis[action.index].proofPoint.interpretation = action.e.target.value;
        //tempData.coreMetrics[action.index].deltaTarget = 0;
        return {
            data: tempData
        }
    }
    if (action.type === 'COREMETRIC_TARGET_CHANGE') {
        const tempData = { ...state.data }
        const deltaTarget = parseInt(action.e.target.value) - tempData.coreMetrics[action.index].baselineValue;
        tempData.coreMetrics[action.index].target = action.e.target.value;
        tempData.coreMetrics[action.index].deltaTarget = deltaTarget;
        return {
            data: tempData
        }
    }
    return defaultCreateIssueState
}

function CreateIssueProvider(props) {
    const [createIssueState, dispatchIssueAction] = useReducer(createIssueReducer, defaultCreateIssueState);

    const handleChange = (name) => (e) => {
        dispatchIssueAction({ type: 'CHANGE', name, e })
    }
    const itemHandler = (name, data) => {
        dispatchIssueAction({ type: 'CHANGE_ITEM', name, data })
    }
    const handleObjectChange = (objectName, name, data) => {
        dispatchIssueAction({ type: 'CHANGE_OBJECT', objectName, name, data })
    }
    const rowHandler = (tablename, name, index, e) => {
        dispatchIssueAction({ type: 'CHANGE_ROW', tablename, name, index, e })
    }
    const proofPointchangeRowHandler = (hypIndex, index, id, name, e) => {
        dispatchIssueAction({ type: 'CHANGE_PROOF_POINT_ROW', hypIndex, index, id, name, e })
    }
    const editRowHandler = (tablename, name, index, value) => {
        dispatchIssueAction({ type: 'EDIT_ROW', tablename, name, index, value })
    }
    const newRowHandler = (name) => {
        dispatchIssueAction({ type: 'NEW_ROW', name })
    }
    const newProofPointRowHandler = (id, index, name) => {
        dispatchIssueAction({ type: 'NEW_PROOF_POINT_ROW', name, id, index })
    }
    const newMetricsRowHandler = (hypothesisIndex) => {
        dispatchIssueAction({ type: 'NEW_METRICS_ROW', hypothesisIndex });
    }
    const deleteProofPointRowHandler = (name, index, id) => {
        dispatchIssueAction({ type: 'DELETE_PROOF_POINT_ROW', name, index, id })
    }
    const deleteCoreMetricRowHandler = (index, metricId) => {
        dispatchIssueAction({ type: 'DELETE_COREMETRIC_ROW', index, metricId })
    }
    const deleteRowHandler = (name, id) => {
        dispatchIssueAction({ type: 'DELETE_ROW', name, id })
    }
    const coremetricHandler = (index, elIndex, elName, e) => {
        dispatchIssueAction({ type: 'COREMETRIC_BASEVALUE_CHANGE', index, elIndex, elName, e })
    }
    const coremetricUnitHandler = (index, elIndex, e) => {
        dispatchIssueAction({ type: 'COREMETRIC_UNIT_CHANGE', index, elIndex, e })
    }
    const coremetricTargetHandler = (index, e) => {
        dispatchIssueAction({ type: 'COREMETRIC_TARGET_CHANGE', index, e })
    }
    const resolutionHypothesisHandler = (tablename, name, index, e) => {
        dispatchIssueAction({ type: 'RESOLUTION_HYPOTHESIS_CHANGE', name, index, e })
    }


    const createIssueContext = {
        data: createIssueState.data,
        handleChange,
        handleObjectChange,
        itemHandler,
        rowHandler,
        editRowHandler,
        newRowHandler,
        deleteRowHandler,
        coremetricHandler,
        coremetricTargetHandler,
        resolutionHypothesisHandler,
        newProofPointRowHandler,
        deleteProofPointRowHandler,
        proofPointchangeRowHandler,
        newMetricsRowHandler,
        deleteCoreMetricRowHandler,
        coremetricUnitHandler,
    }

    return <CreateIssueContext.Provider value={createIssueContext}>
        {props.children}
    </CreateIssueContext.Provider>
}

export { CreateIssueProvider, defaultCreateIssueState };
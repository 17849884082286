import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { request } from 'actions/auth.request.actions';
import { login } from 'actions/auth.login.actions';

import Button from 'components/MainButton';

import { alertActionTypes } from 'constants/alert.action.types';
import { parseRedirectLink } from 'utils/redirect-parser';
import CookieBanner from 'components/CookieBanner';
import LPFooter from 'components/LPFooter';


const LoginPage = ({ isAuthenticated, request, login, alert }) => {
  const { t } = useTranslation(['common']);
  const location = useLocation()
  const history = useHistory();

  const destination = parseRedirectLink(location.search)

  const dispatch = useDispatch()

  const [token, setToken] = useState(null)
  const [formData, setFormData] = useState({
    email: ''
  });

  useEffect(() => {
    const pattern = /^\?t=(?<token>[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*)$/;
    if (pattern.test(location.search)) {
      let match = location.search.match(pattern)
      setToken(match.groups.token)
    } else {
      setToken(null)
    }
  }, [])

  useEffect(() => {
    login(token)
  }, [token])

  useEffect(() => {
    if (alert?.type === 'success' && alert?.text == 'Email sent. Please follow instructions for authentication.') {
      history.push('/email-confirmation')
      dispatch({ type: alertActionTypes.CLEAR })
    }
  }, [alert])

  if (isAuthenticated) {
    if (destination) {
      return <Redirect to={`${destination}`} />;
    }
    return <Redirect to='/home' />;
  }

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault()
    request(email)
  };

  return (
    <div className="relative bg-gray-50 flex flex-col py-6 sm:px-6 lg:px-8"
      style={{ minHeight: 'calc(100vh - 32px)' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md grow flex flex-col justify-center">
        {/* <img className='h-24 mx-auto' src={Logo} alt='Solverint Logo'/> */}
        {/* <img className='h-24 mx-auto' src="https://media.licdn.com/dms/image/C4E0BAQG_woCcHanreQ/company-logo_200_200/0/1552476245443?e=1681344000&v=beta&t=NEvHWqON6x90x8VihOMrKPALkyk3hFKQpDFQyjNHotw"></img> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('signIn', 'Sign in to your account')}</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          <span className='mr-1'>{t('or')}</span>
          <Link to={destination ? `/register/?r=${destination}` : '/register'} className="font-medium text-primary-600 hover:text-primary-500">
            {t('Create account')}
          </Link>
        </p>

        <div className="mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t("Email address")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    value={email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div>
                <Button>{t("loglink", "Login via Email")}</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=''>
        <LPFooter mode="dark" />
      </div>
      <CookieBanner />
    </div>
  )
};

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool,
  alert: PropTypes.object,
  request: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  alert: state.alertReducer
});

export default connect(mapStateToProps, { request, login })(LoginPage);

const topicTypesData = (t) => [
    {
        name: 'PROBLEM_STATEMENT',
        text: t('1_ps_tt_l1', 'Problem Statement'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt1', 'Be outcome-focused, specific, measurable, realistic, and time-bound. Leave room for creativity and align with other parts of your organization')
    },
    {
        name: 'IDEA',
        text: t('1_ps_tt_l2', 'Idea'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt2', 'Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects')
    },
    {
        name: 'USE_CASE',
        text: t('1_ps_tt_l3', 'Use Case'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt3', 'Tell on a high level who is going to use the solution, what it will be used for, and how users are going to use it')
    },
    {
        name: 'RESEARCH_QUESTION',
        text: t('1_ps_tt_l4', 'Research Question'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt4', 'Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects')
    },
    {
        name: 'POV_STATEMENT_DESIGN_GOAL',
        text: t('1_ps_tt_l5', 'POV Statement & Design Goal'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt5', 'In your POV ("Point of View") statement, tell who the user persona is, what she needs and provide insights why. Then ask a "how might we" question leaving room for creativity, but targeted specifically at the design goal formulated in your POV statement')
    },
    {
        name: 'OTHER',
        text: t('1_ps_tt_l6', 'Other'),
        color: 'bg-primary-100',
    },

];

export default topicTypesData;

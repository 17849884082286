import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faEdit, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import Container from "components/Container";
import FormContainer from "components/FormContainer";
import SimpleGroupHover from 'components/SimpleGroupHover';
import CommunityOverview from 'pages/CreateCommunityPage/Overview/CommunityOverview';
import CommunityProvider from 'pages/CreateCommunityPage/context/CommunityProvider';
import { parseItem } from 'utils/session-storage';
import TeamTable from 'components/TeamTable';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CommunityPage = ({ currentCommunity }) => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const [organisationRole, setOrganisationRole] = useState(null);
  const [communityRole, setCommunityRole] = useState(null);
  const [communityData, setCommunityData] = useState({ config: null, users: [] });
  const [isAdmin, setIsAdmin] = useState(false);

  const { communityId } = useParams()

  useEffect(() => {
    if (currentCommunity) {
      setCommunityData(
        {
          ...currentCommunity
        }
      )
    }
  }, [currentCommunity])

  // organisation role
  useEffect(() => {
    let currentOrganisation = parseItem('organisation')
    if (currentOrganisation && currentOrganisation.role) {
      setOrganisationRole(currentOrganisation.role)
    } else {
      setOrganisationRole(null)
    }
  }, [sessionStorage.getItem('organisation')]);

  // community role
  useEffect(() => {
    if (currentCommunity && currentCommunity.role) {
      setCommunityRole(currentCommunity.role);
    } else {
      setCommunityRole(null)
    }

  }, [currentCommunity]);

  useEffect(() => {
    // community bypassing must not be possible
    setIsAdmin(false)
    if (communityRole === 'OWNER' || communityRole === 'ADMIN') {
      setIsAdmin(true)
    }

    if (organisationRole === 'OWNER' || organisationRole === 'ADMIN') {
      setIsAdmin(true)
    }
  }, [communityRole, organisationRole])

  const handleBackClick = () => {
    history.push("/home");
  }

  return (
    <Container>
      <CommunityProvider communityId={communityId}>
        <CommunityOverview
          isAdmin={isAdmin}
          id={communityData.id}
          pass={communityData.password}
          config={communityData.config} />
      </CommunityProvider>
      <FormContainer
        width="full"
        guide={false}>

        <div id='communityUserDisplay' className="px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
              <p className="mt-2 text-sm text-gray-700">
                Please find here a list of all the members of this community. Click on the name to review the member's profile.
              </p>
            </div>
            {/* {isAdmin && 
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  Add user
                </button>
              </div>
            } */}
          </div>
          <TeamTable
            showOrganisationRoles
            showCommunityRoles
            showStatus
            showCreatedAt
            showLastLogin
            users={communityData.users} />
        </div>

      </FormContainer>
      <div id='buttonContainer' className='bg-white w-full absolute bottom-8 pt-2'>
        <Button id='communityBackButton' onClick={handleBackClick}>{t('Back')}</Button>
      </div>
    </Container>
  )
}

CommunityPage.propTypes = {
  currentCommunity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  organisations: state.organisationReducer.organisations,
  currentCommunity: state.communityReducer.currentCommunity
});

export default connect(mapStateToProps, null)(CommunityPage);
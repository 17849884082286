export const TAGS_WORDS = [
  'Agenturberatung',
  'Agenturen',
  'Agilität',
  'Aktuariat',
  'Alternative Investments',
  'Ambient Assisted Living (AAL)',
  'Analytik',
  'Antragsmanagement',
  'Anwendungsentwicklung',
  'API',
  'Arbeitskraftabsicherung',
  'Arbeitswelten / New Work',
  'Assistance',
  'Aufsichtsrecht',
  'Ausschließlichkeitsvertrieb',
  'Außendienst',
  'Bancassurance',
  'Bank',
  'BAV',
  'Behavioral Analytics',
  'Benchmarking',
  'Berufsunfähigkeitsversicherung',
  'Beschwerdemanagment',
  'Bestandsmanagement',
  'Betriebliche Krankenversicherung',
  'Betriebsorganisation',
  'Betrugsmanagement',
  'Big Data',
  'Bilanzierung',
  'Blockchain',
  'Blogging',
  'Bonitätsprüfung',
  'Business Continuity Management (BCM)',
  'Business Intelligence',
  'Business Process Modelling und Automation',
  'Call Center',
  'Changemanagement',
  'Cloud',
  'Compliance',
  'Controlling',
  'CRM',
  'CSR',
  'Customer Care',
  'Cyber',
  'Cybercrime',
  'Cyberversicherung (Gewerbe)',
  'Cyberversicherung (Privat)',
  'Data Analytics / Advanced Analytics',
  'Datenschutz',
  'Demographie',
  'Dialogmarketing',
  'Dienstleistersteuerung',
  'Digital Advisors / Robo Advisors',
  'Digital Design',
  'Digitalisierung',
  'Direktvertrieb',
  'Dunkelverarbeitung',
  'E-Business',
  'Einkauf / Vendor- und Lieferantenmanagement',
  'Elementarversicherung',
  'Embedded Insurance',
  'E-Payment',
  'ESG',
  'EU-Gesetzgebung',
  'Facharchitektur',
  'Fehlerkultur',
  'Finanzen',
  'Firmenkunden',
  'Forschung & Entwicklung',
  'GAFA',
  'Gamification',
  'Gebäudeversicherung',
  'Geldwäsche',
  'Geointelligence',
  'Gesundheit',
  'Gewerbeversicherung',
  'Governance',
  'Hacking',
  'Haftpflichtversicherung',
  'Hausratversicherung',
  'HealthTech',
  'Healthtech / E-Health',
  'Honorarberatung',
  'IDD',
  'IFRS',
  'Immobilien',
  'Industrieversicherung',
  'Inkasso',
  'Innendienst',
  'Innovationen',
  'Innovationsmanagement',
  'Internationalisierung',
  'Interne Revision',
  'Investmentrecht',
  'IT',
  'IT-Governance',
  'IT-Revision',
  'IT-Sicherheit',
  'Jahresabschlussanalyse',
  'Kapitalanlage',
  'Kapitalmarktrecht',
  'Kfz-Versicherung',
  'KI / AI / Künstliche Intelligenz',
  'Klimaschutz',
  'KMU',
  'Kompositversicherung',
  'Kooperationen',
  'Krankenversicherung',
  'Kreditversicherung',
  'Kundenakzeptanz',
  'Kundenbindung',
  'Kundencenter',
  'Kundenloyalität',
  'Kundenmanagement',
  'Kundenrückgewinnung',
  'Kundensegmentierung',
  'Kundenservice',
  'Kundenwert',
  'Lead Generation / Lead Management',
  'Leadership / Führung',
  'Lebensarbeitszeitkonten',
  'Lebensversicherung',
  'Leistungsmanagement',
  'Makler',
  'Makler (Gewerbe)',
  'Makler (privat)',
  'Maklervertrieb',
  'Marketing',
  'Marketingcontrolling',
  'Marktbeobachtung',
  'Marktentwicklung',
  'Marktforschung',
  'Marktkommunikation',
  'Metaversum',
  'Mitarbeiterentwicklung',
  'Mitarbeiterkommunikation',
  'Mobile Devices',
  'Mobile Technologien',
  'Mobilität',
  'Nachfolgemanagement',
  'Nachhaltigkeit',
  'Net Promoter Score',
  'Neue Geschäftsfelder',
  'Neue Geschäftsmodelle',
  'Ökosystem',
  'Onlinestrategie',
  'Operations',
  'Organisationsentwicklung',
  'Outsourcing',
  'Partnermanagement',
  'Pay as you drive',
  'Pay as you life',
  'Peer2Peer',
  'Personal (Human Resources)',
  'Personalcontrolling',
  'Personalentwicklung',
  'Personalmarketing',
  'Personalplanung',
  'Personenschadenmanagent',
  'Personenversicherung',
  'POG',
  'Politik',
  'Portfoliomanagement',
  'PR',
  'Predictive Analytics',
  'Privatkunden',
  'Procurement',
  'Produkte',
  'Produktentwicklung',
  'Produktmanagement',
  'Produktportfolio',
  'Produktreporting',
  'Programmierung',
  'Projektmanagement',
  'Provision',
  'Prozessmanagement',
  'Qualitätsmanagement',
  'Quantencomputing',
  'Rating',
  'Rechnungswesen',
  'Recht',
  'Rechtsschutzversicherung',
  'Recruiting',
  'Regresse',
  'Reiseversicherung',
  'Reporting',
  'Revision',
  'Riester',
  'Risikomanagement',
  'Robotics / RPA / Intelligent Process Automation',
  'Rückstellungen',
  'Rückversicherung',
  'Sachversicherung',
  'Schadenmanagement',
  'Self-Service',
  'Servicequalität',
  'Smart Home',
  'Social Media',
  'Soft Skills',
  'Software',
  'Solvency II',
  'Start-up',
  'Statistik',
  'Strategie',
  'Studien',
  'Technologie',
  'Telematik',
  'Trendforschung',
  'Trends',
  'Underwriting',
  'Unfallversicherung',
  'Unternehmenskommunikation',
  'Unternehmenskultur',
  'Unternehmensplanung',
  'Unternehmenssteuerung',
  'Unternehmensstrategie',
  'Up- und Cross-Selling',
  'Verband',
  'Verkaufsförderung',
  'Vermittlerrecht',
  'Versicherungsaufsichtsrecht',
  'Versicherungstechnik',
  'Vertragsmanagement',
  'Vertrieb',
  'Vertriebscontrolling',
  'Vertriebspartner',
  'Vertriebsrecht',
  'Vertriebssteuerung',
  'Vertriebsunterstützung',
  'Verwaltung',
  'Videoidentifizierung',
  'Vorsorge',
  'Weiterbildung',
  'Werbung',
  'Wissensmanagement',
  'Wohnungswirtschaft',
  'Einzelmakler',
  'Mittelständische Makler',
  'Spezialmakler',
  'Maklervertriebe',
  'Maklerpools',
  'Pools',
  'Industriemakler',
  'Onlinemakler',
  'Firmenverbundene Vermittler',
];

import Input from "components/InputElement";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import RowDeletable from "./RowDeletable";

/**
 * Linklist can display the current List of an object, 
 * and an 'edit Mode' where new Links can be added
 * resetList can be called by the parent through the useRef hook 
 */
const LinkList = forwardRef(({ editMode, list, changeList, urlInputActive }, ref) => {
    const [linkList, setLinkList] = useState(list || []);
    const [currentLink, setCurrentLink] = useState({
        id: Date.now(),
        url: '',
        text: ''
    })
    const [validUrl, setValidUrl] = useState(false)

    const onAdd = () => {
        const newLink = {
            id: currentLink.id,
            url: currentLink.url,
            text: currentLink.text || currentLink.url
        }
        setLinkList(prevList => [...prevList, newLink]);
        setCurrentLink({
            id: Date.now(),
            url: null,
            text: null
        })
        urlInputActive(false);
        setValidUrl(false);
    }

    const handleDelete = (id) => {
        setLinkList(prevList => prevList.filter(link => link.id !== id));
    };

    const onChange = (e) => {
        setCurrentLink({ ...currentLink, [e.target.name]: e.target.value });
        if (e.target.name === 'url') {
            setValidUrl(isValidUrl(e.target.value))
            urlInputActive(e.target.value.length > 0)
        }
    }

    /** Exposes a function named 'resetList' to the parent component through the ref.
    * When called, 'resetList' resets the internal state of the child component,
    * clears the list and other fields / validation
    */
    useImperativeHandle(ref, () => ({
        resetList() {
            setLinkList([])
            setCurrentLink({
                id: Date.now(),
                url: null,
                text: null
            })
            urlInputActive(false)
            setValidUrl(false)
        }
    }));

    const isValidUrl = (url) => {
        return url.length > 0;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (validUrl) {
                onAdd()
            }
        }
    }

    useEffect(() => {
        if (list) {
            setLinkList(list)
        } else {
            setLinkList([])
        }
    }, [list]);

    useEffect(() => {
        if (changeList) {
            changeList(linkList)
        }
    }, [linkList]);

    return (
        <div id="linkList">
            {linkList?.length > 0 &&
                <ul>
                    {linkList.map((link) => (
                        <li key={link.id}>
                            <RowDeletable editMode={editMode} id={link.id} handleDelete={handleDelete}>
                            <a href={`${link.url.startsWith('http') ? '' : '//'}` + link.url} target="_blank"
                                    className="text-blue-500 hover:underline flex-grow group-hover:bg-primary-100 rounded">
                                    {link.text}
                                </a>
                            </RowDeletable>
                        </li>
                    ))}
                </ul>}
                
            {editMode && <div className="flex space-x-1 items-center">
                <Input
                    id='url'
                    value={currentLink.url}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    placeholder={'url'} />
                <Input
                    id='text'
                    value={currentLink.text}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    placeholder={'display text'} />
                <Button
                    onClick={onAdd}
                    disabled={!validUrl}
                    className={'w-5 h-[37px] mt-1 align-middle'}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>}
        </div>
    );
})

export default LinkList;
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import EditableRow from "components/EditableRow";
import CreateProblemContext from '../context/ProblemContext';

function StakeholderRow(props) {
    const { t } = useTranslation();
    const ctx = useContext(CreateProblemContext);
    const thisRow = ctx.data.stakeholders[props.index];
    const oneRow = ctx.data.stakeholders?.length === 1;
    const tableName = "stakeholders";

    const [rowData, setRowData] = useState([
        { id: 0, name: 'stakeholder', value: thisRow.stakeholder, type: 'area', style: 'w-20' },
        {
            id: 1, name: 'impact', value: thisRow.impact, type: 'selectMenu', style: 'w-32',
            itemList: [" - ",
                t("impact-5", "Very High"),
                t("impact-4", "High"),
                t("impact-3", "Medium"),
                t("impact-2", "Low"),
                t("impact-1", "Very Low")]
        },
        { id: 2, name: 'impactExplanation', value: thisRow.impactExplanation, type: 'area', style: 'w-48' },
        { id: 3, name: 'affected', value: thisRow.affected, type: 'area', style: 'w-48' },
        { id: 4, name: 'need', value: thisRow.need, type: 'area', style: 'w-48' },
        { id: 5, name: 'keyPreferences', value: thisRow.keyPreferences, type: 'area', style: 'w-48' },
        { id: 6, name: 'relationship', value: thisRow.relationship, type: 'area', style: 'w-20' },
    ])

    const deleteHandler = () => {
        ctx.deleteRowHandler(tableName, thisRow.id);
    }

    const changeHandler = (rowIndex, name, event, itemId) => {
        let tempData = [...rowData];
        tempData[itemId].value = event.target.value;
        setRowData(tempData);

        ctx.rowHandler(tableName, name, rowIndex, event);
    }

    return (
        <EditableRow
            editActive={props.editActive}
            oneRow={oneRow}
            rowId={thisRow.id}
            rowIndex={props.index}
            rowData={rowData}
            delete={deleteHandler}
            changeHandler={changeHandler}
        />
    );
}

export default StakeholderRow;
const organisationActionTypes = {
    LIST_ORGANISATIONS_REQUEST: 'LIST_ORGANISATIONS_REQUEST',
    LIST_ORGANISATIONS_SUCCESS: 'LIST_ORGANISATIONS_SUCCESS',
    LIST_ORGANISATIONS_FAILURE: 'LIST_ORGANISATIONS_FAILURE',

    UPDATE_ORGANISATION_REQUEST: 'UPDATE_ORGANISATION_REQUEST', // not used
    UPDATE_ORGANISATION_SUCCESS: 'UPDATE_ORGANISATION_SUCCESS', // not used
    UPDATE_ORGANISATION_FAILURE: 'UPDATE_ORGANISATION_FAILURE', // not used
};

export { organisationActionTypes };
import { combineReducers } from 'redux';
import { alertReducer } from './alert.reducer';
import { authReducer } from './auth.reducer';
import { loadingReducer } from './loading.reducer';
import { profileReducer } from './profile.reducer';
import { organisationReducer } from './organisation.reducer';
import { communityReducer } from './community.reducer';
import { projectReducer } from './project.reducer';
import { publicProfileReducer } from './public-profile.reducer';
import { problemReducer } from './problem.reducer';
import { commentReducer } from './comment.reducer';
import { votingReducer } from './voting.reducer';
import { segmentReducer } from './segment.reducer';
import { valueChainReducer } from './value-chain.reducer';
import { tagReducer } from './tag.reducer';
import { userAlertReducer } from './user-alert.reducer';
import { aiReducer } from './ai.reducer';

import { authActionTypes } from 'constants/auth.action.types'

const appReducer = combineReducers({
  alertReducer,
  authReducer,
  loadingReducer,
  profileReducer,
  organisationReducer,
  communityReducer,
  projectReducer,
  publicProfileReducer,
  problemReducer,
  commentReducer,
  votingReducer,
  segmentReducer,
  valueChainReducer,
  tagReducer,
  userAlertReducer,
  aiReducer
})

function delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// clean up storage on logout
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
  if (action.type === authActionTypes.LOGOUT) {
      // console.log('removing persisted state')
      // sessionStorage.removeItem('persist:root')

      // clear up cookies
      // delete_cookie('access_token_cookie'); // due to HttpOnly it will not be deleted
      // delete_cookie('csrf_access_token');

      // clear local and session storage 
      // localStorage.clear();
      // sessionStorage.clear();

      // return appReducer(undefined, action);
      return appReducer(state, action);  // to update loading properly, otherweise loadings get negative
  }
  return appReducer(state, action);
};

export default rootReducer;
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";

function useRelativeTime(timestamp) {
  const { t } = useTranslation(['common']);
  const [relativeTime, setRelativeTime] = useState('');

  const updateRelativeTime = () => {
    const now = moment();
    const created = moment(timestamp);

    const secondsDiff = now.diff(created, 'seconds');
    const minutesDiff = now.diff(created, 'minutes');
    const hoursDiff = now.diff(created, 'hours');
    const daysDiff = now.diff(created, 'days');

    if (secondsDiff < 60) {
      setRelativeTime(t('just now'));
    } else if (minutesDiff < 60) {
      setRelativeTime(`${t('pre1','')} ${minutesDiff} ${t('minutes ago')}`);
    } else if (hoursDiff < 24) {
      setRelativeTime(`${t('pre1','')} ${hoursDiff} ${t('hours ago')}`);
    } else if (daysDiff === 1) {
      setRelativeTime(`${t('yesterday at')} ${created.format('HH:mm')}`);
    } else if (daysDiff <= 7) {
      setRelativeTime(`${t('pre1','')} ${daysDiff} ${t('days ago')}`);
    } else {
      setRelativeTime(created.format('DD.MM.YYYY'));
    }
  };

  useEffect(() => {
    updateRelativeTime();
    const interval = setInterval(updateRelativeTime, 60000);
    return () => clearInterval(interval);
  }, [timestamp, t]);

  return relativeTime;
}

export default useRelativeTime;

import { instance as axios } from 'ajax';
import { batch } from 'react-redux';

import Cookies from 'js-cookie';
import { loadingActions } from './loading.actions';
import { alertActions } from './alert.actions';
import { projectActionTypes as types } from 'constants/project.action.types';
import { toCamelCase, toSnakeCase } from 'utils/snake-converter'
import { parseItem } from 'utils/session-storage';

export function createProject(title, desc, links) {

    
    let community_id = null
    if (parseItem('community')?.id) {
        community_id = parseItem('community').id
    }

    return dispatch => {
        const config = {
            headers: {'Content-Type': 'application/json',
                       'X-CSRF-TOKEN': Cookies.get('csrf_access_token')},
            params: { community_id: community_id }
        };

        const body = JSON.stringify({ title, desc, links });

        batch(() => {
            dispatch(request());
            dispatch(loadingActions.start());
        });

        return axios
            .post('/api/v1/projects', toSnakeCase(body), config)
            .then(
                response => {
                    batch(() => {
                        // dispatch(success(response.data));
                        dispatch(alertActions.success(response.data));
                        dispatch(loadingActions.finish());
                    });

                    return response;
                },
                error => {
                    batch(() => {
                        dispatch(failure(error));
                        dispatch(loadingActions.finish());
                    });
                    
                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            );
    };

    function request(payload) { return { type: types.CREATE_PROJECT_REQUEST, payload: payload } }
    function success(response) { return { type: types.CREATE_PROJECT_SUCCESS, payload: toCamelCase(response) } }
    function failure(error) { return { type: types.CREATE_PROJECT_FAILURE, payload: error } }

};
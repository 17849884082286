import { DotsVerticalIcon, DotsHorizontalIcon } from '@heroicons/react/solid'

/**
 * 
 * @param {*} param0 
 * @returns Dotmenu that can be shown horizontal and vertical, default is vertical
 */
function DotMenu({ className, onClick, buttonStyle, color, alignment }) {

    return (
        <div id='dotMenu' className={`${className} flex-shrink-0 pr-2`}>
            <button
                onClick={onClick}
                type="button"
                className={`${buttonStyle}
                ${color || 'text-gray-400 hover:text-gray-500'}
                w-5 h-5 
                inline-flex items-center justify-center 
                rounded-full bg-transparent 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
                <span className="sr-only">Open options</span>
                {alignment === 'horizontal'
                    ? <DotsHorizontalIcon className="w-5 h-5" aria-hidden="true" />
                    : <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                }
            </button>
        </div>
    );
}

export default DotMenu;
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from "@fortawesome/free-solid-svg-icons";
import CommentList from './CommentList';
import NewCommentInput from './NewCommentInput';
import { useTranslation } from "react-i18next";
import useRelativeTime from 'hooks/useRelativeTime';
import CommentProvider from './context/CommentProvider';
import SortOption from './SortOption';
import PropTypes from 'prop-types'
import { CloseButton } from 'components/Elements/CloseButton';

/**
 * Maincomponent for Comments 
 * Displays comments
 * @param {active, onClose} props 
 * @returns 
 */
function CommentOverlay({
    active,
    onClose,
    profile,
    parentId,
}) {
    const { t } = useTranslation(['common']);
    const [lastRefreshtime, setLastRefreshtime] = useState()
    const lastRefreshedTime = useRelativeTime(lastRefreshtime)

    // Get initial loading timestamp
    useEffect(() => {
        const now = new Date();
        setLastRefreshtime(now.toISOString())
    }, [])

    if (active) {
        return (
            <CommentProvider profile={profile} parentId={parentId}>
                <div className='flex flex-col h-full bg-white'>
                    <CloseButton onClose={onClose}/>
                    {/* Comment area header */}
                    <div className='flex-none'>
                        <span>{t('Comments')}</span>
                        <span className='ml-4 text-xs'>{t('Last Refresh')} {lastRefreshedTime}</span>
                    </div>
                    {/* Comment input */}
                    <div className='flex-none py-2'>
                        <NewCommentInput />
                    </div>
                    {/* Sort option */}
                    <div className='flex-none'>
                        <SortOption />
                    </div>
                    {/* Commentlist */}
                    <div className='flex-grow overflow-y-auto pr-1 mt-1'>
                        <CommentList className='mt-2' />
                    </div>
                </div>
            </CommentProvider>
        );
    } else {
        return null;
    }
}

CommentOverlay.propTypes = {
    active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    parentId: PropTypes.string.isRequired,
}

export default CommentOverlay;
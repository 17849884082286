import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faGears } from "@fortawesome/free-solid-svg-icons";
import { faFileClipboard, faThumbsUp } from "@fortawesome/free-regular-svg-icons";

import FormContainer from "components/FormContainer";
import GuideForm from "components/Guide/GuideForm";
import TooltipBase from "components/TooltipBase";
import Card from "components/Card";
import CardGrid from "components/CardGrid";

import ProblemContext from "../context/ProblemContext";

const iconClass = "w-10 h-10 mx-auto"

function ProblemRatingForm() {
    const [guideActive, setGuideActive] = useState(false);
    const [hoverInfo, setHoverInfo] = useState(false);
    const [hoverContent, setHoverContent] = useState();
    const { t } = useTranslation();
    const text = {
        headline: t('1-pr-hl', "Problem Rating"),
        description: t('1-pr-desc', "How would you rate your problem?"),
    }
    const rating = [
        {
            name: 'EASY',
            title: t('1-pr-r1t', 'Easy'),
            text: t('1-pr-r1d', 'Environment is clear, outcome is (almost) certain'),
            info: t('1-pr-explanation1', 'E.g.\n Emergency start your car according to a manual'),
            icon: <FontAwesomeIcon icon={faThumbsUp} className={iconClass} />
        },
        {
            name: 'COMPLICATED',
            title: t('1-pr-r2t', 'Complicated'),
            text: t('1-pr-r2d', 'Environment and/or potential outcomes are uncertain to a medium degree'),
            info: t('1-pr-explanation2', 'E.g.\n Perform maintenance on a power plant'),
            icon: <FontAwesomeIcon icon={faGears} className={iconClass} />
        },
        {
            name: 'COMPLEX',
            title: t('1-pr-r3t', 'Complex'),
            text: t('1-pr-r3d', 'Environment and/or potential outcomes are uncertain to a high degree'),
            info: t('1-pr-explanation3', 'E.g.\n Developing a car for a new market'),
            icon: <FontAwesomeIcon icon={faFileClipboard} className={iconClass} />
        },
        {
            name: 'CHAOTIC',
            title: t('1-pr-r4t', 'Chaotic'),
            text: t('1-pr-r4d', 'Environment and potential outcomes are uncertain to an extremely high degree'),
            info: t('1-pr-explanation4', 'E.g.\n Respond to lockdown of car production'),
            icon: <FontAwesomeIcon icon={faBolt} className={iconClass} />
        },
    ]

    //TODO English Guide is missing
    const guideText = [
        { header: t('1-pr-g1h', ''), text: t('1-pr-g1t', '') },
        { header: t('1-pr-g2h', ''), text: t('1-pr-g2t', '') },
    ]

    const ctx = useContext(ProblemContext)
    let activeCard = ctx.data.rating;

    let [hoverPosition, setHoverPosition] = useState()
    const hoverHandler = (e, index) => {
        const mousePosition = e.clientY;
        // Handles tooltip hight according to mouse position
        const position = mousePosition < 455 ? 'top-6' : 'top-60';
        setHoverPosition(position);
        setHoverInfo(true);
        setHoverContent(rating[index].info);
    }

    const hoverEndHandler = () => {
        setHoverInfo(false);
    }

    const handleClick = (index) => {
        if (activeCard === index) {
            ctx.itemHandler("rating", undefined);
        } else {
            ctx.itemHandler("rating", index);
        }
    }


    return (
        <div>
            <GuideForm guideContent={guideText} active={guideActive} onClose={() => setGuideActive(!guideActive)} />
            <FormContainer openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                title={text.headline}>
                <div className="relative">
                    {hoverInfo &&
                        <div className={`absolute left-full ${hoverPosition}`}>
                            <TooltipBase>
                                {hoverContent}
                            </TooltipBase>
                        </div>}
                    <label className="text-base font-medium text-gray-700 select-none">{text.description}</label>
                    <CardGrid>
                        {rating.map((item, index) => (
                            <Card
                                key={index + item.title}
                                title={item.title} text={item.text} icon={item.icon}
                                index={index}
                                active={activeCard === item.name ? true : false}
                                onMouseEnter={(e) => hoverHandler(e,index)}
                                onMouseLeave={hoverEndHandler}
                                onClick={() => handleClick(item.name)}
                            />
                        ))}
                    </CardGrid>
                </div>
            </FormContainer>
        </div>
    );
}

export default ProblemRatingForm;
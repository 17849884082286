import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated },
  ...rest
}) => {

  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login?r=${location.pathname}${location.search}`} />
        )
      }
    />
  )
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return ({
    auth: state.authReducer
  })
};

export default connect(mapStateToProps, null)(PrivateRoute);

import { React, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

import Container from 'components/Container';

import ProblemOverview from 'pages/ProblemOverviewPage';
import IssuesList from 'pages/IssuesListPage';
import ProblemStructure from 'pages/CreateProblemStructure';

function ProblemTabOverviewPage(props) {
    const { t } = useTranslation();

    const location = useLocation();
    const query = location.search.replace('?id=', '');
    const { problemId } = useParams();

    const [tabs, setTabs] = useState([
        { name: t('2-probdef', 'Problem Definition'), href: '?id=problemDefinition', id: 'problemDefinition', count: 1, component: <ProblemOverview problemId={problemId} /> },
        { name: t('2-probstru', 'Problem Structure'), href: '?id=problemStructure', id: 'problemStructure', count: 1, component: <ProblemStructure problemId={problemId} /> },
        { name: t('2-iss', 'Issues'), href: '?id=issues', id: 'issues', count: 4, component: <IssuesList /> },
        { name: t('2-app', 'Approaches'), href: '?id=approaches', count: 0, id: 'approaches' },
        { name: t('2-decspa', 'Decision Space'), href: '?id=decisionSpace', count: 0, id: 'decisionSpace' },
        { name: t('2-sum', 'Executive Summary'), href: '?id=communicate', count: 0, id: 'communicate' },
        { name: 'Links', href: '?id=links', id: 'links' },
    ]);

    const problemTitle = props?.problem?.title || ''
    const [activeTab, setActiveTab] = useState(query || 'problemDefinition');

    const onClickHandler = (e, index) => {
        // e.preventDefault();
        setActiveTab(location.search.replace('?id=', ''))
    }

    // const updateNewCommentCount = (id, newCommentCount) => {
    //     let tempList = [...tabs];
    //     const index = tempList.map(tab => tab.id).indexOf(id);
    //     let currentTab = {
    //         ...tempList[index],
    //         newCommentCount: newCommentCount
    //     }
    //     tempList[index] = currentTab;
    //     setTabs(tempList);
    // }   

    return (
        <Container id='problemOverviewTabs'>
            <span className='text-xl leading-7'>{problemTitle}</span>
            <ul className="nav nav-tabs flex flex-col space-x-0.5 md:flex-row flex-wrap list-none border-b-0 pl-0 mt-3" id="tabs-tab"
                role="tablist">
                {tabs.map((tab, index) => (
                    <li key={tab.id} className="nav-item" role="presentation">
                        <a
                            className={`
                                ${(activeTab === tab.href.replace('?id=', ''))
                                    ? "border-b-2 border-primary-600 bg-primary-50"
                                    : "hover:border-transparent focus:border-transparent border-transparent"}
                                block font-medium text-base sm:text-sm
                            border-x-0 border-t-0  
                            px-5 py-3 rounded-t-lg focus:outline-none
                             hover:bg-gray-100
                            `}
                            href={location.pathname + tab.href}
                            onClick={(e) => onClickHandler(e, index)}>
                            {tab.name}

                            {tab.count ? (
                                <span
                                    className={`
                                        ${tab.current ? 'bg-indigo-100 text-primary-600' : 'bg-gray-100 text-gray-900'} 
                                            hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`}>
                                    {tab.count}
                                </span>
                            ) : null
                            }
                        </a>
                    </li>
                ))}
            </ul>
            <div>{tabs.map((tab, index) => (
                (activeTab === tab.href.replace('?id=', '')) ? tab.component : null
            ))}
            </div>
        </Container>
    );
}

// TODO: check prop types missing

const mapStateToProps = (state) => ({
    problem: state.problemReducer.problem
});

export default connect(mapStateToProps, null)(ProblemTabOverviewPage);

import { useContext } from "react";
import TreeContext from "./context/tree-context";

import PercentageBar from 'components/PercentageBar';
import SidePanel from "components/SidePanel";

// TODO Translation
function OverviewPanel() {
    const ctx = useContext(TreeContext);
    const baseline = ctx.getBaselineSum(ctx.data);
    const estimate = ctx.getEstimateSum(ctx.data);
    const target = 500;
    const arr = [baseline, target, estimate];
    const max = Math.max(...arr)
    const factorCount = ctx.data.children?.length;
    const numberStyle = ' font-bold'

    const calcBar = (value) => {
        if (value === max) {
            return 100
        } else {
            return value / max * 100;
        };
    }

    const estimateColor = () => {
        if (target < baseline) {
            return estimate <= target;
        } else {
            return estimate >= target;
        }
    }

    return (
        <SidePanel>
            <p className="text-lg font-medium leading-6 text-gray-900 select-none">
                Total Factor Contribution
            </p>
            <p className="text-sm font-medium text-gray-500 mb-4 select-none">
                Calculated from {factorCount} factors
            </p>
            <p>Baseline: <span className={numberStyle}>{baseline}</span></p>
            <PercentageBar number={calcBar(baseline)} />
            <p>Target: <span className={numberStyle}>{target}</span></p>
            <PercentageBar number={calcBar(target)} />
            <p>Estimate <span className={numberStyle}>{estimate}</span></p>
            <PercentageBar
                color={estimateColor() ? 'bg-green-600' : 'bg-red-600'}
                number={calcBar(estimate)} />
        </SidePanel>
    );
}

export default OverviewPanel;

const parseRedirectLink = (string) => {

    let destination = null

    const pattern = /\?r=(?<destination>[a-zA-Z0-9\-\.\/\?=]+)$/

    if (pattern.test(string)) {
        let match = string.match(pattern)
        destination = match.groups.destination
    }

    return destination
}

export { parseRedirectLink }
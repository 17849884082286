import { useState, useEffect } from "react";
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageWrapper from "components/PageWrapper";
import FormContainer from "components/FormContainer";

import DisplayProfile from "./DisplayProfile";
import { getCommunityById } from 'actions/community.get.actions';
import { getPublicProfileById } from 'actions/public-profile.get.actions';
import { parseItem } from 'utils/session-storage';
import { translateItemWithChildren as translateItem } from "utils/translate-item-with-children";

/**
 * @returns Displays the profile for all other users
 */
function PublicUserProfilePage({
    organisations,
    communities,
    profile,
    publicProfile,
    getPublicProfileById,
    segments,
    valueChains
}) {
    const { i18n } = useTranslation();
    const history = useHistory();
    const { userId } = useParams();

    const [userData, setUserData] = useState({
        firstName: null,
        lastName: null,
        email: null,
        organisation: null,
        position: null,
        interestTags: [],
        segmentSelected: [],
        valueChainSelected: [],
        problemMatches: [], // not shown
        ownProblems: [],
        userCommunities: []
    });
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [organisationRole, setOrganisationRole] = useState(null);
    const [communityRole, setCommunityRole] = useState(null);
    const [myOrganisations, setMyOrganisations] = useState([]);
    const [myCommunities, setMyCommunities] = useState([]);
    const [memberOfOrganisations, setMemberOfOrganisations] = useState([]);
    const [memberOfCommunities, setMemberOfCommunities] = useState([]);

    // organisation role
    useEffect(() => {
        if (parseItem('organisation')?.role) {
            setOrganisationRole(parseItem('organisation')?.role)
        }
    }, [sessionStorage.getItem('organisation')]);

    // community role
    useEffect(() => {
        if (parseItem('community')?.role) {
            setCommunityRole(parseItem('community')?.role)
        }
    }, [sessionStorage.getItem('community')]);

    useEffect(() => {
        // bypassing must not be possible
        setIsAdmin(false)
        if (communityRole === 'OWNER' || communityRole === 'ADMIN') {
            setIsAdmin(true)
        }

        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN') {
            setIsAdmin(true)
        }
    }, [communityRole, organisationRole])

    useEffect(() => {
        getPublicProfileById(userId)
    }, [userId]);

    useEffect(() => {
        if (profile && publicProfile) {
            if (userId === profile.userId && userId === publicProfile.userId) {
                history.push('/profiles/me')
            }
        }
    }, [userId, profile, publicProfile]);

    useEffect(() => {
        if (publicProfile) {
            setUserData({
                firstName: publicProfile?.firstName || null,
                lastName: publicProfile?.lastName || null,
                email: publicProfile?.email || null,
                organisation: publicProfile?.organisation || null,
                position: publicProfile?.position || null,
                interestTags: publicProfile?.interestTags || [],
                segmentSelected: publicProfile?.segments || [],
                valueChainSelected: publicProfile?.valueChains || [],
                problemMatches: [], // not shown
                ownProblems: publicProfile?.ownProblems || [],
                userCommunities: publicProfile?.userCommunities || []
            })
        } else (
            setUserData({
                firstName: null,
                lastName: null,
                email: null,
                organisation: null,
                position: null,
                interestTags: [],
                segmentSelected: [],
                valueChainSelected: [],
                problemMatches: [], // not shown
                ownProblems: [],
                userCommunities: []
            }) 
        )
    }, [publicProfile]);

    useEffect(() => {
        if (segments) {
            const translatedSegments = segments.map(segment => translateItem(segment, i18n.language));
            setSegmentList(translatedSegments);
        }
    }, [segments, i18n.language]);

    useEffect(() => {
        if (valueChains) {
            const translatedValueChain = valueChains.map(valueChain => translateItem(valueChain, i18n.language));
            setValueChainList(translatedValueChain)
        }
    }, [valueChains, i18n.language]);

    const {
        problemMatches,
        ownProblems
    } = userData;

    useEffect(() => {
        const arr = organisations.map(o => o.id);
        setMyOrganisations(arr)
    }, [organisations]);

    useEffect(() => {
        const arr = communities.map(c => c.id);
        setMyCommunities(arr)
    }, [communities]);

    useEffect(() => {
        let members = publicProfile?.userCommunities?.map((item) => {
            if (organisations?.map(o => o.id)?.includes(item.organisationId)) {
                if(item.organisationId) {
                    return {id: item.organisationId, text: item.organisationTitle};
                }
            } else {
                if(item.organisationId) {
                    return {id: item.organisationId, text: item.organisationTitle?.replace(/[a-zA-Z0-9]/g,"x")};
                }
            }
          });

        // deduplication
        members = members?.filter((source, index, self) =>
            index === self.findIndex((target) => (
                target.id === source.id && target.text === source.text
            ))
        )

        // sorting
        members?.sort((a, b) => a.text.localeCompare(b.text))

        setMemberOfOrganisations(members)
    }, [publicProfile, organisations, myOrganisations]);

    useEffect(() => {
        let members = publicProfile?.userCommunities?.map((item) => {
            if (communities?.map(o => o.id)?.includes(item.communityId)) {
                if(item.communityId) {
                    return {id: item.communityId, text: item.communityTitle};
                }
            } else {
                if(item.communityId) {
                    return {id: item.communityId, text: item.communityTitle?.replace(/[a-zA-Z0-9]/g,"x")};
                }
            }
          });
        // deduplication
        members = members?.filter((source, index, self) =>
            index === self.findIndex((target) => (
                target && source && target.id === source.id && target.text === source.text
            ))
        )

        // sorting
        members?.sort((a, b) => a.text.localeCompare(b.text))

        setMemberOfCommunities(members)
    }, [publicProfile, communities, myCommunities]);

    return (
        <PageWrapper>
            <FormContainer width={'w-full xl:w-3/4 2xl:w-2/3'}>
                <div className="pt-11">
                    <DisplayProfile
                        userData={userData}
                        problemMatches={problemMatches}
                        ownProblems={ownProblems}
                        segmentList={segmentList}
                        valueChainList={valueChainList}
                        memberOfOrganisations={memberOfOrganisations}
                        memberOfCommunities={memberOfCommunities}
                        owner={false}
                        isAdmin={isAdmin} />
                </div>
            </FormContainer>
        </PageWrapper>
    );
}

PublicUserProfilePage.propTypes = {
    organisations: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    publicProfile: PropTypes.object.isRequired,
    getPublicProfileById: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    organisations: state.organisationReducer.organisations,
    communities: state.communityReducer.communities,
    profile: state.profileReducer.profile,
    publicProfile: state.publicProfileReducer.profile,
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains
});


export default connect(mapStateToProps, { getPublicProfileById })(PublicUserProfilePage);
import PageWrapper from "components/PageWrapper";

function DSEEng() {

    let paragraph = 1
    const address = 'whitespace-pre-line mb-4 ml-8'

    return (
        <PageWrapper>
            <div id="dse-de" className="w-7/8 lg:w-2/3 2xl:w-1/2 pr-2 pt-10">
                <div className="mb-4">
                    <p className="text-xl">Privacy Notice Website</p>
                    <p className="text-sm">in the version of April 1st, 2023</p>
                    <p className="pt-4">This English version is provided to you as a service. Only the German version is legally binding.</p>
                    <p className="text-sm underline mt-4"><a href='/dse'>German Version</a></p>

                </div>
                <p className="whitespace-pre-line mb-4 pt-4">
                    {`solverint GmbH is the Data Controller in the sense of Art. 4 No. 7 GDPR for the freely accessible areas of the website forum.solverint.com . 
                    \nIn the protected area of the website, solverint GmbH is the data processor in the sense of Art. 4 No. 8 GDPR on behalf of its respective corporate customers. 
                    \nWith this platform, the aforementioned corporate clients and their users are provided with a protected space in which relevant problems and issues can be jointly described, enriched, prioritised and further developed. This is the subject of a contract with the corporate clients.  
                    \nThe website to which this privacy notice applies is used for the registration and login of users. `}
                </p>

                <p className="font-bold">§{paragraph} Privacy</p>
                <p className="whitespace-pre-line mb-4">
                    {`We take the protection of all personal data very seriously. All personal information is treated confidentially and in accordance with legal regulations. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Purpose and legal basis of processing </p>
                <p className="whitespace-pre-line mb-4">
                    {`We process the above-mentioned collected information for the following purposes: registration or login to the website, ensuring a smooth connection of the website, ensuring a comfortable use of our website, evaluation of system security and stability, prevention and elimination of malfunctions as well as for other administrative purposes. 
                    \nYour personal data will not be transferred to third parties for purposes other than those listed below. We will only disclose your personal data to third parties if: 
                    \n(1) you have given your express consent to this in accordance with Art. 6 Para. 1 S. 1 lit. a GDPR,  
                    \n(2) the disclosure is necessary for the assertion, exercise or defence of legal claims pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR and there is no reason to assume that you have an overriding interest worthy of protection in the non-disclosure of your data,  
                    \n(3) there is a legal obligation for the disclosure pursuant to Art. 6 para. 1 sentence 1 lit. c GDPR, 
                    \n(4) this is legally permissible and necessary for the processing of contractual relationships according to Art. 6 para. 1 p. 1 lit. b GDPR. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Your rights  </p>
                <p className="whitespace-pre-line mb-4">
                    {`You have the right:
                    \n(1) to request information about your personal data processed by us in accordance with Art. 15 GDPR. In particular, you can request information about the processing purposes, the category of personal data, the categories of recipients to whom your data has been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, as well as the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details; 
                    \n(2) in accordance with Art. 16 GDPR, to request without delay the correction of incorrect or completion of your personal data stored by us; 
                    \n(3) pursuant to Article 17 of the GDPR, to request the erasure of your personal data stored by us, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest, or for the establishment, exercise or defence of legal claims; 
                    \n(4) pursuant to Art. 18 GDPR, to request the restriction of the processing of your personal data, insofar as the accuracy of the data is disputed by you, the processing is unlawful, but you object to its erasure and we no longer require the data, but you need it for the assertion, exercise or defence of legal claims or you have objected to the processing pursuant to Art. 21 GDPR; 
                    \n(5) pursuant to Art. 20 GDPR, to receive your personal data that you have provided to us in a structured, common and machine-readable format or to request that it be transferred to another controller; 
                    \n(6) in accordance with Art. 7 (3) GDPR, to revoke your consent once given to us at any time. This has the consequence that we may no longer continue the data processing based on this consent in the future; and 
                    \n(7) complain to a supervisory authority (ULD - Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein) in accordance with Art. 77 GDPR; 
                    \n(8) object to the processing of your personal data in accordance with Art. 21 GDPR, provided that there are grounds for doing so which arise from your particular situation or the objection is directed against direct advertising. In the latter case, you have a general right of objection, which will be implemented by us without specifying a particular situation. You have the option of notifying us of the objection in writing, by telephone or by e-mail. However, in accordance with the principles of this platform, direct advertising does not take place. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Server-Log-Files </p>
                <p className="whitespace-pre-line mb-4">
                    {`The site provider automatically collects and stores data in server log files that are transmitted to us by your browser. This data includes:`}</p>
                <ul className="pl-4 mb-4 list-disc">
                    <li>Browser type/ browser version</li>
                    <li>Operating system of the computer</li>
                    <li>Referrer URL</li>
                    <li>Host name of the accessing computer</li>
                    <li>Time of the server request</li>
                    <li>IP address</li>
                </ul>
                <p className="whitespace-pre-line mb-4">
                    {`This data is not merged with other data sources. If we become aware of concrete indications of illegal use, we reserve the right to check this data retrospectively. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Deletion of personal data </p>
                <p className="whitespace-pre-line mb-4">
                    {`The deletion of the data referred to in § 4 shall normally take place after thirty (30) days, unless there are conflicting retention rights or obligations. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Cookies</p>
                <p className="whitespace-pre-line mb-4">
                    {`Many websites use cookies. Cookies are harmless to your computer and virus-free. They are used to make Internet offers more friendly, effective and secure for visitors. Cookies are small text files that are stored on your computer and used by your browser. 
                    \nOur offer does not use cookies that are not technically mandatory. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Security and data storage  </p>
                <p className="whitespace-pre-line mb-4">
                    {`We implement security procedures and technical restrictions to protect your personal data from unauthorized access, destruction or modification. We will retain your data only for as long as is necessary to fulfill the purposes set forth in this Privacy Policy, unless a longer retention period is required or permitted by law.`}
                </p>

                <p className="font-bold">§{paragraph += 1} Amendments to the Privacy Policy   </p>
                <p className="whitespace-pre-line mb-4">
                    {`Our privacy policy may be amended at irregular intervals to comply with current legal requirements or to implement changes to our services. For your next visit, the new privacy policy will then automatically apply, which can be accessed in the current version at this address. `}
                </p>

                <p className="font-bold">§{paragraph += 1} Data protection officer  </p>
                <p className="whitespace-pre-line mb-4">
                    {`For questions regarding data protection, please send a message to our data protection officer:`}
                </p>
                <p className={address}>
                    Felix Kunz
                    <p>
                        E-Mail:
                        <a className="link-primary" href="mailto:f.kunz@rhein-consulting.de " target="_blank" > f.kunz@rhein-consulting.de</a>
                    </p>
                    +49 2774 924913210
                </p>
            </div>
        </PageWrapper>
    );
}

export default DSEEng;
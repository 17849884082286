import React from 'react';

//import { defaultCreateIssueState } from './CreateIssueProvider'

const CreateIssueContext = React.createContext({
    //data: defaultCreateIssueState.data,
    nextHandler: () => {},
    prevHandler: () => {},
    handleChange: (name, e) => {},
    handleObjectChange: (objectName, name, data) => {},
    itemHandler: (name, data) => {},
    rowHandler: (tablename, name, index, e) => {},
    editRowHandler: (tablename, name, index, value) => {},
    newRowHandler: (name) => {},
    deleteRowHandler: (name, id) => {},
    openOverview: () => {}
})

export default CreateIssueContext;
import PageWrapper from "components/PageWrapper";

function PrinciplesDe() {
    return (
        <PageWrapper>
            <div id="principles-de" className="w-7/8 lg:w-2/3 2xl:w-1/2 pr-2 pt-10">
                <div className="mb-4">
                    <p className="text-xl">Grundsätze</p>
                    <p className="text-sm">in der Fassung vom 01.04.2023</p>
                </div>
                <p className="whitespace-pre-line mb-4">
                    {`Mit dieser Plattform wird Institutionen und deren Nutzerinnen und Nutzern ein geschützter Raum zur Verfügung gestellt, in welchem gemeinsam relevante Probleme und Fragestellungen beschrieben, angereichert, priorisiert und weiterentwickelt werden können. 
Perspektivisch soll diese Plattform ihre Nutzerinnen und Nutzer nach hohen intellektuellen und methodischen Standards bei der gesamten Problemlösung unterstützen. 

Zur Konzentration auf diesen Zweck gelten für die Entwicklung und den Betrieb dieser Plattform die folgenden Grundsätze. `}
                </p>
                <p className="font-bold">Unabhängigkeit</p>
                <p className="whitespace-pre-line mb-4">
                    Die Plattform ist inhaltlich unabhängig. Die einzige Erlösquelle sind Lizenzgebühren der Kunden. Werbung, Sponsored Content, Affiliate Marketing oder Produkteverkauf finden nicht statt.
                </p>

                <p className="font-bold">Privacy by design</p>
                <p className="whitespace-pre-line mb-4">
                    Weitgehende Datenschutzstandards wurden von Anfang an in der Programmierung berücksichtigt. Daten werden in der Cloud ausschließlich mit Serverstandort in Deutschland gespeichert.
                </p>

                <p className="font-bold">Privacy by default </p>
                <p className="whitespace-pre-line mb-4">
                    Es werden prinzipiell datenschutzfreundliche Voreinstellungen genutzt und keinerlei Kundendaten zu Marketingzwecken gesammelt und verwendet.
                </p>

                <p className="font-bold">Vertraulichkeit</p>
                <p className="whitespace-pre-line mb-4">
                    Informationen können je nach Grad der Vertraulichkeit sicher geteilt werden. Alle Nutzerinnen und Nutzer werden auf die Wahrung der Vertraulichkeit verpflichtet.
                </p>

                <p className="font-bold">Sicherheit</p>
                <p className="whitespace-pre-line mb-4">
                    Unser Cloud-Dienstleister ist nach ISO 27001, Privacy Shield und SOC 3 zertifiziert. Sämtliche Daten sind im Ruhezustand, bei der Speicherung und bei der Übertragung verschlüsselt. Die Plattform kann nur über HTTPS erreicht werden.
                </p>


            </div>
        </PageWrapper>
    );
}

export default PrinciplesDe;
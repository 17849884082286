import InputSwitch from "./InputSwitch";

function ValueTile(props) {
    return (
        <div>
            <div className="text-xs text-primary-700">{props.name}</div>
            <div className="flex items-end">
                <InputSwitch className={'w-16 inline-block text-right pr-1'}
                    value={props.value} editActive={props.editActive} 
                    onChange={props.onChange}
                    type={props.type}/>
                <span className="text-xs pl-1 pr-2 pb-1">{props.label}</span>
            </div>
        </div>
    );
}

export default ValueTile;
/**
 * @param {Object[]} options 
 * @param {string} value  
 * @param {function}  onChange
 * @returns General dropdown component
 */
function Dropdown({ options, value, onChange }) {

    return (
        <select
            className="border p-1 px-3 rounded w-32 border-gray-400"
            onChange={onChange}
            value={value}
        >
            {options.map((opt) => (
                <option
                    key={opt.value}
                    value={opt.value}>
                    {opt.label}
                </option>
            ))}
        </select>
    );
}

export default Dropdown;
import { DotsHorizontalIcon } from '@heroicons/react/solid'

function HorizontalDotMenu(props) {
    return (
        <div id='horizontalDotMenu' className={`${props.className} flex-shrink-0 pr-2`}>
            <button
                onClick={props.onClick}
                type="button"
                className={`${props.buttonStyle}
                ${props.color || 'text-gray-400 hover:text-gray-500'}
                w-5 h-5 
                inline-flex items-center justify-center 
                rounded-full bg-transparent 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
                <span className="sr-only">Open options</span>
                <DotsHorizontalIcon className="w-5 h-5" aria-hidden="true" />
            </button>
        </div>
    );
}

export default HorizontalDotMenu;
import { useState, useEffect, useContext } from 'react';
import CircularAvatarWithInitials from 'components/avatar/CircularAvatarWithInitials';
import CommentInput from './CommentInput';
import { CommentContext } from './context/CommentContext';

/**
 * New Comment Component
 */
function NewCommentInput({
  replyToObj,
  parentHandleSubmit,
  parentReply
}) {
  const { profile, updateCommentList } = useContext(CommentContext)
  const [initials, setInitials] = useState('NA')

  useEffect(() => {
    if (profile?.firstName && profile?.lastName) {
      setInitials(`${profile.firstName.charAt(0)}${profile.lastName.charAt(0)}`)
    }
  }, [profile]);


  const handleSubmit = (comment) => {
    if (comment) {
      updateCommentList(comment);
      parentHandleSubmit?.();
    }
  }

  return (
    <div id='newCommentInput' className="flex items-start space-x-2 z-50">
      <div className="flex-shrink-0">
        <CircularAvatarWithInitials size="8" >{initials || 'NA'}</CircularAvatarWithInitials>
      </div>
      <CommentInput
        replyToObj={replyToObj}
        handleSubmit={handleSubmit}
        userId={profile?.userId}
        parentReply={parentReply} />
    </div>
  );
}

export default NewCommentInput;
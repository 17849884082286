const issueActionTypes = {
    GET_ISSUE_REQUEST: 'GET_ISSUE_REQUEST',
    GET_ISSUE_SUCCESS: 'GET_ISSUE_SUCCESS',
    GET_ISSUE_FAILURE: 'GET_ISSUE_FAILURE',

    CREATE_ISSUE_REQUEST: 'CREATE_ISSUE_REQUEST',
    CREATE_ISSUE_SUCCESS: 'CREATE_ISSUE_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    CREATE_ISSUE_FAILURE: 'CREATE_ISSUE_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { issueActionTypes };
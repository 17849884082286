
import React, { useState, useEffect } from 'react';
import Modal from "components/Modal";
import ToggleElement from "components/ToggleElement";
import { useTranslation } from "react-i18next";

/**
 * Modal for settings for 'new problem'
 */
function ProblemSettingsModal({ ctx, id, config, handleSave }) {
    const { t, i18n } = useTranslation(['problemDefinition', 'common']);

    const [problemSettings, setProblemSettings] = useState([])
    useEffect(() => {
        if (config) {
            setProblemSettings([
                { id: 'isProblemTitle', label: t('1-ps-title','Title & Topic'), enabled: true, mandatory: true },
                { id: 'isProblemStatement', label: t('1-ps-prompt','Topic & State'), enabled: true, mandatory: true },
                { id: 'isProblemVisibility', label: t('1-ov-visib','Visibility'), enabled: true, mandatory: true },
                { id: 'isProblemDocuments', label: t('1-ps-docs','Related Documents'), enabled: config.isProblemDocuments, mandatory: false }, // TODO db settings
                { id: 'isProblemLinks', label: t('1-ps-linkList','Related Links'), enabled: config.isProblemLinks, mandatory: false },
                { id: 'isProblemSegments', label: t('area1','Market Segments'), enabled: config.isProblemSegments, mandatory: false },
                { id: 'isProblemValueChains', label: t('area2','Value Chain'), enabled: config.isProblemValueChains, mandatory: false },
                { id: 'isProblemTags', label: t('1-ps-tags','Tags'), enabled: config.isProblemTags, mandatory: false },
                { id: 'isProblemSituation', label: t('1-sc-sit','Situation'), enabled: config.isProblemSituation, mandatory: false },
                { id: 'isProblemComplications', label: t('1-sc-comp','Complications'), enabled: config.isProblemComplications, mandatory: false }
            ])
        }
    }, [config]);

    const setEnabled = (id, enabled) => {
        const currentIndex = problemSettings.findIndex(item => item.id === id);
        const updatedItem = { ...problemSettings[currentIndex], enabled: !enabled };
        const updatedProblemSettings = [...problemSettings];
        updatedProblemSettings[currentIndex] = updatedItem;
        setProblemSettings(updatedProblemSettings);
        ctx.itemHandler('config', updatedProblemSettings.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.enabled }), {}))
    }

    return (
        <Modal
            id={id}
            title={t('New Topic Settings')}
            onClose={handleSave}
            backdrop={false}
            closeText={t('Save and Close')}>

            {problemSettings && problemSettings.map((item) => (
                <div key={item.id} className="py-1">
                    <ToggleElement id={item.id} leftLabel={item.label} activated={item.enabled} mandatory={item.mandatory} toggleState={setEnabled} />
                </div>
            ))}
        </Modal>
    );
}

export default ProblemSettingsModal;
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import CardGrid from "components/CardGrid";
import Card from "components/Card";
import ChangeWithinTechniqueSvg from '../Images/svg/Folie35-ChangeWithinTechnique.SVG';
import Button from "components/Button";

import Modal from "components/Modal";

import NewTechniqueSvg from '../Images/svg/Technikwechsel_ENG.svg';

// TODO Translation
// TODO Image Translation
function Step7ChangeYourLense() {
    const { t } = useTranslation();
    const text = {
        pageTitle: t('Consider changing your lense by drawing and saving another structure'),
        card1Title: t('Same technique'),
        card1Text: t(`Use, e.g., different logical dimensions within one technique in order to identify blind spots`),
        card2Title: t('New technique'),
        card2Text: t(`Try another technique that might be suitable – you can save both of them`),
    }

    // Download Buttons for Modals
    const modalButton1 = <a id="a-DownloadSameTech" href={ChangeWithinTechniqueSvg} download='SameTechnique'>
        <Button>{t('Download')}</Button></a>
    const modalButton2 = <a id="a-DownloadNewTech" href={NewTechniqueSvg} download='NewTechnique'>
        <Button>{t('Download')}</Button></a>

    return (
        <div id="changeLense">
            <Modal id='changeWithinTechnique' title={text.card4Title} size='modal-xl'
                buttons={modalButton1}>
                <img src={ChangeWithinTechniqueSvg} />
            </Modal>
            <Modal id='newTechnique' title={text.card4Title} size='modal-xl'
                buttons={modalButton2}>
                <img src={NewTechniqueSvg} />
            </Modal>
            <FormContainer
                title={text.pageTitle}
                width="lg:w-2/3 w-5/6">
                <CardGrid gridCol="grid-cols-1 md:grid-cols-2">
                    <Card title={text.card1Title} text={text.card1Text} justify='justify-top'
                        databstoggle='modal' databstarget='#changeWithinTechnique'>
                        <img className="w-full md:w-3/4 mx-auto"
                            src={ChangeWithinTechniqueSvg} />
                    </Card>
                    <Card className="h-full" title={text.card2Title} text={text.card2Text} justify='justify-top'
                        databstoggle='modal' databstarget='#newTechnique'>
                        <img className="w-full md:w-3/4 mx-auto"
                            src={NewTechniqueSvg} />
                    </Card>
                </CardGrid>
            </FormContainer>
        </div>
    );
}

export default Step7ChangeYourLense;
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faPlus } from "@fortawesome/free-solid-svg-icons";

import CircularAvatarWithInitials from 'components/avatar/CircularAvatarWithInitials';

import { getInitials } from 'utils/get-initials';
import convertToUTCString from 'utils/datetime';
import { timeAgo } from 'utils/time-ago';
import Dropdown from "./Dropdown";
import { updateOrganisationById } from 'actions/organisation.update.actions';
import { updateCommunityById } from 'actions/community.update.actions';
import { updateProjectById } from 'actions/project.update.actions';
import { updateProblemById } from 'actions/problem.update.actions';
import { parseItem } from 'utils/session-storage';
import { isHaveGreaterOrSameRole } from 'utils/roles-comparator';
import LabelWithInfoHover from './LabelWithInfoHover';

/**
 * @param {Object[]} users - An array of user objects, each containing user information.
 * @param {boolean} showOrganisationRoles - Indicates whether the organization feature is active.
 * @param {boolean} showCommunityRoles - Indicates whether the community feature is active.
 * @param {string} organisationRole - Organisation role
 * @param {string} communityRole - Community role
 * @param {string} projectRole - Project role
 * @returns Table of the users
 */
function TeamTable({
    users,
    showOrganisationRoles,
    showCommunityRoles,
    showProjectRoles,
    showProblemRoles,
    showStatus,
    showCreatedAt,
    showLastLogin,
    showAddButton,
    projectRole,
    problemRole,
    updateOrganisationById,
    updateCommunityById,
    updateProjectById,
    updateProblemById,
    addUser,
    profile
}) {

    const { t } = useTranslation()
    const { projectId, problemId } = useParams();

    const thClass = 'sm:pl-0 md:pl-3 py-3.5 text-sm font-semibold text-gray-900 text-left'
    const tdClass = 'sm:pl-0 md:pl-3 whitespace-nowrap py-5 text-sm text-gray-500'

    const [isCommunityAdmin, setIsCommunityAdmin] = useState(false);
    const [usersArr, setUsersArr] = useState(users);
    const [organisationRole, setOrganisationRole] = useState(null);
    const [communityRole, setCommunityRole] = useState(null);
    const [dropdownOptionsOrganisationRole, setDropdownOptionsOrganisationRole] = useState([]);
    const [dropdownOptionsCommunityRole, setDropdownOptionsCommunityRole] = useState([]);
    const [dropdownOptionsProjectRole, setDropdownOptionsProjectRole] = useState([]);
    const [dropdownOptionsProblemRole, setDropdownOptionsProblemRole] = useState([]);

    const text = {
        organisationRoleTooltip: t('organisation-role-tooltip', `The owner can grant administrators.\n
        Owners and administrators can create their own content and manage third-party content.`),
        communityRoleTooltip: t('community-role-tooltip', `The owner can grant administrators.\n
        Owners and administrators can create their own content and manage third-party content.\n
        Editors can view, create and comment on topics in all clusters.\n
        Viewers only view the content`),
        projectRoleTooltip: t('project-role-tooltip', `The owner can grant administrators.\n
        Owners and administrators can create their own content and manage third-party content.\n
        Editors can view, create and comment on topics within the cluster.\n
        Viewers only view the content`),
        problemRoleTooltip: t('problem-role-tooltip', `The owner can grant administrators.\n
        Owners and administrators can create their own content and manage third-party content.\n
        Editors can view and comment on the topic.\n
        Viewers only view the content`)
    }

    useEffect(() => {
        setOrganisationRole(parseItem('organisation')?.role)
        setCommunityRole(parseItem('community')?.role)
    }, [sessionStorage.getItem('organisation'), sessionStorage.getItem('community')])

    useEffect(() => {
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN' || communityRole === 'OWNER' || communityRole === 'ADMIN') {
            setIsCommunityAdmin(true)
        } else {
            setIsCommunityAdmin(false)
        }
    }, [organisationRole, communityRole])

    useEffect(() => {
        setUsersArr(users)
    }, [users])

    useEffect(() => {
        switch (organisationRole) {
            case 'OWNER':
                setDropdownOptionsOrganisationRole([
                    { value: 'ADMIN', label: t('ADMIN') },
                    { value: 'NONE', label: t('NONE') },
                ])
                break;
            case 'ADMIN':
                setDropdownOptionsOrganisationRole([
                    { value: 'ADMIN', label: t('ADMIN') },
                    { value: 'NONE', label: t('NONE') },
                ])
                break;
            default:
                setDropdownOptionsOrganisationRole([])
        }
    }, [organisationRole])

    useEffect(() => {
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN') {
            setDropdownOptionsCommunityRole([
                { value: 'ADMIN', label: t('ADMIN') },
                { value: 'EDITOR', label: t('EDITOR') },
                { value: 'VIEWER', label: t('VIEWER') },
                { value: 'NONE', label: t('NONE') },
            ])
        } else {
            switch (communityRole) {
                case 'OWNER':
                    setDropdownOptionsCommunityRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                case 'ADMIN':
                    setDropdownOptionsCommunityRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                default:
                    setDropdownOptionsCommunityRole([])
            }
        }
    }, [organisationRole, communityRole])

    useEffect(() => {
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN' || communityRole === 'OWNER' || communityRole === 'ADMIN') {
            setDropdownOptionsProjectRole([
                { value: 'ADMIN', label: t('ADMIN') },
                { value: 'EDITOR', label: t('EDITOR') },
                { value: 'VIEWER', label: t('VIEWER') },
                { value: 'NONE', label: t('NONE') },
            ])
        } else {
            switch (projectRole) {
                case 'OWNER':
                    setDropdownOptionsProjectRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                case 'ADMIN':
                    setDropdownOptionsProjectRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                default:
                    setDropdownOptionsProjectRole([])
            }
        }
    }, [organisationRole, communityRole, projectRole])

    useEffect(() => {
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN' || communityRole === 'OWNER' || communityRole === 'ADMIN' || projectRole === 'OWNER' || projectRole === 'ADMIN') {
            setDropdownOptionsProblemRole([
                { value: 'ADMIN', label: t('ADMIN') },
                { value: 'EDITOR', label: t('EDITOR') },
                { value: 'VIEWER', label: t('VIEWER') },
                { value: 'NONE', label: t('NONE') },
            ])
        } else {
            switch (problemRole) {
                case 'OWNER':
                    setDropdownOptionsProblemRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                case 'ADMIN':
                    setDropdownOptionsProblemRole([
                        { value: 'ADMIN', label: t('ADMIN') },
                        { value: 'EDITOR', label: t('EDITOR') },
                        { value: 'VIEWER', label: t('VIEWER') },
                        { value: 'NONE', label: t('NONE') },
                    ])
                    break;
                default:
                    setDropdownOptionsProblemRole([])
            }
        }
    }, [organisationRole, communityRole, projectRole, problemRole])

    const onRoleChange = (user, action, id, role) => {

        let updatedUsers = [...usersArr]
        const userIndex = updatedUsers.findIndex(
            (u) => u.userId === user.userId
        )
        updatedUsers[userIndex] = { ...user, ...role }
        action(id, { users: updatedUsers })
        setUsersArr(updatedUsers)

        // updating role, saved in session on the fly
        if (user.userId === profile.userId) {
            if ('organisationRole' in role && parseItem('organisation')?.role != role.organisationRole) {
                sessionStorage.setItem('organisation', JSON.stringify({ ...parseItem('organisation'), role: role.organisationRole }))
            } else if ('communityRole' in role && parseItem('community')?.role != role.communityRole) {
                sessionStorage.setItem('community', JSON.stringify({ ...parseItem('community'), role: role.communityRole }))
            } /*else if ('projectRole' in role && parseItem('project')?.role != role.projectRole) {
                sessionStorage.setItem('project', JSON.stringify({...parseItem('project'), role: role.projectRole}))
            } else if ('problemRole' in role && parseItem('problem')?.role != role.problemRole) {
                sessionStorage.setItem('problem', JSON.stringify({...parseItem('problem'), role: role.problemRole}))
            }*/
        }
    }

    // const removeUser = (user) => {
    //     updateCommunityById()
    // }

    return (
        <div id='teamTable' className="mt-8 flow-root">
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th className={thClass}>#</th>
                                <th className={thClass}>{t('Name')}</th>
                                <th className={thClass}>Organisation / Position</th>
                                {showOrganisationRoles && <th className={thClass}><LabelWithInfoHover label={t('Organisation Role')} tooltip={text.organisationRoleTooltip} /></th>}
                                {showCommunityRoles && <th className={thClass}><LabelWithInfoHover label={t('Community Role')} tooltip={text.communityRoleTooltip} /></th>}
                                {showProjectRoles && <th className={thClass}><LabelWithInfoHover label={t('Cluster Role')} tooltip={text.projectRoleTooltip} /></th>}
                                {showProblemRoles && <th className={thClass}><LabelWithInfoHover label={t('Topic Role')} tooltip={text.problemRoleTooltip} /></th>}
                                {isCommunityAdmin && showStatus && <th className={thClass}>{t('Status')}</th>}
                                {isCommunityAdmin && showCreatedAt && <th className={thClass}>{t('Created at')}</th>}
                                {showLastLogin && <th className={thClass}>Last login</th>}
                                {/* {isCommunityAdmin && <th className={thClass}>{t('Remove User')}</th>} */}
                                {showAddButton && isCommunityAdmin && <th className={thClass}></th>}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {usersArr && usersArr.map((user, i) => (
                                <tr key={user.email}>
                                    <td className={tdClass}>{i + 1}</td>
                                    {/* Name, Email */}
                                    <td className={tdClass}>
                                        <a href={`/profiles/${user.userId}`}>
                                            <div className="flex items-center">
                                                <div className="h-11 w-11 flex-shrink-0">
                                                    <CircularAvatarWithInitials size="8">{getInitials(user)}</CircularAvatarWithInitials>
                                                </div>
                                                <div className="ml-4">
                                                    <div className="font-medium text-gray-900">{`${user.firstName} ${user.lastName}`}</div>
                                                    {isCommunityAdmin && <div className="mt-1 text-gray-500">{user.email}</div>}
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    {/* Organisation / Position */}
                                    <td className={tdClass}> {user.organisation} <br /> {user.position} </td>
                                    {/* Organisation Role */}
                                    {showOrganisationRoles &&
                                        <td className={tdClass}>
                                            {isCommunityAdmin && dropdownOptionsOrganisationRole?.length > 0 && isHaveGreaterOrSameRole(organisationRole, user.organisationRole) ?
                                                (user.userId === profile.userId ?
                                                    <Dropdown
                                                        options={[...dropdownOptionsOrganisationRole, ...(user.organisationRole ? [{ value: user.organisationRole, label: t(user.organisationRole) }] : [])]}
                                                        value={user.organisationRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateOrganisationById, null, { organisationRole: e.target.value })} />
                                                    :
                                                    <Dropdown
                                                        options={dropdownOptionsOrganisationRole}
                                                        value={user.organisationRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateOrganisationById, null, { organisationRole: e.target.value })} />
                                                )
                                                :
                                                <span>{user.organisationRole || 'NONE'}</span>
                                            }
                                        </td>
                                    }
                                    {/* Community Role */}
                                    {showCommunityRoles &&
                                        <td className={tdClass}>
                                            {isCommunityAdmin && dropdownOptionsCommunityRole?.length > 0 && (organisationRole || (communityRole && isHaveGreaterOrSameRole(communityRole, user.communityRole))) ?
                                                (user.userId === profile.userId ?
                                                    <Dropdown
                                                        options={[...dropdownOptionsCommunityRole, ...(user.communityRole ? [{ value: user.communityRole, label: t(user.communityRole) }] : [])]}
                                                        value={user.communityRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateCommunityById, null, { communityRole: e.target.value })} />
                                                    :
                                                    <Dropdown
                                                        options={dropdownOptionsCommunityRole}
                                                        value={user.communityRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateCommunityById, null, { communityRole: e.target.value })} />
                                                )
                                                :
                                                <span>{user.communityRole || 'NONE'}</span>
                                            }
                                        </td>}
                                    {/* Project Role */}
                                    {showProjectRoles &&
                                        <td className={tdClass}>
                                            {isCommunityAdmin && dropdownOptionsProjectRole?.length > 0 && (organisationRole || communityRole || (projectRole && isHaveGreaterOrSameRole(projectRole, user.projectRole))) ?
                                                (user.userId === profile.userId ?
                                                    <Dropdown
                                                        options={[...dropdownOptionsProjectRole, ...(user.projectRole ? [{ value: user.projectRole, label: t(user.projectRole) }] : [])]}
                                                        value={user.projectRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateProjectById, projectId, { projectRole: e.target.value })} />
                                                    :
                                                    <Dropdown
                                                        options={dropdownOptionsProjectRole}
                                                        value={user.projectRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateProjectById, projectId, { projectRole: e.target.value })} />
                                                )
                                                :
                                                <span>{user.projectRole || 'NONE'}</span>
                                            }
                                        </td>}
                                    {/* Problem Role */}
                                    {showProblemRoles &&
                                        <td className={tdClass}>
                                            {isCommunityAdmin && dropdownOptionsProblemRole?.length > 0 && (organisationRole || communityRole || projectRole || (problemRole && isHaveGreaterOrSameRole(problemRole, user.problemRole))) ?
                                                (user.userId === profile.userId ?
                                                    <Dropdown
                                                        options={[...dropdownOptionsProblemRole, ...(user.problemRole ? [{ value: user.problemRole, label: t(user.problemRole) }] : [])]}
                                                        value={user.problemRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateProblemById, problemId, { problemRole: e.target.value })} />
                                                    :
                                                    <Dropdown
                                                        options={dropdownOptionsProblemRole}
                                                        value={user.problemRole || 'NONE'}
                                                        onChange={(e) => onRoleChange(user, updateProblemById, problemId, { problemRole: e.target.value })} />
                                                )
                                                :
                                                <span>{user.problemRole || 'NONE'}</span>
                                            }
                                        </td>}
                                    {/* Status */}
                                    {isCommunityAdmin && showStatus &&
                                        <td className={tdClass}>{user.status}</td>
                                    }
                                    {/* Created At */}
                                    {isCommunityAdmin && showCreatedAt &&
                                        <td className={tdClass}>
                                            <div>{convertToUTCString(user.createdTs)}</div>
                                            <div>{timeAgo(user.createdTs)}</div>
                                        </td>
                                    }
                                    {/* Last login at */}
                                    {showLastLogin && <td className={tdClass}>
                                        <div>{convertToUTCString(user.loginTs)}</div>
                                        <div>{timeAgo(user.loginTs)}</div>
                                    </td>
                                    }
                                    {/* Add User */}
                                    {showAddButton && isCommunityAdmin &&
                                        <td className={`text-gray-500 text-center group hover:text-black`}>
                                            <FontAwesomeIcon
                                                className="cursor-pointer"
                                                id="addUser"
                                                icon={faPlus}
                                                onClick={() => addUser(user)} />
                                        </td>
                                    }
                                    {/* {isCommunityAdmin &&
                                        <td className={`text-gray-500 text-center group hover:text-black`}>
                                            <FontAwesomeIcon
                                                className="cursor-pointer"
                                                id="removeUser"
                                                icon={faX}
                                                onClick={() => removeUser(user)} />
                                        </td>
                                    } */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

TeamTable.propTypes = {
    users: PropTypes.array,
    communityActive: PropTypes.bool,
    organisationActive: PropTypes.bool,
    organisationRole: PropTypes.string,
    communityRole: PropTypes.string,
    updateOrganisationById: PropTypes.func,
    updateCommunityById: PropTypes.func,
    updateProjectById: PropTypes.func,
    updateProblemById: PropTypes.func
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});



export default connect(mapStateToProps, {
    updateOrganisationById,
    updateCommunityById,
    updateProjectById,
    updateProblemById
})(TeamTable);
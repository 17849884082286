const prioritizedIssueDict = {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////// Start of 'Create Issue'
    // *** Create Issue ***
    // Issue Statement
    "3-is-header": "Welche Themen möchten Sie priorisieren?",
    "3-is-label1": "Priorisiertes Thema: Titel",
    "3-is-tooltip1": "Kurztitel, z.B. “Verhaltensänderungen” - dieser könnte einem Ast oder Blatt Ihres Problembaums entsprechen",
    "3-is-label2": "Fragestellung",
    "3-is-tooltip2": "Beispiel: “Welche Veränderungen in der Heizungstechnologie sowie im Verhalten müssen wir herbeiführen, um mindestens 25% an Emissionen einzusparen?“",
    // Issue Insight 3-in-
    '3-in-header': "Fügen Sie Beobachtungspunkte hinzu",
    '3-in-label1': 'Ausgangslage für dieses Thema',
    '3-in-info1': 'Beispiel: „80% der Treibhausgasemissionen sind auf den Bereich Heizung zurückzuführen“',
    '3-in-tip1t': 'Fakten',
    '3-in-tip1d': 'Bleiben Sie neutral und vermeiden Sie Wertungen',
    '3-in-tip2t': 'Konsens',
    '3-in-tip2d': 'Es sollte Konsens aller Stakeholder über die Ausgangslage bestehen',
    '3-in-tip3t': 'Klarheit',
    '3-in-tip3d': 'Auch Außenstehende sollten Ihre Fakten und Definitionen nachvollziehen können ',
    '3-in-label2': 'Beobachtungspunkte',
    '3-in-info2': 'Beispiel: „Neben der technologischen Erneuerung ist das Heizverhalten eine wichtige Quelle zur Impacterzeugung, aber auch zur Kontroverse“',
    '3-in-tip4t': 'Essenz',
    '3-in-tip4d': 'Was haben Sie bislang an Erkenntnissen gewonnen?',
    '3-in-tip5t': 'Klärungsbedarf',
    '3-in-tip5d': 'Wo brauchen Sie mehr Klarheit?',
    '3-in-tip6t': 'Herausforderungen',
    '3-in-tip6d': 'Welche Risiken oder Hürden muss Ihr Team im Blick haben?',
    // Guide for Issue Insight 
    '3-in-guide1h': `Priorisiertes Thema: Begriffsbestimmung`,
    '3-in-guide1t': `Mit einem zu priorisierendem Thema ist eine noch ungelöste Frage gemeint, die sich auf einen erfolgswirksamen, beeinflussbaren Einflussfaktor Ihres Problembaums bezieht. Setzen Sie Ihre Teams entlang der priorisierten Themen ein 
    \nQuelle: Conn, C. & McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley; vgl. Kapitel4, v.a. S. 91 ff.`,
    '3-in-guide2h': `Hypothesengetriebenes Arbeiten`,
    '3-in-guide2t': `Der Problemlösungsprozess ist hypothesengetrieben* – dies bedeutet, dass Sie früh möglichst plausible, widerspruchsfreie Aussagen basierend auf Annahmen treffen sollten, die Sie noch nicht verifizieren können. 
    Die Methode ist oft ressourceneffizient und schnell, insbesondere wenn Sie viel Expertise im Team haben, sie bedarf aber mehrerer Iterationen, bis Hypothesen und die dazugehörigen Zahlengerüste stabil werden
    \n* Eine Lösungshypothese ist eine zunächst unbewiesene Aussage zur bestmöglichen Lösung des priorisierten Themas im Sinne der Problemstellung. Eine Hypothese sollte gewissen Qualitätskriterien gehorchen, z.B. überprüfbar sein`,
    '3-in-guide3h': `Dokumentation`,
    '3-in-guide3t': `Sie können dieses Tool nutzen, um entscheidungsrelevantes Wissen weiterzugeben und zu speichern. Dies erzeugt einen gewissen Aufwand, wird jedoch häufig später Wertschätzung erfahren, z.B. von Teams, die an diesem oder einem verwandten Thema arbeiten, oder einer Führungskraft, die sich einen Überblick verschaffen möchte.
    Nehmen Sie sich daher Zeit und versuchen Sie, die wesentlichen Punkte zu erfassen`,
    '3-in-place1': `Raum 123 konsumier 35% der Heizenergie`,
    '3-in-place2': `...Raum 123 wird viel genutzt`,
    // Resolution Hypothesis 3-rh-
    '3-rh-headline': `"Nennen Sie eine oder mehrere Lösungshypothesen sowie die erforderlichen Analysen zur Validierung`,
    '3-rh-l1': `Lösungshypothese`,
    '3-rh-tip1t': `Vorläufigkeit`,
    '3-rh-tip1d': `Ihre Hypothese mag nach anschließenden Tests verworfen werden – schreiben Sie sie dennoch auf, denn um sie herum wird die Arbeit ihres Teams strukturiert`,
    '3-rh-tip2t': `Impact und Plausibilität `,
    '3-rh-tip2d': `Bedenken Sie das Ende: Kann Ihre Hypothese das Problem wirksam lösen und Akzeptanz finden?`,
    '3-rh-tip3t': `Überprüfbarkeit`,
    '3-rh-tip3d': `Durch Experimente oder Prüfung muss ihre Hypothese falsifiziert oder validiert werden können `,
    '3-rh-tip4t': `Klarheit und Präzision `,
    '3-rh-tip4d': `Unterstützen Sie das Hypothesentesten sprachlich: Je klarer und präziser Sie formulieren, umso zielgenauer können die Analysen ausgestaltet werden `,
    '3-rh-tip5t': `Handlungsorientierung`,
    '3-rh-tip5d': `Nach Validierung sollte Ihre Hypothese zu konkreten Schritten führen und entsprechend formuliert sein`,
    '3-rh-info1': `Beispiel: “Wir sollten eine Pelletheizung einbauen und gleichzeitig die 10 Goldenen Regeln des Heizverhaltens einführen“ `,
    '3-rh-info2': `In Abhängigkeit der Stoßrichtung Ihrer Hypothese können Sie hier das Zahlengerüst anpassen, mit dem Sie in der Problemstrukturierung gearbeitet haben. Die Zahlen werden zuweilen noch nicht stabil sein und sollten durch Analysen validiert werden, die Sie in diesem Modul festlegen können `,
    '3-rh-tip6t': `Zielgerichtetheit`,
    '3-rh-tip6d': `Der Wertbeitrag sollte sich direkt auf die Unternehmensziele und ein oder mehrere quantitative Kernresultate beziehen`,
    '3-rh-tip7t': `Plausibilität und Pragmatismus`,
    '3-rh-tip7d': `Ihre Abschätzungen sollten so präzise und glaubwürdig wie mit vertretbarem Aufwand möglich sein – Sie können Analysen definieren, um die Zahlen im Nachgang zu erhärten`,
    '3-rh-tip8t': `Link zur Baseline`,
    '3-rh-tip8d': `Der Wertbeitrag sollte gegen die Baseline abgetragen haben, die Sie in der Problemdefinition gesetzt haben`,
    '3-rh-l2': `Zahlengerüst der Hypothese`,
    '3-rh-l3': `Erklärung`,
    '3-rh-tip9t': `Zielsegmente`,
    '3-rh-tip9d': `Nennen Sie die Zielsegmente, für die Wert geschaffen werden soll, z.B. Kunden, Vertriebspartner, die eigene Belegschaft, die Umwelt, …`,
    '3-rh-tip10t': `Wertbeitrag `,
    '3-rh-tip10d': `Stellen Sie einen möglichst direkten Beitrag zu den übergeordneten Unternehmenszielen und zu den Kernresultaten aus der Problemdefinition sicher`,
    '3-rh-tip11t': `Quelllogik des Wertbeitrags`,
    '3-rh-tip11d': `Beschreiben Sie in wenigen Worten, welche Mehrwerte mit welchen Maßnahmen geschaffen werden`,
    '3-rh-tip12t': `Einfachheit der Umsetzung`,
    '3-rh-tip12d': `Begründen Sie die Wahl des Niveaus der Einfachheit der Umsetzung`,
    '3-rh-info3': `Sie können sich bereits an den Qualitätskriterien orientieren, die später bei der Entwicklung der Lösungsansätze relevant werden, z.B.: “Einführung eines Standardsoftware-Kernsystems; hierdurch Senkung der jährlichen IT-Kosten um 35% sowie beträchtliche Verbesserung des Nutzererlebnisses im Fachbereich. Umsetzbarkeit ist herausfordernd aufgrund des Anpassungsbedarfs in den Systemen“`,
    '3-rh-label4': `Analysen zur Validierung`,
    '3-rh-info4': `Nutzen Sie dieses Format als groben, aber effizienten und managementtauglichen inhaltlichen Arbeitsplan. Sie können die konkreten Schritte auch mit einem anderen Programm (z.B. jira) verlinken`,
    '3-rh-tip13t': `Zielgerichtetheit`,
    '3-rh-tip13d': `Keine Analyse ohne Hypothese: Führen Sie Analysen zielgerichtet zwecks Hypothesentestung durch`,
    '3-rh-tip14t': `Priorisierung`,
    '3-rh-tip14d': `Führen Sie zunächst besonders wichtige Analysen durch, die die prinzipielle Plausibilität der Hypothese erhellen. Gehen Sie erst schrittweise ins Detail`,
    '3-rh-tip15t': `Methodenadäquanz`,
    '3-rh-tip15d': `Verwenden Sie Zeit für die Wahl der richtigen Methode – gerade am Anfang können auch einfache Techniken erhellend sein, z.B. Heuristiken`,
    '3-rh-tip16t': `Planung und Verzahnung`,
    '3-rh-tip16d': `Strukturieren Sie den Arbeitsplan um die Analysen herum mit klaren Deadlines und Verantwortlichkeiten. Verlinken Sie diese Aufgaben mit anderen Tools, z.B. jira, über die „App“-Funktionalität`,
    '3-rh-tip17t': `Interpretation und Argumente`,
    '3-rh-tip17d': `Werten Sie die Methoden und Resultate der Analysen kritisch aus und interpretieren Sie diese mithilfe  eines crossfunktionalen Teams. Formulieren Sie überzeugende Argumente`,
    '3-rh-tip18t': `Iteration`,
    '3-rh-tip18d': `Definieren Sie detailliertere Analysen, ggf. mit anderen Methoden, bis Sie die Hypothese falsifizieren oder validieren können `,
    '3-rh-btn1': `Hypothese hinzufügen`,
    // Proof Point Table
    '3-ppt1': `Analyse`,
    '3-ppt2': `Verantwortlichkeit`,
    '3-ppt3': `Deadline`,
    '3-ppt4': `Ergebnis`,
    '3-ppt5': `Interpretation`,
    // Guide Resolution Hypothesis
    '3-rh-g1h': `Lösungshypothese`,
    '3-rh-g1t': `Eine Lösungshypothese ist eine zunächst unbewiesene Aussage zur bestmöglichen Lösung des priorisierten Themas im Sinne der Problemstellung. Eine Hypothese sollte gewissen Qualitätskriterien gehorchen, z.B. sollte sie plausibel, klar, und überprüfbar sein. In der Praxis werden Hypothesen meist vorweggenommene künftige Lösungsansätze oder auch Prognosen darstellen, manchmal retrospektiv Erscheinungen erklären, und selten Beweise führen`,
    '3-rh-g2h': `Argument`,
    '3-rh-g2t': `Ein Argument ist eine Aussage, die der möglichst triftigen Begründung einer Hypothese dient. Ein Argument sollte neben der Begründung der Aussage auch möglichst Beispiele, Belege, Analogien, Zitate, Benchmarks o.ä. enthalten. 
    \nIn den exakten Naturwissenschaften kann die Begründung objektiv die Wahrheit einer Aussage beweisen. Häufig jedoch wird eine sehr gute, anschauliche Begründung nur dazu hinreichen, eine Aussage mit hoher Wahrscheinlichkeit als valide zu untermauern `,
    '3-rh-g3h': `Wie quantifizieren?`,
    '3-rh-g3t': `• Beginnen Sie, die Einflussfaktoren möglichst mit Baseline-Werten zu versehen 
    Schätzen Sie dann ab, wieviel der jeweilige Einflußfaktor zu Ihrer Zielerreichung beitragen könnte 
    \n• Nutzen Sie das Wissen aus Ihrem Team  
    \n• Nutzen Sie ggf. einfache Quantifizierungstechniken wie Heuristiken  
    \n• Schätzen Sie möglichst realistisch, im Zweifel ggf. eher konservativ 
    \n• Definieren Sie zielgerichtete Analysen zur Validierung`,
    '3-rh-g4h': `Einfachheit der Umsetzung`,
    '3-rh-g4t': `• Die Einfachheit der Umsetzung kann entlang der folgenden Frage eingeschätzt werden: “Wie einfach kann ich den Einflußfaktor nutzen, um mein Ziel zu erreichen bzw. ihm näherzukommen?” 
    \nDie Frage kann weiter zerlegt werden: 
    • Kann ich den Einflußfaktor überhaupt direkt oder indirekt beeinflussen?  
    • Wenn ja: Kann ich ihn adressieren, oder gibt es z.B. formale Hürden? 
    • Wenn ja: Gibt es technische, juristische, budgetäre, politische, zwischenmenschliche oder andere Hürden? Hier können Sie mit Ihren definierten Leitplanken abgleichen, aber auch z.B. ihre Stakeholderbeschreibung überprüfen 
    \n• Beispiel “einfach”: Sie ändern eine Reisekostenregel, um eine ohnehin bereits gelebte Praxis auch formell zu reflektieren 
    \n• Beispiel “sehr hart”: Sie ersetzen ein IT-Kernsystem `,
    '3-rh-g5h': `Analysen zur Validierung `,
    '3-rh-g5t': `Die Analysen werden das Rückgrat der Begründung in den Argumenten sein, die für oder gegen eine Hypothese sprechen.  
    \nDie Arbeit an den Analysen ist daher erfolgskritisch und sollte so zielgerichtet wie möglich entlang der Kette Hypothese – Argument –Begründung – Analyseresultat erfolgen`,
    '3-rh-g6h': `Fachliteratur`,
    '3-rh-g6t': `Wir empfehlen zur weiteren Vertiefung Kapitel 4 aus dem folgenden Buch, durch das dieses Modul inspiriert wurde, insbesondere hinsichtlich des Zusammenhangs zwischen Hypothese und Analyse:
    \nConn, C. & McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley `,
    // OVERVIEW 3-ov-
    "3-ov-header":"Übersicht",
    "3-ov-label1":"Priorisiertes Thema",
    "3-ov-label2":"Fragestellung",
    "3-ov-label3":"Ausgangslage",
    "3-ov-label4":"Beobachtungspunkte",
    "3-ov-label5":"Lösungshypothesen",
    // Issue List 3-li
    "3-li-btn":"Neues Thema",
    "3-li-tooltip1":"Aus Problemstruktur exportiert",
    "3-li-tooltip2":"In Bearbeitung",
    "3-li-tooltip3":"Abgeschlossen - Bereit zum Teilen",
    "3-li-tooltip4":"Abgeschlossen und Geteilt",
    "3-li-tooltip5":"Feedback erhalten",
    // ** Prioritized Issue ** > 3-pi- ALT ////////////////////////////////
    // Tipps
    "3-pi-1t": "Erfolgswirksamkeit",
    "3-pi-1d": "Das Thema sollte sich auf einen Einflussfaktor mit hoher Erfolgswirksamkeit auf Ihr Problem beziehen",
    "3-pi-2t": "Einfachheit der Umsetzung",
    "3-pi-2d": "Sie sollten einen – noch zu entwickelnden – Lösungsansatz für das Thema überhaupt umsetzen können",
    "3-pi-3t": "Frageform",
    "3-pi-3d": "Sie können die geschlossene oder offene Frage- oder die Aussageform verwenden",
    "3-pi-4t": "Spezifität",
    "3-pi-4d": "Versuchen Sie, die Frage so spezifisch und detailliert wie möglich zu stellen",
    "3-pi-5t": "Granularität",
    "3-pi-5d": "Wählen Sie eine sinnvolle Granularitätsebene – überprüfen Sie ggf. nochmals den Teil zur Portfoliozusammenstellung",
    //  Guide
    "1-pi-g1t": "Priorisiertes Thema: Begriffsbestimmung",
    "1-pi-g1d": `Mit einem zu priorisierendem Thema ist eine noch ungelöste Frage gemeint, die sich auf einen erfolgswirksamen, beeinflussbaren Einflussfaktor Ihres Problembaums bezieht. Setzen Sie Ihre Teams entlang der priorisierten Themen ein Quelle: Conn, C. &amp; McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley; vgl. Kapitel4, v.a.S. 91 ff. `,
    "1-pi-g2t": "Hypothesengetriebenes Arbeiten",
    "1-pi-g2d": `Der Problemlösungsprozess ist hypothesengetrieben* – dies bedeutet, dass Sie früh möglichst plausible, widerspruchsfreie Aussagen basierend auf Annahmen treffen sollten, die Sie noch nicht verifizieren können.   Die Methode ist oft ressourceneffizient und schnell, insbesondere wenn Sie viel Expertise im Team haben, sie bedarf aber mehrerer Iterationen, bis Hypothesen und die dazugehörigen Zahlengerüste stabil werden 
    \n * Eine Lösungshypothese ist eine zunächst unbewiesene Aussage zur bestmöglichen Lösung des priorisierten Themas im Sinne der Problemstellung. Eine Hypothese sollte gewissen Qualitätskriterien gehorchen, z.B. überprüfbar sein`,
    // *** Create Issue ***
    "1-ip-title": "Welche Themen möchten Sie priorisieren?",
    "1-ip-issue-title": "Priorisiertes Thema: Titel",
    "1-ip-issue-title-tooltip": "Kurztitel, z.B. “Verhaltensänderungen” - dieser könnte einem Ast oder Blatt Ihres Problembaums entsprechen",
    "1-ip-question-tooltip": "Mit Thema ist eine noch ungelöste Frage gemeint, die sich auf einen erfolgswirksamen, beeinflussbaren Einflussfaktor Ihres Problembaums bezieht. Setzen Sie Ihre Teams entlang der priorisierten Themen ein ",
    "1-ip-issue-insight-title": "Fügen Sie Beobachtungspunkte hinzu ",
    //  Guide
    "1-ip-g1t": "Priorisiertes Thema: Begriffsbestimmung",
    "1-ip-g1d": "Mit Thema ist eine noch ungelöste Frage gemeint, die sich auf einen erfolgswirksamen, beeinflussbaren Einflussfaktor Ihres Problembaums bezieht. Setzen Sie Ihre Teams entlang der priorisierten Themen ein   ",
    "1-ip-g2t": "Hypothesengetriebenes Arbeiten",
    "1-ip-g2d": `Der Problemlösungsprozess ist hypothesengetrieben* – dies bedeutet, dass Sie früh möglichst plausible, widerspruchsfreie Aussagen basierend auf Annahmen treffen sollten, die Sie noch nicht verifizieren können.
                Die Methode ist oft ressourceneffizient und schnell, insbesondere wenn Sie viel Expertise im Team haben, sie bedarf aber mehrerer Iterationen, bis Hypothesen und die dazugehörigen Zahlengerüste stabil werden `,
    "1-ip-resolution-title": "Nennen Sie eine oder mehrere Lösungshypothesen sowie die erforderlichen Analysen zur Validierung ",
    //////////////////////// End of 'Create Issue'
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default prioritizedIssueDict;
import commonDict from "./de/common";
import problemDefinitionDict from "./de/problem-definition";
import prioritizedIssueDict from "./de/prioritized-issue";
import userDict from "./de/user";
import communityDict from "./de/community";

import forenDict from "./de/foren";
import bannersDict from "./de/infobanners";
import registerDict from "./de/register";
import aiDict from "./de/ai";

const resources = {
    de: {
        common: commonDict,
        problemDefinition: problemDefinitionDict,
        prioritizedIssue: prioritizedIssueDict,
        user: userDict,
        community: communityDict,
        foren: forenDict,
        infobanners: bannersDict,
        register: registerDict,
        ai: aiDict,
    }
}

export default resources
import PropTypes from 'prop-types'
/**
 * @returns Array of currently checked IDs with all relevant IDs of children or parents
 */
export const changeSelectedIdList = (selectedList, dataList, item, checked) => {
    let set = new Set(selectedList)
    if (checked) {
        set.add(item.id)
        // searching for parents
        let parent = findParent(dataList, item.id)
        while (parent) {
            set.add(parent.id)
            parent = findParent(dataList, parent.id)
        }
    } else {
        set.delete(item.id)
        // recursively unclick children
        item.children?.forEach(child => { set = changeSelectedIdList(Array.from(set), dataList, child, false) })
    }
    return Array.from(set)
}

// https://stackoverflow.com/a/64026283
const findParent = (items, id, parent = null) => {
    for (let item of items) {
        let res = item.id === id ? parent : item.children && findParent(item.children, id, item);
        if (res) {
            return res;
        }
    }
}

changeSelectedIdList.propTypes = {
    selectedList: PropTypes.array.isRequired,
    dataList: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired
};
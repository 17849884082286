import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import TextareaAutosize from 'react-textarea-autosize';

import EditableStatTile from "components/EditableStatTile";

import CreateProblemContext from '../context/ProblemContext';

// Core Metric Grid for Overview Component
function MetricStatGrid(props) {
    const ctx = useContext(CreateProblemContext);
    const { t } = useTranslation();

    // FIXME currently percentage is set to 100 to avoid 'infinity'
    // We need to discuss how the baseline % should be handled, and if we always want the base to be 100%
    const calcPercentage = (base, value) => {
        let percentage = 0;
        if (parseInt(base) === 0) {
            percentage = 100;
        } else {
            percentage = Math.round(value / base * 100);
        }
        return percentage;
    }

    const stats =
        [
            { name: 'Baseline', value: props.data.baselineValue, percentage: '100', onChange: (e) => ctx.coremetricBasevalueHandler(props.index, e) },
            { name: 'Target', value: props.data.target, percentage: calcPercentage(props.data.baselineValue, props.data.target), onChange: (e) => ctx.coremetricTargetHandler(props.index, e) },
            { name: 'Delta', value: props.data.deltaTarget, percentage: calcPercentage(props.data.baselineValue, props.data.deltaTarget), readOnly: true },
        ]
        || [{ name: 'empty', value: '0', percentage: '0%' }];


    return (
        <div className="rounded-lg bg-white shadow border">
            <div className="flex mt-1 w-full justify-between">
                {/* Baseline Description */}
                {props.editActive ?
                    <TextareaAutosize
                        onChange={props.onDescriptionChange}
                        className='w-1/2 resize-none ml-1 p-1 border-0 focus:border-1 focus:bg-primary-100'
                        value={props.data.baselineDescription || ''}
                        placeholder={t('1-pm-label1', "Denomination and Date")}
                    >
                    </TextareaAutosize>
                    :
                    <label className="pl-2 text-gray-500">{props.data.baselineDescription || '...'}</label>
                }

                <h3 className="text-base font-small mt-1 text-gray-400 mr-2">{props.headerLabel}</h3>
            </div>
            <div className={`
                    mt-1 grid grid-cols-2  
                    2xl:grid-cols-4 
                    overflow-hidden divide-x divide-gray-200  `
            }>
                {/* Stats, Baseline, Target, Delta */}
                {stats.map((item) => (
                    <EditableStatTile
                        key={item.name}
                        editActive={props.editActive}
                        name={item.name}
                        type="number"
                        value={item.value}
                        unit={props.data.unit}
                        percentage={item.percentage}
                        readOnly={item.readOnly}
                        onChange={item.onChange}
                        onUnitChange={(e) => ctx.editRowHandler('coreMetrics', 'unit', props.index, e.target.value)}
                    />
                ))}
                {/* Company Goal */}
                <EditableStatTile
                    editActive={props.editActive}
                    name={"Company Goal"}
                    type="text"
                    value={props.data.companyGoal}
                    percentage={props.data.companyGoalPercent}
                    badgeColor={"bg-blue-100 text-blue-800"}
                    onChange={(e) => ctx.editRowHandler('coreMetrics', 'companyGoal', props.index, e.target.value)}
                    percentageEditable="true"
                    onPercentageChange={(e) => ctx.editRowHandler('coreMetrics', 'companyGoalPercent', props.index, e.target.value)} />
            </div>
        </div>
    );
}

export default MetricStatGrid;
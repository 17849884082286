import { useTranslation } from "react-i18next";

import CardGrid from "components/CardGrid";
import Card from "components/Card";
import FormContainer from "components/FormContainer";
import Button from "components/Button";

import Modal from "components/Modal";

import MeceSvg from '../Images/svg/MECE_ENG.svg';

// TODO translate
function Step4Mece() {
    const { t } = useTranslation();
    const text = {
        pageTitle: t('Follow the MECE principle'),
        card1Title: t('ME = Mutually Exclusive'),
        card1Text: t(`Avoid overlapping factors, like “EU customers” and “international customers”, since both groups overlap. Instead, you could divide “international” into “EU” and “non-EU”`),
        card2Title: t('	CE = Collectively Exhaustive'),
        card2Text: t(`Name all factors that could contribute to the resolution of your problem, as specified in your Key Results. Try to think about factors that do not come to your mind immediately.`),
    }

    const modalButton1 = <a id="a-DownloadMece" href={MeceSvg} download='ConceptOfMece'>
        <Button>{t('Download')}</Button></a>

    return (
        <div id="step-mece">
            <Modal id='mece' title={text.pageTitle} size='modal-xl'
                buttons={modalButton1}>
                <img src={MeceSvg} />
            </Modal>
            <FormContainer title={text.pageTitle} width="w-full lg:w-3/4">
                <CardGrid gridCol='grid-cols-2'>
                    <Card title={text.card2Title} text={text.card1Text} noClick={true} />
                    <Card title={text.card1Title} text={text.card2Text} noClick={true} />
                </CardGrid>
                <Card databstoggle='modal' databstarget='#mece'>
                    <img className="mx-auto md:w-full lg:w-1/2" src={MeceSvg} />
                </Card>
            </FormContainer>
        </div>
    );
}

export default Step4Mece;
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

import Button from "components/Button";
import PageWrapper from "components/PageWrapper";

import StepFormWrapper from "components/StepFormWrapper";

import Step1WhyStructuringMatters from "./Steps/Step1WhyStructuringMatters";
import Step2ChooseTechnique from "./Steps/Step2ChooseTechnique";
import Step3RefineStructure from "./Steps/Step3RefineStructure";
import Step4Mece from "./Steps/Step4Mece";
import Step5Quantify from "./Steps/Step5Quantify";
import Step6RateImplementation from "./Steps/Step6RateImplementation";
import Step7ChangeYourLense from "./Steps/Step7ChangeLense";

/**
 * @returns Guided Steps to create a problem structure
 */
function ProblemStructureGuidedStepsPage() {
    const { t } = useTranslation();
    const stepList =
        [{ name: 'Step 1', href: '#', status: 'current' },
        { name: 'Step 2', href: '#', status: 'upcoming' },
        { name: 'Step 3', href: '#', status: 'upcoming' },
        { name: 'Step 4', href: '#', status: 'upcoming' },
        { name: 'Step 5', href: '#', status: 'upcoming' },
        { name: 'Step 6', href: '#', status: 'upcoming' },
        ]
    let [currentStep, setCurrentStep] = useState(1);

    const { projectId } = ':id' //TODO get real id
    const { problemId } = ':id' //TODO get real id

    // TODO route to new structure
    const buttonsFinalpage = <div className="space-x-2">
        <Link className="mt-2"
            to={`/projects/${projectId}/problems/${problemId}?id=problemStructure`}>
            <Button>{t('To Overview')}</Button>
        </Link>
        <Button>{t('Create new Structure')}</Button>
    </div>

    return (
        <PageWrapper id="ProblemStructureGuidedStepsPage">
            <StepFormWrapper isValid={true} stepList={stepList} currentStep={setCurrentStep}
                backLink={`/projects/${projectId}/problems/${problemId}?id=problemStructure`}
                customFinalButtons={buttonsFinalpage}>
                <div className="py-8">
                    {currentStep === 1 && <Step1WhyStructuringMatters />}
                    {currentStep === 2 && <Step2ChooseTechnique />}
                    {currentStep === 3 && <Step3RefineStructure />}
                    {currentStep === 4 && <Step4Mece />}
                    {currentStep === 5 && <Step5Quantify />}
                    {currentStep === 6 && <Step6RateImplementation />}
                    {currentStep === 7 && <Step7ChangeYourLense/>}
                </div>
            </StepFormWrapper>
        </PageWrapper>
    );
}

export default ProblemStructureGuidedStepsPage;
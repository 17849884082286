import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faPen, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";

const roles = {
    OWNER: {
        icon: faCrown,
        label: 'Owner',
        color: 'text-lime-200'
    },
    ADMIN: {
        icon: faWandMagicSparkles,
        label: 'Admin',
        color: 'text-amber-200'
    },
    EDITOR: {
        icon: faPen,
        label: 'Editor',
        color: 'text-cyan-200'
    },
}

/**
 * @param {string} role - Role of the current user to the object, can be OWNER, ADMIN, EDITOR 
 * @returns Icon for the role 
 */
function RoleIcon({ role, className }) {
    const hoverInfoClass = 'absolute top-7 left-1/2 transform -translate-x-1/2 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200'

    if (!roles[role]) {
        // console.error(`NONE role: ${role}`);
        return null;
    }

    return (
        <span id='roleIcon' className={`${className} group ${roles[role].color} relative`}>
            <FontAwesomeIcon icon={roles[role].icon} />
            <span className={hoverInfoClass}>
                {roles[role].label}
            </span>
        </span>
    );
}

export default RoleIcon;
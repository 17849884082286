import {
    FireIcon,
    EmojiHappyIcon,
    EmojiSadIcon,
    ThumbUpIcon,
    PaperClipIcon,
    BanIcon,
    XIcon,
    ExclamationIcon,
    HandIcon,
    MinusIcon
} from '@heroicons/react/solid';

const commentMoods = [
    { name: 'Excited', value: 'excited', icon: EmojiHappyIcon, iconColor: 'text-white', bgColor: 'bg-green-500' },
    { name: 'Thumbsy', value: 'thumbsy', icon: ThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'Skeptical', value: 'skeptical', icon: ExclamationIcon, iconColor: 'text-white', bgColor: 'bg-red-400' },
    { name: 'Negative', value: 'negative', icon: EmojiSadIcon, iconColor: 'text-white', bgColor: 'bg-yellow-500' },
    { name: 'I feel nothing', value: null, icon: XIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
]

export default commentMoods;
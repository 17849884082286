import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import FormContainer from 'components/FormContainer';
import Button from 'components/Button';

import CreateIssueContext from '../context/create-issue-context';
import HypothesisSubForm from '../Steps/HypothesisSubForm';
import OverviewRowModule from './OverviewRowModule';

function ProblemDefinitionOverview({ editOnly, title, updateNewCommentCount }) {
    const [editModeActive, setEditModeActive] = useState(editOnly);
    //TODO calc all comments
    // const [overviewCommentCount, setOverviewCommentCount] = useState();
    const [overviewNewCommentCount, setOverviewNewCommentCount] = useState([0, 0, 0, 0, 0]);

    const { t } = useTranslation();
    const text = {
        header: t('3-ov-header', "Overview"),
        title: t('3-ov-label1', "Issue Title"),
        question: t('3-ov-label2', "Question to resolve"),
        situation: t('3-ov-label3', "Situation"),
        firstInsight: t('3-ov-label4', "First Insight"),
        hypothesis: t('3-ov-label5', "Resolution Hypothesis")
    }

    const ctx = useContext(CreateIssueContext);

    const editActiveHandler = () => {
        setEditModeActive(!editModeActive);
    }

    // Updates the arra of new comments, sends number to Tabview
    const updateNewCommentCountProblemOverview = (index, newRowComment) => {
        const tempNewCommentCount = [...overviewNewCommentCount];
        tempNewCommentCount[index] = newRowComment;
        setOverviewNewCommentCount(tempNewCommentCount);
        const allNewComments = tempNewCommentCount.reduce((result, number) => result + number);
        updateNewCommentCount('problemDefinition', allNewComments); // send number of new comments to parent, for tabview
    }

    return (
        <div className=''>
            {/* FIXME Text Translation */}
            {!editModeActive &&
                <span className='float-right mt-0'>
                    {/* Button for Edit Mode switch */}
                    {/* FIXME needs to be invisible for certain users */}
                    <Button id='issueOverview-toggleEditActive-btn'
                        className="mr-2"
                        onClick={editActiveHandler}
                        bg={editModeActive ? 'bg-green-600' : ""} >
                        {editModeActive ? t("Save") : t("Edit")}
                    </Button>
                    {/* Button for Share Mode */}
                    {/* FIXME make url dynamic */}
                    <Link to={'/projects/:id/:problemId/share'}>
                        <Button id='issueOverview-share-btn'>
                            {t("Share")}
                        </Button>
                    </Link>
                </span>
            }
            <FormContainer title={text.header} width="w-full">
                <div className="w-full">
                    <OverviewRowModule index='0' label={text.title} value={ctx.data.title} onChange={ctx.handleChange('title')} type="area" editActive={editModeActive} updateNewCommentCount={updateNewCommentCountProblemOverview} />
                    <OverviewRowModule index='1' label={text.question} value={ctx.data.question} onChange={ctx.handleChange('question')} type="area" editActive={editModeActive} updateNewCommentCount={updateNewCommentCountProblemOverview} />
                    <OverviewRowModule index='2' label={text.situation} value={ctx.data.situation} onChange={ctx.handleChange('situation')} type="area" editActive={editModeActive} updateNewCommentCount={updateNewCommentCountProblemOverview} />
                    <OverviewRowModule index='3' label={text.firstInsight} value={ctx.data.firstInsight} onChange={ctx.handleChange('firstInsight')} type="area" editActive={editModeActive} updateNewCommentCount={updateNewCommentCountProblemOverview} />
                    <OverviewRowModule index='4' label={text.hypothesis} type="other" editActive={editModeActive} updateNewCommentCount={updateNewCommentCountProblemOverview}>
                        <HypothesisSubForm overview={true} />
                    </OverviewRowModule>
                </div>
            </FormContainer>
        </div >
    );
}

export default ProblemDefinitionOverview;
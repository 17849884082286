const registerDict = {
    // register
    "welcome": "Herzlich willkommen!",
    "register-accept": "Mit der Anmeldung stimmen Sie unseren Dokumenten zu: ",
    "register-tou": "Mit der Registrierung bestätigen Sie, die Nutzungsbestimmungen Ihres Veranstalters zu akzeptieren. Sie finden diese jederzeit im geschützten Bereich.",
    "terms": "Nutzungsbedingungen",
    "data-policy": "Datenrichtlinien",
    "cookies-policy": "Cookie-Richtlinien",
    "and": "und",
    "register-welcome": `Mit dieser Plattform können Sie auf Veranlassung Ihres Organisators Ihre Veranstaltung inhaltlich vorbereiten und prägen.\nSprechen Sie die kollektive Intelligenz Ihrer Gruppe zielgerichtet an: Teilen Sie Problemstellungen, Ideen oder offene Fragen. Reichern Sie diese gemeinsam an, priorisieren Sie die relevantesten und vernetzen Sie sich.\n\nZur Registrierung geben Sie bitte Ihren Eventcode und Ihre geschäftliche Mailadresse an. Sie gelangen dann über Ihr Postfach direkt zu Ihrem Veranstaltungsbereich.\n\nWenn Sie bereits registriert sind, nutzen Sie bitte die Schaltfläche „Einloggen“. `,
    "register-correctEmail":"Bitte geben Sie eine korrekte E-Mail-Adresse ein.",
}

export default registerDict;
import {TreeProvider} from './Tree/context/TreeProvider';
import TreePage from './TreePage';

function TreeWrapper () {
    return ( 
        <TreeProvider>
            <TreePage/>
        </TreeProvider>
    );
}

export default TreeWrapper ;
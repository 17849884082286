
const emptyProblemState = {
    data: {
        confidentialLevel: null,
        title: null,
        statement: null,
        criticalDefinitions: [
            {
                id: 1,
                term: null,
                meaning: null,
                commentary: null,
            }
        ],
        rating: null,
        situation: null,
        complications: null,
        stakeholders: [
            {
                id: 1,
                stakeholder: null,
                impact: null,
                impactExplanation: null,
                affected: null,
                need: null,
                keyPreferences: null,
                relationship: null
            },
        ],
        definitionOfDone: null,
        coreMetrics: [
            {
                id: 1,
                baselineDescription: null,
                baselineValue: null,
                unit: null,
                target: null,
                deltaTarget: null,
                companyGoal: null,
                companyGoalPercent: 100,
            },
        ],
        constraints: [
            {
                id: 1,
                constraint: null,
                level: null,
            },
        ],
    }
}

export { emptyProblemState };
import { instance as axios } from 'ajax';
import { batch } from 'react-redux';

import Cookies from 'js-cookie';
import { alertActions } from './alert.actions';
import { loadingActions } from './loading.actions';
import { communityActionTypes as types } from 'constants/community.action.types';
import { toCamelCase, toSnakeCase } from 'utils/snake-converter';
import { parseItem } from 'utils/session-storage';

export function updateCommunityById(id, data) {

    if (!id) {
        let community = parseItem('community')
        id = community.id 
    }

    

    return dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
            }
        };

        batch(() => {
            dispatch(request());
            dispatch(loadingActions.start());
        });

        return axios
            .put(`/api/v1/communities/${id}`, toSnakeCase(data), config)
            .then(
                response => {
                    batch(() => {
                        // dispatch(success(response.data));
                        dispatch(alertActions.success(response.data));
                        dispatch(loadingActions.finish());
                    });

                    return response;
                },
                error => {
                    batch(() => {
                        dispatch(failure(error));
                        dispatch(loadingActions.finish());
                    });
                    
                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            );
    };

    function request(payload) { return { type: types.UPDATE_COMMUNITY_REQUEST, payload: payload } }
    function success(response) { return { type: types.UPDATE_COMMUNITY_SUCCESS, payload: toCamelCase(response) } }
    function failure(error) { return { type: types.UPDATE_COMMUNITY_FAILURE, payload: error } }

};
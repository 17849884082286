import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
    HomeIcon,
    UserGroupIcon,
  } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}  

function Navigation({currentCommunity, collapsed}) {
    const [navigation, setNavigation] = useState([])
    const location = useLocation();  

    useEffect(() => {
        setNavigation([
            { name: 'Home', icon: HomeIcon, href: '/home', current: location.pathname === '/home' },
            { name: 'Community Overview', icon: UserGroupIcon, href: `/community/${currentCommunity.id}`, current: location.pathname === `/community/${currentCommunity.id}` }
        ])
    }, [location, currentCommunity])

    return (
      <nav className={`${collapsed ? 'justify-center' : 'px-2'} mt-5 flex flex-col bg-gray-800 space-y-1 transition-all`} aria-label="Sidebar">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={classNames(
              item.current ? 'bg-gray-300 text-black' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
              collapsed && 'justify-center'
            )}
          >
            <item.icon
              className={classNames(
                item.current ? 'text-gray-800' : 'text-gray-400 group-hover:text-gray-300',
                'h-6 w-6',
                collapsed ? 'mx-auto' : 'mr-3'
              )}
              aria-hidden="true"
            />
            {!collapsed && (
              <div className='collapse-horizontal show multi-collapse whitespace-nowrap'>
                <span className="flex-1">{item.name}</span>
                {item.count ? (
                  <span
                    className={classNames(
                      item.current ? 'bg-gray-800' : 'bg-gray-900 group-hover:bg-gray-800',
                      'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
                    )}
                  >
                    {item.count}
                  </span>
                ) : null}
              </div>
            )}
          </Link>
        ))}
      </nav>
    );
}

export { Navigation };
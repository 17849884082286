import React, { useState, useEffect, useRef } from 'react';

/**
 * Needs a replyToObject with firstname, lastName and text
 */
function Blockquote({ replyToObj, borderColor }) {
    const [isClamped, setIsClamped] = useState(true);
    const [isTextOverflowing, setIsTextOverflowing] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        // Check if the text is overflowing to render icons accordingly
        if (textRef.current && isClamped) {
            setIsTextOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
        }
    }, [isClamped]);

    const toggleClamp = () => {
        setIsClamped(!isClamped);
    };

    return (
        <div id='blockquote' className='text-sm rounded relative'>
            <blockquote className={`${borderColor ? borderColor : 'border-gray-300'} bg-gray-50 border-l-4 p-1  text-xs`}
                onClick={toggleClamp}>
                <span className='bg-gray-100 mr-2'>
                    {replyToObj.firstName} {replyToObj.lastName}:
                </span>
                <p ref={textRef}
                    className={`text-sm italic font-medium leading-relaxed text-gray-800 pr-3 ${isClamped ? 'line-clamp-3' : 'line-clamp-none'
                        }`}>
                    {replyToObj.text}
                </p>
                {isTextOverflowing &&
                    <span className='cursor-pointer text-gray-400 hover:text-gray-600 absolute bottom-0 right-0 p-1 bg-gray-50'>
                        {isClamped ? '▼' : '▲'}
                    </span>}
            </blockquote>
        </div >
    );
}

export default Blockquote;

import { useContext, useState, useEffect } from "react";
import { useXarrow } from "react-xarrows";

import InputSwitch from "components/InputSwitch";
import ValueTile from "components/ValueTile";
import DotMenu from "components/DotMenu";
import SideMenu from "components/SideMenu";
import SideMenuButton from "components/SideMenuButton";

import FlagCompactGrid from "components/FlagCompactGrid";

import TreeContext from "./context/tree-context";

// TODO Translation
function NodeCard(props) {
    const updateXarrow = useXarrow();
    const { data, findNode,
        changeName, changeBaseline,
        changeChange, changeEstimate,
        changeRating,
        addChild, deleteCard,
        addSelectedCard, removeSelectedCard
    } = useContext(TreeContext);
    const thisNode = findNode(data, props.id);
    const hasChildren = props.children?.length > 0;
    const [selectModeActive, setSelectModeActive] = useState(props.selectModeActive);
    const [editActive, setEditActive] = useState(props.selectModeActive);
    const [cardSelected, setCardSelected] = useState(false);
    let numberEditActive = editActive && !hasChildren;
    const [menuActive, setMenuActive] = useState(false);

    const rating = [
        {
            name: 'EASY',
            text: 'Easy',
            color: 'bg-green-200'
        },
        {
            name: 'MEDIUM',
            text: 'Medium',
            color: 'bg-yellow-200'
        },
        {
            name: 'HARD',
            text: 'Hard',
            color: 'bg-red-400'
        },
    ]

    useEffect(() => {
        setEditActive(!props.selectModeActive);
        setSelectModeActive(props.selectModeActive);
    }, [props.selectModeActive]);

    // This is a fix for the case whenever the value is '0' 
    // because right now, 0 is never displayed otherwise
    const handleZero = (input) => {
        if (input === null || input === 0 || input === false) {
            return '0'
        } else {
            return input
        }
    }

    const baseline = handleZero(thisNode.baseline);
    const change = handleZero(thisNode.change);
    const estimate = handleZero(thisNode.estimate);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    }

    const toggleEdit = () => {
        setEditActive(!editActive);
        setMenuActive(false);
    }

    const changeNameHandler = (e) => {
        changeName(props.id, e);
    }

    const changeBaselineHandler = (e) => {
        changeBaseline(props.id, e);
    }

    const changeChangeHandler = (e) => {
        changeChange(props.id, e);
    }

    const changeEstimateHandler = (e) => {
        changeEstimate(props.id, e);
    }

    const changeRatingHandler = (index) => {
        changeRating(props.id, index);
    }

    const addChildHandler = () => {
        updateXarrow();
        addChild(props.id);
        setMenuActive(false);
    }

    const deleteHandler = () => {
        updateXarrow();
        deleteCard(props.id);
        if (cardSelected) {
            removeSelectedCard(props.id);
        }
    }

    const toggleCardSelected = (e) => {
        if (e.target.checked) {
            addSelectedCard(props.id);
        } else {
            removeSelectedCard(props.id);
        }
        setCardSelected(!cardSelected);
    }

    return (
        <div id="cardWrapper" className="flex items-center">
            <div className="relative">
                <div id={props.id} className={`${props.bg || cardSelected ? 'bg-primary-200' : 'bg-primary-100'} 
            border border-primary-700 rounded-md
            px-1 pb-1 inline-block m-1`}>
                    {/* Upper Row */}
                    <div className="flex justify-between pt-1 pl-1 pb-1">
                        <span className="text-xs text-primary-700">{props.number}</span>
                        <span className="flex">
                            {/* Rating Flags */}
                            <FlagCompactGrid
                                editActive={editActive}
                                data={rating}
                                active={thisNode.rating}
                                setActiveFlag={changeRatingHandler}
                                flagProps={{ margin: 'mx-1', border: 'border', borderColor: 'border-primary-200' }} />
                            {selectModeActive &&
                                <input
                                    id=""
                                    name="selectBox"
                                    type="checkbox"
                                    checked={cardSelected}
                                    onChange={toggleCardSelected}
                                    className="h-6 w-6 ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />}
                            <DotMenu onClick={toggleMenu} name='rating'
                                buttonStyle="mt-1 ml-1 -mr-1" color='text-primary-400 hover:text-primary-500' />
                        </span>
                    </div>
                    {/* Name of Card */}
                    <div className={`${editActive ? '' : 'ml-2'} ${selectModeActive && 'pb-1'}`}>
                        <InputSwitch className={`w-full`} value={thisNode.name} onChange={changeNameHandler} editActive={editActive} />
                    </div>
                    {/* Row with Baseline, Change, Estimate */}
                    <div className="flex mt-2 mb-1">
                        <ValueTile type={'number'} name={'Baseline'} value={baseline} onChange={changeBaselineHandler} label={'tons'} editActive={numberEditActive} />
                        <ValueTile type={'number'} name={'Change'} value={change} onChange={changeChangeHandler} label={'tons'} editActive={numberEditActive} />
                        <ValueTile type={'number'} name={'Estimate'} value={estimate} onChange={changeEstimateHandler} label={'tons'} editActive={numberEditActive} />
                    </div>
                </div>
                {/* Dot Menu, TODO Translations */}
                {menuActive && <SideMenu border='border border-primary-700'>
                    {selectModeActive &&
                        <SideMenuButton onClick={toggleEdit} padding="py-0 px-1">
                            {editActive ? 'Save' : 'Edit'}
                        </SideMenuButton>}
                    {!props.lastChild &&
                        <SideMenuButton className="whitespace-nowrap"
                            onClick={addChildHandler} padding="py-0 px-1">
                            Add Child
                        </SideMenuButton>}
                    <SideMenuButton onClick={deleteHandler} padding="py-0 px-1">Delete</SideMenuButton>
                </SideMenu>}
            </div>
        </div>
    );
}

export default NodeCard;
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipBase from "./TooltipBase";
import { useState } from "react";

/**
 * Displays a FontAwesomeIcon with a hover-information below
 * icons can be used for modal switches or normal 'onClick' actions
 * relevant numbers are displayed on the top right
 * icons can be active/inactive
 */
function IconWithHoverinfo({ icon, tooltip, active, modal, number, id, onClick, iconColor = 'text-primary-700 ' }) {
    const [hoverActive, setHoverActive] = useState(false);

    return (
        <div
            id={'iconWithHoverInfo_' + id}
            className="flex justify-center relative"
            data-bs-toggle={modal && 'modal'}
            data-bs-target={active && modal}
        >
            <div>
                <FontAwesomeIcon
                    className={`
                ${active ? iconColor + ' cursor-pointer' : "text-gray-400"}  h-5 `}
                    icon={icon}
                    onMouseEnter={() => setHoverActive(true)}
                    onMouseLeave={() => setHoverActive(false)}
                    onClick={onClick}
                />
                {/* displays optional number on top right */}
                {active && number &&
                    <span className="absolute -translate-y-3 text-xs bg-primary-100 text-primary-700 rounded-full px-1 ">
                        {number}
                    </span>}
            </div>
            {/* displays tooltip below icon */}
            {tooltip && hoverActive &&
                <TooltipBase
                    width="w-auto max-w-[160px]"
                    className={`absolute translate-y-6 z-20`}
                    text={tooltip} />
            }
        </div>
    )
}

IconWithHoverinfo.propTypes = {
    icon: PropTypes.object.isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    active: PropTypes.bool.isRequired,
    modal: PropTypes.string,
    number: PropTypes.number,
    id: PropTypes.string,
    onClick: PropTypes.func,
    iconColor: PropTypes.string
}

export default IconWithHoverinfo;
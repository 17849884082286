import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    InformationCircleIcon,
    LightBulbIcon
} from '@heroicons/react/solid';

// General Alerting based on tailwind-ui examples:
// https://tailwindui.ccomponents/application-ui/feedback/alerts

function InfoBanner({ title, children }) {

    return (
        <div id='infoBanner' className="rounded-md bg-blue-50 p-4 whitespace-pre-line">
            <div className="flex">
                <div className="flex-shrink-0">
                    <LightBulbIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-blue-800">{title}</h3>
                    <div className="text-sm text-blue-700">
                        {/* <p>{children}</p> */}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}


InfoBanner.propTypes = {
    props: PropTypes.object
};

export default InfoBanner;

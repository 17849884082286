const problemActionTypes = {
    LIST_PROBLEMS_REQUEST: 'LIST_PROBLEMS_REQUEST',
    LIST_PROBLEMS_SUCCESS: 'LIST_PROBLEMS_SUCCESS',
    LIST_PROBLEMS_FAILURE: 'LIST_PROBLEMS_FAILURE',

    GET_PROBLEM_REQUEST: 'GET_PROBLEM_REQUEST',
    GET_PROBLEM_SUCCESS: 'GET_PROBLEM_SUCCESS',
    GET_PROBLEM_FAILURE: 'GET_PROBLEM_FAILURE',

    CREATE_PROBLEM_REQUEST: 'CREATE_PROBLEM_REQUEST',
    CREATE_PROBLEM_SUCCESS: 'CREATE_PROBLEM_SUCCESS',
    CREATE_PROBLEM_FAILURE: 'CREATE_PROBLEM_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    UPDATE_PROBLEM_REQUEST: 'UPDATE_PROBLEM_REQUEST',
    UPDATE_PROBLEM_SUCCESS: 'UPDATE_PROBLEM_SUCCESS',
    UPDATE_PROBLEM_FAILURE: 'UPDATE_PROBLEM_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    DELETE_PROBLEM_REQUEST: 'DELETE_PROBLEM_REQUEST',
    DELETE_PROBLEM_SUCCESS: 'DELETE_PROBLEM_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    DELETE_PROBLEM_FAILURE: 'DELETE_PROBLEM_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { problemActionTypes };
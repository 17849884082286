import { useTranslation } from "react-i18next";

const EmailConfirmationPage = () => {
    const { t } = useTranslation(['common']);

    return (
        <main className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8" style={{ minHeight: `calc(100vh - 50px)` }}>
            <div className="text-center">
                <p className="text-base font-semibold text-indigo-600">{t("log-suc","Success")}</p>
                <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900">{t("log-check","Check Your Email")}</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">{t("log-text","An email has been sent to your email address with further instructions.")}</p>
                <div className="mt-6">
                    <a href="/" className="text-base font-medium text-primary-600 hover:text-primary-500">
                        Go back home<span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
};

export default EmailConfirmationPage;

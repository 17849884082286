import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import {
    MenuAlt1Icon,
    XIcon
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom';

import { getUserProfile } from 'actions/profile.get.actions'
import { listOrganisations } from 'actions/organisations.list.actions'
import { logout } from 'actions/auth.logout.actions'

import Sidebar from './Navigation/Sidebar'
// import Sidebar from './Navigation/Sidebar/Sidebar'
import Header from './Navigation/Header'
import Alert from './Alert'
import Copyright from './Navigation/Copyright';
import Spinner from 'routing/Spinner';

import { parseItem } from 'utils/session-storage';


function BrowserRouter({ children,
    getUserProfile,
    listOrganisations,
    logout,
    organisations,
    auth: { isAuthenticated },
    activeLoadings,
    profile: { profile },
    alert
}) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [organisationId, setOrganisationId] = useState(sessionStorage.getItem('organisation')?.id || null);

    // Logout
    useEffect(() => {
        if (alert?.type === 'danger') {
            if(alert?.text === 'Missing cookie "access_token_cookie"' || alert?.text === 'Missing CSRF token') {
                console.log('logout')
                logout()
                //history.push('/login')
                //window.location.reload() // sometimes is needed due to Modal issues
            }
        }
    }, [alert]);

    useEffect(() => {
        if (isAuthenticated && !organisationId) {
            listOrganisations()
        }
    }, [isAuthenticated, organisationId]);

    // setting up initial organisation
    useEffect(() => {
        if (!sessionStorage.getItem('organisation') && organisations?.length > 0) {
            setOrganisationId(organisations[0].id)
            sessionStorage.setItem('organisation', JSON.stringify(organisations[0]))
        }
    }, [organisations, sessionStorage.getItem('organisation')])

    useEffect(() => {
        if (isAuthenticated) {
            getUserProfile();
        }
    }, [isAuthenticated]);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    let first_name = (profile?.first_name) || '?'
    let last_name = (profile?.last_name) || '?'

    const user = {
        name: first_name + " " + last_name,
        imageUrl: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }

    // MinHeight -70px because of the topBanner
    const mainContentContainer =
        <>
            {activeLoadings > 0 && <Spinner />}
            <div className={`flex flex-col ${activeLoadings > 0 ? 'hidden': null}`} style={{ minHeight: 'calc(100vh - 70px)' }}>
                <div className='sticky top-0 z-50'>
                    <Alert type={alert.type}
                        title={alert.title}>
                        <p>{alert.text}</p>
                    </Alert>
                </div>
                <div className='flex-grow  overflow-auto bg-white'>
                    {children}
                </div>
                <div>
                    <Copyright />
                </div>
            </div>
        </>

    return (
        isAuthenticated && profile ? (
            <div className="relative h-screen flex overflow-hidden bg-white">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        static
                        className="fixed inset-0 flex z-40 lg:hidden"
                        open={sidebarOpen}
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div id='smallViewSidebarDiv' className="flex-1 min-h-full overflow-y-auto bg-gray-800">
                                    <Sidebar />
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <Sidebar />
                </div>

                <div className='flex flex-col w-full h-full bg-white'>
                    <div className='flex w-full'>
                        {/* Search header, Visible on small Screen*/}
                        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
                            {/* Button to open Mobile Sidebar */}
                            <button
                                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-800 lg:hidden"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <Header user={user} />
                    </div>
                    {/* Main Content Container */}
                    {mainContentContainer}
                </div>
            </div>)
            : (
                <>
                    {/* Main Content Container */}
                    {mainContentContainer}
                </>
            )
    );
}

BrowserRouter.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    communities: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    activeLoadings: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    getUserProfile: PropTypes.func.isRequired,
    listOrganisations: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    activeLoadings: state.loadingReducer.activeLoadings,
    auth: state.authReducer,
    organisations: state.organisationReducer.organisations,
    profile: state.profileReducer,
    alert: state.alertReducer
});


export default connect(mapStateToProps, { getUserProfile, listOrganisations, logout })(BrowserRouter);

import { authActionTypes as types } from 'constants/auth.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.authReducer) || {
  isAuthenticated: false,
};

export function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.REGISTER_REQUEST:
    case types.AUTH_REQUEST:
    case types.LOGIN_REQUEST:
      return {
        ...state,
        ...payload
      };
    // case types.REGISTER_SUCCESS: not used
    case types.LOGIN_SUCCESS:
      return {
        isAuthenticated: true,
      };
    case types.LOGOUT:
      return {
        isAuthenticated: false,
      };
    case types.REGISTER_FAILURE:
    case types.AUTH_FAILURE:
    case types.LOGIN_FAILURE:
    default:
      return state;
  }
}

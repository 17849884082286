import { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import GuideForm from "components/GuideForm";
import Taglist from 'components/Taglist/Taglist';
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from 'components/LabelWithSmarttips';
import Input from "components/InputElement";

import TopicContext from "../context/TopicContext"
import ConfidentialFlagGrid from "./Components/ConfidentialFlagGrid"
import LinkList from "components/LinkList";
import { ButtonAi } from "components/ButtonAi";
import { AiDisplay } from "components/VertexForm";
import MultiSelectBoxMultiLevel from "components/MultiSelectBoxMultiLevel";
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list";
import { updateCheckitemState } from "utils/update-checkitem-state";
import { filterValueChains } from 'pages/UserProfilePage/ProfileForm';
import TopicTypesFlagGrid from "./Components/TopicTypesFlagGrid";
import ToggleElement from "components/ToggleElement";
import FileCommentList from "components/FileCommentList";

function ProblemStatementForm({
    handleValidation,
    currentCommunity }) {
    const { t } = useTranslation(['problemDefinition', 'user', 'common']);
    const text = {
        title: t('1-ps-title', "Title & Type"),
        headline: t('1-ps-hl', "Your Topic"),
        titlePlaceholder: t('1-ps-placeholder', "E.g. 'CO2 reduction in school'"),
        topicTypeInfo: t('1_ps_ttinfo', `Exemplary problem statement: How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?
        \nExemplary use case: An insurer wants to develop an internal search tool helping call center agents reduce response time and improve quality by giving specific, near-time input to advanced customer inquiries.
        \nExemplary POV statement & design goal: A call center agent needs variety w.r.t. to the sentiment and content of customer inquiries because his engagement and thus customer satisfaction will rise with multifaceted work. How then can we increase variety in the work of call center agents?`),
        problemStatement: t('1-ps-prompt', "Topic & State"),
        problemStatementPlaceholder: "...",
        problemStatementInfo: t('1-ps-info', "How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?”"),
        problemStatementTips: [
            {
                title: t('1-ps-1t', "Specific"),
                description: t('1-ps-1d', "What is the objective you want to reach? E.g., “increase our NPS score”")
            },
            {
                title: t('1-ps-2t', "Measurable & Realistic"),
                description: t('1-ps-2d', "Set a quantitative, realistic objective")
            },
            {
                title: t('1-ps-3t', "Time bound"),
                description: t('1-ps-3d', "By when should it be solved? E.g. “by 2030”, “within next 2 years”")
            },
            {
                title: t('1-ps-4t', "Open"),
                description: t('1-ps-4d', "Avoid providing any solution. Leave room for creativity and innovations")
            },
            {
                title: t('1-ps-5t', "Contributing"),
                description: t('1-ps-5d', "Contributing directly or indirectly to the overall goals of your organization")
            },
            {
                title: t('1-ps-6t', "Inspirational"),
                description: t('1-ps-6d', "Your problem statement should motivate a team to get the problem solved")
            },
        ],
        confidentialTile: t('1-ps-visibilityTitel', 'Visibility'),
        confidentialInfo: t('1-ps-visibilityTooltip', 'Define who is allowed to read and comment on your input'),
        tags: t('1-ps-tags', 'Tags'),
        segment: t('area1', 'Market Segment', { ns: 'problemDefinition' }),
        valueChain: t('area2', 'Value Chain', { ns: 'problemDefinition' }),
        LinkList: t('1-ps-linkList', 'Related Links'),
        topicState: [t('1_ps_topicState1', 'Open'), t('1_ps_topicState2', 'Implemented')],
        documents: t('1-ps-docs', 'Related Documents'),
    }
    const guideText = [
        {
            header: t('1-ps-g1t', 'Why sensitivity classifications?'),
            text: t('1-ps-g1d', `Sensitivity classifications are a time-tested instrument to ensure confidentiality of personal data and Intellectual Property rights including classified information like business secrets.\n  
            Legal obligations like the General Data Protection Regulation (GDPR) and Directive (EU) 2016/943 on the protection of undisclosed know-how and business information compel organizations to protect their personal data and other confidential information.\n
            But beyond legal requirements, confidentiality is first and foremost a prerequisite for open, trustful and enriching discussions. Regulations ensuring confidentiality therefore help all of us to adhere to the highest professional standards. `)
        },
        {
            header: t('1-ps-g2t', 'What are sensitivity classifications?'),
            text: t('1-ps-g2d', `The inappropriate disclosure of confidential information can lead to physical, material or non-material damage for individuals or legal entities, e.g., where the processing may give rise to financial loss, damage to the reputation or competitive position, or any other significant economic or social disadvantage.\n
            The higher the potential severity of the damage, the higher the sensitivity classification. `)
        },
        {
            header: t('1-ps-g3t', 'How are sensitivity classifications connected to data protection regulations? '),
            text: t('1-ps-g3d', `Protection measures of personal data, Intellectual Property rights or other classified information like business secrets are often overlapping in practice, while the legal frameworks differ.\n  
        The sensitivity classifications are helpful to protect the aforementioned categories of data respectively information. E.g., you should award the sensitivity classification “strictly confidential” to both special categories of personal data and to highly sensitive business secrets. `)
        },
        {
            header: t('1-ps-g4t', 'How are the sensitivity classifications defined? '),
            text: t('1-ps-g4d', `By default, sensitivity classifications are defined as follows:\n
            Strictly confidential:  
            • Applicable to strictly confidential information and/or special categories of personal data (Art. 9 GDPR), protected by a highly restricted access policy applying to a strictly defined number of persons listed by name only 
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause exceptionally grave damage 
            • Examples: Special categories of personal data, passwords 
            
            Confidential:  
            • Applicable to confidential information and/or personal data, protected by a restricted access policy applying to a defined group of individuals 
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause grave to medium damage 
            • Example: Personal data, product development documentations 
            
            Internal:  
            • Applicable to internal information and/or personal data, protected by a simple access policy  
            • The inappropriate disclosure to a third party is prohibited  
            • The inappropriate disclosure could reasonably be expected to cause light damage 
            • Example: internal regulations, competitive research not readily available publicly  
            
            Public:  
            • Applicable to public information or information meant to be published. There are no handling restrictions 
            • The inappropriate disclosure could reasonably be expected to cause no or no significant damage 
            • Example: marketing flyers `)
        },
        {
            header: t('1-ps-g5t', 'What are the consequences resulting from the sensitivity classification? '),
            text: t('1-ps-g5d', `Depending on the likelihood of the damage materializing, the classified information needs to be protected by technical and organisational measures to ensure a level of security appropriate to the risk.\n Risks can arise from accidental or unlawful destruction, loss, alteration, unauthorised disclosure, or access to personal data transmitted, stored or otherwise processed (cf. Art. 32 (2) GDPR).\n 
        Examples of these measures are:
        • Sensitive personal data from the stakeholder section are not shareable technically 
        • Each user has to be authenticated and has to accept a confidentiality agreement before being able to view the information 
        • Each end product is to be classified separately\n
        In the case of information classified as “public”, you could try to actively publish the information on this platform, in order to attract experts or contributors you are unaware of. `)
        },
    ];
    const ctx = useContext(TopicContext);
    const [guideActive, setGuideActive] = useState(false);
    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);
    const [vertexActive, setVertexActive] = useState(false);

    const [isTouched, setIsTouched] = useState({ title: false, statement: false });

    const [isValid, setIsValid] = useState({
        title: ctx.data.title || false,
        statement: ctx.data.statement || false,
        confidential: ctx.data.visibility || false,
        linkInput: true,
    });

    // updates internal segmentList with the checked objects
    useEffect(() => {
        if (ctx.segmentListData?.length > 0) {
            const newSegmentList = ctx.segmentListData.map(item => updateCheckitemState(item, ctx.data.segmentSelected || []));
            setSegmentList(newSegmentList);
            setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry));
        }
    }, [ctx.segmentListData, ctx.data.segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains 
    useEffect(() => {
        let filteredList = filterValueChains(ctx.valueChainListData, ctx.data.valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, ctx.data.valueChainSelected || []));
        setValueChainList(newValueList);
    }, [ctx.valueChainListData, industryList, ctx.data.valueChainSelected]);

    const handleBlur = (field) => (e) => {
        setIsTouched((prev) => ({
            ...prev,
            [field]: !e.target.value,
        }));
    };

    // updates the context data and the validation for the fields 
    const changeAndUpdateValidation = (event, fieldName) => {
        ctx.itemHandler(fieldName, event.target.value)
        setIsValid({ ...isValid, [fieldName]: event.target.value?.length > 0 })
    }

    const handleConfidential = (state) => {
        setIsValid({ ...isValid, confidential: !state })
    }

    // Form is invalid while link isn't added to list
    const handleUrlInputActive = (state) => {
        setIsValid({ ...isValid, linkInput: !state })
    }

    const handleStateChange = (id, stateIsClosed) => {
        ctx.itemHandler('isOpen', stateIsClosed)
    }

    useEffect(() => {
        if (isValid.title && isValid.statement && isValid.confidential && isValid.linkInput) {
            handleValidation(true);
        } else {
            handleValidation(false);
        }
    }, [isValid]);

    // updates formData with new Checked id 
    const changeSegmentList = (item, checked) => {
        ctx.itemHandler('segmentSelected', changeIdList(ctx.data.segmentSelected, ctx.segmentListData, item, checked))
    };

    const changeValueChainList = (item, checked) => {
        ctx.itemHandler('valueChainSelected', changeIdList(ctx.data.valueChainSelected, ctx.valueChainListData, item, checked))
    };

    const selectFlag = (index) => {
        ctx.itemHandler('type', index)
    }

    const baseStyle = `shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`
    const requiredInfo = <span title={t("This field is required", { ns: 'common' })} className="text-red-600 px-1">*</span>

    const closeAiForm = () => {
        setVertexActive(false)
    }

    return (
        <div id="problemStatementForm">
            <AiDisplay active={vertexActive} context={ctx.data.statement} handleClose={closeAiForm} />
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4 2xl:w-1/3'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={false}
                title={text.headline}>
                {/* Title Input */}
                <div>
                    <label htmlFor={text.title}
                        className="block text-base font-medium text-gray-700">
                        {text.title}
                        {requiredInfo}
                    </label>
                    <div className="mt-1">
                        <input
                            id="ProblemTitleInput"
                            type="text"
                            name={text.title}
                            className={`${isTouched.title && !ctx.data.title ? 'border-red-500' : ''} ${baseStyle}`}
                            placeholder={text.titlePlaceholder}
                            value={ctx.data.title}
                            onChange={(e) => changeAndUpdateValidation(e, 'title')}
                            onBlur={handleBlur("title")}
                            autoFocus
                        />
                    </div>
                </div>
                {/* Topic Types */}
                <div className="z-20">
                    <Tooltip
                        className={"-mt-4 transform translate-y-2"}
                        content={text.topicTypeInfo} />
                    <TopicTypesFlagGrid
                        tooltipActive={true}
                        editActive={true}
                        activeFlag={ctx.data?.type}
                        selectFlag={selectFlag}
                    />
                </div>
                {/* Problem Statement, Textarea */}
                <div>
                    <Tooltip content={text.problemStatementInfo} />
                    <LabelWithSmarttips id="problemStatementTips" title={text.problemStatement} tips={text.problemStatementTips} required />
                    <div className="mt-1 relative">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="problemStatement"
                            id="problemStatement"
                            className={`${isTouched.statement && !ctx.data.statement ? 'border-red-500' : ''} ${baseStyle}`}
                            placeholder={text.problemStatementPlaceholder}
                            value={ctx.data.statement}
                            onChange={(e) => changeAndUpdateValidation(e, 'statement')}
                            onBlur={handleBlur("statement")}
                        />
                        <ButtonAi onClick={() => setVertexActive(!vertexActive)} />
                    </div>
                    {/* TODO toggle */}
                    <div className="mt-2 mb-4">
                        <ToggleElement
                            rightLabel={ctx.data.isOpen ? text.topicState[0] : text.topicState[1]}
                            firstColor={'bg-yellow-500'}
                            secondColor={'bg-green-500'}
                            activated={!ctx.data.isOpen}
                            toggleState={handleStateChange}
                        />
                    </div>
                    <div>

                    </div>
                </div>
                {/* TODO further insight field */}
                {/* <div className="transform -translate-y-4">
                    <label>Further Insights</label>
                    <div className="mt-1 relative">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="problemStatement"
                            id="problemStatement"
                            className={`${baseStyle} `}
                            placeholder={text.problemStatementPlaceholder}
                            value={ctx.data.statement}
                            onChange={(e) => changeAndUpdateValidation(e, 'statement')}
                            onBlur={handleBlur("statement")}
                        />
                    </div>
                </div> */}
                {/* Attached Documents */}
                {currentCommunity?.config?.isProblemDocuments &&
                    <div>
                        <label htmlFor={'Documents'}
                            className="block text-base font-medium text-gray-700">
                            {text.documents}
                        </label>
                        <FileCommentList
                            editMode
                            documentList={ctx.data.documentList}
                            setTempFiles={(data) => ctx.itemHandler('documentList', data)}
                        />
                        {/* TODO Add Documents hold module */}
                    </div>}
                {/* List of Links */}
                {currentCommunity?.config?.isProblemLinks &&
                    <div>
                        <label htmlFor={'LinkList'}
                            className="block text-base font-medium text-gray-700">
                            {text.LinkList}
                        </label>
                        <LinkList
                            editMode
                            list={ctx.data.linkList}
                            changeList={(data) => ctx.itemHandler('linkList', data)}
                            urlInputActive={handleUrlInputActive}
                        />
                    </div>
                }
                {/* Visibility Level */}
                <div>
                    <Tooltip content={text.confidentialInfo} />
                    <label htmlFor='ConfidenialFlagGrid' className="block text-base font-medium text-gray-700">
                        {text.confidentialTile}
                        {requiredInfo}
                    </label>
                    <ConfidentialFlagGrid
                        tooltipActive={true}
                        editActive={true}
                        activeFlag={ctx.data.type}
                        isSelected={handleConfidential} />
                </div>
                {currentCommunity?.config?.isProblemSegments &&
                    <Input
                        id={'interestsSegment'}
                        label={text.segment}
                        other={true}
                        labelClass={`pb-1 block`}
                    >
                        <MultiSelectBoxMultiLevel
                            list={segmentList}
                            updateList={changeSegmentList}
                            name={'interestsSegment'}
                        />
                    </Input>
                }
                {currentCommunity?.config?.isProblemValueChains &&
                    <Input
                        id={'interestsValueChain'}
                        label={text.valueChain}
                        other={true}
                        labelClass={`pb-1 block`}
                    >
                        <MultiSelectBoxMultiLevel
                            list={valueChainList}
                            updateList={changeValueChainList}
                            name={'interestsValueChain'}
                        />
                    </Input>
                }
                {currentCommunity?.config?.isProblemTags &&
                    <div>
                        <label htmlFor='Taglist' className="block text-base font-medium text-gray-700 mb-1">
                            {text.tags}
                        </label>
                        <Taglist
                            value={ctx.data.tags}
                            onChange={(data) => ctx.itemHandler('tags', data)}
                        />
                    </div>
                }
            </FormContainer>
        </div>
    );
}

ProblemStatementForm.propTypes = {
    segments: PropTypes.array,
    valueChains: PropTypes.array,
    currentCommunity: PropTypes.object
};

const mapStateToProps = state => ({
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains,
    currentCommunity: state.communityReducer.currentCommunity
});


export default connect(mapStateToProps, null)(ProblemStatementForm)
import { LightBulbIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * Footer for Landing Page and login page
 */
function LPFooter({ mode }) {
    const { t } = useTranslation(['common']);
    const text = {
        label: t('About & Legal'),
    }
    const navigation = {
        information: [
            { name: t('doc-princ', 'Principles'), href: 'principles' },
            { name: t('doc-legal', 'Legal Notice'), href: 'legalNotice' },
            { name: t('doc-pnw', 'Privacy Notice Website'), href: 'dse' },
        ]
    }
    const [collapsed, setCollapsed] = useState(true)

    mode = mode || 'light'
    const textColor = mode === 'light' ? 'text-gray-300' : 'text-gray-700'
    const textColo2 = mode === 'light' ? 'text-white' : 'text-gray-900'

    return (
        <div id="lpFooter" className="w-full">
            <div className="px-6 pb-24 lg:pb-2 pt-2 lg:px-8">
                <div className="lg:w-1/2">
                    <h3 className={`${collapsed ? textColor : textColo2} text-sm font-semibold leading-6  py-2 text-center lg:text-left pt-8 md:pt-0 md:cursor-pointer select-none`}
                        onClick={() => setCollapsed(!collapsed)}>
                        {text.label}
                    </h3>
                    <div style={{ height: '25px' }}>
                        <ul role="list"
                            className={`${collapsed ? 'md:scale-x-0 t' : 'md:scale-x-100'} origin-left transition-all duration-300 ease-in-out space-y-4 lg:space-y-0 lg:flex lg:flex-wrap lg:justify-between text-center lg:text-left`}>
                            {navigation.information.map((item) => (
                                <li key={item.name} className="">
                                    <a href={item.href} target="_blank"
                                        className={`${textColor} text-sm leading-6 ${'hover:' + textColo2}`}>
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LPFooter;
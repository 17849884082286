import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
// import Button from 'components/Button';

// TODO Translation
// TODO action for buttons
function FooterBanner() {
    const { t } = useTranslation();
    const text = {
        // header: t('Your privacy'),
        // text: t('Wir nutzen im Rahmen unserer Privatsphärenstrategie Cookies nur äußerst sparsam, z.B. um Ihren Aufenthalt auf der Webseite angenehm zu gestalten (Beispiel: Sie brauchen nicht immer wieder die Sprache Ihrer Wahl einstellen). Wir nutzen ausdrücklich keine Cookies zu kommerziellen Marketingzwecken.'),
        text: t('Only essential Cookies in use. By proceeding you agree to our '),
        dismiss: t('Consent'),
        // dismiss: t('Nur technisch erforderliche Cookies'),
        // allow: t('Alle zulassen'),
        link: t('Datenschutzerklärung'),
    }
    const [active, setActive] = useState(true);

    return (
        <div id="cookieBanner"
            className={`${active ? 'bottom-0 opacity-100' : 'opacity-0 -bottom-full'} 
                transition-all duration-300 ease-in-out
                inset-x-0 fixed`}>
            <div className="bg-gray-50 border-t border-gray-300">
                <button onClick={() => setActive(false)} className='float-right mt-4 mr-4'><FontAwesomeIcon icon={faXmark} className={'w-5 h-5 mx-auto text-gray-500'} /></button>
                <div className="mx-auto max-w-7xl py-1 px-1 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap items-center justify-between">
                        <div className="flex flex-row w-0 flex-1 items-center justify-center">
                            <div className='mt-2 mb-2'>
                                <FontAwesomeIcon icon={faCookieBite} className={'w-5 h-5 text-gray-500'} />
                                <p className="text-lg ml-3 font-semibold text-gray-500 whitespace-normal">{text.header}</p>
                            </div>
                            <div className="mx-2 font-normal text-gray-500 whitespace-normal">
                                <p>{text.text} <a className="link-primary" href="/dse" target="_blank" >{text.link}</a></p>
                            </div>
                            {/* <div className="w-1/2 flex flex-col md:flex-row 
                                            justify-center space-y-1 md:space-y-0 md:space-x-4 flex-shrink-0 
                                            mb-2 md:mb-2"> */}
                                {/* <Button>
                                    <div className='text-sm'>
                                        {text.dismiss}
                                    </div>
                                </Button> */}
                                {/* <Button>{text.allow}</Button> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBanner;
import {
    CheckCircleIcon,
    ExclamationIcon,
    XCircleIcon,
    InformationCircleIcon,
    XIcon
} from '@heroicons/react/solid';

function AlertBox({ title, onClick, children, color }) {

    let bgColor = 'bg-gray-50'
    let borderColor = ''
    let iconColor = 'text-gray-400'
    let headerTextColor = 'text-gray-800'
    let baseTextColor = 'text-gray-700'
    let xButtonColor = 'text-gray-500'
    let xButtonColorHover = 'hover:bg-gray-100'
    let xButtonColorFocusRing = 'focus:ring-gray-600'
    let xButtonColorFocusRingOffset = 'focus:ring-offset-gray-50'
    let icon = null

    if (color === 'green') {
        bgColor = 'bg-green-50'
        borderColor = 'border-green-100'
        iconColor = 'text-green-400'
        headerTextColor = 'text-green-800'
        baseTextColor = 'text-green-700'
        xButtonColor = 'text-green-500'
        xButtonColorHover = 'hover:bg-green-100'
        xButtonColorFocusRing = 'focus:ring-green-600'
        xButtonColorFocusRingOffset = 'focus:ring-offset-green-50'
        icon = <CheckCircleIcon className={`${iconColor} h-5 w-5`} aria-hidden="true" />
    } else if (color === 'yellow') {
        bgColor = 'bg-yellow-50'
        borderColor = 'border-yellow-100'
        iconColor = 'text-yellow-400'
        headerTextColor = 'text-yellow-800'
        baseTextColor = 'text-yellow-700'
        xButtonColor = 'text-yellow-500'
        xButtonColorHover = 'hover:bg-yellow-100'
        xButtonColorFocusRing = 'focus:ring-yellow-600'
        xButtonColorFocusRingOffset = 'focus:ring-offset-yellow-50'
        icon = <ExclamationIcon className={`${iconColor} h-5 w-5`} aria-hidden="true" />
    } else if (color === 'red') {
        bgColor = 'bg-red-50'
        borderColor = 'border-red-100'
        iconColor = 'text-red-400'
        headerTextColor = 'text-red-800'
        baseTextColor = 'text-red-700'
        xButtonColor = 'text-red-500'
        xButtonColorHover = 'hover:bg-red-100'
        xButtonColorFocusRing = 'focus:ring-red-600'
        xButtonColorFocusRingOffset = 'focus:ring-offset-red-50'
        icon = <XCircleIcon className={`${iconColor} h-5 w-5`} aria-hidden="true" />
    } else if (color === 'blue') {
        bgColor = 'bg-blue-50'
        borderColor = 'border-blue-100'
        iconColor = 'text-blue-400'
        headerTextColor = 'text-blue-800'
        baseTextColor = 'text-blue-700'
        xButtonColor = 'text-blue-500'
        xButtonColorHover = 'hover:bg-blue-100'
        xButtonColorFocusRing = 'focus:ring-blue-600'
        xButtonColorFocusRingOffset = 'focus:ring-offset-blue-50'
        icon = <InformationCircleIcon className={`${iconColor} h-5 w-5`} aria-hidden="true" />
    }

    return (
        <div id='alertBox'
            className={`${bgColor} fixed bottom-2 left-1/2 transform -translate-x-1/2 mt-4 mx-auto md:mr-4 rounded-md p-4 min-h-[50px] flex items-center shadow-lg border cursor-pointer`}
            onClick={onClick}>
            <div className="flex w-full">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className="ml-3">
                    <h3 className={`${headerTextColor} text-sm font-medium`}>{title}</h3>
                    <div className={`${baseTextColor} pr-8 text-sm`}>
                        {children}
                    </div>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            className={`${xButtonColor} ${xButtonColorHover} ${xButtonColorFocusRing} ${xButtonColorFocusRingOffset} absolute top-2 right-2 inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2`}
                            onClick={onClick}>
                            <span className="sr-only">Dismiss</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertBox;
import { instance as axios } from 'ajax';
import { batch } from 'react-redux';

import { alertActions } from './alert.actions';
import { loadingActions } from './loading.actions';
import { authActionTypes as types } from 'constants/auth.action.types';

export function request(email) {
    return dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const body = JSON.stringify({ email });

        batch(() => {
            dispatch(request({ email }));
            dispatch(loadingActions.start());
        });
        

        return axios
            .post('/api/auth', body, config)
            .then(
                response => {
                    batch(() => {
                        dispatch(alertActions.success(response.data));
                        dispatch(loadingActions.finish());
                    });

                    return response;
                },
                error => {
                    batch(() => {
                        dispatch(failure(error));
                        dispatch(loadingActions.finish());
                    });
                    
                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            );
    };

    function request(payload) { return { type: types.AUTH_REQUEST, payload: payload } }
    function failure(error) { return { type: types.AUTH_FAILURE, payload: error } }

};
import React, { useState, useContext } from 'react';
import EditableRow from 'components/EditableRow';
import CreateProblemContext from '../context/ProblemContext';

function CriticalDefinitionsRow(props) {
    const ctx = useContext(CreateProblemContext);
    const thisRow = ctx.data.criticalDefinitions[props.index];
    const oneRow = ctx.data.criticalDefinitions?.length === 1;
    const tableName = "criticalDefinitions";

    const [rowStructure, setRowStructure] = useState([
        { id: 0, name: 'term', value: thisRow.term, type: "area", style: "w-1/4 font-semibold" },
        { id: 1, name: 'meaning', value: thisRow.meaning, type: "area" },
        { id: 2, name: 'commentary', value: thisRow.commentary, type: "area" },
    ])

    const deleteHandler = () => {
        ctx.deleteRowHandler(tableName, thisRow.id);
    }

    const changeHandler = (rowIndex, name, event, itemId) => {
        let tempData = [...rowStructure];
        tempData[itemId].value = event.target.value;
        setRowStructure(tempData);

        ctx.rowHandler(tableName, name, rowIndex, event);
    }

    return (
        <EditableRow
            editActive={props.editActive}
            rowId={thisRow.id}
            rowIndex={props.index}
            oneRow={oneRow}
            rowData={rowStructure}
            delete={deleteHandler}
            changeHandler={changeHandler}>
        </EditableRow>
    );
}

export default CriticalDefinitionsRow;
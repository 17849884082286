import React from 'react';

export default function convertToUTCString(isoString) {
    // const unixTime = Date.parse(string)
    // const dt = new Date(unixTime)

    const dt = new Date(isoString)

    // return dt.toUTCString()
    // return dt.toString()
    return dt.toLocaleString("de-DE", {timeZone: "Europe/Berlin"}) // TODO: make it read from user settings
}
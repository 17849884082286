function buildPath(pathname, 
  currentCommunity, 
  projects, 
  problems) {
    let pages = []
    if (pathname === '/home') {
      pages = []
    } else if (pathname === '/clusters') {
      pages = []
    } else if (pathname === '/profiles/me') {
      pages = [
        { name: 'me', href: '/profiles/me', current: true }
      ]
    } else if (pathname === '/communities/new') {
      pages = [
        { name: 'new', href: '/communities/new', current: true }
      ]
    } else if (/^\/profiles\/(?<profileId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/profiles\/(?<profileId>[a-zA-Z0-9-]+)$/)

  
      pages = [
        { name: match.groups.profileId, href: `/profiles/${match.groups.profileId}`, current: true }
      ]
    // } else if (/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/.test(pathname)) {
    //   let match = pathname.match(/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/)

    //   let communityName = match.groups.communityId
    //   if (currentCommunity?.title) {
    //     communityName = currentCommunity.title
    //   }

    //   pages = [
    //     { name: communityName, href: `/community/${match.groups.communityId}`, current: true }
    //   ]

    } else if (/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)$/)
  
      let projectName = match.groups.projectId
      if (projects) {
        let project = null
        if (projects.projects) {
          project = projects.projects.find(x => x.id === match.groups.projectId)
        } else if (projects.project) {
          project = projects.project
        }
        if (project?.title) {
          projectName = project.title
        }
      }

      if (match.groups.projectId === '00000000-0000-0000-0000-000000000000') {
        projectName = 'SHARED'
      }
  
      pages = [
        { name: projectName, href: `/clusters/${match.groups.projectId}`, current: true }
      ]
    } else if (/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/new$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/new$/)
  
      let projectName = match.groups.projectId
      if (projects) {
        let project = null
        if (projects.projects) {
          project = projects.projects.find(x => x.id === match.groups.projectId)
        } else if (projects.project) {
          project = projects.project
        }
        if (project?.title) {
          projectName = project.title
        }
      }

      if (match.groups.projectId === '00000000-0000-0000-0000-000000000000') {
        projectName = 'SHARED'
      }
  
      pages = [
        { name: projectName, href: `/clusters/${match.groups.projectId}`, current: false },
        { name: 'new', href: `/clusters/${match.groups.projectId}/topics/new`, current: true }
      ]
    } else if (/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)$/)
  
      let projectName = match.groups.projectId
      if (projects) {
        let project = null
        if (projects.projects) {
          project = projects.projects.find(x => x.id === match.groups.projectId)
        } else if (projects.project) {
          project = projects.project
        }
        if (project?.title) {
          projectName = project.title
        }
      }

      if (match.groups.projectId === '00000000-0000-0000-0000-000000000000') {
        projectName = 'SHARED'
      }
  
      let problemName = match.groups.problemId
      if (problems?.problem) {
        problemName = problems.problem.title
      }
  
      pages = [
        { name: projectName, href: `/clusters/${match.groups.projectId}`, current: false },
        { name: problemName, href: `/clusters/${match.groups.projectId}/topics/${match.groups.problemId}`, current: true }
      ]
    } else if (/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)\/team$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<projectId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)\/team$/)
  
      let projectName = match.groups.projectId
      if (projects) {
        let project = null
        if (projects.projects) {
          project = projects.projects.find(x => x.id === match.groups.projectId)
        } else if (projects.project) {
          project = projects.project
        }
        if (project?.title) {
          projectName = project.title
        }
      }

      if (match.groups.projectId === '00000000-0000-0000-0000-000000000000') {
        projectName = 'SHARED'
      }
  
      let problemName = match.groups.problemId
      if (problems?.problem) {
        problemName = problems.problem.title
      }
  
      pages = [
        { name: projectName, href: `/clusters/${match.groups.projectId}`, current: false },
        { name: problemName, href: `/clusters/${match.groups.projectId}/topics/${match.groups.problemId}`, current: false },
        { name: 'team', href: `/clusters/${match.groups.projectId}/topics/${match.groups.problemId}/team`, current: true }
      ]
    } else {
      pages = []
    }
    return pages
  }

  export default buildPath
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageWrapper from 'components/PageWrapper';
import StepFormWrapper from 'components/StepFormWrapper';
import { createIssue } from 'actions/issue.create.actions';

import IssueStatementForm from './Steps/IssueStatementForm';
import IssueInsightsForm from './Steps/IssueInsightsForm';
import HypothesisForm from './Steps/HypothesisForm';
import IssueOverview from "./Overview/IssueOverview";

import CreateIssueContext from './context/create-issue-context';

function NewIssueForm() {
    const ctx = useContext(CreateIssueContext);
    const stepList = [{ name: 'Step 1', href: '#', status: 'current' },
    { name: 'Step 2', href: '#', status: 'upcoming' },
    { name: 'Step 3', href: '#', status: 'upcoming' },
    ];

    let [currentStep, setCurrentStep] = useState(1);

    // TODO
    const onSave = (e) => {
        e.preventDefault()
        createIssue(ctx.data)
    };

    return (
        <PageWrapper>
            {/* TODO Add Backlink */}
            <StepFormWrapper stepList={stepList}
                isValid={true} 
                currentStep={setCurrentStep} 
                onSave={onSave} 
                backLink={'#'}
                buttonGroupId={'buttonGroup-issueForm'}>
                <div className='overflow-x-visible mb-2'>
                    <div className='py-4 max-w-7xl'>
                        {/* Container for Forms */}
                        <div id='NewIssueFormSectionContainer' className='py-4'>
                            {currentStep === 1 && <IssueStatementForm />}
                            {currentStep === 2 && <IssueInsightsForm />}
                            {currentStep === 3 && <HypothesisForm createIssue={createIssue} />}
                            {currentStep === 4 && <IssueOverview createIssue={createIssue} editOnly={true} />}
                        </div>
                    </div>
                </div>
            </StepFormWrapper>
        </PageWrapper>
    );
}

NewIssueForm.propTypes = {
    createIssue: PropTypes.func.isRequired
};

export default connect(null, { createIssue })(NewIssueForm);
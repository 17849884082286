import { faCheck, faHourglassStart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

/**
 * Displays a flag for the states 'open/implemented'
 */
function StateFlag({ isOpen }) {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        topicState: [
            t('1_ps_topicState1', 'Open'),
            t('1_ps_topicState2', 'Implemented')]
    }

    return (
        <div id="stateFlag"
            className='h-[24px] flex items-center text-xs 
            font-normal text-gray-600 bg-gray-100 border border-gray-300 p-2 rounded-md whitespace-nowrap'>
            {isOpen === true
                ? <span><FontAwesomeIcon className="mr-1" icon={faHourglassStart} /> {text.topicState[0]}</span>
                : isOpen === false
                    ? <span><FontAwesomeIcon className="mr-1" icon={faCheck} /> {text.topicState[1]}</span>
                    : <span>Undefined</span>
            }
        </div>
    );
}

export default StateFlag;
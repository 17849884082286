import { Switch } from '@headlessui/react'
import PropTypes from 'prop-types';

/**
 * Toggle Element with label left or right (labelLeft, labelRight)
 * a toggle can be set to 'mandatory' so the state is not changable but will appear with a green color and disabled
 * colors for switch can be modified (firstColor, secondColor)
 */
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ToggleElement({ id, leftLabel, rightLabel, activated, mandatory, toggleState, firstColor, secondColor }) {
    activated = activated === null ? activated = false : activated

    const switchColor = activated ? secondColor || 'bg-indigo-600' : firstColor || 'bg-gray-200'

    const label =
        <span className={`${rightLabel && 'ml-4'} flex flex-grow flex-col`}>
            <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                {leftLabel || rightLabel}
            </Switch.Label>
        </span>

    return (
        <Switch.Group as="div" className="flex items-center justify-between">
            {leftLabel && label}
            <Switch
                checked={activated}
                disabled={mandatory}
                onChange={() => toggleState(id, activated)}
                className={classNames(
                    mandatory ? 'bg-green-600' : switchColor,
                    mandatory ? 'cursor-not-allowed' : 'cursor-pointer',
                    'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        activated ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                />
            </Switch>
            {rightLabel && label}
        </Switch.Group>
    );
}

ToggleElement.propTypes = {
    id: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    activated: PropTypes.bool.isRequired,
    mandatory: PropTypes.bool,
    toggleState: PropTypes.func.isRequired,
    firstColor: PropTypes.string,
    secondColor: PropTypes.string,
}

export default ToggleElement;
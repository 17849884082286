import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import FlagCompactGrid from "components/FlagCompactGrid";

import CreateProblemContext from '../context/ProblemContext';

function ConfidentialFlagGrid(props) {
    const ctx = useContext(CreateProblemContext);
    const { t } = useTranslation();
    const confidentialRating = [
        {
            name: 'STRICTLY_CONFIDENTIAL',
            text: t('1-ps-confi1', 'Strictly Confidential'),
            color: 'bg-red-400',
            hoverInfo: t('1-ps-confi1tooltip','E.g., medical data, highly sensitive business secrets'),
        },
        {
            name: 'CONFIDENTIAL',
            text: t('1-ps-confi2', 'Confidential'),
            color: 'bg-yellow-400',
            hoverInfo: t('1-ps-confi2tooltip','E.g., Personal data, product development documentations'),
        },
        {
            name: 'INTERNAL',
            text: t('1-ps-confi3', 'Internal'),
            color: 'bg-yellow-200',
            hoverInfo: t('1-ps-confi3tooltip','E.g., Competitive research not readily available publicly'),
        },
        {
            name: 'PUBLIC',
            text: t('1-ps-confi4', 'Public'),
            color: 'bg-green-200',
            hoverInfo: t('1-ps-confi4tooltip','E.g., Marketing material'),
        },
    ]

    const selectFlag = (index) => {
        ctx.itemHandler('confidentialLevel' , index)
    }

    return (
        <FlagCompactGrid
            id='ConfidenialFlagGrid'
            data={confidentialRating}
            name='confidentialLevel'
            tooltipActive={props.tooltipActive}
            active={ctx.data.confidentialLevel}
            setActiveFlag={selectFlag}
            editActive={props.editActive}
        />
    );
}

export default ConfidentialFlagGrid;
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "components/Tooltip";
import LabelWithSmarttips from "components/LabelWithSmarttips";
import FormContainer from "components/FormContainer";
import GuideForm from "components/Guide/GuideForm";

import StakeholdersTable from "./StakeholdersTable";

function StakeholdersForm() {
    const { t } = useTranslation();
    const text = {
        headline: t('1-sh-hl', "Stakeholders"),
        tooltip: t('1-sh-tooltip', `Defining your stakeholders is critical to ensure you involve essential people early in the process turning them into co-authors`),
        label: t('1-sh-prompt', "Define relevant internal and external participants"),
        stakeholderTips: [
            {
                title: t('1-sh-1t', "Balance"),
                description: t('1-sh-1d', "Is your view balanced?")
            },
            {
                title: t('1-sh-2t', "Iceberg"),
                description: t('1-sh-2d', "Did you identify points that are ‘below the surface’?")
            },
            {
                title: t('1-sh-3t', "Pragmatic completeness"),
                description: t('1-sh-3d', "Did you identify all relevant stakeholder groups? Consider both impact and affectedness")
            },
            {
                title: t('1-sh-4t', "Confidentiality"),
                description: t('1-sh-4d', "Make sure to insert highly confidential information in the fields labelled “CONFIDENTIAL - CORE TEAM ONLY”")
            },
        ],
    }
    const [guideActive, setGuideActive] = useState(false);
    const guideText = [
        { header: t('1-sh-table-1', 'Stakeholder'), text: t('1-sh-g1t', 'A group or a person who has direct or indirect impact on the outcome of your project, or is otherwise affected by it.') },
        { header: t('1-sh-table-2', 'Impact'), text: t('1-sh-g2t', 'The impact of a stakeholder is determined by his ability to influence the outcome of the project based on professional or personal grounds. This information is very sensitive and therefore is restricted to core team members by default') },
        { header: t('1-sh-table-3', 'Explanation of Impact'), text: t('1-sh-g3t', 'Required to adequately handle the stakeholder. This information is very sensitive and therefore is restricted to core team members by default') },
        { header: t('1-sh-table-4', 'Affectedness'), text: t('1-sh-g4t', 'A stakeholder can be affected by your actions and their consequences without having a material impact (e.g., ops teams downstream in the value chain)') },
        { header: t('1-sh-table-5', 'Needs & expectations '), text: t('1-sh-g5t', 'What the stakeholder officially stated or what can be reasonably expected he wants to get out of the project due to his role. Try to be precise and fact-based and clarify if necessary') },
        { header: t('1-sh-table-6', 'Personal preferences and objectives'), text: t('1-sh-g6t', 'The part below the surface: What the stakeholder is further motivated by, e.g., a possible promotion, altruistic motives, the need to build a lasting reputation, personal animosities… \n\nThis information is very sensitive and therefore is restricted to core team members by default') },
        { header: t('1-sh-table-7', 'Relationship with the stakeholder'), text: t('1-sh-g7t', 'E.g., close, trustful, supportive, …\n\nThis information is very sensitive and therefore is restricted to core team members by default') },
    ]

    return (
        <div>
            <GuideForm guideContent={guideText}
                active={guideActive}
                onClose={() => setGuideActive(!guideActive)} 
                defaultOpen={false}/>
            <FormContainer title={text.headline}
                width="lg:w-full w-full"
                guideWidth="w-3/5 md:w-2/3 xl:w-3/4 2xl:w-5/6"
                openGuide={() => setGuideActive(!guideActive)}
                guide={true} onGuideActive={guideActive}>
                <div>
                    <Tooltip content={text.tooltip} breakpoint="none" />
                    <LabelWithSmarttips id="stakeholdeTips" title={text.label} tips={text.stakeholderTips} />
                    <StakeholdersTable editActive={true}/>
                </div>
            </FormContainer>
        </div>
    );
}

export default StakeholdersForm;
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CardGrid from "components/CardGrid";
import Card from "components/Card";
import FormContainer from "components/FormContainer";
import Button from "components/Button";

import Modal from "components/Modal";
import GuideForm from "components/GuideForm";

import ImpactVsImplementation from '../Images/svg/ImpactVsEOI.svg';
import EaseOfImplementationImg from '../Images/EaseOfImplementation.png'

// TODO Translation
// TODO Translation Images
function Step6RateImplementation() {
    const { t } = useTranslation();
    const [guideActive, setGuideActive] = useState(false);
    const text = {
        pageTitle: t('Rate the ease of implementation of your factors'),
        card1Title: t('Ease of implementation'),
        card1Text: t(`After having rated the impact, do now evaluate the ease of implementation - estimate how easily the factor can be used to contribute to the resolution of your problem`),
        card2Title: t('Reasoning'),
        card2Text: t(`Avoid later focusing on topics that you can cannot influence with reasonable effort. In addition, this helps to get a firmer grasp of the dimensions affecting the problem`),
        card3Title: t('Potential dimensions of EoI'),
        card3Text: t(`Think along different dimensions including technical, regulatory and juridical, budgetary, stakeholder-related, political, reputational, and other obstacles. Think about the constraints you set as well`),
    };
    const guideText = [
        {
            header: t('Ease of implementation'),
            text: t(`•	EiO answers the question: “How easy is the successful use of the factor to contribute to the resolution of your problem, as specified in your Key Results?”
            \n•	Firstly, can you influence the factor directly or indirectly? If not, then implementation could be close to impossible
            \n•	Can you also address it, or are you prohibited from doing so, e.g., for formal reasons?
            \n•	If you can influence and address the factor, then think about technical, juridical, budgetary, stakeholder-related, political, reputational, and other obstacles. Think about the constraints you defined. Consequently, a factor infringing a hard constraint should carry an “impossible” EoI label 
            \n•	An example of “very high” might be: Change a rule you are entitled to change that gets in effect immediately without sparking any substantial resistance, e.g., “do not stay in five star superior hotels when travelling”
            \n•	An example of “very low” might be: Replace all IT core systems within one year
            `),
            linkText: t('Ease of implementation vs. impact →'),
            link: ImpactVsImplementation,
        },
    ];
    const cardPadding = 'px-2 py-1';
    // Download Buttons for Modals
    const modalButton1 = <a id="a-DownloadEoi" href={EaseOfImplementationImg} download='EaseOfImplementation'>
        <Button>{t('Download')}</Button></a>

    return (
        <div id="rateImplementation">
            <GuideForm guideContent={guideText} active={guideActive} defaultOpen={false}
                onClose={() => setGuideActive(!guideActive)}
            />
            <Modal id='easeOfImpl' title={text.card1Title} size='modal-lg modal-dialog-centered modal-dialog'
                buttons={modalButton1}>
                <img src={EaseOfImplementationImg} />
            </Modal>
            <FormContainer
                title={text.pageTitle}
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                width="lg:w-2/3 w-5/6">
                <CardGrid>
                    <Card title={text.card1Title} text={text.card1Text} justify='justify-top'
                        databstoggle='modal' databstarget='#easeOfImpl'>
                        <img src={EaseOfImplementationImg} />
                    </Card>
                    <CardGrid gridCol="grid-cols-1">
                        <Card title={text.card2Title} text={text.card2Text} justify='justify-top'></Card>
                        <Card title={text.card3Title} text={text.card3Text} justify='justify-top'></Card>
                    </CardGrid>
                </CardGrid>
            </FormContainer>
        </div>
    );
}

export default Step6RateImplementation;
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import HorizontalDotMenu from 'components/HorizontalDotMenu';
import commentMoods from './CommentMoods';
import CircularAvatarWithInitials from 'components/avatar/CircularAvatarWithInitials';
import SideMenu from 'components/SideMenu';
import SideMenuButton from 'components/SideMenuButton';
import CommentInput from './CommentInput';
import { useTranslation } from "react-i18next";
import useRelativeTime from 'hooks/useRelativeTime';
import Blockquote from 'components/Blockquote';
import { CommentContext } from './context/CommentContext';

/**
 * Wrapper for Comments, handles edit & reply to
 */
function SingleCommentStructure({ comment, index, replyToObj }) {
    const { t } = useTranslation(['common']);
    const { profile, deleteComment } = useContext(CommentContext)
    const [menuActive, setMenuActive] = useState(false);
    const [editModeActive, setEditModeActive] = useState(false);
    const relativeTime = useRelativeTime(comment.createdTs);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const getMoodIcon = (value) => {
        const mood = commentMoods.find((m) => m.value === value);
        if (mood) {
            return <span
                className={classNames(
                    mood.bgColor,
                    'flex h-6 w-6 items-center justify-center rounded-full'
                )}>
                <mood.icon className="h-4 w-4 flex-shrink-0 text-white" aria-hidden="true" />
            </span>
        } else {
            return null
        }
    }

    const handleOutsideClick = () => {
        setMenuActive(false)
    }

    const handleEditModeActive = () => {
        setEditModeActive(!editModeActive)
        setMenuActive(false)
    }

    // Sets edit mode false after submit
    const handleSubmit = () => {
        setEditModeActive(false)
    }

    const handleDelete = () => {
        deleteComment(comment.id)
        setMenuActive(false)
    }

    return (
        <>
            <div id='singleCommentStructure' key={index} className="relative flex items-start space-x-2">
                <div className="flex-shrink-0">
                    <CircularAvatarWithInitials size="8" >
                        {`${comment.firstName && comment.firstName.charAt(0) || 'N'}${comment.lastName && comment.lastName.charAt(0) || 'N'}`}
                    </CircularAvatarWithInitials>
                </div>
                <div className="relative group min-w-0 flex-1 border rounded-lg bg-white p-2">
                    {/* upper row comment */}
                    <div className='flex gap-2 h-5 w-full pr-6'>
                        {/* Name with link to profile */}
                        <p className="text-black text-sm font-semibold truncate shrink">
                            <Link to={`/profiles/${comment.userId}`}>
                                {comment.firstName || 'null'}&nbsp;{comment.lastName || 'null'}
                            </Link>
                        </p>
                        <div className='flex flex-row justify-between float-right'>
                            <div className="text-gray-600 text-xs leading-[20px]">
                                {/* date last edited */}
                                <span className='truncate'>{relativeTime}</span>
                                {/* edited/deleted tag */}
                                {comment.edited &&
                                    <span className='border rounded border-gray-300 text-sm ml-2 bg-gray-100 px-1'>
                                        {t('edited')}
                                    </span>}
                                {comment.deleted &&
                                    <span className='border rounded border-gray-300 text-sm ml-2 bg-gray-100 px-1'>
                                        {t('deleted')}
                                    </span>}
                            </div>
                        </div>
                    </div>
                    {/* Reply to... */}
                    <span>
                        {replyToObj &&
                            <div className='my-1'>
                                <Blockquote replyToObj={replyToObj} />
                            </div>
                        }
                    </span>
                    {/* Displayed text / 'comment deleted' */}
                    <p className={`${comment.deleted && 'italic text-gray-500'} pt-1 sm:text-sm overflow-hidden w-[90%]`}>
                        {comment.deleted ? t('comdel', 'Comment was deleted') : comment.text}</p>
                    {/* EDIT MODE */}
                    {editModeActive &&
                        <CommentInput
                            id={comment.id}
                            handleSubmit={handleSubmit}
                            commentValue={comment.text}
                            currentMood={comment.mood} />
                    }
                    {/* TODO editable for admin*/}
                    {/* Menu */}
                    {profile.userId === comment.userId &&
                        <span className={`absolute right-0 top-[8px]`}>
                            <HorizontalDotMenu
                                onClick={() => setMenuActive(!menuActive)} />
                        </span>}
                    {/* Mood Icon */}
                    {(comment.mood && !editModeActive) && <span className='absolute right-1 bottom-1'>{getMoodIcon(comment.mood)}</span>}
                </div>

                {/* Menu-Items */}
                {menuActive &&
                    <SideMenu
                        onOutsideClick={handleOutsideClick}
                        position={'top-[4px] right-10'} >
                        <SideMenuButton onClick={handleEditModeActive}>{t('Edit')}</SideMenuButton>
                        <SideMenuButton onClick={handleDelete}>{t('Delete')}</SideMenuButton>
                    </SideMenu>
                }
            </div>
        </>
    );
}

export default SingleCommentStructure;